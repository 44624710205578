import React from "react";
import { useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import SearchIcon from "../../assets/images/searchIcon.svg";

function Search({ onSearch,search }) {
  return (
    <InputGroup className="mb-3">
      <img src={SearchIcon} alt="Search Icon" />
      <Form.Control
        placeholder="Search"
        aria-label="Search"
        value={search}
        onChange={onSearch}
        aria-describedby="basic-addon1"
      />
    </InputGroup>
  );
}

export default Search;
