import React, { useEffect, useRef } from "react";
import { Col, Container, Row, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import CollaborationTeamCard from "../../Components/CollaborationTeamCard";
import GoogleCalendarCard from "../../Components/GoogleCard";
import Header from "../../Components/Header";
import ProductivityCard from "../../Components/ProductivityCard";
import ProfileCard from "../../Components/ProfileCard";
import SalesforceCard from "../../Components/SalesforceCard";

const HomeScreen = () => {
	const { data } = useSelector((state) => state.getSFData);
	const auth = useSelector((state) => state.Auth);
	const location = useLocation();
	const dashbaordName = location?.state?.dashboardName;
	const dashboardId = location?.state?.dashboardId;
	const dashboard = useSelector((state) => state.dashboardReducer);
	const dashSettings = dashboard?.getDashboardSettingsData;
	const prod_componet_pinned = dashSettings && dashSettings?.length > 0 ? dashSettings[0].prod_componet_pinned === 1 : true;
	const collab_component_pinned = dashSettings && dashSettings?.length > 0 ? dashSettings[0].collab_component_pinned === 1 : true;
	const google_component_pinned =
		localStorage.getItem("googleAuthAccessToken") !== null ? (dashSettings && dashSettings?.length > 0 ? dashSettings[0].google_component_pinned : true) : false;
	const sf_component_pinned = data?.length ? (dashSettings && dashSettings?.length > 0 ? dashSettings[0].sf_component_pinned : true) : false;

	const [pinnedProd, setPinnedProd] = React.useState(prod_componet_pinned); // If Productivity component is pinned
	const [refereshTaskList, setRefereshTaskList] = React.useState(false);
	const [pinnedCollab, setPinnedCollab] = React.useState(collab_component_pinned); // If Collaboration component is pinned

	/** 3DPL */
	const [pinnedGoogle, setPinnedGoogle] = React.useState(google_component_pinned); // If Google component is pinned
	const [pinnedSaleForce, setPinnedSaleForce] = React.useState(sf_component_pinned); // If Saleforce component is pinned

	const isPinnedProd = (pinnedProd) => {
		setPinnedProd(pinnedProd);
	};
	const isPinnedCollab = (pinnedCollab) => {
		setPinnedCollab(pinnedCollab);
	};
	const isPinnedGoogle = (pinnedG) => {
		setPinnedGoogle(pinnedG);
	};
	const isPinnedSalesForce = (pinnedSF) => {
		setPinnedSaleForce(pinnedSF);
	};

	const setExpanded = (expanded) => {
		setPinnedCollab(!expanded);
		setPinnedProd(!expanded);
	};

	const childRef = useRef(null);
	const handleDragDrop = (event) => {
		if (childRef.current != undefined && childRef.current.handleDragDrop != undefined) {
			childRef.current.handleDragDrop(event);
		}
	};

	const refreshList = (event) => {
		setRefereshTaskList(new Date().getTime());
	};
	console.log("QQQ", "Google", pinnedGoogle, "SF", pinnedSaleForce, "Prod", pinnedProd, "Collab", pinnedCollab);
	return (
		<Container>
			<div
				style={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "space-between",
					margin: "30px 0",
					alignItems: "center",
				}}
			>
				<Header
					dashboard={true}
					dashboardId={dashboardId}
					pinnedProdHook={isPinnedProd}
					hideProdBtn={pinnedProd}
					pinnedCollabHook={isPinnedCollab}
					hideCollabBtn={pinnedCollab}
					pinnedGoogleHook={isPinnedGoogle}
					hideGoogleBtn={pinnedGoogle}
					pinnedSaleForceHook={isPinnedSalesForce}
					hideSaleForceBtn={pinnedSaleForce}
				/>

				<ProfileCard path="/profile" userData={auth?.profileData} bgNone={"transparent"} textAlign={"center"} />
			</div>

			<Row className="g-3">
				<Col lg={pinnedProd ? 8 : 12}>
					<Row className="g-3">
						<Col lg={pinnedGoogle ? (pinnedProd ? 5 : 6) : 12} hidden={!pinnedSaleForce}>
							<SalesforceCard
								pinnedCollab={pinnedCollab}
								pinnedProd={pinnedProd}
								expanded={setExpanded}
								dashboardId={dashboardId}
								isPinnedSalesForce={isPinnedSalesForce}
							/>
						</Col>
						<Col lg={pinnedSaleForce ? (pinnedProd ? 7 : 6) : 12} hidden={!pinnedGoogle}>
							<GoogleCalendarCard
								pinnedCollab={pinnedCollab}
								pinnedProd={pinnedProd}
								expanded={setExpanded}
								dashboardId={dashboardId}
								isPinnedGoogle={isPinnedGoogle}
							/>
						</Col>
						<Col lg={12} hidden={!pinnedCollab}>
							<CollaborationTeamCard ref={childRef} notPinned={pinnedSaleForce || pinnedGoogle} refreshList={refreshList} pinned={isPinnedCollab} />
						</Col>
					</Row>
				</Col>
				<Col lg={pinnedCollab || pinnedGoogle || pinnedSaleForce ? (pinnedProd ? 4 : 0) : 12} hidden={!pinnedProd}>
					<ProductivityCard handleDragDrop={handleDragDrop} refereshTaskList={refereshTaskList} pinned={isPinnedProd} />
				</Col>
			</Row>
		</Container>
	);
};

export default HomeScreen;
