import React, { useEffect, useState } from "react";
import { Button, Col, Nav, Row, Tab } from "react-bootstrap";
import bellIcon from "../../../../assets/images/bell-icon.png";
import addIcon from "../../../../assets/images/add.png";
import minus from "../../../../assets/images/minus.svg";
import avatarIcon from "../../../../assets/images/defaultAvatar.png";
import dropdownIcon from "../../../../assets/images/dropdown-icon.png";
import profile1 from "../../../../assets/images/profile-1.png";
import profile2 from "../../../../assets/images/profile-3.png";
import profile3 from "../../../../assets/images/profile-4.png";
import Delete from "../../../../assets/images/delete.png";

import DatePicker from "react-datepicker";
import axios from "axios";
import {
  UpdatedToast,
  SelectedToast,
  SimpleToast,
  DeleteToast,
} from "../../../../utils/Toast";
import axiosApiInstance from "../../../../utils/axiosInterceptor";
import { logExternalIntegrationTask } from "../../../../redux/actions/TasksAction";
import { useDispatch, useSelector } from "react-redux";
import moment from 'moment';

function GoogleCalendarTabs({ setGoogleAuth, onSuccess,updateData,setUpdateData,isNewData,setIsNewData, dashboardId }) {
  const [getTitle, setGetTitle] = useState();
  const [titleError,setTitleError] = useState(false)
  const [usersRecurrenceKey, setUsersRecurrenceKey] = useState();
  const [getDesc, setGetDesc] = useState("Add Description");
  const [usersRecurrence, setUsersRecurrence] = useState({
    key: "",
    value: "Does not repeat",
  });
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [notification, setNotification] = useState("0");
  const [isLoading, setIsLoading] = useState(false);
  const [attendees, setAttendees] = useState(false);
  const [userData, setUserData] = useState([]);
  const [data, setData] = useState([]);

const getAllTheSameDaysDateOfMonth = () =>{
  const monthDate = (updateData?.start?.dateTime && new Date(updateData?.start?.dateTime)) ||
    new Date()
  const year = monthDate?.getFullYear() || new Date().getFullYear(); 
  const month = monthDate?.getMonth() || new Date().getMonth(); 
  const dayOfWeek = monthDate?.getDay()  || new Date().getDay()
  const lastDay = new Date(year, month + 1, 0).getDate();
  const dateToday = monthDate?.getDate() || new Date().getDate()
  const arr =[]

  // Loop through all the days in the month
  for (let day =1; day <=  lastDay; day++) {
    const date = new Date(year, month, day);
    // Check if the day is a Monday
    if (date.getDay() === dayOfWeek) {
      arr.push(date.getDate())
    }
  }
  const dayCountNum = arr.reduce((acc,curr,index) =>{
    // console.log(dateToday,arr,lastDay, monthDate?.getDay() ,'my datetoday')
    if(curr === dateToday){
      acc = index + 1
      return acc
    }
    return acc
  },0)
  const dayName = moment(monthDate || new Date()).format("dddd")
  let dayCount;
    if(dayCountNum === 1) dayCount = 'first' 
    if(dayCountNum === 2) dayCount = 'second' 
    if(dayCountNum === 3) dayCount = 'third' 
    if(dayCountNum === 4) dayCount = 'fourth' 
    if(dayCountNum === 5) dayCount = 'fifth' 
  return {dayCount,dayName,dayCountNum}
}

  const dispatch = useDispatch();
  const auth = useSelector((state) => state.Auth);

  useEffect(() => {
    setStartDate(
      (updateData?.start?.dateTime && new Date(updateData?.start?.dateTime)) ||
        new Date()
    );
    setEndDate(
      (updateData?.end?.dateTime && new Date(updateData?.end?.dateTime)) ||
        new Date()
    );
    setGetTitle(updateData?.summary);
    setGetDesc(updateData.description || "Add Description");
    setNotification(
      ((updateData?.reminders?.overrides || []).length > 0 &&
        updateData?.reminders?.overrides[0]?.minutes) ||
        0
    );
    setData(
      ((updateData?.attendees || []).length > 0 &&
        updateData.attendees.map((i) => ({ ...i, key: false }))) ||
        []
    );
    setUserData(
      ((updateData?.attendees || []).length > 0 &&
        updateData.attendees.map((i) => ({ ...i, key: false }))) ||
        []
    );
    setUsersRecurrenceKey(updateData?.recurrence || []);
    const printUserRecurr = (key) => {
      if ((updateData?.recurrence || []).length > 0) {
        const str = updateData?.recurrence[0];
    const mydate = (updateData?.start?.dateTime && new Date(updateData?.start?.dateTime)) ||
    new Date()
        const newObj ={
        "my key": "Does not repeat",
        "RRULE:FREQ=DAILY": "Daily",
        [`RRULE:FREQ=WEEKLY;BYDAY=${getAllTheSameDaysDateOfMonth().dayName.slice(0,2).toUpperCase()}`]: `Weekly on ${getAllTheSameDaysDateOfMonth().dayName}`,
        [`RRULE:FREQ=MONTHLY;BYDAY=${getAllTheSameDaysDateOfMonth().dayCountNum}${getAllTheSameDaysDateOfMonth().dayName.slice(0,2).toUpperCase()}`]: `Monthly on the ${getAllTheSameDaysDateOfMonth().dayCount} ${getAllTheSameDaysDateOfMonth().dayName} `,
        "RRULE:FREQ=YEARLY": `Annually on ${moment(mydate).format('MMMM DD')}`,
        "RRULE:FREQ=WEEKLY;BYDAY=MO,TU,WE,TH,FR":
          "Every weekday (Monday to Friday)",
      }
        let result
        if(Object.keys(newObj).includes(str)) result = newObj[str]
        else result ="Does not repeat"
        return result;
      } else {
        return key;
      }
    };
    setUsersRecurrence({
      value: printUserRecurr(),
    });

   
  }, [updateData]);
  
  


  const openAddDescModalHandle = () => {
    UpdatedToast({
      text: ` Add Description.`,
      iconName: "info",
      iconColor: "#add8e6",
      confirmBtnText: "Confirm",
      inputPlaceholder:"Enter Description",
      preConfirm: (value) => setGetDesc(value),
      input: "textarea",
      inputValue:updateData?.description ?? ""
    });
  };
  
 
  const openDoseNotRepeatModalHandle = () => {
    
    SelectedToast({
      text: ` Select Options`,
      iconName: "info",
      iconColor: "#add8e6",
      confirmBtnText: "Confirm",
      inputOptions: {
        "my key": "Does not repeat",
        "RRULE:FREQ=DAILY": "Daily",
        [`RRULE:FREQ=WEEKLY;BYDAY=${getAllTheSameDaysDateOfMonth().dayName.slice(0,2).toUpperCase()}`]: `Weekly on ${getAllTheSameDaysDateOfMonth().dayName}`,
        [`RRULE:FREQ=MONTHLY;BYDAY=${getAllTheSameDaysDateOfMonth().dayCountNum}${getAllTheSameDaysDateOfMonth().dayName.slice(0,2).toUpperCase()}`]: `Monthly on the ${getAllTheSameDaysDateOfMonth().dayCount} ${getAllTheSameDaysDateOfMonth().dayName} `,
        "RRULE:FREQ=YEARLY": `Annually on ${moment(startDate).format('MMMM DD')}`,
        "RRULE:FREQ=WEEKLY;BYDAY=MO,TU,WE,TH,FR":
          "Every weekday (Monday to Friday)",
      },
      // inputPlaceholder: "Select Options For Recurrence",
      preConfirm: ({ key, value }) => {
        console.log(value,'my valeu')
        if(value === 'Does not repeat') {
          setUsersRecurrence({ key:"my key", value:'Does not repeat' });
          setUsersRecurrenceKey([""])
          return
        } 
        setUsersRecurrence({ key, value });
        setUsersRecurrenceKey([key])
      },
      input: "select",
      inputValue:updateData?.recurrence ? usersRecurrenceKey :"my key"
    });
  };

  const openUpdateNotificaltionModalHandle = () => {
    UpdatedToast({
      text: `Set Reminder`,
      iconName: "info",
      iconColor: "#add8e6",
      confirmBtnText: "Confirm",
      inputValue: notification,
      preConfirm: (value) => {
        setNotification(value);
      },
      input: "text",
    });
  };

  const AddGoogleCalendarData = async () => {
    console.log(getTitle,'my title')
    if(getTitle === "" || !getTitle) {
      setTitleError(true)
      return
    }
    setTitleError(false)
    const event = {
      summary: getTitle,
      description: getDesc,
      start: {
        dateTime: startDate.toISOString(),
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
      end: {
        dateTime: endDate.toISOString(),
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
      reminders: {
        useDefault: false,
        overrides: [
          { method: "popup", minutes: notification ? notification : "30" },
        ],
      },
      recurrence: [usersRecurrence.key || []],
      attendees: [
        ...(userData.map((email) => {
          return email 
        }) || []),
      ],
    };
    console.log(event,'my event')
    setIsLoading(true);
    await axiosApiInstance
      .post(
        "https://www.googleapis.com/calendar/v3/calendars/primary/events",
        event
      )
      .then(async (res) => {
        let attendees = [];
        res?.data?.attendees?.forEach(element => {
          attendees.push(element.email);
        });
        const eventData = {
          "user_id": auth?.profileData.id,
          "dashboard_id": dashboardId,
          "unique_id": res.data.htmlLink,
          "description": `${auth?.profileData.first_name} created 1 meeting.`,
          "integration_platform": "Google Workspace",
          "status": "Completed",
          "action_type": "Create Meeting",
          "created_datetime": moment().format(),
          "gw_task_recipients": JSON.stringify(attendees)
        }
        const resp = await dispatch(logExternalIntegrationTask(eventData));
        console.log(resp)

        setIsLoading(false);

        SimpleToast({
          title: "Success",
          text: "Event successfully created.",
          iconName: "success",
          iconColor: "green",
        });
      })
      .catch((err) => {
        setIsLoading(false);
        SimpleToast({
          title: "Error",
          text: `${err.message}`,
          iconName: "error",
          iconColor: "#d33",
        });
      });
  };


  const UpdateGoogleCalendarData = async () => {
    const UpdateBody = {
      summary: getTitle,
      description: getDesc,
      start: {
        dateTime: startDate.toISOString(),
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
      end: {
        dateTime: endDate.toISOString(),
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
      reminders: {
        useDefault: false,
        overrides: [{ method: "popup", minutes: notification }],
      },
      recurrence: [`${usersRecurrenceKey}` || []],
      attendees: (userData.map((email) => {
        console.log(email,'my attendees')
          return email ;
        }) || []),
      
    };
    console.log(usersRecurrenceKey,UpdateBody,'UpdateBody')
    // return
    setIsLoading(true);
    await axiosApiInstance
      .put(
        `https://www.googleapis.com/calendar/v3/calendars/primary/events/${updateData.id}`,
        UpdateBody
        // {
        //   headers: {
        //     Authorization: "Bearer " + session?.provider_token,
        //   },
        // }
      )
      .then((res) => {
        setIsLoading(false);
        onSuccess();
        SimpleToast({
          title: "Success",
          text: "Information successfully added.",
          iconName: "success",
          iconColor: "green",
        });
      })

      .catch((err) => {
        setIsLoading(false);
        SimpleToast({
          title: "Error",
          text: `${err.message}`,
          iconName: "error",
          iconColor: "#d33",
        });
      });
  };
  const DeleteGoogleCalendarData = async () => {
    setIsLoading(true);
    await axiosApiInstance
      .delete(
        `https://www.googleapis.com/calendar/v3/calendars/primary/events/${updateData.id}`
        // {
        //   headers: {
        //     Authorization: "Bearer " + session?.provider_token,
        //   },
        // }
      )
      .then((res) => {
        setIsLoading(false);
        onSuccess();
      })

      .catch((err) => {
        setIsLoading(false);
        SimpleToast({
          title: "Error",
          text: `${err.message}`,
          iconName: "error",
          iconColor: "#d33",
        });
      });
  };
  console.log(updateData,'updateData')
  const deleteModalHandle = () => {
    DeleteToast({
      title: "Confirmation",
      text: `Do you want to delete this event?`,
      iconName: "warning",
      iconColor: "red",
      onSubmit: () => {
        DeleteGoogleCalendarData();
        setGoogleAuth(false);
      },
      input: "select",
    });
  };

  return (
    <div className="google-calendar-tabs blocktabs h-100">
      <Button
        variant="none"
        className="poppins"
        onClick={() => {
          setGoogleAuth(false);
          onSuccess();
        }}
        style={{
          backgroundColor: "var(--secondry-color)",
          borderRadius: 10
        }}
      >
        <p style={{ color: "#FFF", margin: 0, padding: 0 }}>Google Calendar</p>
      </Button>
      <Tab.Container id="left-tabs-example" defaultActiveKey="Event">
        <Row className={`mt-2 ${!isNewData && 'justify-content-center'}`}>
          { !!isNewData && <Col
            lg={3}
            className="d-flex justify-content-start align-items-center mt-2"
          >
            <p
              style={{
                color: "grey",
                padding: 0,
                margin: 0,
                fontSize: 14,
                marginRight:10
              }}
            >
              Today
            </p>
            <div
              style={{
                cursor: "pointer",
              }}
            >
              <img
                src={dropdownIcon}
                style={{ width: 20, height: 20, objectFit: "contain" }}
                alt={"add-icon"}
              />
            </div>
          </Col>}
          <Col
            lg={8}
            className={`calendar-grid d-flex justify-content-center align-items-center mt-2 ${!isNewData && 'ml-5'}`}
          >
            <Nav variant="pills" className="d-flex justify-content-between" style={{marginLeft:'-5rem'}}>
              {["Event"].map((i, index) => (
                <Nav.Item key={index}>
                  <Nav.Link style={{ backgroundColor: "var(--secondry-color)" }} eventKey={i}
                    >{i}</Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
          </Col>
        </Row>

        <Tab.Content>
          {["Event", "Task", "Reminder"].map((i, indx) => (
            <Tab.Pane eventKey={i} key={indx}>
              <div className="d-flex justify-content-between flex-column h-100 gap-3">
                <div className="mt-3">
                  <input
                    placeholder="Title"
                    value={getTitle}
                    onChange={(e) => {
                      if(e.target.value === ""){
                        setTitleError(true)
                      }
                      else setTitleError(false)
                      setGetTitle(e.target.value)}
                    } 
                    style={{
                      outline: "none",
                      borderWidth: "0 0 2px",
                      borderColor: "#FFF",
                      padding: "5px 10px 20px 10px",
                      background: "none",
                      borderRadius: 0,
                      color: "#FFF",
                      textAlign: "center",
                      fontSize: 20,
                      height: 40
                    }}
                  />
                  {titleError && <p className="text-center mt-2 text-white" >Title is required!</p>}
                { !isNewData &&  <img
                className="position-absolute top-0 end-0 translate-middle p-2"
                onClick={deleteModalHandle}
                style={{
                  width: 50,
                  height: 50,
                  objectFit: "contain",
                  marginRight: "0px",
                  marginTop: "40px",
                  cursor: "pointer",
                }}
                alt={"delete-icon"}
                src={Delete}
              />}
                </div>

                <Row className="d-flex mt-3 justify-content-between">
                  <Col lg={6} md={6} sm={6}>
                    <div className="pick-style d-flex flex-column gap-1">
                      <p style={{ margin: 0, padding: 0, color: "grey" }}>
                        Start Date Time
                      </p>
                      <DatePicker
                        selected={startDate}
                        className="date-picker-style inputStyle"
                        onChange={(date) => setStartDate(date)}
                        timeInputLabel="Time:"
                        dateFormat="d/MMM/yyyy h:mm aa"
                        showTimeInput
                      />
                      <p style={{ margin: 0, padding: 0, color: "grey" }}>
                        End Date Time
                      </p>
                      <DatePicker
                        selected={endDate}
                        className="date-picker-style inputStyle"
                        onChange={(date) => setEndDate(date)}
                        timeInputLabel="Time:"
                        dateFormat="d/MMM/yyyy h:mm aa"
                        showTimeInput
                      />
                      <div className="d-flex align-items-center">
                        <p
                          style={{
                            margin: 0,
                            padding: 0,
                            color: "grey",
                            cursor: "pointer",
                          }}
                          onClick={openDoseNotRepeatModalHandle}
                        >
                          {usersRecurrence.value ?? "Does not repeat"}
                        </p>

                        <div
                          style={{
                            cursor: "pointer",
                            marginLeft: 10,
                          }}
                          onClick={openDoseNotRepeatModalHandle}
                        >
                          <img
                            src={dropdownIcon}
                            style={{
                              width: 20,
                              height: 20,
                              objectFit: "contain",
                            }}
                            alt={"add-icon"}
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col lg={5} md={5} sm={5}>
                    <div className="desc-dots d-flex flex-column align-items-end gap-3">
                      <div
                          className="description d-flex align-items-center"
                        >
                          <p
                            style={{
                              margin: 0,
                              padding: 0,
                              color: "white",
                              cursor: "pointer"
                            }}
                            onClick={openAddDescModalHandle}
                          >
                            {getDesc}
                          </p>
                          {/* <div style={{color:"white"}} dangerouslySetInnerHTML={{ __html: getDesc }} /> */}
                      </div>

                      <div
                        className="minute-time-picker d-flex align-items-center my-2"
                        onClick={openUpdateNotificaltionModalHandle}
                        style={{
                          width: "-webkit-fill-available",
                        }}
                      >
                        <img
                          src={bellIcon}
                          style={{
                            width: 20,
                            height: 20,
                            objectFit: "contain",
                          }}
                          alt={"bell-icon"}
                        />
                        <p
                          style={{
                            margin: 0,
                            color: "grey",
                            fontSize: 16,
                            width: "47px",
                            cursor: "pointer",
                            textAlign: "center",
                          }}
                        >
                          {notification}
                        </p>
                        <p
                          style={{
                            margin: 0,
                            padding: 0,
                            color: "grey",
                            cursor: "pointer",
                            fontSize: 14,
                          }}
                        >
                          minutes before
                        </p>
                      </div>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col lg={6} md={6} sm={6}>
                    <div className="mt-1" style={{ display: "flex" }}>
                      <img
                        src={avatarIcon}
                        style={{
                          width: 25,
                          height: 25,
                          objectFit: "contain",
                        }}
                        alt={"avatar-icon"}
                      />
                      <img
                        src={avatarIcon}
                        style={{
                          width: 25,
                          height: 25,
                          objectFit: "contain",
                        }}
                        alt={"avatar-icon"}
                      />
                      <img
                        src={addIcon}
                        onClick={() =>{ 
                          // setAttendees(!attendees)
                          UpdatedToast({
                            text: `Invite User To Add In Calendar`,
                            iconName: "info",
                            iconColor: "#add8e6",
                            confirmBtnText: "Confirm",
                            inputPlaceholder: "Enter Email",
                            // inputOptions: {
                            //   "dev.faheemlogo@gmail.com": "dev.faheemlogo@gmail.com",
                            //   "cdreher@atsapllc.com": "cdreher@atsapllc.com",
                            // },
                            preConfirm: (value) => {
                              console.log(userData,'my userData')
                              setData([...data,{email:value}])
                              setUserData([...userData,{email:value}])
                            },
                      
                            input: "text",
                          });
                        }}
                        style={{
                          width: 25,
                          height: 25,
                          objectFit: "contain",
                          marginLeft: 5,
                          cursor: "pointer",
                        }}
                        alt={"add-icon"}
                      />
                    </div>
                  </Col>
                </Row>

                  <Col lg={12} md={12} sm={12}>
                    <div className="">
                      {data?.map((i, index) => (
                        <div
                          className="user-modal d-flex gap-2 align-items-center my-2 justify-content-start"
                          key={index}
                        >
                          {/* <img
                            style={{
                              width: 30,
                              height: 30,
                              objectFit: "contain",
                              marginRight: 6,
                            }}
                            src={i.image}
                            alt={i.email}
                          /> */}
                          <h2
                            style={{
                              width: "80%",
                              color: "#fff",
                              fontSize: 15,
                              fontWeight: 400,
                              // textTransform: "capitalize",
                            }}
                          >
                            {i.email}
                          </h2>
                          <img
                            src={minus}
                            alt="add-icon"
                            style={{
                              width: 25,
                              height: 25,
                              objectFit: "contain",
                              cursor: "pointer",
                              marginRight: 10,
                            }}
                            onClick={() => {
                              const { email } = i;
                              let emails = [...userData];
                              console.log(emails,'my emails')
                              // const emailexist = emails.find(item => item.email === email)
                              // const emailexist = emails.includes(email);
                              // if (!emailexist) {
                              //   console.log(emailexist,'emailexist')
                              //   emails.push({email});
                              // } else {
                                emails = emails.filter((eMail) => {
                                  console.log(eMail,email,'my eaksjflsjdf')
                                  return eMail.email !== email;
                                });
                              // }
                              console.log(emails,'my emailss')
                              setUserData(emails);
                              setData(emails)
                              // const newData = [...data];
                              // newData[index]["key"] = !i.key;
                              // setData(newData);
                            }}
                          /> 
                        </div>
                      ))}
                    </div>
                  </Col>
              
                <Row>
                  <Col className="d-flex justify-content-center mt-3 mb-3">
                    <Button
                      size="md"
                      className="poppins"
                      variant="none"
                      disabled={isLoading}
                      style={{
                        backgroundColor: "var(--white-color)",
                        borderRadius: 100,
                        padding: "10px 20px",
                      }}
                    >
                      <p
                        style={{
                          margin: 0,
                          padding: 0,
                          color: "var(--secondry-color)",
                          borderRadius: 10,
                          fontWeight: "bold",
                          width: "225px",
                        }}
                        onClick={ !isNewData ? UpdateGoogleCalendarData : AddGoogleCalendarData}
                      >
                        {isLoading ? (
                          <div
                            className="spinner-border spinner-border-sm text-primary"
                            role="status"
                          />
                        ) : (
                         !isNewData ? "Update Google Calendar" : "Save To Google Calendar"
                        )}
                      </p>
                    </Button>
                  </Col>
                </Row>
              </div>
            </Tab.Pane>
          ))}
        </Tab.Content>
      </Tab.Container>
    </div>
  );
}

export default GoogleCalendarTabs;
