import axios from "axios";
import { useFormik } from "formik";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import leadsIcon from "../assets/images/leads-icon.png";
import {
  salesforceLeadsIndustry,
  salesforceLeadsSources,
} from "../utils/constant";
import ArrowSelect from "./ArrowSelect";
import Input from "./Input";
import { logExternalIntegrationTask } from "../redux/actions/TasksAction";
import moment from "moment";
import { SimpleToast } from "../utils/Toast";

axios.defaults.headers.post["Content-Type"] = "application/json";

const EditLead = ({ dashboardId, leadId, isCreateMode = false }) => {
  const SFData = useSelector((state) => state.getSFData);
  const [lead, setLead] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({
    leadId: leadId || "",
    isCreateMode: isCreateMode || false,
  });
  const baseUrl = useMemo(
    () => SFData?.getSFData?.data?.[0]?.url,
    [SFData?.getSFData?.data]
  );
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.Auth);
  const [salesforceLeadsStatus, setLeadsStatuses] = useState([]);
  const [salesforceLeadsRatings, setLeadsRatings] = useState([]);

  const fetchSFLeadStatusRating = async () => {
    const salesforceLoginAccessToken = localStorage?.getItem(
      "salesforceAccessToken"
    );
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${salesforceLoginAccessToken}`;
    try {
      let response = await axios.get(
        `${baseUrl}/services/data/v57.0/sobjects/Lead/describe`
      );
      let statuses = response.data.fields.filter(val => val.label === "Status");
      let rating = response.data.fields.filter(val => val.label === "Rating");

      let values;
      if (statuses?.length > 0) {
        values = [];
        statuses[0].picklistValues.forEach(val => {
          if(!!val.active){
            values.push(val.label);
          }
        });
        setLeadsStatuses(values);
      }
      if (rating?.length > 0) {
        values = [];
        rating[0].picklistValues.forEach(val => {
          if(!!val.active){
            values.push(val.label);
          }
        });
        setLeadsRatings(values);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getLeadById = useCallback(async () => {
    const salesforceLoginAccessToken = localStorage?.getItem(
      "salesforceAccessToken"
    );
    try {
      const response = await axios.get(
        `${baseUrl}/services/data/v57.0/sobjects/Lead/${state.leadId}`,
        {
          headers: {
            Authorization: `Bearer ${salesforceLoginAccessToken}`,
          },
        }
      );

      if (response.data) {
        setLead(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  }, [baseUrl, state.leadId]);

  useEffect(() => {
    if (!state.isCreateMode) {
      if (state.leadId) {
        getLeadById();
      }
    }
    fetchSFLeadStatusRating();
  }, [state.leadId, getLeadById, state.isCreateMode]);

  const checkEvents = (payload) => {
    const events = [];
    const eventData = {
      unique_id: `${baseUrl}/${lead.Id}`,
      user_id: auth?.profileData.id,
      dashboard_id: dashboardId,
      description: `${auth.profileData.first_name} changed 1 Lead name`,
      integration_platform: "Salesforce",
      status: "Completed",
      action_type: "Lead name changed",
      created_datetime: moment().format(),
    };
    if (
      payload.FirstName !== lead.FirstName ||
      payload.LastName !== lead.LastName
    ) {
      events.push({ ...eventData });
    }
    if (payload.Status !== lead.Status) {
      events.push({
        ...eventData,
        description: `${auth.profileData.first_name} changed Lead status to ${payload.Status}`,
        action_type: "Change Lead Status",
      });
    }
    if (payload.Rating !== lead.Rating) {
      events.push({
        ...eventData,
        description: `${auth.profileData.first_name} changed Lead rating to ${payload.Rating}`,
        action_type: "Change Lead rating",
      });
    }
    if (payload.Status === "Qualified") {
      events.push({
        ...eventData,
        description: `${auth.profileData.first_name} qualified/converted Lead`,
        action_type: "Qualified Lead",
      });
    }

    return events;
  };

  const updateLead = async (payload) => {
    const salesforceLoginAccessToken = localStorage?.getItem(
      "salesforceAccessToken"
    );
    try {
      const response = await axios.patch(
        `${baseUrl}/services/data/v57.0/sobjects/Lead/${state.leadId}`,
        payload,

        {
          headers: {
            "Access-Control-Allow-Methods": "PATCH",
            Authorization: `Bearer ${salesforceLoginAccessToken}`,
            "Access-Control-Allow-Headers": "*",
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      if (response.status >= 200 && response.status <= 300) {
        SimpleToast({
          title: "Success",
          text: "Lead updated successfully.",
          iconName: "success",
          iconColor: "green",
        });
        const eventData = checkEvents(payload);
        delete axios.defaults.headers.common["Authorization"];

        if (eventData.length !== 0) {
          eventData.forEach(async (event) => {
            await dispatch(logExternalIntegrationTask(event));
          });
        }
        getLeadById();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const createLead = async (payload) => {
    const salesforceLoginAccessToken = localStorage?.getItem(
      "salesforceAccessToken"
    );
    try {
      const response = await axios.post(
        `${baseUrl}/services/data/v57.0/sobjects/Lead`,
        { ...payload, DateLastContacted__c: new Date() },

        {
          headers: {
            "Access-Control-Allow-Methods": "POST",
            Authorization: `Bearer ${salesforceLoginAccessToken}`,
            "Access-Control-Allow-Headers": "*",
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      if (response.data) {
        SimpleToast({
          title: "Success",
          text: "Lead created successfully.",
          iconName: "success",
          iconColor: "green",
        });
        const eventData = {
          unique_id: `${baseUrl}/${response.data.id}`,
          user_id: auth?.profileData.id,
          dashboard_id: dashboardId,
          description: `${auth.profileData.first_name} created 1 Lead`,
          integration_platform: "Salesforce",
          status: "Completed",
          action_type: "Create Lead",
          created_datetime: moment().format(),
        };
        await dispatch(logExternalIntegrationTask(eventData));

        setState({ leadId: response.data.id, isCreateMode: false });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const initialValues = {
    Salutation: lead?.Salutation || "",
    FirstName: lead?.FirstName || "",
    LastName: lead?.LastName || "",
    Company: lead?.Company || "",
    Title: lead?.Title || "",
    Email: lead?.Email || "",
    LeadSource: lead?.LeadSource || "",
    // Advertisement: lead?.Advertisement || "",
    Industry: lead?.Industry || "",
    Website: lead?.Website || "",
    Phone: lead?.Phone || "",
    Status: lead?.Status || "",
    Rating: lead?.Rating || "",
    AnnualRevenue: lead?.AnnualRevenue || "",
    NumberOfEmployees: lead?.NumberOfEmployees || "",
  };

  const validate = (values) => {
    const errors = {};
    if (!values.LastName) {
      errors.LastName = "Required";
    }
    if (!values.Company) {
      errors.Company = "Required";
    }
    if (!values.Title) {
      errors.Title = "Required";
    }

    return errors;
  };

  const handleSubmit = async (e) => {
    const payload = { ...e };

    setIsLoading(true);
    if (state.isCreateMode) {
      await createLead(payload);
    } else {
      await updateLead(payload);
    }
    setIsLoading(false);
  };

  const formik = useFormik({
    initialValues,
    validate,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  const isFormikValid = useMemo(() => {
    return (
      !formik.errors.LastName && !formik.errors.Company && !formik.errors.Title
    );
  }, [formik.errors]);

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            flexDirection: "row",
            display: "flex",
            alignItems: "center",
          }}
        >
          <div
            style={{
              backgroundColor: "var(--bg-color)",
              borderRadius: 7,
              padding: 10,
            }}
          >
            <img
              src={leadsIcon}
              style={{
                width: 20,
                height: 20,
                objectFit: "contain",
              }}
              alt={"lead-icon"}
            />
          </div>
          <div style={{ marginLeft: 10 }}>
            <p
              style={{
                color: "#FFF",
                padding: 0,
                margin: 0,
                fontSize: 12,
              }}
            >
              Leads
            </p>
            <p
              style={{
                color: "grey",
                padding: 0,
                margin: 0,
                fontSize: 12,
              }}
            >
              Owner: Lf
            </p>
          </div>
        </div>
        <div
          style={{
            cursor: "pointer",
            padding: 10,
          }}
        ></div>
      </div>

      <div style={{ marginTop: "2vh" }}>
        <div style={{ display: "flex", flexFlow: "row nowrap" }}>
          <div
            style={{
              display: "flex",
              flexFlow: "column wrap",
              flexBasis: "50%",
            }}
          >
            <p style={{ color: "grey", fontSize: "14px", marginBottom: "0px" }}>
              Salutation
            </p>
            <Input
              value={formik.values.Salutation}
              onChange={(e) =>
                formik.setFieldValue("Salutation", e.target.value)
              }
            />
          </div>
          <div style={{ flexBasis: "50%" }}>
            <p style={{ color: "grey", fontSize: "14px", marginBottom: "0px" }}>
              Phone
            </p>
            <Input
              value={formik.values.Phone}
              onChange={(e) => formik.setFieldValue("Phone", e.target.value)}
            />
          </div>
        </div>

        <div style={{ display: "flex", flexFlow: "row nowrap" }}>
          <div
            style={{
              display: "flex",
              flexFlow: "column wrap",
              flexBasis: "50%",
            }}
          >
            <p style={{ color: "grey", fontSize: "14px", marginBottom: "0px" }}>
              First Name
            </p>
            <Input
              value={formik.values.FirstName}
              onChange={(e) =>
                formik.setFieldValue("FirstName", e.target.value)
              }
            />
          </div>
          <div style={{ flexBasis: "50%" }}>
            <div style={{ display: "flex", alignItems: "center", gap: "2px" }}>
              <p
                style={{ color: "grey", fontSize: "14px", marginBottom: "0px" }}
              >
                Lead Status
              </p>
              <ArrowSelect
                options={salesforceLeadsStatus}
                onChange={(e) => formik.setFieldValue("Status", e.target.value)}
              />
            </div>
            <p
              style={{ color: "white", fontSize: "16px", marginBottom: "0px" }}
            >
              {formik.values.Status}
            </p>
          </div>
        </div>

        <div style={{ display: "flex", flexFlow: "row nowrap" }}>
          <div
            style={{
              display: "flex",
              flexFlow: "column wrap",
              flexBasis: "50%",
            }}
          >
            <p style={{ color: "grey", fontSize: "14px", marginBottom: "0px" }}>
              Last Name *
            </p>
            <Input
              value={formik.values.LastName}
              onChange={(e) => formik.setFieldValue("LastName", e.target.value)}
            />
          </div>
          <div style={{ flexBasis: "50%" }}>
            <div style={{ display: "flex", alignItems: "center", gap: "2px" }}>
              <p
                style={{ color: "grey", fontSize: "14px", marginBottom: "0px" }}
              >
                Rating
              </p>
              <ArrowSelect
                options={salesforceLeadsRatings}
                onChange={(e) => formik.setFieldValue("Rating", e.target.value)}
              />
            </div>
            <p
              style={{ color: "white", fontSize: "16px", marginBottom: "0px" }}
            >
              {formik.values.Rating}
            </p>
          </div>
        </div>

        <div style={{ display: "flex", flexFlow: "row nowrap" }}>
          <div
            style={{
              display: "flex",
              flexFlow: "column wrap",
              flexBasis: "50%",
            }}
          >
            <p style={{ color: "grey", fontSize: "14px", marginBottom: "0px" }}>
              Company *
            </p>
            <Input
              value={formik.values.Company}
              onChange={(e) => formik.setFieldValue("Company", e.target.value)}
            />
          </div>
          <div style={{ flexBasis: "50%" }}>
            <p style={{ color: "grey", fontSize: "14px", marginBottom: "0px" }}>
              Annual Revenue
            </p>
            <Input
              value={formik.values.AnnualRevenue}
              onChange={(e) =>
                formik.setFieldValue("AnnualRevenue", e.target.value)
              }
            />
          </div>
        </div>

        <div style={{ display: "flex", flexFlow: "row nowrap" }}>
          <div
            style={{
              display: "flex",
              flexFlow: "column wrap",
              flexBasis: "50%",
            }}
          >
            <p style={{ color: "grey", fontSize: "14px", marginBottom: "0px" }}>
              Title *
            </p>
            <Input
              value={formik.values.Title}
              onChange={(e) => formik.setFieldValue("Title", e.target.value)}
            />
          </div>
          <div style={{ flexBasis: "50%" }}>
            <p style={{ color: "grey", fontSize: "14px", marginBottom: "0px" }}>
              No. Of Employees
            </p>
            <Input
              value={formik.values.NumberOfEmployees}
              onChange={(e) =>
                formik.setFieldValue("NumberOfEmployees", e.target.value)
              }
            />
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexFlow: "row nowrap",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexFlow: "column wrap",
              flexBasis: "50%",
            }}
          >
            <p style={{ color: "grey", fontSize: "14px", marginBottom: "0px" }}>
              Email
            </p>
            <Input
              value={formik.values.Email}
              onChange={(e) => formik.setFieldValue("Email", e.target.value)}
            />
          </div>
          <div style={{ flexBasis: "50%" }}>
            {isLoading ? (
              <div className="h-50 w-100 d-flex justify-content-center align-items-center">
                <div
                  className="spinner-border spinner-border-md text-primary"
                  role="status"
                />
              </div>
            ) : (
              <button
                style={{
                  backgroundColor: isFormikValid ? "#238FDB" : "grey",
                  padding: "0.5rem",
                  paddingBottom: "0.25rem",
                  paddingTop: "0.25rem",
                  border: "none",
                  borderRadius: "5px",
                  color: "whitesmoke",
                }}
                type="submit"
                onClick={formik.handleSubmit}
                disabled={!isFormikValid}
              >
                Save
              </button>
            )}
          </div>
        </div>

        <div style={{ flexBasis: "50%" }}>
          <div style={{ display: "flex", alignItems: "center", gap: "2px" }}>
            <p style={{ color: "grey", fontSize: "14px", marginBottom: "0px" }}>
              Lead Source
            </p>
            <ArrowSelect
              options={salesforceLeadsSources}
              onChange={(e) =>
                formik.setFieldValue("LeadSource", e.target.value)
              }
            />
          </div>
          <p style={{ color: "white", fontSize: "16px", marginBottom: "0px" }}>
            {formik.values.LeadSource}
          </p>
        </div>

        <div style={{ flexBasis: "50%" }}>
          <div style={{ display: "flex", alignItems: "center", gap: "2px" }}>
            <p style={{ color: "grey", fontSize: "14px", marginBottom: "0px" }}>
              Industry
            </p>
            <ArrowSelect
              options={salesforceLeadsIndustry}
              onChange={(e) => formik.setFieldValue("Industry", e.target.value)}
            />
          </div>
          <p style={{ color: "white", fontSize: "16px", marginBottom: "0px" }}>
            {formik.values.Industry}
          </p>
        </div>

        <div
          style={{
            display: "flex",
            flexFlow: "column wrap",
            flexBasis: "50%",
          }}
        >
          <p style={{ color: "grey", fontSize: "14px", marginBottom: "0px" }}>
            Website
          </p>
          <Input
            value={formik.values.Website}
            onChange={(e) => formik.setFieldValue("Website", e.target.value)}
          />
        </div>
      </div>
    </div>
  );
};

export default EditLead;
