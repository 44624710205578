import React, { useEffect, useState } from "react";
import "./index.css";
import edit from "../../assets/images/edit-1.png";
import plus from "../../assets/images/plus.png";
import connection from "../../assets/images/connection.png";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useGoogleLogin } from "@react-oauth/google";
import { gapi } from "gapi-script";
import { AiOutlineLogout } from "react-icons/ai";
import { getSFDataAction, insertSFDataAction, updateSFDataAction } from "../../redux/actions/SalesforceAction";
import { SimpleToast } from "../../utils/Toast";
import { useNavigate } from "react-router-dom";
import { clearErrorMessageAction } from "../../redux/actions/DashboardAction";

const Connection = ({ setConnectionCom }) => {
	const [showForm, setShowForm] = useState(false);
	const [token, setToken] = useState(null);
	const [onStateChange, setOnStateChange] = useState(false);
	const getSFData = useSelector((state) => state.getSFData);
	const user = localStorage?.getItem("userId");
	const SFData = useSelector((state) => state.insertSFData);
	const [inputs, setInputs] = useState({
		url: "",
		username: "",
		password: "",
		accessToken: "",
	});
	const [validUrl, setValidUrl] = useState(true);

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const handleChange = (e) => {
		if (e.target.name === "url") {
			setValidUrl(e.target.value.slice(-1) !== "/");
		}
		setInputs({
			...inputs,
			[e.target.name]: e.target.value,
		});
	};
	const salesforceHandle = async (e) => {
		e.preventDefault();
		if (!inputs?.url || !inputs?.username || !inputs?.password || !inputs?.accessToken || !user) {
			SimpleToast({
				title: "Error",
				text: "Please Fill out all the fields",
				iconName: "error",
			});
			return;
		}
		const data = { ...inputs, user };
		if (!getSFData?.getSFData?.data?.length) {
			const response = await dispatch(insertSFDataAction(data));
			if (response?.status === 200 && response?.data?.affectedRows === 1) {
				SimpleToast({
					title: "Success",
					text: "Information successfully added.",
					iconName: "success",
					iconColor: "green",
				});

				setTimeout(() => {
					navigate("/profile");
				}, 1000);
			} else {
				return;
			}
		} else {
			const data = { ...inputs, user };
			const body = {
				id: getSFData?.getSFData?.data?.[0]?.id,
				updates: data,
			};
			const response = await dispatch(updateSFDataAction(body));
			if (response?.status === 200 && response?.data?.affectedRows === 1) {
				SimpleToast({
					title: "Success",
					text: "Information successfully updated.",
					iconName: "success",
					iconColor: "green",
				});
			} else {
				console.log("error");
				return;
			}
		}
	};
	useEffect(() => {
		if (SFData?.isInsertSFError || getSFData?.isGetSFError) {
			SimpleToast({
				title: "Error",
				text: "Oops, something went wrong.",
				iconName: "error",
				iconColor: "#d33",
			});
		}
		setTimeout(() => {
			dispatch(clearErrorMessageAction());
		}, 1500);
	}, [SFData?.isInsertSFError, getSFData?.isGetSFError, dispatch]);

	const clientId = process.env.REACT_APP_CLIENT_ID;
	useEffect(() => {
		gapi.load("client:auth2", () => {
			gapi.auth2.init({ clientId });
		});
	}, []);
	let scopes =
		"https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/gmail.labels https://www.googleapis.com/auth/docs https://www.googleapis.com/auth/drive https://www.googleapis.com/auth/drive.metadata https://www.googleapis.com/auth/drive.file https://www.googleapis.com/auth/gmail.send https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/gmail.modify https://www.googleapis.com/auth/gmail.compose https://mail.google.com/ ";

	const onGoogleSuccess = (codeResponse) => {
		// eslint-disable-next-line no-unused-expressions
		localStorage.setItem("googleRefreshCode", codeResponse?.code), setOnStateChange(true);
		if (onStateChange) setOnStateChange(false);
	};

	const handleGoogleLogin = useGoogleLogin({
		onSuccess: (codeResponse) => onGoogleSuccess(codeResponse),
		flow: "auth-code",
		scope: scopes,
	});
	useEffect(() => {
		const tokenn = localStorage.getItem("googleAuthRefreshToken") ?? localStorage.getItem("googleRefreshCode");
		setToken(tokenn);
	}, [onStateChange]);
	const handleGoogleLogout = () => {
		localStorage.removeItem("googleRefreshCode");
		localStorage.removeItem("googleAuthRefreshToken");
		localStorage.removeItem("googleAuthAccessToken");
		setToken(null);
	};

	useEffect(() => {
		dispatch(getSFDataAction(user));
	}, [dispatch, user, SFData?.insertSFData]);
	useEffect(() => {
		setInputs({
			url: getSFData?.getSFData?.data?.[0]?.url || "",
			username: getSFData?.getSFData?.data?.[0]?.username || "",
			password: getSFData?.getSFData?.data?.[0]?.password || "",
			accessToken: getSFData?.getSFData?.data?.[0]?.accessToken || "",
		});
	}, [getSFData?.getSFData]);

	return (
		<div className="connection_mainContainer">
			<div onClick={() => (showForm ? setShowForm(false) : setConnectionCom(false))} className="header-img-container">
				<img src={connection} alt="connection" />
				<p>External Integrations</p>
			</div>
			<div className="connection_cardContainer">
				{!showForm ? (
					getSFData?.isGetSFLoading ? (
						<div
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								height: "100%",
							}}
						>
							<div className="spinner-border spinner-border text-primary mb-4" role="status" />
						</div>
					) : (
						<>
							<div
								style={{
									display: "flex",
									width: 150,
									justifyContent: "space-between",
									alignItems: "center",
								}}
							>
								<div
									style={{
										backgroundColor: "#262E4A",
										display: "flex",
										flexDirection: "column",
										alignItems: "center",
										justifyContent: "center",
										width: 80,
										height: 60,
										borderRadius: 7,
									}}
								>
									{getSFData?.getSFData?.data?.length ? (
										<p
											style={{
												margin: 0,
												padding: 0,
												fontSize: 11,
												fontWeight: "bold",
												color: "#7B7F90",
											}}
										>
											connected
										</p>
									) : (
										<p
											style={{
												margin: 0,
												padding: 0,
												fontSize: 11,
												fontWeight: "bold",
												color: "var(--secondry-color)",
											}}
										>
											connect 2
										</p>
									)}

									<p
										style={{
											margin: 0,
											padding: 0,
											textAlign: "center",
											fontSize: 14,
											color: "var(--white-color)",
										}}
									>
										Salesforce
									</p>
								</div>
								{getSFData?.getSFData?.data?.length ? (
									<img
										onClick={() => setShowForm(true)}
										style={{
											width: 25,
											height: 25,
											cursor: "pointer",
											objectFit: "contain",
										}}
										src={edit}
										alt="edit"
									/>
								) : (
									<img
										onClick={() => setShowForm(true)}
										style={{
											width: 25,
											height: 25,
											cursor: "pointer",
											objectFit: "contain",
										}}
										src={plus}
										alt="add"
									/>
								)}
							</div>

							<div
								style={{
									display: "flex",
									width: 150,
									justifyContent: "space-between",
									alignItems: "center",
									marginTop: 20,
								}}
							>
								<div
									style={{
										backgroundColor: "#262E4A",
										display: "flex",
										flexDirection: "column",
										alignItems: "center",
										justifyContent: "center",
										width: 80,
										height: 60,
										borderRadius: 7,
									}}
								>
									<p
										style={{
											margin: 0,
											padding: 0,
											fontSize: 11,
											fontWeight: "bold",
											color: "var(--secondry-color)",
										}}
									>
										{token ? "connected" : "connect 2"}
									</p>
									<p
										style={{
											margin: 0,
											padding: 0,
											textAlign: "center",
											fontSize: 14,
											color: "var(--white-color)",
										}}
									>
										Google Workspace
									</p>
								</div>
								{token ? (
									<>
										<button
											style={{
												marginLeft: "1rem",
												cursor: "pointer",
												border: "none",
												color: "white",
												background: "transparent",
											}}
											onClick={handleGoogleLogout}
										>
											{" "}
											<AiOutlineLogout style={{ fontSize: "25px" }} />{" "}
										</button>
									</>
								) : (
									<img
										onClick={handleGoogleLogin}
										style={{
											width: 25,
											height: 25,
											cursor: "pointer",
											objectFit: "contain",
										}}
										src={plus}
										alt="plus"
									/>
								)}
							</div>
						</>
					)
				) : (
					<form onSubmit={salesforceHandle} className="g-3" autocomplete="off">
						<div
							style={{
								justifyContent: "center",
								alignItems: "center",
								display: "flex",
								flexDirection: "column",
								gap: 10,
							}}
						>
							<div className="input_label">
								<label className="label">Salesforce Login URL</label>
								<input type="text" name="url" placeholder="`https://test.salesforce.com`" value={inputs.url} onChange={handleChange} />
								<div hidden={validUrl} style={{ color: "#e64545", fontSize: 14 }}>
									Origin login URLs may not end with '/'.
								</div>
							</div>
							<div className="input_label">
								<label className="label ">Username</label>
								<input type="text" name="username" placeholder="liamjf-ay8s@force.com" value={inputs.username} onChange={handleChange} autocomplete="off" />
							</div>
							<div className="input_label">
								<label className="label ">Password</label>
								<input type="password" name="password" placeholder="********" value={inputs.password} onChange={handleChange} autocomplete="off" />
							</div>
							<div className="input_label">
								<label className="label ">Access Token</label>
								<input type="password" name="accessToken" placeholder="********" value={inputs.accessToken} onChange={handleChange} autocomplete="off" />
							</div>
						</div>
						<div className="d-flex justify-content-center">
							<Button
								disabled={SFData.isInsertSFLoading || !validUrl}
								type="submit"
								className="mt-3 sign_up"
								style={{ borderRadius: 8, padding: 5, cursor: "pointer" }}
							>
								{SFData.isInsertSFLoading ? (
									<div className="spinner-border spinner-border-sm text-primary" role="status" />
								) : getSFData?.getSFData?.data?.length ? (
									"Update"
								) : (
									"Connect"
								)}
							</Button>
						</div>
					</form>
				)}
			</div>
		</div>
	);
};

export default Connection;
