import axios from "axios"

const client_id= process.env.REACT_APP_CLIENT_ID
const client_secret = process.env.REACT_APP_CLIENT_SECRET 

export  const get_refresh_token = async () =>{
    let refreshAccessCode =  localStorage?.getItem('googleRefreshCode')   
    if(!refreshAccessCode) return
     let tokenRequest = await axios({
         method: 'post',
         url: "https://www.googleapis.com/oauth2/v4/token",
         headers: {"content-type": "application/x-www-form-urlencoded"},
         params: {
           code: refreshAccessCode,
           client_id,
           client_secret,
           redirect_uri: "postmessage",
           grant_type: "authorization_code"
         }
      });
     
    localStorage.setItem("googleAuthRefreshToken",tokenRequest.data["refresh_token"]);
    localStorage.removeItem('googleRefreshCode')

    if(tokenRequest.data["refresh_token"]) await get_access_token()
    
 }
 
 export  const get_access_token = async () =>{
    let refreshAccessToken =  localStorage?.getItem('googleAuthRefreshToken')
    if(!refreshAccessToken) return   
    let tokenRequestt = await axios({
         method: 'post',
         url: "https://oauth2.googleapis.com/token",
         headers: {"content-type": "application/x-www-form-urlencoded"},
         params: {
           client_id,
           client_secret,
           refresh_token: refreshAccessToken,
           grant_type: "refresh_token"
         } 
      });
       
     localStorage.setItem("googleAuthAccessToken",tokenRequestt.data["access_token"])
     return tokenRequestt.data["access_token"]
 }


 export const setAccesToken=async()=>{
   const googleAuthAccessToken=await get_access_token();
    if(!localStorage.getItem("googleAuthAccessToken")){
        localStorage.setItem("googleAuthAccessToken",googleAuthAccessToken);
    }
    return googleAuthAccessToken
}