import profile2 from "../assets/images/profile-2.png";
import profile3 from "../assets/images/profile-3.png";
import profile4 from "../assets/images/profile-4.png";
import profile from "../assets/images/user-profile.png";
export const generateRandomPassword = () => {
  return Math.random().toString(36).slice(-8);
};
export const teamData = [
  {
    id: 0,
    name: "Robbie Parisius",
    designation: "Vice Presisius",
    image: profile,
    delBtn: "Delete?",
    editBtn: "Edit Info.",
  },
  {
    id: 1,
    name: "Michael Right",
    designation: "Sales Manager",
    image: profile3,
    delBtn: "Delete?",
    editBtn: "Edit Info.",
  },

  {
    id: 2,
    name: "Lidya",
    designation: "Account Executive",
    image: profile2,
    delBtn: "Delete?",
    editBtn: "Edit Info.",
  },
  {
    id: 3,
    name: "James Kapur",
    designation: "SDR",
    image: profile4,
    delBtn: "Delete?",
    editBtn: "Edit Info.",
  },
  {
    id: 4,
    name: "James Kapur",
    designation: "SDR",
    image: profile4,
    delBtn: "Delete?",
    editBtn: "Edit Info.",
  },
  {
    id: 5,
    name: "James Kapur",
    designation: "SDR",
    image: profile4,
    delBtn: "Delete?",
    editBtn: "Edit Info.",
  },
  {
    id: 6,
    name: "James Kapur",
    designation: "SDR",
    image: profile4,
    delBtn: "Delete?",
    editBtn: "Edit Info.",
  },
  {
    id: 7,
    name: "James Kapur",
    designation: "SDR",
    image: profile4,
    delBtn: "Delete?",
    editBtn: "Edit Info.",
  },
];

export const salesforceLeadsStatus = [
  "Unqualified",
  "New",
  "Working",
  "Nurturing",
  "Qualified",
];

export const salesforceLeadsRatings = ["Hot", "Warm", "Cold"];

export const salesforceLeadsSources = [
  "Advertisement",
  "Customer Event",
  "Employee Referral",
  "External Referral",
  "Google AdWords",
  "Other",
  "Partner",
  "Purchased List",
  "Trade Show",
  "Webinar",
  "Website",
];

export const salesforceLeadsIndustry = [
  "Agriculture",
  "Apparel",
  "Banking",
  "BioTechnology",
  "Chemicals",
  "Communications",
  "Construction",
  "Consulting",
  "Education",
  "Electronics",
  "Energy",
  "Engineering",
  "Entertainment",
  "Environmental",
  "Finance",
  "Food & Beverage",
  "Government",
  "Health Care",
  "Hospitality",
  "Insurance",
  "Machinery",
  "Manufacturing",
  "Media",
  "Not for Profit",
  "Other",
  "Recreation",
  "Retail",
  "Shipping",
  "Technology",
  "Telecommunications",
  "Transportation",
  "Utilities",
];

export const salesforceSalutations = ["Mr.", "Ms.", "Mrs.", "Dr.", "Prof."];

export const salesforceStages = [
  "Qualification",
  "Needs Analysis",
  "Proposal",
  "Negotiation",
  "Closed Won",
  "Closed Lost",
];

export const salesforceTypes = ["Existing Business", "New Business"];

export const salesforceOwnerships = [
  "Public",
  "Private",
  "Subsidiary",
  "Other",
];
