import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./Components/App";
import "./Components/css/Navbar.css";
import "./Components/css/Footer.css";
import "./Components/css/Home.css";
import "./Components/css/Career.css";
import "./Components/css/About.css";
import "./Components/css/Guide.css";
import "./Components/css/signup.css";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { GoogleOAuthProvider } from '@react-oauth/google';
import axios from "axios";
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId={process.env.REACT_APP_CLIENT_ID}>
      <Provider store={store}>
        <Router>
          <App />
        </Router>
      </Provider>
      </GoogleOAuthProvider>
  </React.StrictMode>
);


axios.interceptors.request.use(function (config) {
  if(config.url != undefined && config.url.indexOf("salesforce.com")>-1){
    return config;
  }
  const token = localStorage.getItem('token');
  var userId = localStorage.getItem('userId');
  if(config.data != undefined && config.data.userId != undefined){
    userId = config.data.userId;    
  }
  config.params = {...config.params, userId: userId}
  if(token){
    config.headers.Authorization =  token+"___"+btoa("userId:"+userId);
  }
  return config;
});