import moment from "moment/moment";
import { ListGroup } from "react-bootstrap";
import Star from "../../../../assets/images/star.svg";
import UnreadIcon from "../../../../assets/images/unread-email.png";
import AttachmentsIcon from "../../../../assets/images/paperclip.svg";

function EmailList({
  setData,
  SingleData,
  setSingleMailData,
  filterValues,
  mailData,
  filterStarredValues,
  pinnedProd
}) {
  return (
    <>
      {SingleData.length > 0 &&
        SingleData?.map((list, index) => {
          return (
            <>
              <ListGroup.Item
                as="li"
                className="bg-transparent border-0 mail d-flex align-items-start gap-5 py-2 px-1"
                key={index}
                onClick={() => {
                  setData(true);
                  setSingleMailData(list.singleData);
                }}
              >
                <div className="d-flex justify-content-between w-100 align-items-center">
                  <h6
                    key={index}
                    className="d-inline-flex justify-content-start align-items-start gap-1"
                    style={{
                      color: "#fff",
                      margin: 0,
                      padding: 0,
                      fontSize: 13,
                      width: pinnedProd ? "75%" : "80%",
                      cursor: "pointer",
                      marginLeft: list?.labelIds.includes("UNREAD") ? -3 : 10,
                    }}
                  >
                  
                    {filterStarredValues === "STARRED" ? (
                      <div
                        style={{
                          width: "15px",
                          marginRight: "5px",
                          marginLeft: "5px",
                        }}
                      >
                        <img
                          src={Star}
                          alt="Star"
                          style={{ width: 100, maxWidth: "15px" }}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                    {list?.labelIds.includes("UNREAD") ? (
                      <div>
                        <img
                          src={UnreadIcon}
                          style={{ maxWidth: "10px" }}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                    <p
                      style={{
                        color: "rgb(255 255 255 / 48%)",
                        fontSize: 16,
                        margin: 0,
                        padding: 0,
                        cursor: "pointer",
                        fontWeight: list?.labelIds.includes("UNREAD") ? "bold" : "normal"
                      }}
                    >
                      {filterValues}
                    </p>
                    <span style={{ fontSize: 16, fontWeight: list?.labelIds.includes("UNREAD") ? "bold" : "normal" }}>{list?.email}</span>
                  </h6>
                  {list?.singleData?.hasAttachments && !pinnedProd ? (
                    <div>
                      <img
                        src={AttachmentsIcon}
                        style={{ maxWidth: "10px" }}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  <p
                    style={{
                      color: list?.labelIds.includes("UNREAD") ? "white" : "rgb(255 255 255 / 48%)" ,
                      marginBottom: 2,
                      padding: 0,
                      fontSize: 16,
                      widt7: "20%",
                      cursor: "pointer",
                      fontWeight: list?.labelIds.includes("UNREAD") ? "bold" : "normal"
                    }}
                  >
                    {moment(list?.date).format("MMM DD")}
                  </p>
                </div>
              </ListGroup.Item>
            </>
          );
        })}
    </>
  );
}

export default EmailList;
