import axios from 'axios'
import { setAccesToken } from './googleTokens';

const axiosApiInstance = axios.create();

  axiosApiInstance.interceptors.request.use(
     async config => {
    const get_access_token = await setAccesToken();
      config.headers['Authorization'] = `Bearer ${get_access_token}`;
          return config;
      },
      error => {
          return Promise.reject(error);
      }
  );

  axiosApiInstance.interceptors.response.use((response) => {
    return response
  }, async function (error) {
    const originalRequest = error.config;
    if ((error.response.status === 401||error.response.status === 403) && !originalRequest._retry ) {
      originalRequest._retry = true;
      localStorage.removeItem('googleAuthAccessToken')
      const get_access_token=  await setAccesToken()          
      axiosApiInstance.defaults.headers.common['Authorization'] = 'Bearer ' + get_access_token
      originalRequest.headers.Authorization = 'Bearer ' + get_access_token
      return axiosApiInstance(originalRequest);
    }
    if(error.response.status === 400 && error.response.data.error_description === 'Token has been expired or revoked.') {
      localStorage.removeItem('googleAuthRefreshToken')
      localStorage.removeItem('googleAuthAccessToken')
      // window.location.href = 'http://localhost:3000/settings?connection=true'
    }
    return Promise.reject(error);
  });


  export default axiosApiInstance