import React, { useState, useRef, useEffect } from "react";
import { Button } from "react-bootstrap";
import Navbar from "../../Components/Navbar";
import Swal from "sweetalert2";
import { userSignupAction } from "../../redux/actions/AuthAction";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { SimpleToast } from "../../utils/Toast";
import { clearErrorMessageAction } from "../../redux/actions/DashboardAction";
import bcrypt from 'bcryptjs'

import { uploadFile } from "react-s3";
import { Buffer } from "buffer";
Buffer.from("anything", "base64");
window.Buffer = window.Buffer || require("buffer").Buffer;
const S3_BUCKET = process.env.REACT_APP_SECRET_CODE === 'Dev' ? process.env.REACT_APP_S3_BUCKET : process.env.REACT_APP_S3_BUCKET_PROD;
const REGION = process.env.REACT_APP_REGION;
const ACCESS_KEY = process.env.REACT_APP_ACCESS_KEY;
const SECRET_ACCESS_KEY = process.env.REACT_APP_SECRET_ACCESS_KEY;

const config = {
  bucketName: S3_BUCKET,
  dirName: "photos",
  region: REGION,
  accessKeyId: ACCESS_KEY,
  secretAccessKey: SECRET_ACCESS_KEY,
};
function UserSignup() {
  const auth = useSelector((state) => state.Auth);
  const [data, setData] = useState({});
  const [check, setCheck] = useState(false);
  const [file, setFileName] = useState(null);
  const [imgLoad, setImgLoad] = useState(false);

  const inputRef = useRef(null);
  const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userSignup = useSelector((state) => state.userSignup);

  const changeHandler = async (e) => {
    setData((b) => {
      return { ...b, [e.target.name]: e.target.value };
    });
    if (e.target.name === "profilePhoto") {
      setFileName(e.target.files[0]);
    }
  };
  const fillOutFields = (field_name) => {
    Swal.fire({
      icon: "error",
      title: "Error",
      text:
        field_name === "Confirm Password"
          ? "Please make sure your passwords must match"
          : ` Please Fill out ${field_name}`,
    });
  };
  const fillOutField = (field_name) => {
    Swal.fire({
      icon: "error",
      title: "Error",
      text: field_name,
    });
  };

  const uploadImageToS3 = async () => {
    setImgLoad(true);
    try {
      const data = await uploadFile(file, config);
      return data;
    } catch (error) {
      setImgLoad(false);

      SimpleToast({
        title: "Error",
        text: error.message,
        iconName: "error",
        iconColor: "#d33",
      });
    }
  };
  const FormSubmit = async (e) => {
    e.preventDefault();
    var allData = Object.keys(data);

    if (!allData.includes("first_name") || !data["first_name"]?.trim().length) {
      fillOutFields("First Name");
    } else if (
      !allData.includes("last_name") ||
      !data["last_name"]?.trim().length
    ) {
      fillOutFields("Last Name");
    } else if (
      !allData.includes("position") ||
      !data["position"]?.trim().length
    ) {
      fillOutFields("Title");
    } else if (
      !allData.includes("password") ||
      !data["password"]?.trim().length
    ) {
      fillOutFields("Password");
    } else if (
      !allData.includes("confirm_password") ||
      !data["confirm_password"]?.trim().length
    ) {
      fillOutFields("confirm password");
    } else if (data["confirm_password"]?.trim() !== data["password"]?.trim()) {
      fillOutFields("Confirm Password");
    } else if (!check) {
      fillOutField(
        "Please check this box to receive important updates and to be notified when our beta is ready for use"
      );
    } else {
      if (
        (auth?.profileData?.profile_image && file === null) ||
        (!auth?.profileData?.profile_image && file === null)
      ) {
        const receive_updates = check ? 1 : 0;
        const is_admin = 0;
        const temp_data = { ...data };
        delete temp_data.confirm_password;
        delete temp_data.profilePhoto;
        temp_data.password = bcrypt.hashSync(temp_data.password, 10);   // hash password
        // let last_login_datetime = new Date().toJSON().slice(0, 10);
        let last_login_datetime = new Date().toISOString();
        const body = {
          ...temp_data,
          receive_updates,
          is_admin,
          last_login_datetime,
        };

        console.log("body", body);

        const response = await dispatch(userSignupAction(body, state?.id));
        if (response?.status === 200) {
          localStorage.setItem("userId", state?.id);

          SimpleToast({
            title: "Success",
            text: "User successfully updated!",
            iconName: "success",
            iconColor: "green",
          });

          setTimeout(() => {
            navigate("/profile", { replace: true });
          }, 1500);
        } else {
          return;
        }
      } else {
        const imageResponse = await uploadImageToS3();

        const receive_updates = check ? 1 : 0;
        const is_admin = 0;
        const temp_data = { ...data };
        delete temp_data.confirm_password;
        delete temp_data.profilePhoto;
        // let last_login_datetime = new Date().toJSON().slice(0, 10);
        let last_login_datetime = new Date().toJSON().slice(0, 10);
        const body = {
          ...temp_data,
          receive_updates,
          is_admin,
          last_login_datetime,
          profile_image: imageResponse?.location,
        };
        if (imageResponse?.result?.status === 204) {
          setImgLoad(false);
          const response = await dispatch(userSignupAction(body, state?.id));
          if (response?.status === 200) {
            localStorage.setItem("userId", state?.id);

            SimpleToast({
              title: "Success",
              text: "User successfully updated!",
              iconName: "success",
              iconColor: "green",
            });

            setTimeout(() => {
              navigate("/profile", { replace: true });
            }, 1500);
          } else {
            return;
          }
        } else {
          return;
        }
      }
    }
  };
  console.log(state);
  useEffect(() => {
    if (!state) {
      navigate("/login", { replace: true });
    }
  }, [navigate, state]);

  useEffect(() => {
    if (userSignup?.isuserSignupError) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Oops, something went wrong.",
      });
    }
    setTimeout(() => {
      dispatch(clearErrorMessageAction());
    }, 2000);
  }, [userSignup?.isuserSignupError, dispatch]);
  return (
    <>
      <div className="main_hero_div" id="main">
        <Navbar />

        <div className="container" id="sign_up_container">
          <div className="d-flex justify-content-center career_main_div  ">
            <div className="border_div_career my-5"></div>
          </div>

          <h4
            className="display-5 text-white text-center mb-4"
            style={{ fontWeight: "300" }}
          >
            Sign Up
          </h4>

          <form className="g-3" onSubmit={FormSubmit}>
            <div className="d-flex gap-5 flex-wrap justify-content-center form-grid">
              <div className="input_label">
                <label className="label">First Name</label>
                <input
                  type="text"
                  name="first_name"
                  value={data?.first_name}
                  onChange={changeHandler}
                />
              </div>
              <div className="input_label">
                <label className="label ">Last Name</label>
                <input
                  type="text"
                  name="last_name"
                  value={data?.last_name}
                  onChange={changeHandler}
                />
              </div>
            </div>

            <div className="d-flex gap-5 flex-wrap justify-content-center mt-3">
              <div className="input_label">
                <label className="label">Title</label>
                <br></br>
                <input
                  type="text"
                  name="position"
                  value={data?.position}
                  onChange={changeHandler}
                />
              </div>
              <div className="input_label">
                <label className="label">Profile Photo</label>
                <br></br>

                <input
                  type="file"
                  name="profilePhoto"
                  onChange={changeHandler}
                  alt="profile-photo"
                  style={{ display: "none" }}
                  ref={inputRef}
                />
                <input
                  type="text"
                  value={file ? file?.name : "Attach Photo"}
                  onClick={() => inputRef.current.click()}
                  style={{ cursor: "pointer" }}
                />
              </div>
            </div>
            <div className="d-flex gap-5 flex-wrap justify-content-center mt-3">
              <div className="input_label">
                <label className="label">Create New Password</label>
                <br></br>
                <input
                  type="password"
                  name="password"
                  value={data?.password}
                  onChange={changeHandler}
                  autocomplete="new-password"
                />
              </div>
              <div className="input_label">
                <label className="label">Confirm Password</label>
                <br></br>
                <input
                  type="password"
                  name="confirm_password"
                  value={data?.confirm_password}
                  onChange={changeHandler}
                  autocomplete="new-password"
                />
              </div>
            </div>

            <div className="d-flex gap-4 flex-wrap justify-content-center mt-3">
              <div className="d-flex gap-2 justify-content-space-between">
                <label
                  className="form-check-label mt-2 "
                  style={{
                    fontSize: "13px",
                    color: "#238FDB",
                    fontWeight: "700",
                  }}
                >
                  I agree to Connect2Co Terms & Conditions
                </label>
                <div class="customCheckbox">
                  <div class="input-container">
                    <input
                      type="checkbox"
                      name="agree"
                      checked={check}
                      onChange={() => setCheck(!check)}
                    />
                    <label for="customCheckboxInput"></label>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center">
              <Button type="submit" className="mt-3 ms-3 sign_up">
                {userSignup.isuserSignupLoading || imgLoad ? (
                  <div
                    class="spinner-border spinner-border-sm text-primary"
                    role="status"
                  />
                ) : (
                  "SUBMIT"
                )}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default UserSignup;
