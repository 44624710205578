import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "../../src/Components/css/Pricing.css";
import { LuChevronRight } from "react-icons/lu";
import Navbar from "./Navbar";
import Footerabout from "./Footerabout";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const Pricing = () => {
	const navigate = useNavigate();
	const auth = useSelector((state) => state.Auth);
	console.log("Auth", auth);
	// console.log(auth?.data?.data?.insertId);
	const [rightClicked, setRightClicked] = useState([false, false, false, false]);

	const [planDetails, setPlanDetails] = useState([]);

	const handleClick = (index) => {
		const updatedState = [...rightClicked];
		updatedState[index] = !updatedState[index];
		setRightClicked(updatedState);
	};

	const fetchPlanInfo = async () => {
		try {
			const resp = await axios.get("https://uiv07lpxq2.execute-api.us-east-1.amazonaws.com/admin/subscriptionPlans");
			let data = await resp.data;
			setPlanDetails(data);
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		fetchPlanInfo();
	}, []);

	console.log(123, auth?.data?.data?.insertId, auth?.data?.data?.insertId, auth?.profileData?.id);
	async function subscribe(plan) {
		/** 3DPL - FOLLOWING CODE IS FOR FREE PLAN */
		const config = {
			headers: {
				"x-api-key": process.env.REACT_APP_C2C_PROD_AWS_ACCESS_KEY,
			},
		};
		/*
		try {
			// if (plan.name === "Basic") {
			if (plan.id === 1) {
				const userData = {
					userId: auth?.data?.data?.insertId,
					planType: plan.name,
				};

				Swal.fire({
					// title: "Are you sure?",
					// text: "You can change your plan in the future. <br> I agree to the terms and conditions.",
					html:
						// '<div><h5>You can change your plan in the future</h5>' +
						'<div style="display:flex; align-items:center; margin-left:80px;"><input type="checkbox" id="agreeCheckbox" required style=" width:20px;">' +
						'<a href="https://www.connect2co.com/terms-conditions" target="_blank" style=" color: black; "><h5 for="agreeCheckbox" style="padding-left: 10px; color:black;">I agree with the Terms & Conditions</h5></a></div></div> ',
					icon: "success",
					// showCancelButton: true,
					confirmButtonColor: "#3085d6",
					cancelButtonColor: "#d33",
					confirmButtonText: "Continue",
					// input: {
					//   type: "checkbox",
					//   label: "I agree to the terms and conditions",
					//   required: true,
					// },
				}).then(async (result) => {
					if (result.isConfirmed) {
						const planUpdate = await axios.post("https://snom96x1zi.execute-api.us-east-1.amazonaws.com/admin/updatePlantype", userData, config);
						const res = await planUpdate.data;
						// Swal.fire("Subscribed!", "Thank you for choosing" `${plan.name}`, "success");
						Swal.fire("Subscribed!", `Thank you for choosing ${plan.name} plan`, "success");

						navigate("/");
					}
				});
			}
		} catch (error) {
			Swal.fire("Failed!", `${plan.name}`, "error");
			console.log(error);
		}
    */

		//Redirect to strip
		// if (plan.name === "Freelance" || plan.name === "Small Team") {

		/** 3DPL - ADDING CODE FOR FREE PLAN */

		if (plan.id === 1 || plan.id === 2 || plan.id === 3) {
			// 3DPL- FREE, SILVER and GOLD PLAN
			const config2 = {
				headers: {
					"x-api-key": process.env.REACT_APP_C2C_PROD_AWS_ACCESS_KEY,
				},
			};
			const initiateData = {
				userId: auth?.data?.data?.insertId ? auth?.data?.data?.insertId : auth?.profileData?.id,
				planId: plan.id,
				successUrl: "https://www.connect2co.com/ThankYouMsg",
				cancelUrl: "https://www.connect2co.com/ErrorMsg",
			};

			Swal.fire({
				// title: "Are you sure?",
				// text: "You can change your plan in the future. <br> I agree to the terms and conditions.",
				html:
					// '<div><h5>You can change your plan in the future</h5>' +
					'<div style="display:flex; align-items:center; margin-left:80px;"><input type="checkbox" id="agreeCheckbox" required style=" width:20px;">' +
					'<a href="https://www.connect2co.com/terms-conditions" target="_blank" style=" color: black;"><h5 for="agreeCheckbox" style="padding-left: 10px; color:black;">I agree with the Terms & Conditions</h5></a></div></div> ',
				icon: "success",
				// showCancelButton: true,
				confirmButtonColor: "#3085d6",
				cancelButtonColor: "#d33",
				confirmButtonText: "Continue",
				// input: {
				//   type: "checkbox",
				//   label: "I agree to the terms and conditions",
				//   required: true,
				// },
			}).then(async (result) => {
				if (result.isConfirmed) {
					try {
						console.log("initiateData", initiateData);
						console.log("config2", config2);

						const response = await axios.post(
							"https://9kzlriejdb.execute-api.us-east-1.amazonaws.com/dev/initiatePayment",
							initiateData,
							config2 // Use config2 instead of config
						);

						console.log("response", response.data); // Log the response data

						// Assuming the response data has a key named "stripeCheckoutUrl"
						const stripeCheckoutUrl = response.data.stripeCheckoutUrl;

						// Redirect to the returned Stripe checkout URL
						window.location.href = stripeCheckoutUrl;
						// stsrt
						// await axios
						// .post(
						//   "https://9kzlriejdb.execute-api.us-east-1.amazonaws.com/dev/initiatePayment",
						//   { initiateData, config2 },

						// )
						// .then((response) => {
						//   console.log("res",response);
						//   return response.data;
						// })
						// .then((res) => {
						//   console.log("re2s",res);

						//   window.location.href = res.stripeCheckoutUrl;

						// });

						// end
					} catch (error) {
						console.error("Error occurred:", error);
						console.error("Error response:", error.response); // Log the full response if available

						// Handle different error scenarios based on status codes
						if (error.response && error.response.status === 404) {
							Swal.fire({
								title: "Error",
								text: "User not found.",
								icon: "error",
							});
						} else if (error.response && error.response.status === 500) {
							Swal.fire({
								title: "Error",
								text: "An error occurred while initiating the payment.",
								icon: "error",
							});
						} else {
							Swal.fire({
								title: "Error",
								text: "An unknown error occurred.",
								icon: "error",
							});
						}
					}
				}
			});
		}

		//Enterprise plan
		if (plan.id === 4) {
			const userData = {
				userId: auth?.data?.data?.insertId ? auth?.data?.data?.insertId : auth?.profileData?.id,
				planType: plan.name,
			};
			Swal.fire({
				// title: "Are you sure?",
				// text: "You can change your plan in the future. <br> I agree to the terms and conditions.",
				html:
					// '<div><h5>You can change your plan in the future</h5>' +
					'<div style="display:flex; align-items:center; margin-left:80px;"><input type="checkbox" id="agreeCheckbox" required style=" width:20px;">' +
					'<a href="https://www.connect2co.com/terms-conditions" target="_blank" style=" color: black;"><h5 for="agreeCheckbox" style="padding-left: 10px; color:black;">I agree with the Terms & Conditions</h5></a></div></div> ',
				icon: "success",
				// showCancelButton: true,
				confirmButtonColor: "#3085d6",
				cancelButtonColor: "#d33",
				confirmButtonText: "Continue",
				// input: {
				//   type: "checkbox",
				//   label: "I agree to the terms and conditions",
				//   required: true,
				// },
			}).then(async (result) => {
				if (result.isConfirmed) {
					const planUpdate = await axios.post("https://snom96x1zi.execute-api.us-east-1.amazonaws.com/admin/updatePlantype", userData, config);
					const res = await planUpdate.data;
					Swal.fire("Subscribed!", `Thank you for choosing ${plan.name} plan`, "success");

					navigate("/");
				}
				// Swal.fire(
				//   "Thank You for reaching us out. We will revert you soon.",
				//   `${plan.name}`,
				//   "success"
				// );
				navigate("/");
			});
		}
	}
	async function unsubscribe() {
		/** 3DPL - FOLLOWING CODE IS FOR FREE PLAN */
		const config = {
			headers: {
				"x-api-key": process.env.REACT_APP_C2C_PROD_AWS_ACCESS_KEY,
			},
		};
		const initiateData = {
			userId: auth?.data?.data?.insertId ? auth?.data?.data?.insertId : auth?.profileData?.id,
		};

		Swal.fire({
			title: "Are you sure you want to cancel?",
			text: "You will not be charged for the next subscrition and the last date of your account with subscription will remain same",
			icon: "warning",
			// showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Unsubscribe",
		}).then(async (result) => {
			if (result.isConfirmed) {
				try {
					const response = await axios.post(
						"https://d44jvqwfg2.execute-api.us-east-1.amazonaws.com/cancelSubscription/cancelsubscription", // URLPASTEHERE
						initiateData,
						config // Use config2 instead of config
					);

					console.log("Cancel Subscription", response); // Log the response data
				} catch (error) {
					console.error("Error occurred:", error);
					console.error("Error response:", error.response); // Log the full response if available

					// Handle different error scenarios based on status codes
					if (error.response && error.response.status === 404) {
						Swal.fire({
							title: "Error",
							text: "User not found.",
							icon: "error",
						});
					} else if (error.response && error.response.status === 500) {
						Swal.fire({
							title: "Error",
							text: "An error occurred while initiating the payment.",
							icon: "error",
						});
					} else {
						Swal.fire({
							title: "Error",
							text: "An unknown error occurred.",
							icon: "error",
						});
					}
				}
			}
		});
	}
	return (
		<>
			<Navbar />
			<div className="d-flex justify-content-center align-items-center career_main_div" style={{ marginTop: "85px" }}>
				<div className="border_div_career my-5"></div>
			</div>
			<div className="Cart">
				<div className="AllCart">
					{planDetails ? (
						planDetails.map((data) => (
							<>
								<div className="Cart_Box">
									<div className="Basic_detail">
										<h3>{data.name}</h3>
										<p>{data.description}</p>
									</div>
									<div className="right_icon">
										<LuChevronRight onClick={() => handleClick(0)} />
									</div>
									{data.id !== 4 ? (
										<div className="Price">
											<h1>${data.price}</h1>
											<span>/{data.duration}</span>
										</div>
									) : null}

									<div className="Button">
										{auth?.data?.data?.insertId ? (
											<>
												<button onClick={() => subscribe(data)}>Choose {data.name} plan</button>
											</>
										) : auth.profileData?.subscription_status === "active" ? (
											<>
												{(auth.profileData?.plan_type === data.name || auth.profileData?.plan_type === data.id) && (
													<button onClick={() => unsubscribe()}>UnSubscribe</button>
												)}
											</>
										) : (
											<>
												{!auth?.data?.data?.insertId && (
													<>
														<button
															onClick={() => {
																navigate("/signup");
															}}
														>
															Signup
														</button>
													</>
												)}
											</>
										)}
									</div>
									<div className="hr_dot">{data.description}</div>
									<div className={`All_Check_Box ${rightClicked[0] ? "All_Check_Box2" : "All_Check_Box"}`}></div>
								</div>
							</>
						))
					) : (
						<div class="spinner-border spinner-border-sm text-primary" role="status" />
					)}
				</div>
			</div>

			<Footerabout />
		</>
	);
};

export default Pricing;
