import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Spinner, Modal } from "react-bootstrap";
import Delete from "../../../../assets/images/delete.png";
import DownloadIcon from "../../../../assets/images/download-black.png";
import axios from "axios";
import axiosApiInstance from "../../../../utils/axiosInterceptor";
import { SimpleToast } from "../../../../utils/Toast";
import { useDispatch, useSelector } from "react-redux";
import { logExternalIntegrationTask } from "../../../../redux/actions/TasksAction";
import moment from 'moment';
import FilePreview from "react-file-preview-latest";

function EmailCard({
  setData,
  SingleData,
  setSingleMailData,
  deletemailData,
  isLoad,
  dashboardId,
  filterValues
}) {

  const [email, setEmail] = useState("");
  const [ccMail, setCcMail] = useState("");
  const [modalIsOpen, setModalOpen] = useState(false);
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [checkCc,setCheckCc] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [attachmentPreviewUrl, setAttachmentPreviewUrl] = useState("");
  const [attachmentFile, setAttachmentFile] = useState(null);
  const [myBlob, setBlob] = useState(null);

  const dispatch = useDispatch();
  const auth = useSelector((state) => state.Auth);

  const sendEmail = async (to, cc, subject, body) => {
    const url = 'https://www.googleapis.com/gmail/v1/users/me/messages/send';
    const data = {
        'raw': btoa(`To: ${to}\nCc:${cc}\nSubject: ${subject}\n\n${body}`).replace(/\+/g, '-').replace(/\//g, '_')
    }
    return new Promise((resolve, reject) => {
      axiosApiInstance.post(url, data)
      .then(async response => {
        console.log(response.data);
        resolve(response);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
    });
  }

  const sendMail = async () =>{
    try{
      if(arr.includes(email) || arr.includes(subject) || arr.includes(message) ||  !emailRegex.test(email)   ) return
      setIsLoading(true)
      
      let cc = "";
      if(ccMail !== undefined){
        cc = ccMail;
      }

      await sendEmail(email, cc,subject, message)
        .then(async response =>{
          console.log(response.data);
          const emailData = {
            "user_id": auth?.profileData.id,
            "dashboard_id": dashboardId,
            "unique_id": response.data.id,
            "description": `${auth?.profileData.first_name} sent 1 email.`,
            "integration_platform": "Google Workspace",
            "status": "Completed",
            "action_type": "Send Email",
            "created_datetime": moment().format(),
            "gw_task_recipients": cc != "" ? `${email},${cc}` : email
          }
          const resp = await dispatch(logExternalIntegrationTask(emailData));
          console.log(resp)
          SimpleToast({
            title: "Success",
            text: "Email successfully sent!",
            iconName: "success",
            iconColor: "green",
          });
        })
        .catch(error => {
          console.log(error);
          SimpleToast({
            title: "Error",
            text: `Email could not be sent. Error Code: ${error.response.status}`,
            iconName: "error",
            iconColor: "#d33",
          });
        });
      setEmail("")
      setSubject("")
      setMessage("")
      setCcMail("")
      setIsLoading(false)
    }
    catch(err){
      setIsLoading(false)
      SimpleToast({
        title: "Error",
        text: err.message,
        iconName: "error",
      });
    }
  }

  useEffect(() => {
    let email = SingleData.email;
    const cc = SingleData?.cc
    SingleData?.payload?.headers.filter((header) => {
      if (header.name === filterValues) {
        email = header.value;
      }
      return email;
    });
    let subject = SingleData.subject;
    SingleData?.payload?.headers.filter((header) => {
      if (header.name === "Subject") {
        subject = header.value;
      }
      return subject;
    });

    setEmail(email);
    setCcMail(cc)
    setSubject(subject);
    SingleData?.body !== undefined ? 
      document.getElementById("myP").innerHTML = SingleData?.body :
      setMessage(SingleData?.body);

    // Get any attachments that are on the selected email.
    let test;
    if(SingleData?.payload?.mimeType !== "multipart/alternative"){
      test = SingleData?.payload?.parts?.filter(part => part.mimeType !== 'multipart/alternative');
    }
    setAttachments(test) 
  }, []);

  async function createFilePreview(file) {
    try{
      const response = await axiosApiInstance.get(`https://gmail.googleapis.com/gmail/v1/users/me/messages/${SingleData?.id}/attachments/${file.body.attachmentId}`);
      console.log(response);

      var base64=(response.data.data).replace(/_/g, '/');
      base64=base64.replace(/-/g, '+');
      var base64Fixed = fixBase64(base64);
      var blob = new Blob([base64Fixed], { type: file.mimeType } );
      
      const url = window.URL.createObjectURL(blob);
      setAttachmentFile(file);
      setAttachmentPreviewUrl(url);
      setBlob(new File([blob], 'test', {type: blob.type}))
      setModalOpen(true);
      // const link = document.createElement('a');
      // link.href = url;
      // link.setAttribute('download', `${file.filename}`);
      // document.body.appendChild(link);
      // link.click();
    }
    catch(err){
      console.log(err)
    }
  }

  function handleDownloadClick() {
    const link = document.createElement('a');
    link.href = attachmentPreviewUrl;
    link.setAttribute('download', `${attachmentFile.filename}`);
    document.body.appendChild(link);
    link.click();
  }

  function fixBase64(binaryData) {
    var base64str = binaryData// base64 string from  thr response of server
    var binary = atob(base64str.replace(/\s/g, ''));// decode base64 string, remove space for IE compatibility
    var len = binary.length;         // get binary length
    var buffer = new ArrayBuffer(len);         // create ArrayBuffer with binary length
    var view = new Uint8Array(buffer);         // create 8-bit Array
  
    // save unicode of binary data into 8-bit Array
    for (var i = 0; i < len; i++)
        view[i] = binary.charCodeAt(i);
  
   return view;
  }

  const arr=[undefined,"",null]
  const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const checkSingleData = SingleData && Object.keys(SingleData).length > 1;
  return (
    <div className="mail-form d-flex flex-column justify-content-evenly h-90 px-3 pb-2">
      <div className="d-flex justify-content-between align-items-center gap-3">
        <div className="w-40 d-flex justify-content-between align-items-center" style={{marginBottom:'1rem'}}>
          <Button
            size="md"
            variant="none"
            className="poppins"
            onClick={() => setData(false)}
            style={{
              width: "80px",
              height: "auto",
              backgroundColor: "var(--secondry-color)",
              borderRadius: 10
            }}
          >
            <p style={{ color: "#FFF", margin: 0, padding: 0 }}>Gmail</p>
          </Button>
        </div>
      </div>
      <div
        className="form-card "
        style={{
          backgroundColor: SingleData?.body == undefined ? "rgb(39 46 74 / 40%)" : null,
          padding: 20,
          borderRadius: 14,
        }}
      >
        <div className="w-40 d-flex justify-content-between align-items-center gap-2">
          <p
            style={{
              color: "rgb(255 255 255 / 48%)",
              fontSize: 13,
              margin: 0,
              padding: 0,
            }}
          >
            New Message
          </p>
          {isLoad ? (
            <Spinner animation="border" variant="primary" />
          ) : (
            <>
              {SingleData && Object.keys(SingleData).length > 1 && (
                <img
                  // className="position-absolute top-50 end-0 translate-middle"
                  style={{
                    width: 20,
                    height: 20,
                    objectFit: "contain",
                    cursor: "pointer",
                  }}
                  alt={"delete-icon"}
                  onClick={() => {
                    deletemailData(SingleData.id);
                  }}
                  src={Delete}
                />
              )}
            </>
          )}
        </div>
        <Form>
          <Form.Group className="my-1">
            <Form.Label className="py-2">
              <p
                style={{
                  color: "rgb(255 255 255 / 48%)",
                  fontSize: 18,
                  margin: 0,
                  padding: 0,
                  fontWeight: 400,
                }}
              >
                {filterValues}
              </p>
              <Form.Control
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                style={{
                  background: "none",
                  border: 0,
                  fontSize: 14,

                  color: "#fff ",
                  outline: "none",
                }}
                type="email"
                placeholder="Enter"
                disabled={checkSingleData}
              />
                <Form.Label  onMouseOut={() => setCheckCc(false)} onMouseOver={() => setCheckCc(true)}
>
              <p
                style={{
                  color: "rgb(255 255 255 / 48%)",
                  fontSize: 14,
                  margin: 0,
                  padding: 0,
                  fontWeight: 400,
                  
                }}
              >
                Cc:
              </p>
              <Form.Control
                onChange={(e) => setCcMail(e.target.value)}
                value={ccMail}
                style={{
                  background: "none",
                  border: 0,
                  fontSize: 14,
                  width: SingleData?.cc && checkCc  ? 250 :100,
                  color: "#fff ",
                  outline: "none",
                }}
                type="email"
                disabled={checkSingleData}
              />
              </Form.Label>
            </Form.Label>
          </Form.Group>

          <Form.Group>
          <Modal show={modalIsOpen} onHide={() => setModalOpen(false)}>
            <Modal.Header>
              File Preview
              <a onClick={() => handleDownloadClick()}>
                <img
                  src={DownloadIcon}
                  style={{
                    width: 25,
                    height: 25,
                    objectFit: "contain",
                    cursor: "pointer"
                  }}
                />
                {/* Download */}
              </a>
            </Modal.Header>
            <Modal.Body style={{ justifyContent: "center", alignItems: "center", display: "flex" }}>
              <FilePreview
                  type={"file"}
                  file={myBlob}
                />
            </Modal.Body>
            <Modal.Footer>
              <a style={{cursor: "pointer"}} onClick={() => setModalOpen(false)}>Close</a>
            </Modal.Footer>
          </Modal>
            <Form.Label className="pb-2">
              {" "}
              <p
                style={{
                  color: "rgb(255 255 255 / 48%)",
                  fontSize: 18,
                  margin: 0,
                  padding: 0,
                  fontWeight: 400,
                }}
              >
                Subject
              </p>
              <Form.Control
                onChange={(e) => setSubject(e.target.value)}
                type="text"
                value={subject}
                style={{
                  background: "none",
                  border: 0,
                  fontSize: 14,
                  color: "#fff ",
                  outline: "none",
                }}
                placeholder="Subject"
                disabled={checkSingleData}
              />
            </Form.Label>
          </Form.Group>

          { attachments?.length > 0 ? 
            <Form.Group>
              <Form.Label className="pb-2" style={{justifyContent: "start"}}>
                {" "}
                <p
                  style={{
                    color: "rgb(255 255 255 / 48%)",
                    fontSize: 18,
                    margin: "0 10px 0 0",
                    padding: 0,
                    fontWeight: 400,
                  }}
                >
                  Attachments
                </p>
                { attachments?.map((item, index) => (
                  <a 
                    style={{
                      cursor: "pointer",
                      marginRight: 5
                    }}
                    onClick={() => createFilePreview(item)}
                  >
                    {item.filename}
                  </a>
                )) }
              </Form.Label>
            </Form.Group>
          : null}
          
          {
            !checkSingleData ? (
              <textarea
                placeholder="Message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                style={{
                  outline: "none",
                  padding: "0",
                  background: "none",
                  border: 0,
                  color: "#FFF",
                  fontSize: 14,
                  resize: "none",
                  width: "100%",
                  height: "180px",
                }}
                disabled={checkSingleData}
              />
            ) 
            : (
              <div id="myP"
                style={{
                  outline: "none",
                  padding: "0",
                  background: "none",
                  border: 0,
                  color: "#FFF",
                  fontSize: 14,
                  resize: "none",
                  width: "100%",
                  position: 'absolute',
                  left: 0
                }}>
              </div>
            )
          }
          {/* <div id="myP"
          style={{
            outline: "none",
            padding: "0",
            background: "none",
            border: 0,
            color: "#FFF",
            fontSize: 14,
            resize: "none",
            width: "100%",
            position: 'absolute',
            left: 0
          }}></div> */}
          {/* <textarea
            placeholder="Message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            style={{
              outline: "none",
              padding: "0",
              background: "none",
              border: 0,
              color: "#FFF",
              fontSize: 14,
              resize: "none",
              width: "100%",
              height: "180px",
            }}
            disabled={checkSingleData}
          /> */}
          <div className="send-btn">
            <Row>
              <Col
                lg={12}
                md={12}
                sm={12}
                className="d-flex justify-content-center align-item-center mt-3 position-relative w-90"
              >
                {SingleData && Object.keys(SingleData).length < 1 && (
                  <Button
                    size="md"
                    variant="none"
                    onClick={sendMail}
                    style={{
                      backgroundColor: "var(--white-color)",
                      borderRadius: 100,
                      padding: "10px 20px",
                      display: "flex"
                    }}
                    disabled={(arr.includes(email) || arr.includes(subject) || arr.includes(message) || !emailRegex.test(email)  ? true : false)}
                  >
                   { isLoading && (<Spinner animation="border" variant="primary" style={{marginRight: 10, height: 25, width: 25}} />)}
                   <p
                      style={{
                        margin: 0,
                        padding: 0,
                        color: "var(--secondry-color)",
                        borderRadius: 10,
                        fontWeight: "bold",
                      }}
                    > 
                      Send with Gmail
                    </p>
                  </Button>
                )}

                {/* {isLoad ? (
                 <Spinner animation="border" variant="primary" />
                ) : (
                  <>
                    {SingleData && Object.keys(SingleData).length > 1 && (
                      <img
                        className="position-absolute top-50 end-0 translate-middle"
                        style={{
                          width: 20,
                          height: 20,
                          objectFit: "contain",
                          cursor: "pointer",
                        }}
                        alt={"delete-icon"}
                        onClick={() => {
                          deletemailData(SingleData.id);
                        }}
                        src={Delete}
                      />
                    )}
                  </>
                )} */}
              </Col>
            </Row>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default EmailCard;
