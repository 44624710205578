import React, { useEffect, useState } from "react";

import { Button } from "react-bootstrap";
import "./index.css";

import "react-datepicker/dist/react-datepicker.css";
import GoogleCalendar from "./GooglCalendar";
import GoogleEmail from "./GoogleEmail";
import GoogleDrive from "./GoogleDrive";
import { Navigate, useNavigate } from "react-router-dom";
import { SimpleToast } from "../../utils/Toast";
import axiosApiInstance from "../../utils/axiosInterceptor";
import UnreadBell from "../../assets/images/bell-icon.png";
import moment from "moment";
import closeIcon from "../../assets/images/edit-cancel.png";

const GoogleCalendarCard = ({ pinnedCollab, pinnedProd, expanded, dashboardId, isPinnedGoogle }) => {
	const [active, setActive] = useState(0);
	const [calendarData, setCalendarData] = useState(null);
	const [unreadEmails, setUnreadEmails] = useState(0);
	const [hasGoogleToken, setGoogleToken] = useState(false);
	const navigate = useNavigate();

	const Workspace = [
		{
			btn: "Google Calendar",
		},
		{
			btn: "Gmail",
		},
		{
			btn: "Google Drive",
		},
	];

	const checkToken = () => {
		const token = localStorage.getItem("googleAuthRefreshToken");
		if (!token) {
			// <Navigate to={"/settings"} state={{ openConnection: true }} />;
			return SimpleToast({
				title: "Warning",
				text: "Please authenticate with google first",
				iconName: "warning",
				iconColor: "#FACEA8",
			});
			setGoogleToken(false);
		} else {
			setGoogleToken(true);
		}
	};
	const handleClick = async (index) => {
		await checkToken();
		setActive(index + 1);
	};

	function getData(msgID) {
		return new Promise((resolve, reject) => {
			axiosApiInstance
				.get(`https://gmail.googleapis.com/gmail/v1/users/me/messages/${msgID}`)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => reject(err));
		});
	}

	const showEmailIdData = () => {
		axiosApiInstance
			.get(`https://gmail.googleapis.com/gmail/v1/users/me/messages?maxResults=20`, {
				params: {
					labelIds: "INBOX",
				},
			})

			.then(async (info) => {
				const ids = info?.data?.messages?.map((obj) => obj.id);
				await Promise.all((ids || [])?.map((id) => getData(id))).then(async (response) => {
					// console.log(response,'my response email')
					if (response?.length) {
						const getEmailsCount = response.reduce((acc, curr) => {
							if (curr.data.labelIds.includes("UNREAD")) {
								acc += 1;
								return acc;
							}
							return acc;
						}, 0);
						setUnreadEmails(getEmailsCount);
					}
				});
			})
			.catch((err) => {
				SimpleToast({
					title: "Error",
					text: `${err.message}`,
					iconName: "error",
					iconColor: "#d33",
				});
			});
	};

	const showGoogleCalendarData = async () => {
		await axiosApiInstance
			.get("https://www.googleapis.com/calendar/v3/calendars/primary/events", {
				params: {
					timeMin: moment().startOf("day").toISOString(),
					timeMax: moment().endOf("day").toISOString(),
				},
			})
			.then((res) => {
				if (res?.data?.items?.length) {
					let mostRecentMeeting;
					let diffHours = 24;

					for (let i = 0; i < res?.data?.items?.length; i++) {
						if (!res?.data?.items[i]?.start?.dateTime) break;
						let t1 = moment(res?.data?.items[i]?.start?.dateTime).hour();
						let t2 = moment().hour();
						if (Math.abs(t1 - t2) < diffHours) {
							// Check difference in hours (abs value)
							diffHours = Math.abs(t1 - t2);
							mostRecentMeeting = res?.data?.items[i]; // Set most recent meeting
						}
					}

					const getAllTheSameDaysDateOfMonth = () => {
						const monthDate = (mostRecentMeeting?.start?.dateTime && new Date(mostRecentMeeting?.start?.dateTime)) || new Date();
						const year = monthDate?.getFullYear() || new Date().getFullYear();
						const month = monthDate?.getMonth() || new Date().getMonth();
						const dayOfWeek = monthDate?.getDay() || new Date().getDay();
						const lastDay = new Date(year, month + 1, 0).getDate();
						const dateToday = monthDate?.getDate() || new Date().getDate();
						const arr = [];

						// Loop through all the days in the month
						for (let day = 1; day <= lastDay; day++) {
							const date = new Date(year, month, day);
							// Check if the day is a Monday
							if (date.getDay() === dayOfWeek) {
								arr.push(date.getDate());
							}
						}

						const dayCountNum = arr.reduce((acc, curr, index) => {
							if (curr === dateToday) {
								acc = index + 1;
								return acc;
							}
							return acc;
						}, 0);

						const dayName = moment(monthDate || new Date()).format("dddd");
						let dayCount;
						if (dayCountNum === 1) dayCount = "first";
						if (dayCountNum === 2) dayCount = "second";
						if (dayCountNum === 3) dayCount = "third";
						if (dayCountNum === 4) dayCount = "fourth";
						if (dayCountNum === 5) dayCount = "fifth";
						return { dayCount, dayName, dayCountNum };
					};

					const printUserRecurr = (key) => {
						if ((mostRecentMeeting?.recurrence || []).length > 0) {
							const str = mostRecentMeeting?.recurrence[0];
							const mydate = (mostRecentMeeting?.start?.dateTime && new Date(mostRecentMeeting?.start?.dateTime)) || new Date();
							const newObj = {
								"my key": "Does not repeat",
								"RRULE:FREQ=DAILY": "Daily",
								[`RRULE:FREQ=WEEKLY;BYDAY=${getAllTheSameDaysDateOfMonth().dayName.slice(0, 2).toUpperCase()}`]: `Weekly on ${
									getAllTheSameDaysDateOfMonth().dayName
								}`,
								[`RRULE:FREQ=MONTHLY;BYDAY=${getAllTheSameDaysDateOfMonth().dayCountNum}${getAllTheSameDaysDateOfMonth()
									.dayName.slice(0, 2)
									.toUpperCase()}`]: `Monthly on the ${getAllTheSameDaysDateOfMonth().dayCount} ${getAllTheSameDaysDateOfMonth().dayName} `,
								"RRULE:FREQ=YEARLY": `Annually on ${moment(mydate).format("MMMM DD")}`,
								"RRULE:FREQ=WEEKLY;BYDAY=MO,TU,WE,TH,FR": "Every weekday (Monday to Friday)",
							};
							let result;
							if (Object.keys(newObj).includes(str)) result = newObj[str];
							else result = "Does not repeat";
							return result;
						} else {
							return key;
						}
					};

					const obj = {
						time: moment(mostRecentMeeting?.start?.dateTime).format("h:mm A"),
						recurrence: printUserRecurr(),
						name: mostRecentMeeting?.summary,
					};
					setCalendarData(obj);
				}
			})
			.catch((err) => {});
	};

	useEffect(() => {
		const token = localStorage.getItem("googleAuthRefreshToken");
		if (!token) {
			//navigate(`/settings`,{state:{openConnection: true}});
			// SimpleToast({
			//   title: "Warning",
			//   text: "Please authenticate with google first",
			//   iconName: "warning",
			//   iconColor: "#FACEA8",
			// });
		} else {
			showGoogleCalendarData();
			showEmailIdData();
		}
	}, []);
	return (
		<div
			className="google-calendar"
			style={{
				backgroundColor: "var(--primary-color)",
				height: pinnedCollab ? "calc(100vh - 50vh)" : "calc(100vh - 25vh)",
				borderRadius: 10,
				padding: 25,
				overflow: "scroll",
				position: "relative",
			}}
		>
			{active === 0 ? (
				<>
					<div className="d-flex justify-content-center align-items-start gap-4 flex-column">
						<div className="d-flex justify-content-between align-items-center w-100">
							<h4 style={{ color: "#FFF", margin: 0, padding: 0, fontWeight: 400, fontSize: 18 }}>Google Workspace</h4>
							<img
								style={{ width: 20, height: 20, objectFit: "contain", cursor: "pointer", marginLeft: "20px" }}
								alt={"remove-icon"}
								src={closeIcon}
								onClick={() => isPinnedGoogle(false)}
							/>
						</div>
						{Workspace.map((item, index) => (
							<div className="d-flex justify-content-between align-items-center gap-4" key={index}>
								<div className="w-40">
									<Button
										size="md"
										variant="none"
										className="poppins"
										onClick={() => handleClick(index)}
										style={{
											width: "165px",
											height: "60px",
											backgroundColor: "var(--secondry-color)",
											borderRadius: 10,
										}}
									>
										<p style={{ color: "#FFF", margin: 0, padding: 0 }}>{item.btn}</p>
									</Button>
								</div>
								<div className="w-60">
									<h6 style={{ color: "#FFF", margin: 0, padding: 0 }}>
										{index === 0 && calendarData?.time}
										{index === 1 && unreadEmails > 0 ? (
											<div style={{ display: "flex", marginBottom: "2%" }}>
												<img src={UnreadBell} style={{ height: 20, maxWidth: 20 }} />
												<p style={{ marginLeft: 5, marginBottom: 0 }}>{unreadEmails}</p>
											</div>
										) : null}
									</h6>
									<p
										style={{
											color: "rgb(255 255 255 / 48%)",
											marginBottom: 2,
											padding: 0,
											fontSize: 13,
										}}
									>
										{index === 0 && calendarData?.recurrence ? calendarData?.recurrence : ""}{" "}
										{index === 0 && calendarData?.name ? " " + calendarData?.name : ""}
										{index === 1 && unreadEmails > 0 ? "Unread Emails" : null}
									</p>
								</div>
							</div>
						))}
					</div>
				</>
			) : (
				<>
					{active === 1 ? (
						<GoogleCalendar
							setActive={setActive}
							expand={expanded}
							pinnedCollab={pinnedCollab}
							pinnedProd={pinnedProd}
							dashboardId={dashboardId}
							hasGoogleToken={hasGoogleToken}
						/>
					) : active === 2 ? (
						<GoogleEmail
							setActive={setActive}
							expand={expanded}
							pinnedCollab={pinnedCollab}
							pinnedProd={pinnedProd}
							dashboardId={dashboardId}
							hasGoogleToken={hasGoogleToken}
						/>
					) : active === 3 ? (
						<GoogleDrive
							setActive={setActive}
							expand={expanded}
							pinnedCollab={pinnedCollab}
							pinnedProd={pinnedProd}
							dashboardId={dashboardId}
							hasGoogleToken={hasGoogleToken}
						/>
					) : (
						<>Not Found</>
					)}
				</>
			)}
		</div>
	);
};

export default GoogleCalendarCard;
