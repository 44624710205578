import React, { useState } from "react";

import { Button } from "react-bootstrap";
import Navbar from "./Navbar";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { signInAction } from "../redux/actions/AuthAction";
import { SimpleToast } from "../utils/Toast";
import { Link} from "react-router-dom";
function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loginAuth = useSelector((state) => state.Login);
  const fillOutField = (field_name) => {
    SimpleToast({
      title: "Error",
      text: ` Please Fill out ${field_name}`,
      iconName: "error",
      iconColor: "#d33",
    });
  };
  const FormSubmit = async (e) => {
    e.preventDefault();
    if (!email) {
      fillOutField("Email");
    } else if (!password) {
      fillOutField("Password");
    } else {
      const response = await dispatch(signInAction(email, password));
      if (!response?.data?.token && response !== "error") {
        SimpleToast({
          title: "Error",
          text: `User Not Found`,
          iconName: "error",
          iconColor: "#d33",
        });
      } else if (response === "error") {
        SimpleToast({
          title: "Error",
          text: "Oops, something went wrong.",
          iconName: "error",
          iconColor: "#d33",
        });
      } else if (response?.data?.is_free === 1 && response?.data?.is_card_added === 0) {
        // setRedirectToAddCard(true);
        if(response.data != undefined && response.data.id != undefined){
          localStorage.setItem("userId", response.data.id);
        }

        navigate("/AddCard", { replace: true });

      } else if (response?.data?.last_login_datetime === null) {
        if(response.data != undefined && response.data.id != undefined){
          localStorage.setItem("userId", response.data.id);
        }
        navigate("/usersignup", { state: response?.data }, { replace: true });
      } 
      // else {
      //   localStorage.setItem("userId", response?.data?.id);
      //   setTimeout(() => {
      //     navigate("/profile", { replace: true });
      //   }, 1500);
      // }
      else {
        const isLoginCount = response?.data?.is_login;
        // const createdTimestamp = response?.data?.created_at;
        const createdTimestamp = new Date(response?.data?.created_at).getTime(); // Convert to timestamp

        const sevenSeptember2023 = new Date('2023-09-07').getTime();
      

      
        if (isLoginCount === 0  && createdTimestamp > sevenSeptember2023) {
          localStorage.setItem("userId", response?.data?.id);
          console.log("ok");

          setTimeout(() => {
            navigate("/editprofile", { replace: true });
          }, 1500);
        } else if (isLoginCount >= 1 || createdTimestamp < sevenSeptember2023) {
          console.log("notok");

          localStorage.setItem("userId", response?.data?.id);
          setTimeout(() => {
            navigate("/profile", { replace: true });
          }, 1500);
        }
      }


    }
  };

  return (
    <div className="main_hero_div" id="main">
      <Navbar />

      <div className="container" id="sign_up_container">
        <div className="d-flex justify-content-center career_main_div">
          <div className="border_div_career my-5"></div>
        </div>

        <h4
          className="display-5 text-white text-center mb-4"
          style={{ fontWeight: "300" }}
        >
          Login
        </h4>

        <form className="g-3" autocomplete="off" onSubmit={FormSubmit}>
          <div
            style={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
              gap: 10,
            }}
          >
            <div className="input_label">
              <label className="label">Email</label>
              <input
                type="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="input_label">
              <label className="label ">Password</label>
              <input
                type="password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                autocomplete="off"
              />
            </div>
          </div>
          <div className="d-flex justify-content-center">
            <Button
              disabled={loginAuth.isLoginLoading}
              type="submit"
              className="mt-3 ms-3 sign_up"
            >
              {loginAuth.isLoginLoading ? (
                <div
                  class="spinner-border spinner-border-sm text-primary"
                  role="status"
                />
              ) : (
                "SUBMIT"
              )}
            </Button>
            <Link style={{
              marginTop:'25px',
              marginLeft:'15px'
            }} to="/forgot_password">Forgot/Change Password</Link>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Login;
