import "../../src/Components/css/ThankYouMsg.css";
import Navbar from "./Navbar";
import Footerabout from "./Footerabout";
import { Col, Container, Row } from "react-bootstrap";
import ThankYouMsggfg from "../assets/images/ThankYouMsg.gif";
import { Link } from 'react-router-dom';

const ThankYouMsg = () => {
  return (
    <div className="ThankYou">
      <Container>
        <Row>
          <Col>
            <Navbar />
          </Col>
        </Row>
        <Row >
        <div className="mt-5 ThankYou_div " >
          <Col  >
            <div className="ThankYou_main" >
            <img src={ThankYouMsggfg} alt="ThankYouMsg" />
            <h2>Your Payment Is complete.</h2>
            <p>Admin will send you your password via email within 24 hours.</p>
            </div>
            
          </Col>
          </div>
        </Row>
        <Row>
          <Col className="btn_col"  >
          <Link to="/">
              <button className="go-to-home-btn">Go to Home</button>
            </Link>
          </Col>
        </Row>
     
        <Row>
          <Col>
            
          </Col>
        </Row>
      </Container>
      <Footerabout />
    </div>
     
  );
};
export default ThankYouMsg;
