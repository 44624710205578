import React, { useState } from "react";
import mainLogo from "../../assets/images/main-logo.png";
import pin from "../../assets/images/pinned.png";
import "./index.css";
import { Button } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { updateDashboardUserSettings, createDashboardUserSettings } from "../../redux/actions/DashboardAction";
import { useDispatch, useSelector } from "react-redux";

const Header = ({
	left,
	dashboard,
	dashboardId,
	pinnedProdHook,
	pinnedCollabHook,
	hideProdBtn,
	hideCollabBtn,
	pinnedGoogleHook,
	hideGoogleBtn,
	pinnedSaleForceHook,
	hideSaleForceBtn,
}) => {
	const location = useLocation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const auth = useSelector((state) => state.Auth);
	const dashboardRedux = useSelector((state) => state.dashboardReducer);
	const { data } = useSelector((state) => state.getSFData);

	const id = dashboardRedux?.getDashboardSettingsData?.length > 0 ? dashboardRedux?.getDashboardSettingsData[0].id : null;
	const [settingId, setSettingId] = useState(id);

	const changeSetting = async (hook, val) => {
		let body;
		if (dashboardRedux?.getDashboardSettingsData?.length > 0) {
			body = {
				id: settingId,
			};

			if (hook === "prod") {
				pinnedProdHook(val);
				body.updates = {
					prod_componet_pinned: 1,
				};
			}
			if (hook === "collab") {
				pinnedCollabHook(val);
				body.updates = {
					collab_component_pinned: 1,
				};
			}
			if (hook === "Google") {
				pinnedGoogleHook(val);
				body.updates = {
					google_component_pinned: 1,
				};
			}
			if (hook === "SF") {
				pinnedSaleForceHook(val);
				body.updates = {
					sf_component_pinned: 1,
				};
			}

			await dispatch(updateDashboardUserSettings(body));
		} else {
			body = {
				user: auth?.profileData?.id,
				dashboard: dashboardId,
				prod_component_pinned: 0,
				google_component_pinned: 0,
				sf_component_pinned: 0,
				collab_component_pinned: 0,
			};
			if (hook === "prod") {
				pinnedProdHook(val);
				body.prod_component_pinned = 1;
			}
			if (hook === "collab") {
				pinnedCollabHook(val);
				body.collab_component_pinned = 1;
			}
			if (hook === "Google") {
				pinnedGoogleHook(val);
				body.google_component_pinned = 1;
			}
			if (hook === "SF") {
				pinnedSaleForceHook(val);
				body.sf_component_pinned = 1;
			}
			const resp = await dispatch(createDashboardUserSettings(body));
			setSettingId(resp.data.insertId);
		}
	};

	return (
		<div
			style={
				dashboard && {
					cursor: "pointer",
					width: 500,
					position: "relative",
					left: 0,
					top: 0,
					justifyContent: "space-between",
					display: "flex",
				}
			}
			className={dashboard ? "dashboardContainer" : "mainLogoContainer"}
		>
			<img onClick={() => navigate("/profile")} alt="logo" src={mainLogo} />
			<span>/</span>
			<span style={{ textTransform: "capitalize" }}>{location?.pathname.substring(1) === "SalesDashboard" ? "Sales Dashboard" : location?.pathname.substring(1)}</span>
			<div hidden={!dashboard} className="buttons">
				<div style={{ paddingLeft: 15 }}>
					<Button
						hidden={hideProdBtn}
						onClick={() => changeSetting("prod", true)}
						style={{
							backgroundColor: "var(--secondry-color)",
							borderRadius: 25,
							height: 35,
							cursor: "pointer",
							// width: 150,
							minWidth: "max-content",
							display: "inline-flex",
							alignItems: "center",
						}}
					>
						<img style={{ width: 19 }} src={pin} />
						<p style={{ color: "#FFF", margin: 0, paddingLeft: 5 }}>Productivity</p>
					</Button>
				</div>
				<div style={{ paddingLeft: 15 }}>
					<Button
						hidden={hideCollabBtn}
						onClick={() => changeSetting("collab", true)}
						style={{
							backgroundColor: "var(--secondry-color)",
							borderRadius: 25,
							cursor: "pointer",
							// width: 165,
							height: 35,
							minWidth: "max-content",
							display: "inline-flex",
							alignItems: "center",
						}}
					>
						<img style={{ width: 19 }} src={pin} />
						<p style={{ color: "#FFF", margin: 0, paddingLeft: 5 }}>Collaboration</p>
					</Button>
				</div>
				{data?.length && (
					<div style={{ paddingLeft: 15 }}>
						<Button
							hidden={hideSaleForceBtn}
							onClick={() => changeSetting("SF", true)}
							style={{
								backgroundColor: "var(--secondry-color)",
								borderRadius: 25,
								cursor: "pointer",
								// width: 165,
								height: 35,
								minWidth: "max-content",
								display: "inline-flex",
								alignItems: "center",
							}}
						>
							<img style={{ width: 19 }} src={pin} />
							<p style={{ color: "#FFF", margin: 0, paddingLeft: 5 }}>Salesforce</p>
						</Button>
					</div>
				)}
				{localStorage.getItem("googleAuthAccessToken") !== null && (
					<div style={{ paddingLeft: 15 }}>
						<Button
							hidden={hideGoogleBtn}
							onClick={() => changeSetting("Google", true)}
							style={{
								backgroundColor: "var(--secondry-color)",
								borderRadius: 25,
								cursor: "pointer",
								// width: 165,
								height: 35,
								minWidth: "max-content",
								display: "inline-flex",
								alignItems: "center",
							}}
						>
							<img style={{ width: 19 }} src={pin} />
							<p style={{ color: "#FFF", margin: 0, paddingLeft: 5 }}>Google Workspace</p>
						</Button>
					</div>
				)}
			</div>
		</div>
	);
};

export default Header;
