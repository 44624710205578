import axios from "axios";
import { useFormik } from "formik";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import leadsIcon from "../assets/images/leads-icon.png";
import { logExternalIntegrationTask } from "../redux/actions/TasksAction";
import {
  salesforceLeadsIndustry,
  salesforceLeadsRatings,
  salesforceOwnerships,
} from "../utils/constant";
import ArrowSelect from "./ArrowSelect";
import Input from "./Input";
import moment from "moment";
import { SimpleToast } from "../utils/Toast";

axios.defaults.headers.post["Content-Type"] = "application/json";

const EditAccount = ({ accountId, dashboardId, isCreateMode = false }) => {
  const [account, setAccount] = useState();
  const SFData = useSelector((state) => state.getSFData);
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.Auth);
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({
    accountId: accountId || "",
    isCreateMode: isCreateMode || false,
  });
  const baseUrl = useMemo(
    () => SFData?.getSFData?.data?.[0]?.url,
    [SFData?.getSFData?.data]
  );

  const getAccountById = useCallback(async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/services/data/v57.0/sobjects/Account/${state.accountId}`
      );

      if (response.data) {
        setAccount(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  }, [state.accountId, baseUrl]);

  useEffect(() => {
    if (!state.isCreateMode) {
      if (state.accountId) {
        getAccountById();
      }
    }
  }, [state.accountId, getAccountById, state.isCreateMode]);

  const checkEvents = (payload) => {
    const events = [];
    const eventData = {
      unique_id: `${baseUrl}/${account.Id}`,
      user_id: auth?.profileData.id,
      dashboard_id: dashboardId,
      description: `${auth.profileData.first_name} changed 1 Account name`,
      integration_platform: "Salesforce",
      status: "Completed",
      action_type: "Account name changed",
      created_datetime: moment().format(),
    };
    if (payload.Name !== account.Name) {
      events.push({ ...eventData });
    }

    return events;
  };

  const updateAccount = async (payload) => {
    const salesforceLoginAccessToken = localStorage?.getItem(
      "salesforceAccessToken"
    );
    delete payload.Ownership;
    delete payload.Number;
    delete payload.Rating;
    try {
      const response = await axios.patch(
        `${baseUrl}/services/data/v57.0/sobjects/Account/${state.accountId}`,
        payload,
        {
          headers: {
            "Access-Control-Allow-Methods": "PATCH",
            Authorization: `Bearer ${salesforceLoginAccessToken}`,
            "Access-Control-Allow-Headers": "*",
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      if (response.status >= 200 && response.status <= 300) {
        SimpleToast({
          title: "Success",
          text: "Account updated successfully.",
          iconName: "success",
          iconColor: "green",
        });
        const eventData = checkEvents(payload);
        if (eventData) {
          eventData.forEach(async (event) => {
            await dispatch(logExternalIntegrationTask(event));
          });
        }
        getAccountById();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const createAccount = async (payload) => {
    const salesforceLoginAccessToken = localStorage?.getItem(
      "salesforceAccessToken"
    );
    delete payload.Ownership;
    delete payload.Number;
    delete payload.Rating;
    try {
      const response = await axios.post(
        `${baseUrl}/services/data/v57.0/sobjects/Account`,
        payload,
        {
          headers: {
            "Access-Control-Allow-Methods": "POST",
            Authorization: `Bearer ${salesforceLoginAccessToken}`,
            "Access-Control-Allow-Headers": "*",
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      if (response.data) {
        SimpleToast({
          title: "Success",
          text: "Account created successfully.",
          iconName: "success",
          iconColor: "green",
        });
        const eventData = {
          unique_id: `${baseUrl}/${response.data.id}`,
          user_id: auth?.profileData.id,
          dashboard_id: dashboardId,
          description: `${auth.profileData.first_name} created 1 Account`,
          integration_platform: "Salesforce",
          status: "Completed",
          action_type: "Create Account",
          created_datetime: moment().format(),
        };
        await dispatch(logExternalIntegrationTask(eventData));
        setState({ accountId: response.data.id, isCreateMode: false });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const initialValues = {
    Name: account?.Name || "",
    Ownership: account?.Ownership || "",
    ParentId: account?.ParentId || "",
    Number: account?.Number || "",
    Website: account?.Website || "",
    Type: account?.Type || "",
    Industry: account?.Industry || "",
    NumberOfEmployees: account?.NumberOfEmployees || "",
    AnnualRevenue: account?.AnnualRevenue || "",
    SicDesc: account?.SicDesc || "",
    Phone: account?.Phone || "",
    Rating: account?.Rating || "",
  };

  const validate = (values) => {
    const errors = {};
    if (!values.Name) {
      errors.Name = "Required";
    }
    return errors;
  };

  const handleSubmit = async (e) => {
    const payload = { ...e };

    setIsLoading(true);
    if (state.isCreateMode) {
      await createAccount(payload);
    } else {
      await updateAccount(payload);
    }
    setIsLoading(false);
  };

  const formik = useFormik({
    initialValues,
    validate,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  const isFormikValid = useMemo(() => {
    return !formik.errors.Name;
  }, [formik.errors]);

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            flexDirection: "row",
            display: "flex",
            alignItems: "center",
          }}
        >
          <div
            style={{
              backgroundColor: "var(--bg-color)",
              borderRadius: 7,
              padding: 10,
            }}
          >
            <img
              src={leadsIcon}
              style={{
                width: 20,
                height: 20,
                objectFit: "contain",
              }}
              alt={"lead-icon"}
            />
          </div>
          <div style={{ marginLeft: 10 }}>
            <p
              style={{
                color: "#FFF",
                padding: 0,
                margin: 0,
                fontSize: 12,
              }}
            >
              Accounts
            </p>
            {/* <p
              style={{
                color: "grey",
                padding: 0,
                margin: 0,
                fontSize: 12,
              }}
            >
              Owner: Lf
            </p> */}
          </div>
        </div>
        <div
          style={{
            cursor: "pointer",
            padding: 10,
          }}
        ></div>
      </div>

      <div style={{ marginTop: "2vh" }}>
        <div style={{ display: "flex", flexFlow: "row nowrap" }}>
          <div
            style={{
              display: "flex",
              flexFlow: "column wrap",
              flexBasis: "50%",
            }}
          >
            <p style={{ color: "grey", fontSize: "14px", marginBottom: "0px" }}>
              Account Name *
            </p>
            <Input
              value={formik.values.Name}
              onChange={(e) => formik.setFieldValue("Name", e.target.value)}
            />
          </div>
          <div style={{ flexBasis: "50%" }}>
            <div style={{ display: "flex", alignItems: "center", gap: "2px" }}>
              <p
                style={{ color: "grey", fontSize: "14px", marginBottom: "0px" }}
              >
                Ownership
              </p>
              <ArrowSelect
                options={salesforceOwnerships}
                onChange={(e) =>
                  formik.setFieldValue("Ownership", e.target.value)
                }
              />
            </div>
            <p
              style={{ color: "white", fontSize: "16px", marginBottom: "0px" }}
            >
              {formik.values.Ownership}
            </p>
          </div>
        </div>

        <div style={{ display: "flex", flexFlow: "row nowrap" }}>
          <div
            style={{
              display: "flex",
              flexFlow: "column wrap",
              flexBasis: "50%",
            }}
          >
            <p style={{ color: "grey", fontSize: "14px", marginBottom: "0px" }}>
              Parent Account
            </p>
            <Input
              value={formik.values.ParentId}
              onChange={(e) => formik.setFieldValue("ParentId", e.target.value)}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexFlow: "column wrap",
              flexBasis: "50%",
            }}
          >
            <p style={{ color: "grey", fontSize: "14px", marginBottom: "0px" }}>
              Account Number
            </p>
            <Input
              value={formik.values.Number}
              onChange={(e) => formik.setFieldValue("Number", e.target.value)}
            />
          </div>
        </div>

        <div style={{ display: "flex", flexFlow: "row nowrap" }}>
          <div
            style={{
              display: "flex",
              flexFlow: "column wrap",
              flexBasis: "50%",
            }}
          >
            <p style={{ color: "grey", fontSize: "14px", marginBottom: "0px" }}>
              Website
            </p>
            <Input
              value={formik.values.Website}
              onChange={(e) => formik.setFieldValue("Website", e.target.value)}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexFlow: "column wrap",
              flexBasis: "50%",
            }}
          >
            <p style={{ color: "grey", fontSize: "14px", marginBottom: "0px" }}>
              Type
            </p>
            <Input
              value={formik.values.Type}
              onChange={(e) => formik.setFieldValue("Type", e.target.value)}
            />
          </div>
        </div>

        <div style={{ display: "flex", flexFlow: "row nowrap" }}>
          <div style={{ flexBasis: "50%" }}>
            <div style={{ display: "flex", alignItems: "center", gap: "2px" }}>
              <p
                style={{ color: "grey", fontSize: "14px", marginBottom: "0px" }}
              >
                Industry
              </p>
              <ArrowSelect
                options={salesforceLeadsIndustry}
                onChange={(e) =>
                  formik.setFieldValue("Industry", e.target.value)
                }
              />
            </div>
            <p
              style={{ color: "white", fontSize: "16px", marginBottom: "0px" }}
            >
              {formik.values.Industry}
            </p>
          </div>
          <div style={{ flexBasis: "50%" }}>
            <p style={{ color: "grey", fontSize: "14px", marginBottom: "0px" }}>
              No. of Employees
            </p>
            <Input
              value={formik.values.NumberOfEmployees}
              onChange={(e) =>
                formik.setFieldValue("NumberOfEmployees", e.target.value)
              }
            />
          </div>
        </div>

        <div style={{ display: "flex", flexFlow: "row nowrap" }}>
          <div
            style={{
              display: "flex",
              flexFlow: "column wrap",
              flexBasis: "50%",
            }}
          >
            <p style={{ color: "grey", fontSize: "14px", marginBottom: "0px" }}>
              AnnualRevenue
            </p>
            <Input
              value={formik.values.AnnualRevenue}
              onChange={(e) =>
                formik.setFieldValue("AnnualRevenue", e.target.value)
              }
            />
          </div>
          <div style={{ flexBasis: "50%" }}>
            <p style={{ color: "grey", fontSize: "14px", marginBottom: "0px" }}>
              SIC Code
            </p>
            <Input
              value={formik.values.SicDesc}
              onChange={(e) => formik.setFieldValue("SicDesc", e.target.value)}
            />
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexFlow: "row nowrap",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexFlow: "column wrap",
              flexBasis: "50%",
            }}
          >
            <p style={{ color: "grey", fontSize: "14px", marginBottom: "0px" }}>
              Phone
            </p>
            <Input
              value={formik.values.Phone}
              onChange={(e) => formik.setFieldValue("Phone", e.target.value)}
            />
          </div>
          <div style={{ flexBasis: "50%" }}>
            {isLoading ? (
              <div className="h-50 w-100 d-flex justify-content-center align-items-center">
                <div
                  className="spinner-border spinner-border-md text-primary"
                  role="status"
                />
              </div>
            ) : (
              <button
                style={{
                  backgroundColor: isFormikValid ? "#238FDB" : "grey",
                  padding: "0.5rem",
                  paddingBottom: "0.25rem",
                  paddingTop: "0.25rem",
                  border: "none",
                  borderRadius: "5px",
                  color: "whitesmoke",
                }}
                type={"submit"}
                onClick={formik.handleSubmit}
                disabled={!isFormikValid}
              >
                Save
              </button>
            )}
          </div>
        </div>

        <div style={{ flexBasis: "50%" }}>
          <div style={{ display: "flex", alignItems: "center", gap: "2px" }}>
            <p style={{ color: "grey", fontSize: "14px", marginBottom: "0px" }}>
              Rating
            </p>
            <ArrowSelect
              options={salesforceLeadsRatings}
              onChange={(e) => formik.setFieldValue("Rating", e.target.value)}
            />
          </div>
          <p style={{ color: "white", fontSize: "16px", marginBottom: "0px" }}>
            {formik.values.Rating}
          </p>
        </div>
      </div>
    </div>
  );
};

export default EditAccount;
