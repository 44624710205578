import "../../src/Components/css/ErrorMsg.css";
import Navbar from "./Navbar";
import Footerabout from "./Footerabout";
import { Col, Container, Row } from "react-bootstrap";
import ErrorMsggfg from "../assets/images/ErrorMsg.gif";
import { Link } from 'react-router-dom';

const ErrorMsg = () => {
  return (
    <div className="ErrorMsg">
      <Container>
        <Row>
          <Col>
            <Navbar />
          </Col>
        </Row>
        <Row >
        <div className="mt-5 ErrorMsg_div " >
          <Col  >
            <div className="ErrorMsg_main" >
            <img src={ErrorMsggfg} alt="ErrorMsg" />
            <h2>Your payment is unsuccessful.</h2>
            <p>Admin will reach out to you via email soon.</p>
            </div>
            
          </Col>
          </div>
        </Row>
     
        <Row  >
          <Col className="btn_col"  >
          <Link to="/">
              <button className="go-to-home-btn">Go to Home</button>
            </Link>
          </Col>
        </Row>
        <Row>
          <Col>
            
          </Col>
        </Row>
      </Container>
      <Footerabout />
    </div>
     
  );
};
export default ErrorMsg;
