import Swal from "sweetalert2";
import "../container/Team/index.css";
const SimpleToast = ({ title, text, iconName, iconColor }) => {
  Swal.fire({
    title: title,
    text: text,
    icon: iconName,
    confirmButtonColor: "var(--primary-color)",
    background: "var(--bg-color)",
    color: "var(--white-color)",
    iconColor: iconColor,
  });
};

const UpdatedToast = async ({
  title,
  text,
  iconName,
  iconColor,
  confirmBtnText,
  preConfirm,
  inputPlaceholder,
  onCancel,
  input,
  data,
  inputValue,
  html,
  showConfirmButton
}) => {
  Swal.fire({
    inputAutoTrim:true,
    title: title,
    text: text,
    icon: iconName,
    showCancelButton: true,
    confirmButtonColor: "var(--primary-color)",
    cancelButtonColor: "var(--primary-color)",
    confirmButtonText: confirmBtnText,
    background: "var(--bg-color)",
    width: 550,
    color: "var(--white-color)",
    showLoaderOnConfirm: true,
    input: input,
    ...(html && {html}),
     showConfirmButton: showConfirmButton ?? true,
    inputValue: inputValue,
    inputPlaceholder:inputPlaceholder,
    inputAttributes: {
      autocapitalize: "off",
    },
    customClass: {
      validationMessage: "my-validation-message",
    },
    iconColor: iconColor,
    preConfirm: (value) => {
      if (!value) {
        Swal.showValidationMessage("You need to write something!");
      } else {
        preConfirm(data ? data : value);
      }
    },
  }).then((result) => {
    if (result.isDismissed) {
      onCancel();
    }
    if (result.isConfirmed) {
      result.dismiss();
    } else {
      return;
    }
  });
};

const SelectedToast = ({
  title,
  text,
  iconName,
  iconColor,
  confirmBtnText,
  preConfirm,
  onCancel,
  input,
  data,
  inputOptions,
  inputValue,
  inputPlaceholder,
}) => {
  Swal.fire({
    title: title,
    text: text,
    icon: iconName,
    inputValue: inputValue,
    showCancelButton: true,
    confirmButtonColor: "var(--primary-color)",
    cancelButtonColor: "var(--primary-color)",
    confirmButtonText: confirmBtnText,
    background: "var(--bg-color)",
    color: "var(--white-color)",
    showLoaderOnConfirm: true,
    input: input,
    inputOptions,
    inputPlaceholder: inputPlaceholder,
    inputAttributes: {
      autocapitalize: "off",
    },
    customClass: {
      validationMessage: "my-validation-message",
    },
    iconColor: iconColor,
    preConfirm: (value) => {
      if (!value) {
        Swal.showValidationMessage("You need to write something!");
      } else {
        preConfirm(data ? data : { key: value, value: inputOptions[value] });
      }
    },
  }).then((result) => {
    if (result.isDismissed) {
      onCancel();
    }
  });
};

const DeleteToast = async ({
  title,
  text,
  iconName,
  iconColor,
  onCancel,
  onSubmit,
  data,
}) => {
  Swal.fire({
    title: title,
    text: text,
    iconColor: iconColor,
    showDenyButton: true,
    confirmButtonColor: "var(--primary-color)",
    background: "var(--bg-color)",
    color: "var(--white-color)",
    icon: iconName,
    denyButtonText: "No",
    confirmButtonText: "Yes",
  })
    .then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          confirmButtonColor: "var(--primary-color)",
          background: "var(--bg-color)",
          color: "var(--white-color)",
          title: "Deleted!",
          text: "Your Event has been deleted.",
          icon: "success",
        });
        onSubmit();
      } else if (result.isDenied) {
        onCancel();
      }
    })
    .then((result) => {
      if (result.isDismissed) {
        return;
      }
      if (result.isConfirmed) {
        result.dismiss();
      } else {
        return;
      }
    });
};

export { SimpleToast, UpdatedToast, SelectedToast, DeleteToast };
