import React from "react";
import { Card } from "react-bootstrap";
import PlusAvatar from "../../../../assets/images/plusavatar.png";
import DownloadIcon from "../../../../assets/images/download.png";
import { SelectedToast, UpdatedToast } from "../../../../utils/Toast";
import { Link } from "react-router-dom";
const ContentCard = ({
  bgColor,
  subTitle,
  title,
  setUrl,
  id,
  shareGoogleFileData,
  onClick
}) => {
  const getUserEmailHandle = () => {
    UpdatedToast({
      text: `Share this file to the other users`,
      iconName: "info",
      iconColor: "#add8e6",
      confirmBtnText: "Confirm",
      inputPlaceholder: "You can enter multiple emails separated by a comma",
      preConfirm: (value) => {
        shareGoogleFileData(value, id);
      },

      input: "text",
    });
    
  };

  return (
    <>
      <Card
        className="cart p-1 my-3"
        style={{ background: `${bgColor}`, borderRadius: 14 }}
      >
         <Link className="text-decoration-none" target={"_blank"} to={setUrl}>
        <Card.Header className="p-0 border-0 bg-transparent">
          <p
            style={{
              color: "#15172C",
              margin: 0,
              padding: 0,
              fontSize: 14,
              textAlign: "center",
            }}
          >
            {subTitle}
          </p>
        </Card.Header>
        </Link>
        <Link  className="text-decoration-none" target={"_blank"} to={setUrl}>   
        <Card.Body className="p-0 pt-2">
          <Card.Title>
            <h4
              style={{
                color: "#15172C",
                fontSize: 15,
                margin: 0,
                fontWeight: 400,
                padding: 0,
                width: 100
              }}
            >
              {title}
            </h4>
          </Card.Title>
        </Card.Body>
        </Link>
        <Card.Footer className="bg-transparent py-1 px-2 border-0 d-flex justify-content-between">
          <img
            src={PlusAvatar}
            onClick={getUserEmailHandle}
            style={{
              width: 25,
              height: 25,
              objectFit: "contain",
              cursor: "pointer",
            }}
            alt={"avatar-icon"}
          />
          <a target="_blank" rel="noreferrer" onClick={onClick}>
            <img
              src={DownloadIcon}
              style={{
                width: 25,
                height: 25,
                objectFit: "contain",
                cursor: "pointer"
              }}
              alt={"download-icon"}
            />
          </a>
        </Card.Footer>
      </Card>
    </>
  );
};

export default ContentCard;
