import React from 'react';
import Card from 'react-bootstrap/Card';
import Footerabout from './Footerabout';
import Navbar from "./Navbar";
import Intouch from './Intouch';
import mainbox from "./Images/mainbox.png";
import sec3 from "./Images/sec3.png";
import card1 from "./Images/card1.png";
import card2 from "./Images/card2.png";
import card3 from "./Images/card3.png";
import card4 from "./Images/card4.png";
import card5 from "./Images/card5.png";
import Liam from "./Images/Liam.png";
import Sanjay from "./Images/Sanjay.png";
import Mckenzie from "./Images/Mckenzie.png";
import Rosa from "./Images/Rosa-McAllister.png";
import Martin from "./Images/Martin.jpg"
import Daeseob from "./Images/Daeseob.png";
import Zarafat from "./Images/Zarafat.png";
import Robbie from "./Images/Robbie.png";


function About() {
  return (<>
    <div className='about_main_div'>
      <div className='main_hero_div'>
        <Navbar />
        <div className='w-100' style={{ marginTop: '133px', position: 'relative' }}>
            <div className='border_div d-flex mx-auto'></div>
          </div>
        <div className='container hero_section' style={{minHeight: '70vh'}}>
          <div className=''>
            <div className='row justify-content-center align-items-center mt-3 '>
              <div className='col-lg-6 col-md-6 col-sm-12'>
                <div className=''>
                  <div className=' team_heading'>The Team</div>
                </div>
                <div className="mainbox">
                  <img src={mainbox} alt="" />
                </div>
              </div>
              <div className='col-lg-6 col-md-6 col-sm-12'>

                <div className='row justify-content-center align-items-center'>
                  <div className='col-lg-6 col-md-6 col-sm-12' style={{ marginTop: "30px" }}>
                    <div className='row_card' id='spacing_div'>
                      <div className='about_card_icon'>
                        <i className="fa-brands fa-linkedin-in "></i>
                      </div>
                      <div className="d-flex justify-content-center">
                        <div className='team_pic_div'>
                          <img src={Liam} width='100%' alt="" /></div></div>
                      <h4>Liam Fitzgerald</h4>
                      <h5>CEO</h5>
                    </div>
                    <div className='row_card mt-4' id='spacing_div'>
                      <div className='about_card_icon'>
                        <i className="fa-brands fa-linkedin-in "></i>
                      </div>
                      <div className="d-flex justify-content-center">
                        <div className='team_pic_div' style={{ height: "auto" }} >
                          <img src={Mckenzie} width='100%' alt='' /></div></div>
                      <h4>Mckenzie Prince</h4>
                      <h5>CTO</h5>
                    </div>
                  </div>
                  <div className='col-lg-6 col-md-6 col-sm-12' id='spacing_div'>

                    <div className='row_card'>
                      <div className='about_card_icon'>
                        <i className="fa-brands fa-linkedin-in "></i>
                      </div>
                      <div className="d-flex justify-content-center">
                        <div className='team_pic_div'>
                          <img src={Robbie} width='100%' alt='' />
                        </div></div>
                      <h4>Robbie Parisius</h4>
                      <h5>Vice President</h5>
                      {/* <h5 className='text-center'>Research/Development</h5> */}
                    </div>

                    <div className='row_card mt-4'>
                      <div className='about_card_icon'>
                        <i className="fa-brands fa-linkedin-in "></i>
                      </div>
                      <div className="d-flex justify-content-center">
                        <div className='team_pic_div'>
                          <img src={Rosa} width='100%' alt='' />
                        </div></div>
                      <h4> Rosa McAllister</h4>
                      <h5>CFO</h5>
                    </div>
                    <div className='row_card mt-4'>
                      <div className='about_card_icon'>
                        <i className="fa-brands fa-linkedin-in "></i>
                      </div>
                      <div className="d-flex justify-content-center">
                        <div className='team_pic_div'>
                          <img src={Martin} width='100%' alt='' />
                        </div></div>
                      <h4> Martin Caron</h4>
                      <h5>Sales Enablement Lead</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      {/* sec 2 */}
      <div className=' container  connect_height'>
        <div className='core_description_div'>

          <div className='row container justy-content-center align-items-center  '>
            <div className='d-flex justify-content-center mt-4 mb-2'>
              <div className='border_div_career mb-5'></div>
            </div>
            <div className=' career_main_heading mb-5'>Connect2Co</div>
            <div className='col-lg-6 col-md-6 col-sm-12 ps-2'>

              <p className='core_description'>
                Connect2Co was realized through a collective mission: <br/>
                To transform the digital workspace into something that <br/>
                is engaging and meaningful, data-driven and efficient.
              </p>
            </div>

            <div className='col-lg-6 col-md-6 col-sm-12 ps-4 core_descriptions'>
              <p className='core_description  mx-auto'>Connect2Co will continue to serve its customers to<br></br> provide the best-in-class, all-in-one business optimization<br></br> and workflow platform. In doing so, we are able to please<br></br> businesses in a variety of industries, to help them scale in<br></br> the direction they want and in turn, enable their success.</p>

            </div>
          </div>
        </div>
      </div>

      {/* sec 3 */}
      <div className='container-fluid background_pic ' id="sec3h" >
        <div className=' container sacling_container mt-5'>
          <div className='row container justy-content-center align-items-center  body_row mt-5 ' id="about-spaces">
            <div className='col-lg-5 col-md-5 col-sm-12 ' id='spacing_div'>
              <img src={sec3} alt="" style={{ width: "100%" }} />
            </div>
            <div className='col-lg-7 col-md-7 col-sm-12' id='spacing_div'>
              <div className='center'>
                <div className='border-line1'></div> </div>
              <div>
                <h4 className=" delegate_hading" id="sec2h">
                  Who we are
                </h4>

                <p className='sec3_line ps-3' style={{ fontSize: "22px", fontWeight: "300" }} >
                  Connect2Co began in 2021 with the objective of scaling
                  businesses in a sustainable and profitable direction. We
                  are driven by the possibility that connection provides. An
                  ecosystem for companies to run, manage, and optimize
                  their business, partner with other companies on the Connect2Co platform, all while growing sustainably.
                </p>

              </div>
            </div>
          </div>
        </div>
      </div>
      {/* sec 4 */}
      <div className='card_height container mt-5 mb-5'>
        <div className='row justify-content-center align-items-center w-100 ' >
          <div className='d-flex justify-content-center mb-5 ' id='spacing_div'>
            <div className='border_div_career'></div>
          </div>

          <div className=' career_main_heading'>Why Connect2Co</div>

          <div className="d-flex flex-wrap gap-5 justify-content-center mt-5 ">
            <Card id="card">

              <img src={card1} alt="" style={{ height: "50px", width: "50px" }} />
              <Card.Body className='px-0'>
                <Card.Text className="card_text">
                  Increased reliance on external solutions to conduct business creates silos among departments, scattered workflows, and a<br></br>
                  lack of holistic transparency.<br></br><br></br>
                  Through use of these external solutions, data gets lost, progress isn't logged and
                  efficiency is oftentimes absent.<br></br><br></br>

                  As a byproduct Managers and executives<br></br>
                  struggle to make strategic, data-driven
                  decisions.
                </Card.Text>

              </Card.Body>
            </Card>

            <Card id="card" >

              <img src={card2} alt="" style={{ height: "50px", width: "50px" }} />
              <Card.Body className='px-0'>
                <Card.Text className="card_text">
                  Connect2Co is a state of the art workflow<br></br>optimization platform.<br></br><br></br>

                  Connect2Co's beta will enable you to:<br></br><br></br>

                  <b>Perform tasks</b> within your external solutions<br/>
                  (Salesforce, Google Workspace, etc.) directly<br/>
                  on the Connect2Co platform.<br/><br/>

                  <b>Productivity Component</b> to save you time by<br/>
                  historically logging completed, ongoing & waiting tasks.<br/><br/>

                  <b>Collaboration Component</b> to streamline communication<br/>
                   and work. Report progress and delegate tasks when your<br/>
                   workload is too heavy.<br/><br/>
                </Card.Text>

              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
      {/* sec 5 */}
      <div className='card_height mt-5'  >
        <div className='row justify-center-center align-items-center w-100 ' >
          <div className='d-flex justify-content-center mb-5 ' id='spacing_div'>
            <div className='border_div_career ' style={{ marginTop: "90px" }}></div>
          </div>

          <div className=' career_main_heading mb-5'>What we’re doing and Where we’re going</div>
          <div>
            <div className="d-flex flex-wrap gap-5 justify-content-center  " >
              <Card className=" text-white p-4" style={{ width: '25rem', backgroundColor: "#191C33" }}>
                <img src={card3} alt="" style={{ height: "50px", width: "50px" }} />
                <Card.Body className='px-0'>
                  <Card.Text className="card_text">
                    Connect2Co is a platform for growth
                    stage organizations. Our objective is
                    to connect companies to the people
                    and tools they need to build and scale
                    an organization in a sustainable
                    and profitable direction.
                  </Card.Text>

                </Card.Body>
              </Card>

              <Card className="text-white p-4" style={{ width: '25rem', backgroundColor: "#191C33" }}>
                {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
                <img src={card4} alt="" style={{ height: "50px", width: "50px" }} />
                <Card.Body className='px-0'>
                  <Card.Text className="card_text">
                    This is made possible through the help
                    of API's and aggregated success
                    metrics with department specific Al
                    insights and analytics. This allows for
                    individual departments and the people
                    therein, to manage their entire workflow
                    in one central place and perform
                    actions directly on platform.
                  </Card.Text>

                </Card.Body>
              </Card>

              <Card className="text-white p-4" style={{ width: '25rem', backgroundColor: "#191C33" }}>
                {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
                <img src={card5} alt="" style={{ height: "50px", width: "50px" }} />
                <Card.Body className='px-0'>
                  <Card.Text className="card_text">
                    This, in turn, enables transparency
                    among departments and provides
                    executives with the tools and metrics
                    they need to convey department
                    specific performance reviews and
                    strategic direction for improvement.
                  </Card.Text>

                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </div>
      {/* sec 6 */}
      <div className='background_pic'>
        <div className='intouch_height'>
          <Intouch />
        </div>
        <div className='mt-5'>
          <Footerabout />
        </div>
      </div>





    </div >

  </>

  )
}

export default About