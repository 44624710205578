import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Career from "./Career";
import Footer from "./Footer";
import Home from "./Home";
import Navbar from "./Navbar";
import Services from "./Services";
import Pricing from "./Pricing";
import About from "./About";
import Body from "./Body";
import Login from "./Login";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";
import VerifyEmail from "./VerifyEmail";
import Signup from "./Signup";
import Intouch from "./Intouch";
import Guide from "./Guide";
import PrivacyPolicy from "./PrivacyPolicy";
import Blog from "./Blog";
import CoreValues from "./CoreValues";
import Profile from "../container/Profile/index";
import Team from "../container/Team/index";
import PrivateRoutes from "../utils/PrivateRoutes";
import UserSignup from "../container/userSignup";
import EditProfile from "../container/EditProfile";
import Notification from "../container/Notification";
import Setting from "../container/Setting";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfileAction } from "../redux/actions/AuthAction";
import HomeScreen from "../container/HomeScreen";
import ErrorMsg from "./ErrorMsg";
import ThankYouMsg from "./ThankYouMsg";
import TermsConditions from "./termsConditions";
import AddCard from "../container/AddCard";
import Billing from "../container/Billing";

import axios from "axios";
import { get_refresh_token } from "../utils/googleTokens";
// import TermsConditions from "./termsConditions";
function App() {
	const auth = useSelector((state) => state.Auth);
	const userId = localStorage.getItem("userId");
	const dispatch = useDispatch();
	const { pathname } = useLocation();

	useEffect(() => {
		if (userId) {
			dispatch(getUserProfileAction(userId));
		}
	}, [userId, dispatch, pathname]);

	const handleRoutes = (component) => {
		if (localStorage?.getItem("googleRefreshCode")) get_refresh_token();
		return component;
	};

	return (
		<Routes>
			<Route exact path="/" element={<Home />} />
			<Route path="/navbar" element={<Navbar />} />
			<Route path="/service" element={<Services />} />
			<Route path="/Pricing" element={<Pricing />} />
			<Route path="/about" element={<About />} />
			<Route path="/career" element={<Career />} />
			<Route path="/body" element={<Body />} />
			<Route path="/footer" element={<Footer />} />
			<Route path="/login" element={<Login />} />
			<Route path="/forgot_password" element={<ForgotPassword />} />
			<Route path="/change-password" element={<ResetPassword />} />
			<Route path="/verify-email" element={<VerifyEmail />} />
			<Route path="/signup" element={<Signup />} />
			<Route path="/intouch" element={<Intouch />} />
			<Route path="/guide" element={<Guide />} />
			<Route path="/privacypolicy" element={<PrivacyPolicy />} />
			<Route path="/Blog" element={<Blog />} />
			<Route path="/Corevalue" element={<CoreValues />} />
			<Route path="/usersignup" element={<UserSignup />} />
			<Route path="/ThankYouMsg" element={<ThankYouMsg />} />
			<Route path="/ErrorMsg" element={<ErrorMsg />} />
			<Route path="/terms-conditions" element={<TermsConditions />} />

			{/* private routes  */}
			<Route element={<PrivateRoutes auth={auth} userId={userId} />}>
				<Route path="/settings" element={<Setting />} />
				<Route path="/Profile" element={handleRoutes(<Profile />)} />
				<Route path="/Team" element={handleRoutes(<Team />)} />
				<Route path="/editProfile" element={handleRoutes(<EditProfile />)} />
				<Route path="/notification" element={handleRoutes(<Notification />)} />
				<Route path="/SalesDashboard" element={handleRoutes(<HomeScreen />)} />
        <Route path="/AddCard" element={handleRoutes(<AddCard />) } />
        <Route path="/Billing" element={handleRoutes(<Billing />) } />
			</Route>
		</Routes>
	);
}

export default App;
