import React, { useState } from "react";
import { useEffect } from "react";
import { Button, Col, Nav, Row, Tab } from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import IconMapExpand from "../../../assets/images/Icon-map-expand.png";
import { SimpleToast } from "../../../utils/Toast";
import Search from "../../Search";
import EmailCard from "./EmailCard";
import EmailList from "./EmailList";
import axiosApiInstance from "../../../utils/axiosInterceptor";

function GoogleEmail({ setActive, expand, pinnedCollab, pinnedProd, dashboardId, hasGoogleToken}) {
  const [data, setData] = useState(false);
  const [mailActiveTab, setMailActiveTab] = useState("Inbox");
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [filterValues, setFilterValues] = useState("From");
  const [filterStarredValues, setFilterStarredValues] = useState("INBOX");
  // const [starred, setStarred] = useState("");
  const [mailsData, setMailsData] = useState([]);
  const [nextPageToken, setNextPageToken] = useState("");
  const [singleMailData, setSingleMailData] = useState({});
  const [resultSizeEstimate, setResultSizeEstimate] = useState(0);
  const [messagesNumbers, setMessagesNumbers] = useState(20);
  const [results, setResults] = useState([]);
  const [newResult, setNewResult] = useState([]);

  const mailTabs = [
    {
      tabs: "Inbox",
      value: "From",
    },
    {
      tabs: "Sent",
      value: "To",
    },
    {
      tabs: "Starred",
      value: "STARRED",
    },
  ];

  function getData(msgID) {
    return new Promise((resolve, reject) => {
      axiosApiInstance
        .get(`https://gmail.googleapis.com/gmail/v1/users/me/messages/${msgID}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  }

  const showEmailIdData = () => {
    setIsLoading(true);
    axiosApiInstance
      .get(
        `https://gmail.googleapis.com/gmail/v1/users/me/messages?maxResults=${messagesNumbers}&nextPageToken=${nextPageToken}`,
        {
          params: {
            labelIds: mailActiveTab.toUpperCase(),
          },
        }
      )

      .then(async (info) => {
        const ids = info?.data?.messages?.map((obj) => obj.id);
        const nextPageToken = info?.data?.nextPageToken;
        const resultSizeEstimate = info?.data?.resultSizeEstimate;
        setNextPageToken(nextPageToken);
        setResultSizeEstimate(resultSizeEstimate);
        await Promise.all((ids || [])?.map((id) => getData(id))).then(
          async (response) => {
            setMailsData(response);
          }
        );
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        SimpleToast({
          title: "Error",
          text: `${err.message}`,
          iconName: "error",
          iconColor: "#d33",
        });
      });
  };

  const deletemailData = (id) => {
    setIsLoading(true);
    axiosApiInstance
      .delete(`https://gmail.googleapis.com/gmail/v1/users/me/messages/${id}`, {
        params: {
          labelIds: mailActiveTab.toUpperCase(),
        },
      })

      .then((res) => {
        setIsLoading(false);
        SimpleToast({
          title: "Success",
          text: "Your E-mail was successfully deleted.",
          iconName: "success",
          iconColor: "green",
        });
        showEmailIdData();
        setData(false);
      })
      .catch((err) => {
        setIsLoading(false);
        SimpleToast({
          title: "Error",
          text: `${err.message}`,
          iconName: "error",
          iconColor: "#d33",
        });
      });
  };

  function getEncryptedData(mail){
    // For those emails that contain attachments we need to handle the body retrieval a bit differently.
    // We need to go one level deeper to grab the body of the message properly.
    let multipart = mail.data.payload.parts?.find(part => part.mimeType === 'multipart/alternative');
    let res;
    if(multipart){
      res = multipart.parts?.find(part => part.mimeType === 'text/html')?.body?.data ? atob(multipart.parts?.find(part => part.mimeType === 'text/html')?.body?.data?.replace(/-/g, '+').replace(/_/g, '/')) : '' 
      return res.replaceAll('Â','').replaceAll('â','\'').replaceAll('â¯',' ');
    }

    if(mail.data.payload?.parts && mail.data.payload?.parts?.length){
      res = mail.data.payload.parts?.find(part => part.mimeType === 'text/html')?.body?.data ? atob(mail.data.payload.parts?.find(part => part.mimeType === 'text/html')?.body?.data?.replace(/-/g, '+').replace(/_/g, '/')) : '' 
      return res.replaceAll('Â','').replaceAll('â','\'').replaceAll('â¯',' ');
    }
    if(mail.data.payload?.body?.data){
      res = atob(mail.data.payload?.body?.data?.replace(/-/g, '+').replace(/_/g, '/')) 
      return res.replaceAll('Â','').replaceAll('â','\'').replaceAll('â¯',' ');
    }
    return ''
  }

  useEffect(() => {
    async function filterData() {
      console.log(mailsData,'my data')
      const arr = [];
      await mailsData.forEach(async (mail) => {
        const obj = {
          date: null,
          email: null,
          cc:null,
          singleData: {
            body: getEncryptedData(mail),
            email: null,
            cc:null,
            subject: null,
            snippet: null,
            id: mail.data.id,
            hasAttachments: false
          },
        };
        const { headers } = mail.data.payload;
        await headers.forEach((header) => {
          if (header.name === "Date") {
            obj.date = header.value;
          }
          if (header.name === `${filterValues}`) {
            obj.email = header.value;
            obj.singleData.email = header.value;
          }
          if (header.name === "Subject") obj.singleData.subject = header.value;
          if (header.name === "Cc") obj.singleData.cc = header.value;
          obj.singleData.snippet = mail?.data?.snippet;
          obj.labelIds = mail?.data?.labelIds;
          obj.singleData.payload = mail?.data?.payload;
          var test;
          test = mail?.data?.payload?.parts?.filter(part => part.mimeType === 'multipart/alternative');
          if(test?.length > 0) obj.singleData.hasAttachments = true;

          if (obj.date !== null && obj.email !== null) {
            arr.push({ ...obj });
            obj.date = null;
            obj.email = null;
            obj.cc = null
          }
        });
      });
      setResults(arr);
      setNewResult(arr);
    }
    if(hasGoogleToken) filterData();
  }, [mailsData, mailActiveTab]);

  useEffect(() => {
    // if (mailsData.length === 0) {
    if(hasGoogleToken) showEmailIdData();
    // }
  }, [messagesNumbers, mailActiveTab]);

  const handleComposeClick = () => {
    setFilterValues("To");
    setData(true);
    setSingleMailData({});
  };
  const handleSearch = (e) => {
    setSearch(e.target.value);
    if (e.target.value === "") {
      setResults(newResult);
    }
    if (e.target.value) {
      const filterByEmail = newResult.filter(({ email }) => {
        if (email.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1) {
          return true;
        } else {
          return false;
        }
      });
      setResults(filterByEmail);
    }
  };

  return (
    <div className="google-email h-100">
      {!data ? (
        <>
          <div className="d-flex justify-content-between align-items-center gap-3 px-2">
            <div className="w-40 d-flex justify-content-between align-items-center gap-2 mb-2">
              <Button
                size="md"
                className="poppins"
                variant="none"
                onClick={() => setActive(0)}
                style={{
                  width: "80px",
                  height: "auto",
                  backgroundColor: "var(--secondry-color)",
                  borderRadius: 10,
                }}
              >
                <p style={{ color: "#FFF", margin: 0, padding: 0 }}>Gmail</p>
              </Button>
            </div>
            <div className="w-60 d-flex">
              <img
                style={{ width: 20, height: 20, objectFit: "contain", cursor: "pointer"}}
                alt={"add-icon"}
                src={IconMapExpand}
                onClick={() => expand(true)}
                hidden={pinnedCollab || pinnedProd ? false : true}
              />
            </div>
          </div>
          <div className="searchBar px-3">
            <Search onSearch={handleSearch} search={search} />
          </div>
          <Tab.Container
            id="left-tabs-example"
            defaultActiveKey={mailActiveTab}
          >
            <Row>
              <Col sm={3} style={{ paddingRight: 0 }}>
                <Nav variant="pills" className="flex-column gap-4 mt-2 poppins">
                  <Nav.Item>
                    <Nav.Link
                      className="p-2 d-inline"
                      onClick={() => handleComposeClick()}
                      style={{ backgroundColor: "var(--secondry-color)" }}
                    >
                      Compose
                    </Nav.Link>
                  </Nav.Item>
                  {mailTabs?.map((item, index) => (
                    <Nav.Item key={index}>
                      <Nav.Link
                        className="p-2 d-inline"
                        onClick={() => {
                          setMailActiveTab(item.tabs);
                          setFilterValues(item.value);
                          if (item.tabs === "Inbox")
                            setFilterStarredValues("INBOX");
                          if (item.tabs === "Sent")
                            setFilterStarredValues("SENT");
                          if (item.tabs === "Starred") {
                            setFilterStarredValues("STARRED");
                            setFilterValues("From");
                          }
                        }}
                        eventKey={item.tabs}
                      >                      
                        {item.tabs}
                      </Nav.Link>
                    </Nav.Item>
                  ))}
                </Nav>
              </Col>
              <Col md={9} sm={9} style={{ paddingLeft: 0 }}>
                <Tab.Content className="h-100 d-flex justify-content-center align-items-center">
                  {mailTabs?.map((item, index) => (
                    <Tab.Pane
                      style={{ width: "100%" }}
                      eventKey={item.tabs}
                      key={index}
                    >
                      {isLoading ? (
                        <div className="h-50 w-100 d-flex justify-content-center align-items-center">
                          <div
                            className="spinner-border spinner-border-md text-primary"
                            role="status"
                          />
                        </div>
                      ) : (
                        <div
                          className = {pinnedCollab ? "tabs-content" : "tabs-content-fullScreen"}
                          // className="tabs-content"
                          id={`scrollableDiv_${index + 1}`}
                        >
                          {results.length ? (
                            <InfiniteScroll
                              dataLength={resultSizeEstimate}
                              next={() => {
                                setMessagesNumbers(messagesNumbers + 20);
                                showEmailIdData();
                              }}
                              style={{ paddingRight: "13px" }}
                              hasMore={true}
                              scrollableTarget={`scrollableDiv_${index + 1}`}
                            >
                              {mailsData.length > 0 && (
                                <EmailList
                                  setData={setData}
                                  SingleData={results}
                                  mailData={mailsData}
                                  filterValues={filterValues}
                                  setSingleMailData={setSingleMailData}
                                  filterStarredValues={filterStarredValues}
                                  pinnedProd={pinnedProd}
                                />
                              )}
                            </InfiniteScroll>
                          ) : (
                            <div
                              style={{
                                height: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <h5
                                style={{
                                  color: "#FFF",
                                  textAlign: "center",
                                }}
                              >
                                No Data Found
                              </h5>
                            </div>
                          )}
                        </div>
                      )}
                    </Tab.Pane>
                  ))}
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </>
      ) : (
        <>
          <EmailCard
            setData={setData}
            isLoad={isLoading}
            deletemailData={deletemailData}
            SingleData={singleMailData}
            setSingleMailData={setSingleMailData}
            dashboardId={dashboardId}
            filterValues={filterValues}
          />
        </>
      )}
    </div>
  );
}

export default GoogleEmail;
