import axios from "axios";
import { authApi, userAuthApi } from "../../utils/api";
import { ActionTypes } from "../constants/index";
import bcrypt from "bcryptjs";

const config = {
	headers: {
		"x-api-key": process.env.REACT_APP_C2C_PROD_AWS_ACCESS_KEY,
	},
};

export const signupAction = (data) => async (dispatch) => {
	dispatch({ type: ActionTypes.SIGNUP_REQ });
	console.log("KEYYY", config);
	const api = `${authApi}/updateusers`;
	try {
		const response = await axios.post(api, data, config);
		// if(response.data != undefined && response.data.token != undefined){
		//   localStorage.setItem("token",response.data.token)
		// }
		dispatch({ type: ActionTypes.SIGNUP_SUCCESS, payload: response });
		return response;
	} catch (error) {
		dispatch({ type: ActionTypes.SIGNUP_FAIL, payload: error?.response?.data });
	}
};

export const signInAction = (email, inputPassword) => async (dispatch) => {
	dispatch({ type: ActionTypes.SIGNIN_REQ });
	const api = `${userAuthApi}/users?type=login`;
	// const api = `https://1cb0dylznc.execute-api.us-east-1.amazonaws.com/dev/users?type=login`;
	try {
		const response = await axios.post(api, { email, inputPassword }, config);
		//Compare hashed password
		console.log("response", response);
		if (response.data != undefined && response.data.token != undefined) {
			localStorage.setItem("token", response.data.token);
			return response;
		} else {
			dispatch({ type: ActionTypes.SIGNIN_SUCCESS, payload: response });
			return null;
		}
	} catch (error) {
		console.log("error", error);
		dispatch({ type: ActionTypes.SIGNIN_FAIL, payload: error?.message });
		return "error";
	}
};
export const resetAction = (email) => async (dispatch) => {
	const api = `${userAuthApi}/users?type=reset_password`;
	try {
		const response = await axios.post(api, { email });
		return response;
	} catch (error) {
		return "error";
	}
};
export const resetPassword = (email, hash, password) => async (dispatch) => {
	const api = `${userAuthApi}/users?type=change_password`;
	try {
		const response = await axios.post(api, { email, hash, password });
		return response;
	} catch (error) {
		return "error";
	}
};

export const getUserProfileAction = (id) => async (dispatch) => {
	dispatch({ type: ActionTypes.GET_PROFILE_REQ });

	const api = `${authApi}/getusers`;
	try {
		const data = await axios.post(api, { id }, config);

		dispatch({ type: ActionTypes.GET_PROFILE_SUCCESS, payload: data });
	} catch (error) {
		if (error?.code === "ERR_NETWORK") return;
		dispatch({
			type: ActionTypes.GET_PROFILE_FAIL,
			payload: error?.response?.data?.message,
		});
	}
};

export const initiateEmailVerification = (userId, email) => async (dispatch) => {
	const api = `${userAuthApi}/users?type=reset_email`;
	try {
		const response = await axios.post(api, { email, userId });
		return response;
	} catch (error) {
		return "error";
	}
};

export const verifyemail = (userid, hash) => async (dispatch) => {
	const api = `${userAuthApi}/users?type=verify_email`;
	try {
		const response = await axios.post(api, { userid, hash });
		return response;
	} catch (error) {
		return "error";
	}
};

export const updateProfileAction = (userId, userData) => async (dispatch) => {
	const currentId = localStorage?.getItem("userId");
	dispatch({ type: ActionTypes.UPDATE_PROFILE_REQ });

	const api = `${authApi}/updateUser`;
	try {
		const data = await axios.post(
			api,
			{
				id: userId,
				updates: userData,
			},
			config
		);
		if (currentId === userId.toString()) {
			dispatch({
				type: ActionTypes.UPDATE_PROFILE_SUCCESS,
				payload: { data, userData },
			});
			return data;
		} else {
			dispatch({
				type: ActionTypes.UPDATE_OTHER_MEMBER,
				payload: { data, userData },
			});
			return data;
		}
	} catch (error) {
		dispatch({
			type: ActionTypes.UPDATE_PROFILE_FAIL,
			payload: error?.response?.data?.message,
		});
	}
};

export const userSignupAction = (userData, id) => async (dispatch) => {
	dispatch({ type: ActionTypes.USER_SIGNUP_REQ });

	const api = `${authApi}/updateUser`;

	try {
		const data = await axios.post(
			api,
			{
				id: id,
				updates: userData,
			},
			config
		);
		dispatch({
			type: ActionTypes.USER_SIGNUP_SUCCESS,
			payload: data,
		});
		return data;
	} catch (error) {
		dispatch({
			type: ActionTypes.USER_SIGNUP_FAIL,
			payload: error?.response?.data?.message,
		});
	}
};

export const logoutAction = () => async (dispatch) => {
	dispatch({ type: ActionTypes.LOGOUT_REQUEST });
};
export const clearDashboardData = () => async (dispatch) => {
	dispatch({ type: ActionTypes.CLEAR_DASHBOARD_DATA });
};
