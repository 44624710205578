import axios from "axios";
import { useFormik } from "formik";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import leadsIcon from "../assets/images/leads-icon.png";
import { logExternalIntegrationTask } from "../redux/actions/TasksAction";
import { userSalesForceBaseUrl } from "../utils/api";
import {
  salesforceLeadsIndustry,
  salesforceLeadsRatings,
  salesforceLeadsSources,
  salesforceLeadsStatus,
} from "../utils/constant";
import ArrowSelect from "./ArrowSelect";
import Input from "./Input";
import moment, { min } from "moment";
import { SimpleToast } from "../utils/Toast";
import { formatQuery } from "../utils/helpers";

axios.defaults.headers.post["Content-Type"] = "application/json";

const EditOpportunity = ({ dashboardId, opportunityId, isCreateMode = false }) => {
  const [opportunity, setOpportunity] = useState();
  const SFData = useSelector((state) => state.getSFData);
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.Auth);
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({
    opportunityId: opportunityId || "",
    isCreateMode: isCreateMode || false,
  });
  const [salesforceStages, setStages] = useState([]);
  const [salesforceTypes, setTypes] = useState([]);

  const fetchSFOpportunityStageTypes = async () => {
    const salesforceLoginAccessToken = localStorage?.getItem(
      "salesforceAccessToken"
    );
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${salesforceLoginAccessToken}`;
    try {
      let response = await axios.get(
        `${baseUrl}/services/data/v57.0/sobjects/Opportunity/describe`
      );
      let types = response.data.fields.filter(val => val.name === "Type");
      let stages = response.data.fields.filter(val => val.name === "StageName");

      let values;
      if (types?.length > 0) {
        values = [];
        types[0].picklistValues.forEach(val => {
          if(!!val.active){
            values.push(val.label);
          }
        });
        setTypes(values);
      }
      if (stages?.length > 0) {
        values = [];
        stages[0].picklistValues.forEach(val => {
          if(!!val.active){
            values.push(val.label);
          }
        });
        setStages(values);
      }
    } catch (error) {
      console.error(error);
    }
  };


  const baseUrl = useMemo(
    () => SFData?.getSFData?.data?.[0]?.url,
    [SFData?.getSFData?.data]
  );

  const getOpportunityById = useCallback(async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/services/data/v57.0/sobjects/Opportunity/${state.opportunityId}`
      );

      if (response.data) {
        setOpportunity(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  }, [state.opportunityId, baseUrl]);

  const getAccountName = useCallback(async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/services/data/v57.0/query?q=${formatQuery(
          `Select Name from Account where Id = '${opportunity?.AccountId}'`
        )}`
      );

      if (response.data) {
        setOpportunity((prev) => ({
          ...prev,
          AccountName: response.data.records?.[0].Name || "",
        }));
      }
    } catch (error) {
      console.error(error);
    }
  }, [baseUrl, opportunity?.AccountId]);

  useEffect(() => {
    if (!state.isCreateMode) {
      if (state.opportunityId) {
        getOpportunityById().finally(() => getAccountName());
      }
    }
    fetchSFOpportunityStageTypes();
  }, [
    state.opportunityId,
    getOpportunityById,
    state.isCreateMode,
    getAccountName,
  ]);

  const checkEvents = (payload) => {
    const events = [];
    const eventData = {
      unique_id: `${baseUrl}/${opportunity.Id}`,
      user_id: auth?.profileData.id,
      dashboard_id: dashboardId,
      description: `${auth.profileData.first_name} changed 1 Opportunity name`,
      integration_platform: "Salesforce",
      status: "Completed",
      action_type: "Opportunity name changed",
      created_datetime: moment().format(),
    };
    if (payload.Name !== opportunity.Name) {
      events.push({ ...eventData });
    }
    if (payload.StageName !== opportunity.StageName) {
      events.push({
        ...eventData,
        description: `${auth.profileData.first_name} changed opportunity stage to ${payload.StageName}`,
        action_type: "Opportunity Stage Change",
      });
    }

    return events;
  };

  const updateOpportunity = async (payload) => {
    const salesforceLoginAccessToken = localStorage?.getItem(
      "salesforceAccessToken"
    );
    delete payload.AccountName;
    try {
      const response = await axios.patch(
        `${baseUrl}/services/data/v57.0/sobjects/Opportunity/${state.opportunityId}`,
        payload,
        {
          headers: {
            "Access-Control-Allow-Methods": "PATCH",
            Authorization: `Bearer ${salesforceLoginAccessToken}`,
            "Access-Control-Allow-Headers": "*",
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      if (response.status >= 200 && response.status <= 300) {
        SimpleToast({
          title: "Success",
          text: "Opportunity updated successfully.",
          iconName: "success",
          iconColor: "green",
        });
        const eventData = checkEvents(payload);
        if (eventData) {
          eventData.forEach(async (event) => {
            await dispatch(logExternalIntegrationTask(event));
          });
        }

        getOpportunityById().finally(() => getAccountName());
      }
    } catch (error) {
      console.error(error);
    }
  };

  const createOpportunity = async (payload) => {
    const salesforceLoginAccessToken = localStorage?.getItem(
      "salesforceAccessToken"
    );
    delete payload.AccountName;
    try {
      const response = await axios.post(
        `${baseUrl}/services/data/v57.0/sobjects/Opportunity`,
        payload,
        {
          headers: {
            "Access-Control-Allow-Methods": "POST",
            Authorization: `Bearer ${salesforceLoginAccessToken}`,
            "Access-Control-Allow-Headers": "*",
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      if (response.data) {
        SimpleToast({
          title: "Success",
          text: "Opportunity created successfully.",
          iconName: "success",
          iconColor: "green",
        });
        const eventData = {
          unique_id: `${baseUrl}/${response.data.id}`,
          user_id: auth?.profileData.id,
          dashboard_id: dashboardId,
          description: `${auth.profileData.first_name} created 1 Opportunity`,
          integration_platform: "Salesforce",
          status: "Completed",
          action_type: "Create Opportunity",
          created_datetime: moment().format(),
        };
        await dispatch(logExternalIntegrationTask(eventData));

        setState({ opportunityId: response.data.id, isCreateMode: false });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const initialValues = {
    Name: opportunity?.Name || "",
    AccountName: opportunity?.AccountName || "",
    Type: opportunity?.Type || "",
    Amount: opportunity?.Amount || "",
    LeadSource: opportunity?.LeadSource || "",
    CloseDate: opportunity?.CloseDate || "",
    StageName: opportunity?.StageName || "",
    Probability: opportunity?.Probability || "",
  };

  const validate = (values) => {
    const errors = {};
    if (!values.Name) {
      errors.Name = "Required";
    }
    if (!values.StageName) {
      errors.StageName = "Required";
    }
    if (!values.CloseDate) {
      errors.CloseDate = "Required";
    }
    return errors;
  };

  const handleSubmit = async (e) => {
    const payload = { ...e };

    setIsLoading(true);

    if (state.isCreateMode) {
      await createOpportunity(payload);
    } else {
      await updateOpportunity(payload);
    }
    setIsLoading(false);
  };

  const formik = useFormik({
    initialValues,
    validate,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  const isFormikValid = useMemo(() => {
    return (
      !formik.errors.Name &&
      !formik.errors.StageName &&
      !formik.errors.CloseDate
    );
  }, [formik.errors]);

  const minimumDate = useMemo(() => {
    const todaysDate = new Date();

    return `${todaysDate.getFullYear()}-${
      todaysDate.getMonth() + 1 > 10
        ? todaysDate.getMonth() + 1
        : `0${todaysDate.getMonth() + 1}`
    }-${
      todaysDate.getDate() > 10
        ? todaysDate.getDate()
        : `0${todaysDate.getDate()}`
    }`;
  }, []);

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            flexDirection: "row",
            display: "flex",
            alignItems: "center",
          }}
        >
          <div
            style={{
              backgroundColor: "var(--bg-color)",
              borderRadius: 7,
              padding: 10,
            }}
          >
            <img
              src={leadsIcon}
              style={{
                width: 20,
                height: 20,
                objectFit: "contain",
              }}
              alt={"lead-icon"}
            />
          </div>
          <div style={{ marginLeft: 10 }}>
            <p
              style={{
                color: "#FFF",
                padding: 0,
                margin: 0,
                fontSize: 12,
              }}
            >
              Opportunities
            </p>
            <p
              style={{
                color: "grey",
                padding: 0,
                margin: 0,
                fontSize: 12,
              }}
            >
              Owner: Lf
            </p>
          </div>
        </div>
        <div
          style={{
            cursor: "pointer",
            padding: 10,
          }}
        ></div>
      </div>

      <div style={{ marginTop: "2vh" }}>
        <div style={{ display: "flex", flexFlow: "row nowrap" }}>
          <div
            style={{
              display: "flex",
              flexFlow: "column wrap",
              flexBasis: "50%",
            }}
          >
            <p style={{ color: "grey", fontSize: "14px", marginBottom: "0px" }}>
              Opportunity Name *
            </p>
            <Input
              value={formik.values.Name}
              onChange={(e) => formik.setFieldValue("Name", e.target.value)}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexFlow: "column wrap",
              flexBasis: "50%",
            }}
          >
            <p style={{ color: "grey", fontSize: "14px", marginBottom: "0px" }}>
              Close Date *
            </p>
            <div style= {{ width: "40%" }}>
              <Input
                type="date"
                min={minimumDate}
                value={formik.values.CloseDate}
                onChange={(e) =>
                  formik.setFieldValue("CloseDate", e.target.value)
                }
              />
            </div>
          </div>
        </div>

        <div style={{ display: "flex", flexFlow: "row nowrap" }}>
          <div
            style={{
              display: "flex",
              flexFlow: "column wrap",
              flexBasis: "50%",
            }}
          >
            <p style={{ color: "grey", fontSize: "14px", marginBottom: "0px" }}>
              Account Name
            </p>
            <Input
              value={formik.values.AccountName}
              onChange={(e) =>
                formik.setFieldValue("AccountName", e.target.value)
              }
            />
          </div>
          <div style={{ flexBasis: "50%" }}>
            <div style={{ display: "flex", alignItems: "center", gap: "2px" }}>
              <p
                style={{ color: "grey", fontSize: "14px", marginBottom: "0px" }}
              >
                Stage *
              </p>
              <ArrowSelect
                options={salesforceStages}
                onChange={(e) =>
                  formik.setFieldValue("StageName", e.target.value)
                }
              />
            </div>
            <p
              style={{ color: "white", fontSize: "16px", marginBottom: "0px" }}
            >
              {formik.values.StageName ?? "-"}
            </p>
          </div>
        </div>

        <div style={{ display: "flex", flexFlow: "row nowrap" }}>
          <div style={{ flexBasis: "50%" }}>
            <div style={{ display: "flex", alignItems: "center", gap: "2px" }}>
              <p
                style={{ color: "grey", fontSize: "14px", marginBottom: "0px" }}
              >
                Type
              </p>
              <ArrowSelect
                options={salesforceTypes}
                onChange={(e) => formik.setFieldValue("Type", e.target.value)}
              />
            </div>
            <p
              style={{ color: "white", fontSize: "16px", marginBottom: "0px" }}
            >
              {formik.values.Type ?? "-"}
            </p>
          </div>
          <div
            style={{
              display: "flex",
              flexFlow: "column wrap",
              flexBasis: "50%",
            }}
          >
            <p style={{ color: "grey", fontSize: "14px", marginBottom: "0px" }}>
              Probability (%)
            </p>
            <Input
              value={formik.values.Probability}
              onChange={(e) =>
                formik.setFieldValue("Probability", e.target.value)
              }
            />
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexFlow: "row nowrap",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexFlow: "column wrap",
              flexBasis: "50%",
            }}
          >
            <p style={{ color: "grey", fontSize: "14px", marginBottom: "0px" }}>
              Amount
            </p>
            <Input
              value={formik.values.Amount}
              onChange={(e) => formik.setFieldValue("Amount", e.target.value)}
            />
          </div>
          <div style={{ flexBasis: "50%" }}>
            {isLoading ? (
              <div className="h-50 w-100 d-flex justify-content-center align-items-center">
                <div
                  className="spinner-border spinner-border-md text-primary"
                  role="status"
                />
              </div>
            ) : (
              <button
                style={{
                  backgroundColor: isFormikValid ? "#238FDB" : "grey",
                  padding: "0.5rem",
                  paddingBottom: "0.25rem",
                  paddingTop: "0.25rem",
                  border: "none",
                  borderRadius: "5px",
                  color: "whitesmoke",
                }}
                type="submit"
                onClick={formik.handleSubmit}
                disabled={!isFormikValid}
              >
                Save
              </button>
            )}
          </div>
        </div>

        <div style={{ flexBasis: "50%" }}>
          <div style={{ display: "flex", alignItems: "center", gap: "2px" }}>
            <p style={{ color: "grey", fontSize: "14px", marginBottom: "0px" }}>
              Lead Source
            </p>
            <ArrowSelect
              options={salesforceLeadsSources}
              onChange={(e) =>
                formik.setFieldValue("LeadSource", e.target.value)
              }
            />
          </div>
          <p style={{ color: "white", fontSize: "16px", marginBottom: "0px" }}>
            {formik.values.LeadSource ?? "-"}
          </p>
        </div>
      </div>
    </div>
  );
};

export default EditOpportunity;
