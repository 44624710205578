import React, { useEffect } from "react";
import edit from "../../assets/images/edit.png";
import add from "../../assets/images/add.png";
import person from "../../assets/images/person.png";
import Header from "../../Components/Header/index";
import Sidebar from "../../Components/Sidebar/index";
import { useDispatch, useSelector } from "react-redux";
import { getDashboards, createDashboardAction, clearErrorMessageAction } from "../../redux/actions/DashboardAction";
import { clearDashboardData, logoutAction, updateProfileAction } from "../../redux/actions/AuthAction";
import "./index.css";
import ProfileCard from "../../Components/ProfileCard";
import { TeamCard } from "../../Components/TeamCard";
import { SimpleToast } from "../../utils/Toast";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import deleteIcon from "../../assets/images/delete-white.png";
import axios from "axios";
const Profile = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const dashboard = useSelector((state) => state.dashboardReducer);
	const auth = useSelector((state) => state.Auth);

	// update profile dashboards
	const updateProfileDashboards = (insertId) => {
		const index = dashboard?.getDashboardData.length + 1;

		return dispatch(
			updateProfileAction(auth?.profileData?.id, {
				[`dashboard${index}`]: insertId,
			})
		);
	};

	//create dashboard
	const handleCreateDashboard = async () => {
		if (dashboard?.getDashboardData?.length === 5) {
			SimpleToast({
				title: "Warning",
				text: "You can not exceed the limit.",
				iconName: "warning",
			});

			return;
		}
		const response = await dispatch(createDashboardAction("Sales Dashboard (new)"));
		const insertId = response.payload.id;

		if (!insertId) return;
		SimpleToast({
			title: "Success",
			text: "Dashboard successfully created.",
			iconName: "success",
			iconColor: "green",
		});

		setTimeout(() => {
			dispatch(clearErrorMessageAction());
		}, 2000);

		updateProfileDashboards(insertId);
	};

	//get Dashboard data
	useEffect(() => {
		if (Object.keys(auth.profileData).length === 0) return;
		const dashboardIds = [];
		for (let i = 1; i <= 5; i++) {
			const id = auth.profileData[`dashboard${i}`];

			if (id) {
				dashboardIds.push(id);
			}
		}

		if (!dashboardIds.length) {
			dispatch(clearDashboardData());
			return;
		}
		dispatch(getDashboards(dashboardIds));

		setTimeout(() => {
			dispatch(clearErrorMessageAction());
		}, 2000);
	}, [dispatch, auth.profileData]);

	useEffect(() => {
		if (dashboard?.isCreateDashboardError || dashboard?.isGetDashboardError || auth?.isProfileError) {
			SimpleToast({
				title: "Error",
				text: "Oops, something went wrong.",
				iconName: "error",
				iconColor: "#d33",
			});
		}
		setTimeout(() => {
			dispatch(clearErrorMessageAction());
		}, 2000);
	}, [dashboard?.isCreateDashboardError, dashboard?.isGetDashboardError, auth?.isProfileError, dispatch]);

	const deleteHandler = () => {
		/** 3DPL - FOLLOWING CODE IS FOR FREE PLAN */
		const userId = localStorage?.getItem("userId");
		const config = {
			headers: {
				"x-api-key": process.env.REACT_APP_C2C_PROD_AWS_ACCESS_KEY,
			},
		};
		const initiateData = {
			userId,
		};

		Swal.fire({
			title: "Want to delete your Account?",
			text: "You will lose all your data and will never be able to recover your account again. Please proceed with cautions.",
			icon: "error",
			confirmButtonText: "Confirm and Proceed",
		}).then(async (result) => {
			if (result.isConfirmed) {
				try {
					const response = await axios.post(
						"https://gx7sfzypve.execute-api.us-east-1.amazonaws.com/deleteUserProfile/deleteuserprofile", // URLPASTEHERE
						initiateData,
						config // Use config2 instead of config
					);
					if (response.status === 200) {
						localStorage.removeItem("userId");
						localStorage.removeItem("salesforceAccessToken");
						localStorage.removeItem("googleAuthAccessToken");
						localStorage.removeItem("googleAuthRefreshToken");
						localStorage.removeItem("token");

						navigate("/login", { replace: true });
					}
					console.log("Logout Success", response); // Log the response data
				} catch (error) {
					console.error("Error occurred:", error);
					console.error("Error response:", error.response); // Log the full response if available

					// Handle different error scenarios based on status codes
					if (error.response && error.response.status === 404) {
						Swal.fire({
							title: "Error",
							text: "User not found.",
							icon: "error",
						});
					} else if (error.response && error.response.status === 500) {
						Swal.fire({
							title: "Error",
							text: "An error occurred while initiating the payment.",
							icon: "error",
						});
					} else {
						Swal.fire({
							title: "Error",
							text: "An unknown error occurred.",
							icon: "error",
						});
					}
				}
			}
		});
	};

	return (
		<div className="d-flex">
			<div>
				<Sidebar /> <Header />
			</div>
			<div className="profile_mainContainer">
				<div className="profileCard_mainContainer">
					<ProfileCard path="/profile" userData={auth?.profileData} />
					<div
						onClick={() => {
							navigate("/editProfile");
						}}
						className="editCard"
						// onClick={() => navigate("/team", { state: { delVal: true } })}
					>
						<img src={edit} alt="edit" />
					</div>
					<div className="edit_editCard del">
						<img onClick={deleteHandler} src={deleteIcon} alt="delete" />
						<span className="tooltiptext">Delete Profile</span>
					</div>
				</div>
				<div className="teamMainContainer">
					<div className="myTeamContainer">
						<p>My Teams</p>

						{dashboard?.isGetDashboardLoading ? (
							<div className="noDashboardFound">
								<div className="spinner-border spinner-border text-white" role="status" />
							</div>
						) : dashboard?.getDashboardData?.length ? (
							dashboard?.getDashboardData.map((item, ind) => {
								return <TeamCard img1={add} img2={person} path="/team" name={item?.name} ind={ind} id={item?.id} />;
							})
						) : (
							<div className="noDashboardFound">
								<p>You are not a member of any teams</p>
							</div>
						)}
					</div>

					{Object.keys(auth?.profileData).length && auth?.profileData?.is_admin === 1 ? (
						<div className="createTeamContainer">
							<p>Create Teams</p>

							{dashboard.isCreateDashboardLoading ? (
								<div className="createTeamLoadingCard">
									<div className="spinner-border spinner-border text-white" role="status" />
								</div>
							) : (
								<div className="createTeamCard" onClick={handleCreateDashboard}>
									<p>Dashboard</p>

									<img src={add} alt="add" />
								</div>
							)}
						</div>
					) : null}
				</div>
			</div>
		</div>
	);
};

export default Profile;
