import React from "react";
import profile from "../../assets/images/defaultAvatar.png";
import "./index.css";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const ProfileCard = ({ path, userData, bgNone, textAlign }) => {
  const navigate = useNavigate();
  const auth = useSelector((state) => state.Auth);

  return (
    <div
      style={{ backgroundColor: bgNone, textAlign: textAlign }}
      className="profileCardContainer"
      onClick={() => navigate(path)}
    >
      <div className="profileCard">
        <p>
          {userData?.first_name} {userData?.last_name}
        </p>
        <p>{userData?.position}</p>
      </div>
      <div className="profileImgCard">
        <img src={auth?.profileData?.profile_image || profile} alt="profile" />
      </div>
    </div>
  );
};

export default ProfileCard;
