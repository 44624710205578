import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import closeIcon from "../../assets/images/edit-cancel.png";
import heartIcon from "../../assets/images/heart-icon.png";
import avatarIcon from "../../assets/images/defaultAvatar.png";
import accept from "../../assets/images/completed-icon.png";
import add from "../../assets/images/add.png";
import { updateDashboardUserSettings, createDashboardUserSettings, getDashboardUserSettings } from "../../redux/actions/DashboardAction";
import { useDispatch, useSelector } from "react-redux";
import { authApi, productivityApi } from "../../utils/api";
import axios from "axios";
import "../css/Collab.css";
import Swal from "sweetalert2";
import Popup from "reactjs-popup";
import rightArrowIcon from "../../assets/images/right-arrow-icon.png";
import { startOfWeek, endOfWeek, format, parseISO } from "date-fns";
import ReactPaginate from "react-paginate";
import TaskService from "../ProductivityCard/task";

const CollaborationTeamCard = forwardRef(({ notPinned, pinned, refreshList }, ref) => {
	const location = useLocation();
	const dispatch = useDispatch();
	const dashboard = useSelector((state) => state.dashboardReducer);
	const [searchParams, setSearchParams] = useSearchParams();
	var dashboardId = searchParams.get("id");
	if (dashboardId == undefined || dashboardId == null) {
		dashboardId = sessionStorage.getItem("dashboardId");
	}
	const [teamList, setTeamList] = useState([]);
	const [isTeamLoaded, setIsTeamLoaded] = useState(false);
	const userId = localStorage?.getItem("userId");

	const [isDropActive, setIsDropActive] = useState(false);
	const [dragOverIndex, setDragOverIndex] = useState(-1);
	const [dragedTask, setDraggedTask] = useState({});

	const apiTeamList = `${authApi}/getusersindashboard`;
	const apiNotification = `${productivityApi}/notification`;
	const auth = useSelector((state) => state.Auth);
	const userData = auth.profileData;

	const [page, setPage] = useState(1);
	const [teamUser, setTeamUser] = useState({});
	const [taskList, setTaskList] = useState([]);
	const [perPage, setPerPage] = useState(10);
	const [totalItem, setTotalItem] = useState(0);
	const [pageCount, setPageCount] = useState(0);
	const [isTaskLoaded, setIsTaskLoaded] = useState(false);
	const [open, setOpen] = useState(false);
	const closeModal = () => setOpen(false);
	const [checkedTaskList, setCheckedTaskList] = useState([]);

	const config = {
		headers: {
			"x-api-key": process.env.REACT_APP_C2C_PROD_AWS_ACCESS_KEY,
		},
	};

	useEffect(() => {
		console.log("Collaboration Loaded");
		fetchData();
	}, []);
	const fetchData = () => {
		axios
			.post(apiTeamList, { dashboardId }, config)
			.then((data) => {
				if (data.status == 200) {
					console.log(data);
					if (data.data != undefined && Array.isArray(data.data) && data.data.length > 0) {
						var userList = data.data.filter((val) => val.id != userId);
						setTeamList(userList);
					} else {
						setTeamList(data.body.data);
					}
				} else {
					setTeamList([]);
				}

				setIsTeamLoaded(true);
			})
			.catch((error) => {
				setIsTeamLoaded(true);
				setTeamList([]);
			});
	};

	const changeSetting = async (val) => {
		pinned(val);
		let body;
		if (dashboard?.getDashboardSettingsData?.length > 0) {
			body = {
				id: dashboard?.getDashboardSettingsData[0].id,
				updates: {
					collab_component_pinned: 0,
				},
			};
			await dispatch(updateDashboardUserSettings(body));
			await dispatch(getDashboardUserSettings(auth?.profileData.id, dashboardId));
		} else {
			body = {
				user: auth?.profileData?.id,
				dashboard: dashboardId,
				prod_componet_pinned: 1,
				collab_component_pinned: 0,
			};
			await dispatch(createDashboardUserSettings(body));
			await dispatch(getDashboardUserSettings(auth?.profileData.id, dashboardId));
		}
	};
	useImperativeHandle(ref, () => ({
		handleDragDrop: (event) => {
			if (event.state == "start") {
				console.log(event);
				setIsDropActive(true);
				if (event.task != undefined) {
					setDraggedTask(event.task);
					if (event.checkedTaskList != undefined && event.checkedTaskList.length) {
						setCheckedTaskList(event.checkedTaskList);
					}
				}
			} else {
				setIsDropActive(false);
				setDragOverIndex(-1);
			}
		},
	}));
	const dragEnter = (e, position) => {
		setDragOverIndex(position);
		console.log(e.task);
	};
	const onDragOver = (event) => {
		event.preventDefault();
	};
	const onDrop = (e, position, name) => {
		console.log(e, position, dragedTask);
		if (dragedTask.id != undefined) {
			console.log("checkedTaskList===>", checkedTaskList);

			if (dragedTask.status == "Completed") {
				finalAssingTask(e, position, name, "", "");
			} else {
				Swal.fire({
					confirmButtonText: "Next",
					confirmButtonColor: "#DC004E",
					showCancelButton: true,
					cancelButtonText: "Cancel",
					reverseButtons: true,
					focusConfirm: true,
					focusCancel: false,
					title: "Select Details",
					confirmButtonText: "Next",
					confirmButtonColor: "#DC004E",
					html: `<select id="swal-input1"  class="swal2-select" style="display: flex;"><option value="">Select Priority</option><option value="warm">Warm</option><option value="hot">Hot</option><option value="fire">Fire</option></select><input class="swal2-input" placeholder="Please enter any relevant link"  id="swal-input2" type="url" style="display: flex;margin-top:35px!important">`,
					showCancelButton: true,
					preConfirm: () => {
						var priority = document.getElementById("swal-input1").value;
						var url = document.getElementById("swal-input2").value;
						return [priority, url];
					},
				}).then((values) => {
					if (values.isConfirmed) {
						finalAssingTask(e, position, name, values.value[0], values.value[1]);
					} else {
						Swal.close();
					}
				});
			}
		}
	};

	const finalAssingTask = (e, position, name, priority, url) => {
		var message;
		var title = "Send task as Report?";
		var confirmText = "Send";
		var respMessage = "Report successfully sent!";
		var respTitle = "Sent!";
		message = dragedTask.description;
		var fullName = "User";
		if (teamList[position]?.first_name != null && teamList[position]?.last_name != null) {
			fullName = `${teamList[position]?.first_name} ${teamList[position]?.last_name}`;
		}

		if (dragedTask.status == "Completed") {
		} else {
			var title = "Assign Task to " + `${fullName}` + " ?";
			var confirmText = "Assign";
			respMessage = "Task assigned successfully!";
			respTitle = "Assigned!";
		}

		if (checkedTaskList.length > 0) {
			title = "Send " + checkedTaskList.length + " tasks as Report?";
			respMessage = checkedTaskList.length + " Reports successfully sent!";
			if (dragedTask.status != "Completed") {
				title = "Assign " + checkedTaskList.length + " Tasks to " + `${fullName}` + " ?";
				respMessage = checkedTaskList.length + " Tasks assigned successfully!";
			}
		}
		Swal.fire({
			title: title,
			icon: "question",
			confirmButtonText: confirmText,
			confirmButtonColor: "#DC004E",
			showCancelButton: true,
			cancelButtonText: "Cancel",
			reverseButtons: true,
			focusConfirm: true,
			focusCancel: false,
			scrollbarPadding: false,
			showLoaderOnConfirm: true,
			preConfirm: () => {
				Swal.showLoading();
				var allPromiseAr = [];
				if (checkedTaskList.length > 0) {
					for (let index = 0; index < checkedTaskList.length; index++) {
						const elementTask = checkedTaskList[index];
						allPromiseAr.push(assignAPI(elementTask, userId, elementTask.description, position, name, priority, url));
					}
				} else {
					allPromiseAr.push(assignAPI(dragedTask, userId, message, position, name, priority, url));
				}

				Promise.all(allPromiseAr).then((allData) => {
					var success = false;
					for (let index = 0; index < allData.length; index++) {
						const element = allData[index];
						if (element) {
							success = true;
						}
					}
					if (success) {
						refreshList();
						Swal.fire({
							title: respTitle,
							text: respMessage,
							icon: "success",
						}).then(function () {});
					} else {
						Swal.fire({
							icon: "error",
							title: "Error",
							text: "Network Error",
						});
					}
				});
				return new Promise((resolve) => {});
			},
		}).then(function (resp) {
			if (resp.isConfirmed) {
			} else {
			}
		});
	};

	const assignAPI = (dragedTask, userId, message, position, name, priority, url) => {
		var postData = {
			taskId: dragedTask.id,
			orgiUserId: userId,
			user_id: teamList[position].id,
			dashboard: dashboardId,
			message: message,
			status: dragedTask.status,
			priority: priority,
			url: url,
		};
		return new Promise((resolve, reject) => {
			axios
				.post(apiNotification + "/?type=notification", postData, config)
				.then((resp) => {
					if (resp.status == 200) {
						resolve(true);
					} else {
						resolve(false);
					}
				})
				.catch((error) => {
					resolve(false);
				});
		});
	};
	const openTasks = (item) => {
		if (item.task_id > 0) {
			TaskService.openTask(item);
		}
	};

	const notyResponse = (item, status) => {
		var postData = { notyId: item.noty_id, taskId: item.task_id, status: status, user_id: userId, orgiUserId: item.assigned_by };
		axios
			.post(apiNotification + "/?type=noty_response", postData)
			.then((resp) => {
				if (resp.status == 200) {
					fetchCollabData(page, teamUser.id);
					refreshList();
				} else {
					Swal.fire({
						icon: "error",
						title: "Error",
						text: "Network Error",
					});
				}
			})
			.catch((error) => {
				Swal.fire({
					icon: "error",
					title: "Error",
					text: "Network Error",
				});
			});
	};

	const openCollabList = (singleUser) => {
		const pageNum = 1;
		setPage(pageNum);
		setOpen(true);
		setIsTaskLoaded(false);
		setTeamUser(singleUser);
		fetchCollabData(pageNum, singleUser.id);
	};
	const apiTaskList = `${productivityApi}/get-tasks`;
	const fetchCollabData = (pageNum, teamUserId) => {
		axios
			.get(apiTaskList + "/?type=getnotification&orig_user_id=" + userId + "&user_id=" + teamUserId + "&page=" + pageNum + "&limit=" + perPage, config)
			.then((resp) => {
				if (resp.status == 200) {
					console.log(resp.data);
					setTaskList(resp.data.data);
					setTotalItem(resp.data.count);
					var numPages = Math.ceil(resp.data.count / perPage);
					setPageCount(numPages);
				} else {
					setTaskList([]);
				}

				setIsTaskLoaded(true);
			})
			.catch((error) => {
				setIsTaskLoaded(true);
				setTaskList([]);
			});
	};
	const handlePageClick = (event) => {
		setIsTaskLoaded(false);
		setPage(event.selected + 1);
		fetchCollabData(event.selected + 1, teamUser.id);
	};

	const getFullName = (userObj) => {
		var curName = "User";
		if (userObj.first_name) {
			curName = userObj.first_name;
		}

		if (userObj.last_name) {
			curName += " " + userObj.last_name;
		}
		return curName;
	};

	const formatNoty = (task) => {
		var noty = "";
		var curName = getFullName(userData);
		var collUserName = getFullName(teamUser);

		if (task.type == "report") {
			if (task.assigned_by == userId) {
				noty = "You reported task '" + task.message + "' to " + collUserName;
			} else {
				noty = collUserName + " reported task '" + task.message + "' to you";
			}
		} else {
			if (task.assigned_by == userId) {
				noty = "You delegated one " + (task.task_status != null ? task.task_status : "") + " task '" + task.message + "' to " + collUserName;
			} else {
				noty = collUserName + " delegated one " + (task.task_status != null ? task.task_status : "") + " task '" + task.message + "' to you";
			}
		}
		return noty;
	};

	return (
		<div
			className={isDropActive ? "drop_area" : ""}
			style={{
				backgroundColor: "var(--primary-color)",
				height: !notPinned ? "calc(100vh - 25vh)" : "calc(100vh - 75vh)",
				borderRadius: 10,
				padding: 15,
				overflow: "scroll",
			}}
		>
			<div
				style={{
					flexDirection: "row",
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
				}}
			>
				<div
					style={{
						flexDirection: "row",
						display: "flex",
						alignItems: "center",
					}}
				>
					<img
						src={heartIcon}
						style={{
							width: 25,
							height: 25,
							objectFit: "contain",
						}}
						alt={"contact-icon"}
					/>

					<p style={{ color: "#FFF", margin: 0, padding: 0, marginLeft: 10 }}>Collaboration (Team)</p>
				</div>
				<div>
					<img
						src={closeIcon}
						onClick={() => changeSetting(false)}
						style={{
							width: 20,
							height: 20,
							objectFit: "contain",
							cursor: "pointer",
						}}
						alt={"contact-icon"}
					/>
				</div>
			</div>
			{!isTeamLoaded && (
				<div style={{ display: "flex", height: "100%", alignItems: "center" }}>
					<div style={{ margin: "0 auto" }} className="spinner-border spinner-border text-white"></div>
				</div>
			)}
			{isTeamLoaded && (
				<div
					style={{
						marginTop: 30,
					}}
				>
					{teamList.map((item, i) => {
						return (
							<div
								onDragEnter={(e) => dragEnter(e, i)}
								onDragOver={onDragOver}
								onDrop={(e) => onDrop(e, i)}
								onClick={(e) => openCollabList(item)}
								key={i}
								class="profileCardContainer"
								style={{
									display: "inline-block",
									textAlign: "center",
									marginRight: "20px",
									marginLeft: "20px",
									cursor: "pointer",
									width: "inherit",
									height: "inherit",
								}}
							>
								{item.profile_image && (
									<img
										className={dragOverIndex == i ? "drop_area_avatar" : " profileImgCard"}
										src={item.profile_image}
										style={{
											width: 58,
											height: 58,
											objectFit: "contain",
										}}
										alt={"contact-icon"}
									/>
								)}
								{!item.profile_image && (
									<img
										className={dragOverIndex == i ? "drop_area_avatar" : ""}
										src={avatarIcon}
										style={{
											width: 58,
											height: 58,
											objectFit: "contain",
										}}
										alt={"contact-icon"}
									/>
								)}
								{item.first_name != null && (
									<span
										style={{
											display: "block",
											color: "#FFF",
										}}
									>
										{item.first_name} {item.last_name}
									</span>
								)}
								{item.first_name == null && (
									<span
										style={{
											display: "block",
											color: "#FFF",
										}}
									>
										User
									</span>
								)}
							</div>
						);
					})}
					{teamList.length == 0 && (
						<div
							style={{
								color: "#FFF",
								fontSize: 15,
								textAlign: "center",
							}}
						>
							No Team member found.
						</div>
					)}
					{/* <img
          src={add}
          style={{
            width: 25,
            height: 25,
            objectFit: "contain",
            marginLeft: 20,
          }}
          alt={"contact-icon"}
        /> */}
				</div>
			)}
			<Popup open={open} closeOnDocumentClick onClose={closeModal} modal>
				<div className="react_modal">
					<button className="close" onClick={closeModal}>
						&times;
					</button>
					<div className="header"> Collaboration List </div>
					<div className="content">
						{!isTaskLoaded && (
							<div style={{ display: "flex", height: "100%", alignItems: "center" }}>
								<div style={{ margin: "0 auto" }} className="spinner-border spinner-border text-white" role="status"></div>
							</div>
						)}
						{isTaskLoaded && (
							<div>
								<div class="task-div">
									{taskList.map((item, i) => {
										return (
											<div
												key={i}
												style={{
													display: "flex",
													flexDirection: "row",
													alignItems: "center",
													marginBottom: 10,
												}}
											>
												<div style={{ width: "10%" }}>
													<img
														src={rightArrowIcon}
														style={{
															width: 12,
															height: 12,
															objectFit: "contain",
														}}
														alt={"rightArrow-icon"}
													/>
												</div>
												<div style={{ width: "90%", cursor: "pointer" }}>
													<p
														style={{
															color: "#FFF",
															padding: 0,
															margin: 0,
															fontSize: 12,
														}}
													>
														{item.priority != undefined && item.priority != "" && (
															<span
																title={item.priority + " task"}
																className={
																	item.priority == "fire" ? item.priority + "_task " + "fas fa-fire-alt" : item.priority + "_task task_priority"
																}
															></span>
														)}
														<span
															className={[
																item.link != undefined && item.link != "" ? "link" : "",
																item.report_status != undefined && item.report_status != "" && item.report_status == "Completed" ? "strike" : "",
															].join(" ")}
															onClick={() => openTasks(item)}
														>
															{" "}
															{formatNoty(item)}{" "}
														</span>
														{userId == item.assigned_by && item.noty_status == "pending" && (
															<span
																style={{
																	display: "inline-block",
																	color: "orange",
																	fontWeight: 500,
																}}
															>
																{" "}
																: Acceptance Pending
															</span>
														)}
														{item.noty_status == "rejected" && (
															<span
																style={{
																	display: "inline-block",
																	color: "#ed6363",
																	fontWeight: 500,
																}}
															>
																{" "}
																: Rejected
															</span>
														)}
														{item.noty_status == "accepted" && item.task_status != "Completed" && (
															<span
																style={{
																	display: "inline-block",
																	color: "green",
																	fontWeight: 500,
																}}
															>
																{" "}
																: Accepted
															</span>
														)}
														{userId != item.assigned_by && item.noty_status == "pending" && (
															<span
																style={{
																	display: "inline-block",
																	color: "#ed6363",
																	fontWeight: 500,
																}}
															>
																<img
																	onClick={() => notyResponse(item, "accepted")}
																	title="Accept"
																	src={accept}
																	style={{
																		width: 25,
																		height: 25,
																		marginLeft: 10,
																		marginRight: 10,
																		objectFit: "contain",
																	}}
																	alt={"accept-icon"}
																/>
																<button
																	onClick={() => notyResponse(item, "rejected")}
																	style={{
																		width: 25,
																		height: 25,
																		border: "1px solid #238fdb",
																		borderRadius: 15,
																		color: "black",
																		backgroundColor: "#238fdb",
																		fontWeight: "bold",
																	}}
																	title="Reject"
																	class="close"
																>
																	X
																</button>
															</span>
														)}
													</p>
													<p
														style={{
															color: "grey",
															padding: 0,
															margin: 0,
															fontSize: 10,
														}}
													>
														{item.datetime != "" && format(new Date(parseInt(item.datetime)), "dd-LL-yyyy,HH:mm a")}
													</p>
												</div>
											</div>
										);
									})}
									{taskList.length == 0 && (
										<div
											style={{
												color: "#FFF",
												fontSize: 15,
												textAlign: "center",
											}}
										>
											No Collaboration found for selected user.
										</div>
									)}

									{pageCount > 1 && (
										<ReactPaginate
											breakLabel="..."
											nextLabel=" >"
											onPageChange={handlePageClick}
											pageRangeDisplayed={1}
											marginPagesDisplayed={2}
											pageCount={pageCount}
											forcePage={page - 1}
											previousLabel="< "
											renderOnZeroPageCount={null}
											pageClassName="page-item"
											pageLinkClassName="page-link"
											previousClassName="page-item"
											previousLinkClassName="page-link"
											nextClassName="page-item"
											nextLinkClassName="page-link"
											breakClassName="page-item"
											breakLinkClassName="page-link"
											containerClassName="pagination"
											activeClassName="active"
										/>
									)}
								</div>
							</div>
						)}
					</div>
				</div>
			</Popup>
		</div>
	);
});

export default CollaborationTeamCard;
