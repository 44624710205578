import axios from "axios";
import { ActionTypes } from "../constants";
import { authApi, dashboardApi, notificationApi } from "../../utils/api";

const config = {
  headers:{
    'x-api-key': process.env.REACT_APP_C2C_PROD_AWS_ACCESS_KEY
  }
};

//create dashboard
export const createDashboardAction = (name) => async (dispatch) => {
  dispatch({ type: ActionTypes.CREATE_DASHBOARD_REQ });

  const api = `${dashboardApi}/updateDashboards`;
  try {
    const data = await axios.post(api, { name }, config);
    return dispatch({
      type: ActionTypes.CREATE_DASHBOARD_SUCCESS,
      payload: { name, id: data?.data?.insertId },
    });
  } catch (error) {
    dispatch({
      type: ActionTypes.CREATE_DASHBOARD_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};

export const getDashboardUserSettings = (userId, dashboardId) => async (dispatch) => {
  dispatch({ type: ActionTypes.GET_DASHBOARDS_REQ });

  const api = `${dashboardApi}/getDashboardSettings`;
  try {
    const data = await axios.post(api, { userId, dashboardId }, config);
    dispatch({ type: ActionTypes.GET_DASHBOARDS_SETTINGS_SUCCESS, payload: data?.data });
    return data;
  } catch (error) {
    if(error?.code === "ERR_NETWORK") return
    dispatch({
      type: ActionTypes.GET_DASHBOARDS_FAIL,
      payload: error?.response?.data?.message,
    });
  }
}

export const createDashboardUserSettings = (body) => async (dispatch) => {
  dispatch({ type: ActionTypes.GET_DASHBOARDS_REQ });

  const api = `${dashboardApi}/createDashboardUserSettings`;
  try {
    const data = await axios.post(api, body, config);
    return data;
  } catch (error) {
    if(error?.code === "ERR_NETWORK") return
    dispatch({
      type: ActionTypes.GET_DASHBOARDS_FAIL,
      payload: error?.response?.data?.message,
    });
  }
}

export const updateDashboardUserSettings = (body) => async (dispatch) => {
  dispatch({ type: ActionTypes.GET_DASHBOARDS_REQ });

  const api = `${dashboardApi}/updatedashsettings`;
  try {
    const data = await axios.post(api, body, config);
    return data;
  } catch (error) {
    if(error?.code === "ERR_NETWORK") return
    dispatch({
      type: ActionTypes.GET_DASHBOARDS_FAIL,
      payload: error?.response?.data?.message,
    });
  }
}

export const getDashboards = (id) => async (dispatch) => {
  dispatch({ type: ActionTypes.GET_DASHBOARDS_REQ });

  const api = `${dashboardApi}/getDashsUser`;
  try {
    const data = await axios.post(api, { id }, config);
    dispatch({ type: ActionTypes.GET_DASHBOARDS_SUCCESS, payload: data?.data });
  } catch (error) {
    if(error?.code === "ERR_NETWORK") return
    dispatch({
      type: ActionTypes.GET_DASHBOARDS_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};

export const getDashboardByNotifcation = (id) => async (dispatch) => {
  dispatch({ type: ActionTypes.GET_DASHBOARDS_REQ });

  const api = `${dashboardApi}/getDashsUser`;
  try {
    const data = await axios.post(api, { id }, config);
    dispatch({
      type: ActionTypes.GET_DASHBOARDS_WITH_NOTIFICATION,
      payload: data?.data,
    });
  } catch (error) {
    dispatch({
      type: ActionTypes.GET_DASHBOARDS_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};

export const deleteDashboard = (id) => async (dispatch) => {
  dispatch({ type: ActionTypes.DELETE_DASHBOARDS_REQ });

  const delDashboardIndashboard = `${dashboardApi}/deletedashboards`;
  try {
    const data = await axios.post(delDashboardIndashboard, { id }, config);
    dispatch({
      type: ActionTypes.DELETE_DASHBOARDS_SUCCESS,
      payload: data,
    });
    return data;
  } catch (error) {
    dispatch({
      type: ActionTypes.DELETE_DASHBOARDS_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};

export const editDashboard = (id, name) => async (dispatch) => {
  dispatch({ type: ActionTypes.EDIT_DASHBOARDS_REQ });
  const body = {
    id: id,
    updates: {
      name: name,
    },
  };
  const api = `${dashboardApi}/updateDash`;
  try {
    const data = await axios.post(api, body, config);
    dispatch({ type: ActionTypes.EDIT_DASHBOARDS_SUCCESS, payload: data });

    return data;
  } catch (error) {
    dispatch({
      type: ActionTypes.EDIT_DASHBOARDS_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};

export const getTeamMembers = (dashboardId) => async (dispatch) => {
  dispatch({ type: ActionTypes.GET_TEAM_REQ });

  const api = `${authApi}/getusersindashboard`;
  const api_pending = `${authApi}/getPendingUsersinDashboard`;
  try {
    const data = await axios.post(api, { dashboardId }, config);
    const data_pending = await axios.post(api_pending, { dashboardId }, config);
    for(let i=0; i < data_pending?.data?.length; i++){
      var item = data_pending.data[i];
      item.invitiation_status = "pending";
      data.data.push(data_pending.data[i]);
    }
    dispatch({ type: ActionTypes.GET_TEAM_SUCCESS, payload: data, payload_pending: data_pending });
    return data;
  } catch (error) {
    dispatch({
      type: ActionTypes.GET_TEAM_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};

export const clearErrorMessageAction = () => async (dispatch) => {
  dispatch({ type: ActionTypes.CELAR_ERROR_MESSAGE });
};

export const inviteTeamMembersAction =
  (data, setSearchMemberLoad) => async (dispatch) => {
    dispatch({ type: ActionTypes.INVITE_MEMBERS_REQ });

    const api = `${dashboardApi}/inviteteammembers`;
    try {
      const response = await axios.post(api, data, config);
      dispatch({ type: ActionTypes.INVITE_MEMBERS_SUCCESS, payload: response });
      setSearchMemberLoad(false);
      return response;
    } catch (error) {
      setSearchMemberLoad(false);
      dispatch({
        type: ActionTypes.INVITE_MEMBERS_FAIL,
        payload: error?.message,
      });
    }
  };

export const getNotificationsAction = (userId) => async (dispatch) => {
  dispatch({ type: ActionTypes.GET_NOTIFICATION_REQ });

  const api = `${notificationApi}/getnotificationsforuser`;
  try {
    const response = await axios.post(api, { userId }, config);
    dispatch({ type: ActionTypes.GET_NOTIFICATION_SUCCESS, payload: response });
    return response;
  } catch (error) {
    dispatch({
      type: ActionTypes.GET_NOTIFICATION_FAIL,
      payload: error?.message,
    });
  }
};
export const deleteNotificationAction = (id) => async (dispatch) => {
  dispatch({ type: ActionTypes.DEL_NOTIFICATION_REQ });

  const api = `${notificationApi}/deleteNotification`;
  try {
    const response = await axios.post(api, { id }, config);
    dispatch({ type: ActionTypes.DEL_NOTIFICATION_SUCCESS, payload: response });
    return response;
  } catch (error) {
    dispatch({
      type: ActionTypes.DEL_NOTIFICATION_FAIL,
      payload: error?.message,
    });
  }
};

export const deletePendingUserFromDashAction = (user, dashboard) => async (dispatch) => {
  dispatch({ type: ActionTypes.DEL_NOTIFICATION_REQ });

  const api = `${notificationApi}/deletePendingUserFromDashboard`;
  const body = {
    userId: user,
    dashboardId: dashboard
  }
  try {
    const response = await axios.post(api, body, config);
    dispatch({ type: ActionTypes.DEL_NOTIFICATION_SUCCESS, payload: response });
    return response;
  } catch (error) {
    dispatch({
      type: ActionTypes.DEL_NOTIFICATION_FAIL,
      payload: error?.message,
    });
  }
};

export const findMember = async (email) => {
  const api = `${authApi}/getusers`;
  try {
    const data = await axios.post(api, { email }, config);
    return data;
  } catch (error) {
    return "error";
  }
};

export const adminNotifyAction = async (data) => {
  const api = `${notificationApi}/addnotification`;
  try {
    const response = await axios.post(api, data, config);
    return response;
  } catch (error) {
    return "error";
  }
};

export const updateNotificationAction = (data) => async (dispatch) => {
  dispatch({ type: ActionTypes.UPDATE_NOTIFICATION_REQ });

  const api = `${notificationApi}/updatenotification`;
  try {
    const response = await axios.post(api, data, config);
    dispatch({
      type: ActionTypes.UPDATE_NOTIFICATION_SUCCESS,
      payload: response,
    });
    return response;
  } catch (error) {
    dispatch({
      type: ActionTypes.UPDATE_NOTIFICATION_FAIL,
      payload: error?.message,
    });
    return "error";
  }
};
