import React from 'react';
import {Link} from "react-router-dom";
import Container from 'react-bootstrap/Container';

import Button from 'react-bootstrap/Button';
import  Navbar  from './Navbar';

function PrivacyPolicy() {
  return (<>
  <div className='main_hero_div' style={{ height: "1000px" }}>
        <Navbar/>
        <div className='d-flex justify-content-center  ' style={{marginTop:"85px"}} >
            <div className='border_div_career my-5'></div>
        </div>
        <Container id="guide_container">
            <h4 className='display-5 guide_heading mb-4'>Privacy Policy</h4>
            <p className="text-white   display-3 guide_lines mt-5" id="guide-lin">     
                At connect2co.com, we take your privacy seriously. This Privacy Policy explains how we collect, use, 
                and share your personal information when you use our website. By using connect2co.com, you agree to 
                the collection and use of your information in accordance with this policy.
            </p>
            <h4 className='display-7 guide_heading mb-7'>Information Collection and Use</h4>
            <p className="text-white display-3 guide_lines mt-5" id="guide-lin">     
                We may collect the following information when you use our website:
                <ul className='ps-4'>
                    <li>Personal information: When you sign up for our services or contact us, we may collect your name, email address, phone number, and other information you provide to us.</li>
                    <li>Usage information: We may collect information about how you use our website, such as your IP address, browser type, and other usage statistics.</li>
                    <li>Cookies: We may use cookies to collect information about your browsing activity and to personalize your experience on our website.</li>
                </ul> 
            </p>
            <h4 className='display-7 guide_heading mb-7'>Information Sharing and Disclosure</h4>
            <p className="text-white display-3 guide_lines mt-5" id="guide-lin">     
                We may share your information with third parties in the following circumstances:
                <ul className='ps-4'>
                    <li>Service providers: We may share your information with service providers that help us operate our website and provide our services to you.</li>
                    <li>Business transfers: If we are involved in a merger, acquisition, or sale of all or a portion of our assets, your information may be transferred as part of that transaction.</li>
                    <li>Legal requirements: We may disclose your information in response to subpoenas, court orders, or other legal processes, or to establish or exercise our legal rights or defend against legal claims.</li>
                </ul> 
            </p>
            <h4 className='display-7 guide_heading mb-7'>Data Security</h4>
            <p className="text-white display-3 guide_lines mt-5" id="guide-lin">     
                We take reasonable measures to protect your information from unauthorized access, alteration, disclosure, or destruction. However, no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.
            </p>
            <h4 className='display-7 guide_heading mb-7'>Links to Other Websites</h4>
            <p className="text-white display-3 guide_lines mt-5" id="guide-lin">     
                Our website may contain links to other websites that are not owned or controlled by us. We are not responsible for the privacy practices or content of those websites.
            </p>
            <h4 className='display-7 guide_heading mb-7'>Children's Privacy</h4>
            <p className="text-white display-3 guide_lines mt-5" id="guide-lin">     
                Our website is not intended for use by children under the age of 13, and we do not knowingly collect personal information from children under the age of 13.
            </p>
            <h4 className='display-7 guide_heading mb-7'>Changes to This Privacy Policy</h4>
            <p className="text-white display-3 guide_lines mt-5" id="guide-lin">     
                We may update this Privacy Policy from time to time by posting a new version on our website. We will notify you of any changes by email or by posting a notice on our website.
            </p>
            <h4 className='display-7 guide_heading mb-7'>Contact Us</h4>
            <p className="text-white display-3 guide_lines mt-5" id="guide-lin">     
                If you have any questions or concerns about this Privacy Policy, please contact us at support@connect2co.com.
            </p>
        </Container>
    </div>
  </>
  )
}

export default PrivacyPolicy
