import editIcon from "../assets/images/edit-colored.png";
import axios from "axios";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { formatQuery } from "../utils/helpers";

const AccountsTable = ({ accounts, setItem, pinnedProd }) => {
  const [accountsData, setAccountsData] = useState(accounts || {});
  const SFData = useSelector((state) => state.getSFData);
  const baseUrl = useMemo(
    () => SFData?.getSFData?.data?.[0]?.url,
    [SFData?.getSFData?.data]
  );
  const [isLoading, setIsLoading] = useState(true);

  const redirect = (id) => {
    const url = `${baseUrl}/lightning/r/Account/${id}/view`;
    window.open(url);
  };

  const getAccountFields = async () => {
    setIsLoading(true);
    const salesforceLoginAccessToken = localStorage?.getItem(
      "salesforceAccessToken"
    );
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${salesforceLoginAccessToken}`;

    accounts.records.forEach(async (account) => {
      try {
        const contactNameData = await axios.get(
          `${baseUrl}/services/data/v57.0/query?q=${formatQuery(
            `Select Name from Contact where AccountId = '${account.Id}'`
          )}`
        );
        const opportunityNameData = await axios.get(
          `${baseUrl}/services/data/v57.0/query?q=${formatQuery(
            `Select Name from Opportunity where AccountId = '${account.Id}'`
          )}`
        );

        if (contactNameData.data || opportunityNameData.data) {
          setAccountsData((prev) => {
            const data = prev.records.find((item) => item.Id === account.Id);
            const records = prev.records.map((item) => {
              if (item.Id === data.Id) {
                return {
                  ...item,
                  ContactName: contactNameData.data.records?.[0]?.Name || "",
                  OpportunityName:
                    opportunityNameData.data.records?.[0]?.Name || "",
                };
              }
              return item;
            });

            return { ...prev, records };
          });
          return {
            ContactName: contactNameData.data.records?.[0]?.Name || "",
            OpportunityName: opportunityNameData.data.records?.[0]?.Name || "",
          };
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error(error);
      }
    });
  };

  useEffect(() => {
    getAccountFields();
  }, []);

  // if (isLoading) {
  //   return (
  //     <div className="h-50 w-100 d-flex justify-content-center align-items-center">
  //       <div
  //         className="spinner-border spinner-border-md text-primary"
  //         role="status"
  //       />
  //     </div>
  //   );
  // }

  return (
    <table style={{ color: "grey", tableLayout: "fixed", width: "100%" }}>
      <thead style={{ fontSize: "12px" }}>
        <th
          style={{
            width: "calc(100% / 25)",
          }}
        ></th>
        <th
          style={{
            width: pinnedProd ? "calc(100% / 3.5)" : "calc(100% / 2)",
            paddingLeft: "10px",
          }}
        >
          Account Name
        </th>
        <th
          style={{
            width: pinnedProd ? "calc(100% / 10)" : "calc(100% / 6)",
            paddingLeft: "10px",
          }}
        >
          Contact Name
        </th>
        { pinnedProd ? null :
          <th
            style={{
              width: "calc(100% / 6)",
              paddingLeft: "10px",
            }}
          >
            Opportunity Name
          </th>
        }
      </thead>

      <tbody style={{ color: "white", fontSize: "14px" }}>
        {accountsData?.records?.map((account, index) => {
          return (
            <tr key={account.Id}>
              <td
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  width: "calc(100% / 25)",
                  paddingLeft: "10px",
                  paddingTop: "3px",
                  paddingBottom: "3px",
                }}
              >
                {index + 1}
              </td>
              <td
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  width: "calc(100% / 3)",
                  paddingLeft: "10px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexFlow: "row nowrap",
                    alignItems: "center",
                  }}
                >
                  <p
                    style={{
                      flexBasis: "90%",
                      marginBottom: "0px",
                      overflow: "hidden",
                      cursor: "pointer",
                    }}
                    onClick={() => redirect(account.Id)}
                  >
                    {account.Name}
                  </p>
                  <img
                    src={editIcon}
                    alt="edit"
                    style={{
                      height: "20px",
                      width: "20px",
                      marginLeft: "5px",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      setItem({
                        id: account.Id,
                        type: "account",
                        isCreateMode: false,
                      })
                    }
                  />
                </div>
              </td>
              <td
                style={{
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  width: "calc(100% / 6)",
                  paddingLeft: "10px",
                }}
              >
                {account.ContactName}
              </td>
              { pinnedProd ? null :
                <td
                  style={{
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    width: "calc(100% / 6)",
                    paddingLeft: "10px",
                  }}
                >
                  {account.OpportunityName}
                </td>
              }
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default AccountsTable;
