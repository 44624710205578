import React, { useEffect, useState } from "react";
import "./index.css";
import menu1 from "../../assets/images/side-menu-1.png";
import menu2 from "../../assets/images/side-menu-2.png";
import menu3 from "../../assets/images/side-menu-3.png";
import menu4 from "../../assets/images/notification-bell.png";
import logout from "../../assets/images/logout.png";
import logo from "../../assets/images/sidebar-logo.png";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logoutAction } from "../../redux/actions/AuthAction";
import { getNotificationsAction } from "../../redux/actions/DashboardAction";

const Sidebar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [notificationNumber, setNotifcationNumber] = useState([]);

  const notifications = useSelector((state) => state.getNotifications);
  const auth = useSelector((state) => state.Auth);
  const delNotifications = useSelector((state) => state.delNotifications);
  const updateNotifications = useSelector((state) => state.updateNotifications);

  const logoutHandle = () => {
    localStorage.removeItem("userId");
    localStorage.removeItem("salesforceAccessToken");
    localStorage.removeItem("googleAuthAccessToken");
    localStorage.removeItem("googleAuthRefreshToken");
    localStorage.removeItem("token");
    dispatch(logoutAction());
    navigate("/login", { replace: true });
  };
  const userId = localStorage.getItem("userId");

  useEffect(() => {
    dispatch(getNotificationsAction(userId));
  }, [
    dispatch,
    userId,
    delNotifications?.delNotificationData,
    auth?.updateProfileData,
    auth?.profileData,
    updateNotifications?.updateNotificationData,
  ]);
  // useEffect(() => {
  //   if (notifications?.notificationData?.data?.length === 0) {
  //     dispatch(clearDashboardData());
  //   }
  //   let dashboardIds = [];
  //   let setUpdatedIds = [];
  //   let notificationIds = [];
  //   for (let i = 0; i <= notifications?.notificationData?.data?.length; i++) {
  //     const id = notifications?.notificationData?.data[i]?.dashboard;
  //     const notificationId = notifications?.notificationData?.data[i];

  //     if (id) {
  //       dashboardIds.push(id);
  //       notificationIds.push(notificationId);
  //     }
  //   }

  //   for (let i = 1; i <= 5; i++) {
  //     const id = auth.profileData[`dashboard${i}`];
  //     if (id) {
  //       setUpdatedIds.push(id);
  //     }
  //   }
  //   for (let i = 0; i <= 5; i++) {
  //     if (setUpdatedIds.length) {
  //       setNotiId(setUpdatedIds);
  //     }
  //   }
  //   if (!dashboardIds.length) return;
  //   dispatch(getDashboardByNotifcation(dashboardIds));

  //   setTimeout(() => {
  //     dispatch(clearErrorMessageAction());
  //   }, 2000);
  // }, [
  //   dispatch,
  //   notifications?.notificationData,
  //   auth?.profileData,
  //   delNotifications?.delNotificationData,
  //   auth?.updateProfileData,
  // ]);

  useEffect(() => {
    setNotifcationNumber(
      notifications?.notificationData?.data?.filter(
        (e) => e.status === "pending"
      )
    );
  }, [
    delNotifications?.delNotificationData,
    auth?.updateProfileData,
    auth?.profileData,
    notifications?.notificationData,
  ]);
  return (
    <div className="sidebarContainer">
      <div className="sidebarSubContainer">
        <div onClick={() => navigate("/profile")} className="logoContainer">
          <img alt="side-logo" src={logo} />
          <hr />
        </div>
        <div className="menuContainer">
          {/* <div
            onClick={() => navigate("/SalesDashboard")}
            className="mouse-click"
          >
            <img alt="side-icon-1" src={menu1} />
          </div> */}
          <div onClick={() => navigate("/profile")} className="mouse-click">
            <img alt="side-icon-2" src={menu2} />
          </div>
          <div onClick={() => navigate("/settings")} className="mouse-click">
            <img alt="side-icon-3" src={menu3} />
          </div>
          <div
            onClick={() => navigate("/notification")}
            className="mouse-click"
          >
            <p
              style={{
                color: "white",
                position: "absolute",
                bottom: 0,
                right: -3,
              }}
            >
              {notificationNumber?.length || 0}
            </p>
            <img
              style={{ width: 25, height: 25, objectFit: "contain" }}
              alt="side-icon-4"
              src={menu4}
            />
          </div>
        </div>

        <div onClick={logoutHandle} className="logoutContainer mouse-click">
          <hr />
          <img alt="logout-icon" src={logout} />
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
