const Input = ({ value, onChange, type = "", min = "" }) => {
  return (
    <input
      style={{
        border: "none",
        outline: "none",
        paddingLeft: "0px",
        backgroundColor: "#1F243E",
      }}
      type={type}
      value={value}
      onChange={onChange}
      min={min}
    />
  );
};

export default Input;
