let baseAuthApi;
let baseDashboardApi;
let baseNotificationApi;
let baseSalesforceApi;
let baseTasksApi;
let prodAPIString;
let userAuthApiString;

if(process.env.REACT_APP_SECRET_CODE === 'Dev'){
  baseAuthApi = 'oui2godvid';
  baseDashboardApi = 'igg034ssla';
  baseNotificationApi = 'gx87fv2roh';
  baseSalesforceApi = 'p9mzjy7qx1';
  baseTasksApi = 'c59mmr3kad';
  prodAPIString = 'https://iw4ewfhym3.execute-api.us-east-1.amazonaws.com/dev';
  userAuthApiString = 'https://1cb0dylznc.execute-api.us-east-1.amazonaws.com/dev';
}
else{
  baseAuthApi = 'sm272vcmv5';
  baseDashboardApi = 'djvkitd58b';
  baseNotificationApi = '85gt3uk2df';
  baseSalesforceApi = 'trj5xwx3o6';
  baseTasksApi = 'viw551hvaf';
  prodAPIString = 'https://vbx7gtgvjc.execute-api.us-east-1.amazonaws.com/ProductivityAPI';
  userAuthApiString = 'https://paeboeq3df.execute-api.us-east-1.amazonaws.com/prod';
}

export const authApi =
  `https://${baseAuthApi}.execute-api.us-east-1.amazonaws.com/UsersAPI`;
export const dashboardApi =
  `https://${baseDashboardApi}.execute-api.us-east-1.amazonaws.com/DashboardsAPI`;

export const notificationApi =
  `https://${baseNotificationApi}.execute-api.us-east-1.amazonaws.com/NotificationsAPI`;

export const salesforceApi =
  `https://${baseSalesforceApi}.execute-api.us-east-1.amazonaws.com/ConnectorsAPI`;

export const tasksApi =
  `https://${baseTasksApi}.execute-api.us-east-1.amazonaws.com/TasksAPI`;


export const productivityApi = prodAPIString;
export const userAuthApi = userAuthApiString;
