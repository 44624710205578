import React, { useState, useRef, useEffect } from "react";
import { Button } from "react-bootstrap";
import Swal from "sweetalert2";
import Header from "../../Components/Header";
import ProfileCard from "../../Components/ProfileCard";
import Sidebar from "../../Components/Sidebar";
import edit from "../../assets/images/edit-cancel.png";
import deleteIcon from "../../assets/images/delete-white.png";
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import { SimpleToast } from "../../utils/Toast";
import { clearErrorMessageAction } from "../../redux/actions/DashboardAction";
import bcrypt from "bcryptjs";
import { getUserProfileAction, updateProfileAction, initiateEmailVerification, } from "../../redux/actions/AuthAction";
import { useNavigate } from "react-router-dom";
import { uploadFile } from "react-s3";
import { Buffer } from "buffer";
import axios from "axios";
Buffer.from("anything", "base64");
window.Buffer = window.Buffer || require("buffer").Buffer;
const S3_BUCKET = process.env.REACT_APP_SECRET_CODE === "Dev" ? process.env.REACT_APP_S3_BUCKET : process.env.REACT_APP_S3_BUCKET_PROD;
const REGION = process.env.REACT_APP_REGION;
const ACCESS_KEY = process.env.REACT_APP_ACCESS_KEY;
const SECRET_ACCESS_KEY = process.env.REACT_APP_SECRET_ACCESS_KEY;

const config = {
	bucketName: S3_BUCKET,
	dirName: "photos",
	region: REGION,
	accessKeyId: ACCESS_KEY,
	secretAccessKey: SECRET_ACCESS_KEY,
};

function EditProfile() {
	const auth = useSelector((state) => state.Auth);
	const dispatch = useDispatch();
	const userId = localStorage?.getItem("userId");
	const [data, setData] = useState({
		first_name: auth?.profileData?.first_name || "",
		last_name: auth?.profileData?.last_name || "",
		email: auth?.profileData?.email || "",
		position: auth?.profileData?.position || "",
		password: auth?.profileData?.password || "",
	});
	const [file, setFileName] = useState(null);
	const [imgLoad, setImgLoad] = useState(false);
	const inputRef = useRef(null);
	const navigate = useNavigate();
	const changeHandler = async (e) => {
		setData((b) => {
			return { ...b, [e.target.name]: e.target.value };
		});
		if (e.target.name === "profilePhoto") {
			setFileName(e.target.files[0]);
		}
	};
	const fillOutFields = (field_name) => {
		Swal.fire({
			icon: "error",
			title: "Error",
			text: ` Please Fill out ${field_name}`,
		});
	};
	const uploadImageToS3 = async () => {
		setImgLoad(true);
		try {
			const data = await uploadFile(file, config);
			return data;
		} catch (error) {
			setImgLoad(false);

			SimpleToast({
				title: "Error",
				text: error.message,
				iconName: "error",
				iconColor: "#d33",
			});
		}
	};

	const FormSubmit = async (e) => {
		e.preventDefault();
		var allData = Object.keys(data);

		if (!allData.includes("first_name") || !data["first_name"]?.trim().length) {
			fillOutFields("First Name");
		} else if (!allData.includes("last_name") || !data["last_name"]?.trim().length) {
			fillOutFields("Last Name");
		} else if (!allData.includes("position") || !data["position"]?.trim().length) {
			fillOutFields("Title");
		} else if (!allData.includes("password") || !data["password"]?.trim().length) {
			fillOutFields("Password");
		} else if (!allData.includes("email") || !data["email"]?.trim().length) {
			fillOutFields("Email");
		} else {
			if ((auth?.profileData?.profile_image && file === null) || (!auth?.profileData?.profile_image && file === null)) {
				const temp_data = { ...data };

				delete temp_data.profilePhoto;
				// temp_data.password = bcrypt.hashSync(temp_data.password, 10); // hash password
				if (!bcrypt.getRounds(temp_data.password)) {
					// Password is not hashed, so hash it
					temp_data.password = bcrypt.hashSync(temp_data.password, 10);
				} else {
					// Password is already hashed, no need to hash it again
					temp_data.password = auth?.profileData?.password;
				}
				// temp_data.password = auth?.profileData?.password;

				   // Check if the email is changed
				   if (auth?.profileData?.email !== data.email) {

					const userId = auth?.profileData?.id;
					// If email is changed, make an API call to initiate email verification
					const emailVerificationResponse = await dispatch(initiateEmailVerification(
					  userId,
					  data.email
					));
			
					if (emailVerificationResponse?.status === 200) {
					  // Email verification initiated successfully
					  // Include the hashed token and changed email in temp_data
					  const verificationData = emailVerificationResponse.data;
					  temp_data.email_reset_token = verificationData.resetToken;
					  temp_data.changed_email = data.email;
					  temp_data.email = verificationData.email;
			
					} else {
					  // Handle error in initiating email verification
					    // Handle error in initiating email verification
            // Handle duplicate email error, e.g., show a user-friendly message
            SimpleToast({
				title: "Error",
				text: "Email is already in use.",
				iconName: "error",
				iconColor: "#d33",
			  });
			  // Optionally, you can prevent further requests or take appropriate action
			  return;
					  return;
					}
				  }

				  
				const body = {
					...temp_data,
				};
				const response = await dispatch(updateProfileAction(userId, body));
				if (response?.status === 200 && response?.data?.affectedRows === 1) {
					if (auth?.profileData?.email !== data.email) {
						SimpleToast({
						  title: "Success",
						  text: "User successfully updated. Please verify your new email to complete the change.",
						  iconName: "success",
						  iconColor: "green",
						});
					  }else{
						SimpleToast({
						  title: "Success",
						  text: "User successfully updated.",
						  iconName: "success",
						  iconColor: "green",
						});
					  }
					dispatch(getUserProfileAction(userId));
					setTimeout(() => {
						navigate("/profile");
					}, 500);
				} else {
					return;
				}
			} else {
				const imageResponse = await uploadImageToS3();
				const temp_data = { ...data };

				delete temp_data.profilePhoto;
				temp_data.password = bcrypt.hashSync(temp_data.password, 10); // hash password
				const body = {
					...temp_data,
					profile_image: imageResponse?.location,
				};

				if (imageResponse?.result?.status === 204) {
					setImgLoad(false);

					const response = await dispatch(updateProfileAction(userId, body));
					if (response?.status === 200 && response?.data?.affectedRows === 1) {
						SimpleToast({
							title: "Success",
							text: "User successfully updated.",
							iconName: "success",
							iconColor: "green",
						});
						dispatch(getUserProfileAction(userId));
						setTimeout(() => {
							navigate("/profile");
						}, 500);
					} else {
						return;
					}
				} else {
					return;
				}
			}
		}
	};

	const deleteHandler = () => {
		/** 3DPL - FOLLOWING CODE IS FOR FREE PLAN */
		const config = {
			headers: {
				"x-api-key": process.env.REACT_APP_C2C_PROD_AWS_ACCESS_KEY,
			},
		};
		const initiateData = {
			userId,
		};

		Swal.fire({
			title: "Want to delete your Account?",
			text: "You will lose all your data and will never be able to recover your account again. Please proceed with cautions.",
			icon: "error",
			confirmButtonText: "Confirm and Proceed",
		}).then(async (result) => {
			if (result.isConfirmed) {
				try {
					const response = await axios.post(
						"https://gx7sfzypve.execute-api.us-east-1.amazonaws.com/deleteUserProfile/deleteuserprofile", // URLPASTEHERE
						initiateData,
						config // Use config2 instead of config
					);
					if (response.status === 200) {
						localStorage.removeItem("userId");
						localStorage.removeItem("salesforceAccessToken");
						localStorage.removeItem("googleAuthAccessToken");
						localStorage.removeItem("googleAuthRefreshToken");
						localStorage.removeItem("token");

						navigate("/login", { replace: true });
					}
					console.log("Logout Success", response); // Log the response data
				} catch (error) {
					console.error("Error occurred:", error);
					console.error("Error response:", error.response); // Log the full response if available

					// Handle different error scenarios based on status codes
					if (error.response && error.response.status === 404) {
						Swal.fire({
							title: "Error",
							text: "User not found.",
							icon: "error",
						});
					} else if (error.response && error.response.status === 500) {
						Swal.fire({
							title: "Error",
							text: "An error occurred while initiating the payment.",
							icon: "error",
						});
					} else {
						Swal.fire({
							title: "Error",
							text: "An unknown error occurred.",
							icon: "error",
						});
					}
				}
			}
		});
	};
	useEffect(() => {
		if (auth?.isProfileError || auth?.isUpdateProfileError) {
			SimpleToast({
				title: "Error",
				text: "Oops, something went wrong.",
				iconName: "error",
				iconColor: "#d33",
			});
		}
		setTimeout(() => {
			dispatch(clearErrorMessageAction());
		}, 2000);
	}, [auth?.isProfileError, auth?.isUpdateProfileError, dispatch]);

	return (
		<>
			{auth?.isProfileLoading ? (
				<div
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						height: "100%",
					}}
				>
					<div className="spinner-border spinner-border text-white" role="status" />
				</div>
			) : (
				<div className="d-flex">
					<div>
						<Sidebar /> <Header />
					</div>
					<div className="edit_profile_mainContainer">
						<div className="edit_profileCard_mainContainer">
							<ProfileCard path="/profile" userData={auth?.profileData} />
							<div className="edit_editCard">
								<img onClick={() => navigate("/profile")} src={edit} alt="edit" />
							</div>
							<div className="edit_editCard del">
								<img onClick={deleteHandler} src={deleteIcon} alt="delete" />
								<span className="tooltiptext">Delete Profile</span>
							</div>
						</div>
					</div>
					<div
						style={{
							width: 700,
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							alignItems: "center",
							marginTop: 150,
						}}
					>
						<h4 className="display-5 text-white text-center mb-4" style={{ fontWeight: "300" }}>
							Edit Info
						</h4>

						<form className="g-3" onSubmit={FormSubmit}>
							<div
								style={{
									display: "flex",
									width: 500,
									justifyContent: "space-between",
									marginTop: 10,
								}}
							>
								<div style={{ width: "45%" }}>
									<label className="label">First Name</label>
									<input type="text" name="first_name" value={data?.first_name} onChange={changeHandler} />
								</div>
								<div style={{ width: "45%" }}>
									<label className="label ">Last Name</label>
									<input type="text" name="last_name" value={data?.last_name} onChange={changeHandler} />
								</div>
							</div>

							<div
								style={{
									display: "flex",
									width: 500,
									justifyContent: "space-between",
									marginTop: 10,
								}}
							>
								<div style={{ width: "45%" }}>
									<label className="label">Password</label>
									<br></br>
									<input type="password" name="password" value={data?.password} onChange={changeHandler} autocomplete="new-password" />
								</div>
								<div style={{ width: "45%" }}>
									<label className="label">Title</label>
									<br></br>
									<input type="text" name="position" value={data?.position} onChange={changeHandler} />
								</div>
							</div>
							<div
								style={{
									display: "flex",
									width: 500,
									justifyContent: "space-between",
									marginTop: 10,
								}}
							>
								<div style={{ width: "45%" }}>
									<label className="label">Email</label>
									<br></br>
									<input type="email" name="email" value={data?.email} onChange={changeHandler} />
								</div>
								<div style={{ width: "45%" }}>
									<label className="label">Profile Photo</label>
									<br></br>

									<input
										accept="image/*"
										type="file"
										name="profilePhoto"
										onChange={changeHandler}
										alt="profile-photo"
										style={{ display: "none" }}
										ref={inputRef}
									/>
									<input
										type="text"
										value={file ? file?.name : auth?.profileData?.profile_image ? auth?.profileData?.profile_image.split("/").pop() : "Attach Photo"}
										onClick={() => inputRef.current.click()}
										style={{ cursor: "pointer" }}
									/>
								</div>
							</div>

							<div className="d-flex justify-content-center mt-4">
								<Button disabled={auth?.isUpdateProfileLoading} type="submit" className="mt-3 ms-3 sign_up">
									{auth?.isUpdateProfileLoading || imgLoad ? <div class="spinner-border spinner-border-sm text-primary" role="status" /> : "SAVE"}
								</Button>
							</div>
						</form>
					</div>
				</div>
			)}
		</>
	);
}

export default EditProfile;
