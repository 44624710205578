import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import ContentCard from "../ContentCard";
import axios from "axios";
import axiosApiInstance from "../../../../utils/axiosInterceptor";

function GoogleDriveFiles({
  filesData,
  shareGoogleFileData,
  setMaxResult,
  maxResult,
  getGoogleDriveData,
  nextPageTokens,
  parentFolderShow,
  childrenFileData,
  setParentFolderShow,
  backOneLevel
}) {

  
  async function handleDownloadClick(item) {
    try{
      console.log(item,'my fileId')
      let mimeType = ''
      let downldType = ''
      console.log(item.mimeType.indexOf('spreadsheet'))
      if(item.mimeType.indexOf('spreadsheet') !== -1){
        console.log(mimeType,'first if')
        mimeType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        downldType ='.xls'
      } 
      else if(item.mimeType.indexOf('document') !== -1) {
        mimeType = "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        downldType ='.doc'
      } 
      else{
        console.log(mimeType,'in else')
        mimeType ='application/vnd.openxmlformats-officedocument.presentationml.presentation'
        downldType ='.ppt'
      } 
      
      const response = await  axiosApiInstance.get(` https://www.googleapis.com/drive/v3/files/${item.id}/export?mimeType=${mimeType}`,{responseType:'blob'});
  
      console.log(response,'my response, ')
      
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      console.log(url,'my response, ')
      link.setAttribute('download', `${item.name}${downldType}`);
      document.body.appendChild(link);
      link.click();
    }
    catch(err){
      console.log(err)
    }
  }
  
  
  return (
    <div className="google-drive-files mt-3">
      <h4
        style={{
          color: "#fff",
          fontSize: 16,
          margin: 0,
          fontWeight: 400,
          padding: "0 10px 0 0",
          display: "flex",
          justifyContent: "space-between",
          alignContent: "center",
        }}
      >
        Files
        {!parentFolderShow ? (
          <Button
            size="md"
            variant="none"
            style={{
              width: "auto",
              height: "auto",
              padding: "7px 30px",
              margin: "0",
              borderRadius: 14,
              display: "inline-block",
              backgroundColor: "var(--secondry-color)",
              borderRadius: 10
            }}
            onClick={() => {
              // setParentFolderShow(!parentFolderShow);
              backOneLevel();
            }}
          >
            <p
              style={{
                color: "#fff",
                margin: 0,
                padding: 0,
                fontWeight: "600",
              }}
            >
              Back
            </p>
          </Button>
        ) : (
          ""
        )}
      </h4>

      <div className="goolge-sheets-list">
        <>
          {parentFolderShow ? (
            <>
              <Row className="mt-2 w-100 mx-auto">
                {filesData.map((item, index) => {
                  return (
                    <Col className="p-1" lg={4} md={6} sm={6} key={index}>
                      <ContentCard
                        bgColor={`${
                          item.mimeType ===
                          "application/vnd.google-apps.document"
                            ? "#79C8FF"
                            : item.mimeType ===
                              "application/vnd.google-apps.spreadsheet"
                            ? "#00CC7E"
                            : item.mimeType ===
                              "application/vnd.google-apps.presentation"
                            ? "#FFD800"
                            : ""
                        }`}
                        subTitle={`${
                          item.mimeType ===
                          "application/vnd.google-apps.document"
                            ? "Google Docs"
                            : item.mimeType ===
                              "application/vnd.google-apps.spreadsheet"
                            ? "Spread Sheet"
                            : item.mimeType ===
                              "application/vnd.google-apps.presentation"
                            ? "Googles Slides"
                            : ""
                        }`}
                        title={item?.name}
                        id={item?.id}
                        shareGoogleFileData={shareGoogleFileData}
                        setUrl={`${
                          item.mimeType ===
                          "application/vnd.google-apps.document"
                            ? `https://docs.google.com/document/d/${item?.id}/edit`
                            : item.mimeType ===
                              "application/vnd.google-apps.spreadsheet"
                            ? `https://docs.google.com/spreadsheets/d/${item?.id}/edit`
                            : item.mimeType ===
                              "application/vnd.google-apps.presentation"
                            ? `https://docs.google.com/presentation/d/${item?.id}/edit`
                            : ""
                        }`}
                        onClick={() =>handleDownloadClick(item)}
                      />
                    </Col>
                  );
                })}
              </Row>

              {nextPageTokens ? (
                <Button
                  size="md"
                  variant="none"
                  onClick={() => {
                    getGoogleDriveData();
                    setMaxResult(maxResult + 50);
                  }}
                  style={{
                    width: "150px",
                    height: "40px",
                    margin: "0 auto",
                    display: "table",
                    backgroundColor: "var(--secondry-color)",
                    borderRadius: 10
                  }}
                >
                  <p style={{ color: "#FFF", margin: 0, padding: 0 }}>
                    Load More
                  </p>
                </Button>
              ) : (
                ""
              )}
            </>
          ) : (
            <Row className="mt-2 w-100 mx-auto">
              <>
                {childrenFileData?.length > 0 ? (
                  <>
                    {childrenFileData?.map((item, index) => {
                      return (
                        <>
                          <Col
                            className="p-1"
                            lg={4}
                            md={6}
                            sm={6}
                            key={index}
                          >
                            <ContentCard
                              bgColor={`${
                                item.mimeType ===
                                "application/vnd.google-apps.document"
                                  ? "#79C8FF"
                                  : item.mimeType ===
                                    "application/vnd.google-apps.spreadsheet"
                                  ? "#00CC7E"
                                  : item.mimeType ===
                                    "application/vnd.google-apps.presentation"
                                  ? "#FFD800"
                                  : "#238fdb"
                              }`}
                              subTitle={`${
                                item.mimeType ===
                                "application/vnd.google-apps.document"
                                  ? "Google Docs"
                                  : item.mimeType ===
                                    "application/vnd.google-apps.spreadsheet"
                                  ? "Spread Sheet"
                                  : item.mimeType ===
                                    "application/vnd.google-apps.presentation"
                                  ? "Googles Slides"
                                  : item.mimeType ===
                                    "application/vnd.google-apps.xlsx"
                                  ? "Xlsx"
                                  : item.mimeType ===
                                    "application/vnd.google-apps.txt"
                                  ? "Text"
                                  : item.mimeType ===
                                    "application/vnd.google-apps.jpg"
                                  ? "Image"
                                  : item.mimeType ===
                                    "application/x-zip-compressed"
                                  ? "Zip File"
                                  : item.mimeType ===
                                    "application/vnd.android.package-archive"
                                  ? "APK"
                                  : ""
                              }`}
                              title={item?.name}
                              id={item?.id}
                              shareGoogleFileData={shareGoogleFileData}
                              setUrl={`${
                                item.mimeType ===
                                "application/vnd.google-apps.document"
                                  ? `https://docs.google.com/document/d/${item?.id}/edit`
                                  : item.mimeType ===
                                    "application/vnd.google-apps.spreadsheet"
                                  ? `https://docs.google.com/spreadsheets/d/${item?.id}/edit`
                                  : item.mimeType ===
                                    "application/vnd.google-apps.presentation"
                                  ? `https://docs.google.com/presentation/d/${item?.id}/edit`
                                  : ""
                              }`}
                              onClick={() =>handleDownloadClick(item)}
                            />
                          </Col>
                        </>
                      );
                    })}
                  </>
                ) : (
                  <>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <h6
                        style={{
                          color: "#FFF",
                          textAlign: "center",
                        }}
                      >
                        No compatible files found
                      </h6>
                    </div>
                  </>
                )}
              </>
            </Row>
          )}
        </>
      </div>
    </div>
  );
}

export default GoogleDriveFiles;
