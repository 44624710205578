import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import dropdownIcon from "../../../../assets/images/dropdown-icon.png";
import rightArrowIcon from "../../../../assets/images/right-arrow-icon.png";
import IconMapExpand from "../../../../assets/images/Icon-map-expand.png";
import GoogleModal from "../../../Modal";
import { SimpleToast } from "../../../../utils/Toast";
import axiosApiInstance from "../../../../utils/axiosInterceptor";

let arr = [
  "12 AM",
  "1 AM",
  "2 AM",
  "3 AM",
  "4 AM",
  "5 AM",
  "6 AM",
  "7 AM",
  "8 AM",
  "9 AM",
  "10 AM",
  "11 AM",
  "12 PM",
  "1 PM",
  "2 PM",
  "3 PM",
  "4 PM",
  "5 PM",
  "6 PM",
  "7 PM",
  "8 PM",
  "9 PM",
  "10 PM",
  "11 PM",
  "12 AM"
];

function GoogleCalendarChart({
  data,
  setData,
  setActive,
  setGoogleAuth,
  isLoading,
  onSuccess,
  setIsLoading,
  updateData,
  setUpdateData,
  setIsNewData,
  expand, 
  pinnedCollab,
  pinnedProd
}) {
  const [today, setToday] = useState(false);
  const [filterDate, setFilterDate] = useState(null);
  const [startDay, setStartDay] = useState(null);
  const [endDay, setEndDay] = useState(null);
  const [showDate, setShowDate] = useState(new Date());
  const [showModal, setShowModal] = useState(false);
  const [filterCalendarData, setFilterCalendarData] = useState([]);
  const [showActive, setShowActive] = useState(false);
  const date = moment(); // the date you want to retrieve events for
  const dateTomorrow = moment().add(1, "days");

  useEffect(() => {
    setFilterCalendarData(
      (data || []).sort((a, b) => {
        return (
          new Date(b?.start?.dateTime).getTime() -
          new Date(a?.start?.dateTime).getTime()
        );
      })
    );
  }, [data]);

  const getMaxData = async () => {
    setIsLoading(true);
    let result = [];
    await axiosApiInstance
      .get("https://www.googleapis.com/calendar/v3/calendars/primary/events", {
        params: {
          timeMin: startDay,
          timeMax: endDay,
          singleEvents: true,
        },
      })
      .then((res) => {
        result = res.data.items;   // 
        res.data.items?.forEach((i, indInner) => {
          var eventsAtGivenTime = result.filter((obj => obj.start.dateTime === i.start?.dateTime));
          var eventWidth = 100/eventsAtGivenTime.length;

          var eventAtTimeIndex = eventsAtGivenTime.findIndex((obj => obj.id === i.id));

          i.width = eventWidth;
          i.left = eventAtTimeIndex;
        });

        setData(res.data.items);

        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        SimpleToast({
          title: "Error",
          text: `${err.message}`,
          iconName: "error",
          iconColor: "#d33",
        });
      });
  };

  useEffect(() => {
    const filterData = async () => {
      if (filterDate) {
        getMaxData();
      } else if (!filterDate) setFilterCalendarData(data);
    };
    filterData();
  }, [filterDate]);

  // console.log(filterDate,'my calendar data')

  return (
    <div className="google-calendar-chart h-100">
      <div className="d-flex justify-content-between align-items-center gap-3">
        <Button
          variant="none"
          className="poppins"
          onClick={() => setActive(0)}
          style={{
            backgroundColor: "var(--secondry-color)",
            borderRadius: 10,
          }}
        >
          <p style={{ color: "#FFF", margin: 0, padding: 0 }}>Google Calendar</p>
        </Button>
        <img
          style={{ width: 20, height: 20, objectFit: "contain", cursor: "pointer"}}
          alt={"add-icon"}
          src={IconMapExpand}
          onClick={() => expand(true)}
          hidden={pinnedCollab || pinnedProd ? false : true}
        />
      </div>
      <Row className="mt-2">
        <Col
          lg={2}
          md={6}
          className="d-flex justify-content-start align-items-center mt-2"
          {...(today && { style: { paddingRight: 0 } })}
        >
          <p
            style={{
              color: "grey",
              padding: 0,
              marginRight: 10,
              marginBottom: 0,
              fontSize: 14,
            }}
          >
            {!today ? "Today" : ""}
          </p>
          <div
            onClick={() => setToday(!today)}
            style={{
              cursor: "pointer",
            }}
          >
            <img
              src={dropdownIcon}
              style={{ width: 20, height: 20, objectFit: "contain" }}
              alt={"add-icon"}
            />
          </div>
        </Col>
        <Col
          lg={10}
          md={8}
          className="d-flex justify-content-start align-items-center mt-2 h-88"
          {...(today && { style: { padding: 0 } })}
        >
          {today ? (
            <>
              <Button
                size="sm"
                variant="none"
                onClick={() => {
                  setFilterDate(new Date().setHours(0,0,0,0));
                  setStartDay(date.startOf("day").toISOString());
                  setEndDay(date.endOf("day").toISOString());
                  setShowActive(false);
                }}
                style={{
                  backgroundColor: showActive
                    ? "var(--secondry-color)"
                    : "var(--bg-color)",
                  borderRadius: 10,
                }}
              >
                <p style={{ color: "#FFF", margin: 0, padding: 0 }}>Today</p>
              </Button>
              <Button
                size="sm"
                variant="none"
                onClick={() => {
                  const todayDate = new Date()
                  const tomorrow = new Date(todayDate)
                  tomorrow.setDate(tomorrow.getDate() + 1)
                  tomorrow.setHours(0,0,0,0);
                  setFilterDate(tomorrow);
                  setStartDay(dateTomorrow.startOf("day").toISOString());
                  setEndDay(dateTomorrow.endOf("day").toISOString());
                  setShowActive(true);
                }}
                style={{
                  backgroundColor: !showActive
                    ? "var(--secondry-color)"
                    : "var(--bg-color)",
                  borderRadius: 10,
                  marginLeft: 5,
                }}
              >
                <p style={{ color: "#FFF", margin: 0, padding: 0 }}>Tomorrow</p>
              </Button>
            </>
          ) : (
            <>
              <div className="pick-style" style={{width: pinnedProd ? "36%" : "27%"}}>
                <p
                  style={{
                    color: "grey",
                    padding: 0,
                    margin: 0,
                    fontSize: 14
                  }}
                >
                  <ReactDatePicker
                    selected={filterDate ?? showDate}
                    onChange={(newDate) => {
                      setFilterDate(new Date(newDate).setHours(0,0,0,0));
                      setStartDay(moment(newDate).startOf("day").toISOString());
                      setEndDay(moment(newDate).endOf("day").toISOString());
                    }}
                    dateFormat="MMMM d, yyyy"
                  />
                </p>
              </div>
              <div
                onClick={() => {
                  setIsNewData(true);
                  setGoogleAuth(true);
                }}
                style={{
                  cursor: "pointer",
                }}
              >
                <img
                  src={rightArrowIcon}
                  style={{
                    width: 15,
                    height: 15,
                    objectFit: "contain",
                  }}
                  alt={"rightArrow-icon"}
                />
              </div>{" "}
            </>
          )}
        </Col>
      </Row>

      <div style={{ display: "inline", position: "relative" }}>
        <div
          className="lines"
          style={{
            overflow: "hidden",
            position: "relative",
            top: "20px",
            width: "100%",
            height: "auto",
            paddingBottom: "10px",
          }}
        >
          {arr.map((item, ind) => {
            return (
              <div className="graph" key={ind}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: 15,
                    width: "100%",
                    alignItems: "center",
                    position: "relative",
                  }}
                >
                  <p
                    style={{
                      color: "#FFF",
                      fontSize: 11,
                      width: "12%",
                      margin: 0,
                      padding: 0,
                    }}
                  >
                    { ind < arr.length - 1 ? item : ""}
                  </p>
                  <div
                    style={{
                      width: "85%",
                      float: "right",
                    }}
                  >
                    { ind < arr.length - 1 ? 
                      <hr
                        style={{
                          height: 2,
                          borderWidth: 0,
                          backgroundColor: "#FFF",
                          opacity: 1,
                          borderRadius: 5,
                          margin: 0,
                        }}
                      /> :
                      <></>
                    }
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div
          className="box"
          style={{
            width: "86%",
            height: "100%",
            display: "grid",
            left: "12%",
            position: "absolute",
            top: "42px",
          }}
        >
          {isLoading ? (
            <div className="h-50 w-100 d-flex justify-content-center align-items-center">
              <div
                className="spinner-border spinner-border-md text-primary"
                role="status"
              />
            </div>
          ) : (
            <>
              {arr.map((item, ind) => {
                return (
                  <div
                    className="bar"
                    key={ind}
                    onClick={() => {
                      const mydate = filterDate
                        ? new Date(filterDate)
                        : new Date();
                      const getEndTime =
                        ind <= 8 ? `0${ind + 2}:00:00` : `${ind + 2}:00:00`;
                      const getStartTime =
                        ind <= 8 ? `0${ind + 1}:00:00` : `${ind + 1}:00:00`;
                      const getStartDate = `${mydate.getFullYear()}-${
                        mydate.getMonth() + 1 <= 9
                          ? `0${mydate.getMonth() + 1}`
                          : mydate.getMonth() + 1
                      }-${
                        mydate.getDate() <= 9
                          ? `0${mydate.getDate()}`
                          : mydate.getDate()
                      }T${getStartTime}`;
                      const getEndDate = `${mydate.getFullYear()}-${
                        mydate.getMonth() + 1 <= 9
                          ? `0${mydate.getMonth() + 1}`
                          : mydate.getMonth() + 1
                      }-${
                        mydate.getDate() <= 9
                          ? `0${mydate.getDate()}`
                          : mydate.getDate()
                      }T${getEndTime}`;

                      const obj = {
                        start: { dateTime: getStartDate },
                        end: { dateTime: getEndDate },
                        summary: null,
                      };
                      setUpdateData(obj);
                      setIsNewData(true);
                      setGoogleAuth(true);
                    }}
                  >
                  </div>
                );
              })}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                {filterCalendarData?.length &&
                  filterCalendarData?.map((i, indInner) => {
                    // Create moments from times for calculations.
                    var startMoment = moment(i.start?.dateTime);
                    var startMoment2 = !filterDate ? moment("12:00:00 am", "HH:mm:ss a") : moment(filterDate);
                    var endMoment = moment(i.end?.dateTime);
                    var duration = moment.duration(endMoment.diff(startMoment));
                    var eventLength = duration.asHours();

                    // Get vertical distance from top of calendar grid
                    // to display event.
                    var duration2 = moment.duration(startMoment.diff(startMoment2));
                    var diffFrom12AM = duration2.asHours();

                    // In the event of an event running over multiple days,
                    // the calculation needs to be performed differently.
                    if (diffFrom12AM < 0){
                      diffFrom12AM = 0;
                      duration2 = moment.duration(endMoment.diff(startMoment2));
                      eventLength = duration2.asHours();
                    }
                    else if(diffFrom12AM === 23){   // if we are at 11PM, the max length should be 1 hour.
                      eventLength = 1;
                    }

                    // Calculate left margin for event.
                    // This is used for events that occur on same time slot.
                    var leftMargin = filterCalendarData[indInner]?.width*filterCalendarData[indInner]?.left;
                    var eventHeight = eventLength < 0.5 ? 16 : (32.5 * eventLength);

                    return (
                        <div
                          key={indInner}
                          className ="chart-title borders"
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setUpdateData(filterCalendarData[indInner]);
                            setIsNewData(false);
                            setGoogleAuth(true);
                          }}
                          style={{
                            position: "absolute",
                            left: `${leftMargin}%`,
                            top: 31.5 * diffFrom12AM,
                            backgroundColor: "var(--secondry-color)",
                            width: `${filterCalendarData[indInner]?.width}%`,
                            padding: "0px 5px",
                            borderRadius: 3,
                            height: eventHeight,
                            cursor: "pointer"
                          }}
                        >
                          <p
                            style={{
                              color: "#FFF",
                              fontWeight: "bold",
                              margin: 0,
                              fontSize: 10,
                              cursor: "pointer",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              overflow: "hidden"
                            }}
                          >
                            {i.summary}
                          </p>
                        </div>
                    );
                  })}
              </div>
            </>
          )}
        </div>
      </div>
      <GoogleModal
        show={showModal}
        setShowModal={setShowModal}
        updateData={updateData}
        setUpdateData={setUpdateData}
        onSuccess={onSuccess}
      />
    </div>
  );
}

export default GoogleCalendarChart;
