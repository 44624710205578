import React, { useEffect, useState } from "react";
import Header from "../../Components/Header";
import Sidebar from "../../Components/Sidebar";
import general from "../../assets/images/general.png";
import billing from "../../assets/images/billing.png";
import permission from "../../assets/images/permission.png";
import connection from "../../assets/images/connection.png";

import "./index.css";
import Connection from "../../Components/Connection";
import { useLocation, useSearchParams } from "react-router-dom";
import { SimpleToast } from "../../utils/Toast";

const Setting = () => {
  const [connectionCom, setConnectionCom] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() =>{
     const query = searchParams.get("connection")
     if(query) {
      SimpleToast({
        title: "Warning",
        text: "Please authenticate with google first!",
        iconName: "warning",
        iconColor: "#FACEA8",
      });
       setConnectionCom(true)

     } 
    },[searchParams])
  const location = useLocation()
  useEffect(() =>{
    if(location?.state?.openConnection) setConnectionCom(true)
  },[])
  return (
    <div className="d-flex">
      <div>
        <Sidebar />
        <Header />
      </div>
      {connectionCom ? (
        <Connection setConnectionCom={setConnectionCom} />
      ) : (
        <div className="setting_mainContainer">
          <div className="setting_option_card">
            <p>General</p>
            <img src={general} alt="general" />
          </div>
          <div className="setting_option_card"onClick={() => {
  // Redirect to Billing route
  window.location.href = "/billing";
}}>
            <p>Billing</p>
            <div className="img-container">
              <img src={billing} alt="billing" />
            </div>
          </div>
          <div className="setting_option_card">
            <p>Permission</p>
            <div className="img-container">
              <img src={permission} alt="permission" />
            </div>
          </div>
          <div
            onClick={() => setConnectionCom(true)}
            className="setting_option_card"
          >
            <p>Connection</p>
            <div className="img-container">
              <img src={connection} alt="connection" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Setting;
