import React, { useState, useEffect, useRef, useCallback } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { DefinedRange } from "react-date-range";
import closeIcon from "../../assets/images/edit-cancel.png";
import completedIcon from "../../assets/images/completed-icon.png";
import add from "../../assets/images/add.png";
import edit from "../../assets/images/edit.png";
import dropdownIcon from "../../assets/images/dropdown-icon.png";
import productivityIcon from "../../assets/images/productivity-icon.png";
import rightArrowIcon from "../../assets/images/right-arrow-icon.png";
import { productivityApi } from "../../utils/api";
import useComponentVisible from "./useComponentVisible";
import "../css/Productivity.css";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { startOfWeek, endOfWeek, format, parseISO } from "date-fns";

import { updateDashboardUserSettings, createDashboardUserSettings, getDashboardUserSettings } from "../../redux/actions/DashboardAction";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import Swal from "sweetalert2";
import TaskService from "./task";

const ProductivityCard = ({ pinned, handleDragDrop, refereshTaskList }) => {
	const taskStatusAr = ["All", "Waiting", "Ongoing", "Completed"];
	const location = useLocation();
	const dispatch = useDispatch();
	const dashboard = useSelector((state) => state.dashboardReducer);
	const [searchParams, setSearchParams] = useSearchParams();
	var dashboardId = searchParams.get("id");
	if (dashboardId == undefined || dashboardId == null) {
		dashboardId = sessionStorage.getItem("dashboardId");
	}
	console.log("dashboardId==>", dashboardId);
	const auth = useSelector((state) => state.Auth);

	const config = {
		headers: {
			"x-api-key": process.env.REACT_APP_C2C_PROD_AWS_ACCESS_KEY,
		},
	};

	const changeSetting = async (val) => {
		pinned(val);
		let body;
		if (dashboard?.getDashboardSettingsData?.length > 0) {
			body = {
				id: dashboard?.getDashboardSettingsData[0].id,
				updates: {
					prod_componet_pinned: 0,
				},
			};
			await dispatch(updateDashboardUserSettings(body));
			await dispatch(getDashboardUserSettings(auth?.profileData.id, dashboardId));
		} else {
			body = {
				user: auth?.profileData?.id,
				dashboard: dashboardId,
				prod_componet_pinned: 0,
				collab_component_pinned: 1,
			};
			await dispatch(createDashboardUserSettings(body));
			await dispatch(getDashboardUserSettings(auth?.profileData.id, dashboardId));
		}
	};

	useEffect(() => {
		if (refereshTaskList) {
			refreshList();
		}
	}, [refereshTaskList]);

	const [page, setPage] = useState(1);
	const [status, setStatus] = useState(sessionStorage.getItem("_status") != null ? sessionStorage.getItem("_status") : taskStatusAr[2]);
	const [taskList, setTaskList] = useState([]);
	const [perPage, setPerPage] = useState(10);
	const [totalItem, setTotalItem] = useState(0);
	const [pageCount, setPageCount] = useState(0);
	const [isTaskLoaded, setIsTaskLoaded] = useState(false);
	const [isMultiChecked, setIsMultiChecked] = useState(false);
	const [checkedTaskListID, setCheckedTaskListID] = useState([]);
	const [checkedTaskList, setCheckedTaskList] = useState([]);

	const [dateFilter, setDateFilter] = useState([
		{
			startDate: startOfWeek(new Date()),
			endDate: endOfWeek(new Date()),
			key: "selection",
		},
	]);
	const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
	const dropDownRef = useComponentVisible(false);

	const userId = localStorage?.getItem("userId");
	const apiTaskList = `${productivityApi}/get-tasks`;
	const apiDeleteTask = `${productivityApi}/delete-task`;
	const apiTask = `${productivityApi}/task`;
	useEffect(() => {
		console.log("ProductivityCard Loaded");
		fetchData(page, dateFilter, status);
	}, []);
	const fetchData = (pageNum, dateFilter, curstatus) => {
		console.log(dateFilter);
		axios
			.get(
				apiTaskList +
					"/?type=gettask&user_id=" +
					userId +
					"&status=" +
					curstatus +
					"&page=" +
					pageNum +
					"&limit=" +
					perPage +
					"&start=" +
					dateFilter[0].startDate.getTime() +
					"&end=" +
					dateFilter[0].endDate.getTime() +
					"&dashboard=" +
					dashboardId,
				config
			)
			.then((resp) => {
				if (resp.status == 200) {
					console.log(resp.data);
					setTaskList(resp.data.data);
					setTotalItem(resp.data.count);
					var numPages = Math.ceil(resp.data.count / perPage);
					setPageCount(numPages);
				} else {
					setTaskList([]);
				}

				setIsTaskLoaded(true);
			})
			.catch((error) => {
				setIsTaskLoaded(true);
				setTaskList([]);
			});
	};

	const openTasks = (item) => {
		TaskService.openTask(item);
	};
	const handlePageClick = (event) => {
		setIsTaskLoaded(false);
		setPage(event.selected + 1);
		fetchData(event.selected + 1, dateFilter, status);
	};

	const filterChanged = (event) => {
		setIsTaskLoaded(false);
		setDateFilter([event.selection]);
		setIsComponentVisible(false);
		setPage(1);
		fetchData(1, [event.selection], status);
	};

	const statusChanged = (status) => {
		sessionStorage.setItem("_status", status);
		setStatus(status);
		setIsTaskLoaded(false);
		setIsComponentVisible(false);
		dropDownRef.setIsComponentVisible(false);
		setPage(1);
		fetchData(1, dateFilter, status);
		resetChecked();
	};

	const refreshList = () => {
		setIsTaskLoaded(false);
		fetchData(page, dateFilter, status);
	};

	const resetChecked = () => {
		setIsMultiChecked(false);
		setCheckedTaskList([]);
		setCheckedTaskListID([]);
		forceUpdate();
	};

	const addTask = async () => {
		const { value: text } = await Swal.fire({
			input: "textarea",
			inputLabel: "Add Task",
			inputPlaceholder: "Type your description here...",
			inputAttributes: {
				"aria-label": "Type your description here",
				maxlength: 500,
				autocapitalize: "off",
				autocorrect: "off",
			},
			confirmButtonText: "Add",
			confirmButtonColor: "#DC004E",
			showCancelButton: true,
			cancelButtonText: "Cancel",
			reverseButtons: true,
			focusConfirm: true,
			focusCancel: false,
			scrollbarPadding: false,
		});

		resetChecked();

		if (text) {
			Swal.showLoading();
			var postData = { dashboard: dashboardId, user_id: userId, description: text, status: status };
			axios
				.post(apiTask + "/?type=add_task", postData, config)
				.then((resp) => {
					Swal.hideLoading();
					if (resp.status == 200) {
						statusChanged(status);
						Swal.fire({
							title: "Success!",
							text: "Task successfully added!",
							icon: "success",
						}).then(function () {});
					} else {
						Swal.fire({
							icon: "error",
							title: "Error",
							text: "Network Error",
						});
					}
				})
				.catch((error) => {
					Swal.hideLoading();
					Swal.fire({
						icon: "error",
						title: "Error",
						text: "Network Error",
					});
				});
		}
	};

	const deleteTask = (task) => {
		resetChecked();
		Swal.fire({
			title: "Delete?",
			icon: "question",
			confirmButtonText: "Delete",
			confirmButtonColor: "#DC004E",
			showCancelButton: true,
			cancelButtonText: "Cancel",
			reverseButtons: true,
			focusConfirm: true,
			focusCancel: false,
			scrollbarPadding: false,
			showLoaderOnConfirm: true,
			preConfirm: () => {
				return axios
					.delete(apiTask + "/?type=delete_task&user_id=" + userId + "&id=" + task.id, config)
					.then((resp) => {
						if (resp.status == 200) {
							refreshList();
							Swal.fire({
								title: "Success!",
								text: "Task deleted successfully!",
								icon: "success",
							}).then(function () {});
						} else {
							Swal.fire({
								icon: "error",
								title: "Error",
								text: "Network Error",
							});
						}
					})
					.catch((error) => {
						Swal.fire({
							icon: "error",
							title: "Error",
							text: "Network Error",
						});
					});
			},
		}).then(function (resp) {
			if (resp.isConfirmed) {
			} else {
			}
		});
	};
	const changeTaskStatus = async (task) => {
		resetChecked();
		const inputOptions = new Promise((resolve) => {
			setTimeout(() => {
				var obj = {};
				for (let index = 0; index < taskStatusAr.length; index++) {
					const sT = taskStatusAr[index];
					if (sT != task.status) {
						if (index == 0 && task.status == taskStatusAr[1]) {
							obj[sT] = sT;
						} else {
							obj[sT] = sT;
						}
					}
				}
				resolve(obj);
			});
		});
		const { value: newStatus } = await Swal.fire({
			title: "Select Status",
			input: "radio",
			inputOptions: inputOptions,
			confirmButtonText: "Change Status",
			confirmButtonColor: "#DC004E",
			showCancelButton: true,
			cancelButtonText: "Cancel",
			reverseButtons: true,
			focusConfirm: true,
			focusCancel: false,
			scrollbarPadding: false,
			inputValidator: (value) => {
				if (!value) {
					return "You need to choose something!";
				}
			},
		});
		if (newStatus) {
			Swal.showLoading();
			var postData = { id: task.id, user_id: userId, status: newStatus };
			axios
				.post(apiTask + "/?type=update_task_status", postData, config)
				.then((resp) => {
					Swal.hideLoading();
					if (resp.status == 200) {
						refreshList();
						Swal.fire({
							title: "Success!",
							text: "Task status changed successfully!",
							icon: "success",
						}).then(function () {});
					} else {
						Swal.fire({
							icon: "error",
							title: "Error",
							text: "Network Error",
						});
					}
				})
				.catch((error) => {
					Swal.hideLoading();
					Swal.fire({
						icon: "error",
						title: "Error",
						text: "Network Error",
					});
				});
		}
	};

	const formatRange = () => {
		return format(dateFilter[0].startDate, "MM-dd-yyyy") + " - " + format(dateFilter[0].endDate, "MM-dd-yyyy");
	};

	const dropItem = useRef();

	const dragStart = (e, position) => {
		handleDragDrop({ element: e, task: taskList[position], state: "start", checkedTaskList: checkedTaskList });
		console.log(e.target.innerHTML);
	};
	const dragEnd = (e, position) => {
		handleDragDrop({ element: e, task: taskList[position], state: "end" });
		resetChecked();
		console.log(e.target.innerHTML);
	};
	const [, updateState] = useState();
	const forceUpdate = useCallback(() => updateState({}), []);
	const handleCheckBox = (event, item) => {
		if (event.target.checked) {
			checkedTaskListID.push(item.id);
			setCheckedTaskListID(checkedTaskListID);

			checkedTaskList.push(item);
			setCheckedTaskList(checkedTaskList);
		} else {
			var index = checkedTaskListID.indexOf(item.id);
			if (index !== -1) {
				checkedTaskListID.splice(index, 1);
			}
			setCheckedTaskListID(checkedTaskListID);

			var index = checkedTaskList.findIndex((x) => x.id == item.id);
			if (index !== -1) {
				checkedTaskList.splice(index, 1);
			}
			setCheckedTaskList(checkedTaskList);
		}

		if (checkedTaskListID.length == 0) {
			setIsMultiChecked(false);
			setCheckedTaskList([]);
		}
		forceUpdate();
	};

	return (
		<div
			style={{
				backgroundColor: "var(--primary-color)",
				height: "calc(100vh - 25vh)",
				borderRadius: 10,
				padding: 15,
				overflow: "scroll",
			}}
		>
			<div
				style={{
					flexDirection: "row",
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
				}}
			>
				<div
					style={{
						flexDirection: "row",
						display: "flex",
						alignItems: "center",
					}}
				>
					<img
						src={productivityIcon}
						style={{
							width: 25,
							height: 25,
							objectFit: "contain",
						}}
						alt={"contact-icon"}
					/>

					<p style={{ color: "#FFF", margin: 0, padding: 0, marginLeft: 10 }}>Productivity</p>
				</div>
				<div>
					<img
						onClick={() => addTask()}
						src={add}
						style={{
							cursor: "pointer",
							width: 25,
							height: 25,
							objectFit: "contain",
							marginRight: 20,
						}}
						alt={"contact-icon"}
					/>
					<img
						src={closeIcon}
						onClick={() => changeSetting(false)}
						style={{
							width: 20,
							height: 20,
							objectFit: "contain",
							cursor: "pointer",
						}}
						alt={"contact-icon"}
					/>
				</div>
			</div>
			<div
				style={{
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					justifyContent: "space-between",
					marginTop: 20,
				}}
			>
				<div
					style={{
						flexDirection: "row",
						display: "flex",
						alignItems: "center",
					}}
				>
					<div
						style={{
							backgroundColor: "var(--bg-color)",
							borderRadius: 7,

							width: 40,
							height: 40,
							justifyContent: "center",
							alignItems: "center",
							display: "flex",
							margin: 0,
							padding: 0,
						}}
					>
						<img
							src={completedIcon}
							style={{
								width: 20,
								height: 20,
								objectFit: "contain",
							}}
							alt={"lead-icon"}
						/>
					</div>
					<div style={{ marginLeft: 10 }}>
						<p
							style={{
								color: "#FFF",
								padding: 0,
								margin: 0,
								fontSize: 12,
							}}
						>
							{status}
						</p>
						<p
							style={{
								color: "grey",
								padding: 0,
								margin: 0,
								fontSize: 12,
							}}
						>
							<div style={{ cursor: "pointer" }} onClick={() => setIsComponentVisible(!isComponentVisible)}>
								{formatRange()}
							</div>
							<div ref={ref} style={{ position: "absolute", zIndex: 9 }}>
								{isComponentVisible && <DefinedRange onChange={filterChanged} ranges={dateFilter} />}
							</div>
						</p>
					</div>
				</div>
				<div
					style={{
						cursor: "pointer",
					}}
				>
					<img
						onClick={() => {
							console.log(dropDownRef.isComponentVisible);
							dropDownRef.setIsComponentVisible(!dropDownRef.isComponentVisible);
						}}
						src={dropdownIcon}
						style={{ width: 20, height: 20, objectFit: "contain" }}
						alt={"add-icon"}
					/>
				</div>
			</div>
			{!isTaskLoaded && (
				<div style={{ display: "flex", height: "100%", alignItems: "center" }}>
					<div style={{ margin: "0 auto" }} className="spinner-border spinner-border text-white" role="status">
						<div ref={dropDownRef.ref}>
							{dropDownRef.isComponentVisible && (
								<div id="myDropdown" class="dropdown-content open">
									{taskStatusAr.map((singleStatus) => (
										<a
											onClick={() => statusChanged(singleStatus)}
											style={{ width: "90%", cursor: "pointer" }}
											class="status"
											className={status == singleStatus ? "active" : ""}
										>
											{" "}
											{singleStatus}
										</a>
									))}
								</div>
							)}
						</div>
					</div>
				</div>
			)}
			<div ref={ref} style={{ position: "absolute", zIndex: 9 }}>
				{isComponentVisible && <DefinedRange onChange={filterChanged} ranges={dateFilter} />}
			</div>
			{isTaskLoaded && (
				<div style={{ marginTop: 20, height: "100%" }}>
					<div ref={dropDownRef.ref}>
						{dropDownRef.isComponentVisible && (
							<div id="myDropdown" class="dropdown-content open">
								{taskStatusAr.map((singleStatus) => (
									<a
										onClick={() => statusChanged(singleStatus)}
										style={{ width: "90%", cursor: "pointer" }}
										class="status"
										className={status == singleStatus ? "active" : ""}
									>
										{" "}
										{singleStatus}
									</a>
								))}
							</div>
						)}
					</div>
					<div class="task-div">
						{taskList.map((item, i) => {
							return (
								<div
									onDragStart={(e) => dragStart(e, i)}
									onDragEnd={(e) => dragEnd(e, i)}
									draggable
									key={i}
									style={{
										display: "flex",
										flexDirection: "row",
										alignItems: "center",
										marginBottom: 10,
									}}
								>
									<div style={{ width: "10%" }}>
										{!isMultiChecked && (
											<img
												onClick={() => {
													setIsMultiChecked(true);
													checkedTaskListID.push(item.id);
													setCheckedTaskListID(checkedTaskListID);

													checkedTaskList.push(item);
													setCheckedTaskList(checkedTaskList);
												}}
												src={rightArrowIcon}
												style={{
													width: 12,
													height: 12,
													objectFit: "contain",
												}}
												alt={"rightArrow-icon"}
											/>
										)}
										{isMultiChecked && (
											<input
												checked={checkedTaskListID.indexOf(item.id) > -1}
												onChange={(event) => {
													handleCheckBox(event, item);
												}}
												style={{ width: "10px", height: "10px" }}
												type="checkbox"
											></input>
										)}
									</div>
									<div onClick={() => openTasks(item)} style={{ width: "90%", cursor: "pointer" }}>
										<p
											className={"status_" + item.status.toLowerCase()}
											style={{
												color: "#FFF",
												padding: 0,
												margin: 0,
												fontSize: 12,
											}}
										>
											{item.priority != undefined && item.priority != "" && (
												<span
													title={item.priority + " task"}
													className={item.priority == "fire" ? item.priority + "_task " + "fas fa-fire-alt" : item.priority + "_task task_priority"}
												></span>
											)}{" "}
											{item.description}
										</p>
										<p
											style={{
												color: "grey",
												padding: 0,
												margin: 0,
												fontSize: 10,
											}}
										>
											{item.created_datetime != "" && format(parseISO(item.created_datetime), "dd-LL-yyyy,HH:mm a")}
										</p>
									</div>
									{item.status != taskStatusAr[2] && (
										<img
											title="Change Status of task"
											onClick={() => changeTaskStatus(item)}
											src={edit}
											style={{
												cursor: "pointer",
												width: 10,
												height: 10,
												objectFit: "contain",
												marginRight: 10,
											}}
											alt={"contact-icon"}
										/>
									)}
									<img
										title="Delete task"
										src={closeIcon}
										onClick={() => deleteTask(item)}
										style={{
											width: 10,
											height: 10,
											objectFit: "contain",
											cursor: "pointer",
										}}
										alt={"delete-icon"}
									/>
								</div>
							);
						})}
						{taskList.length == 0 && (
							<div
								style={{
									color: "#FFF",
									fontSize: 15,
									textAlign: "center",
								}}
							>
								No Tasks found for current criteria.
							</div>
						)}

						{pageCount > 1 && (
							<ReactPaginate
								breakLabel="..."
								nextLabel=" >"
								onPageChange={handlePageClick}
								pageRangeDisplayed={1}
								marginPagesDisplayed={2}
								pageCount={pageCount}
								forcePage={page - 1}
								previousLabel="< "
								renderOnZeroPageCount={null}
								pageClassName="page-item"
								pageLinkClassName="page-link"
								previousClassName="page-item"
								previousLinkClassName="page-link"
								nextClassName="page-item"
								nextLinkClassName="page-link"
								breakClassName="page-item"
								breakLinkClassName="page-link"
								containerClassName="pagination"
								activeClassName="active"
							/>
						)}
					</div>
				</div>
			)}
		</div>
	);
};

export default ProductivityCard;
