import { ActionTypes } from "../constants";

const initial_state = {
  insertSFData: {},
  isInsertSFLoading: false,
  isInsertSFError: false,
  insertSFErrorMessage: "",

  getSFData: {},
  isGetSFLoading: false,
  isGetSFError: false,
  getSFErrorMessage: "",
};
export const insertSFDataReducer = (state = initial_state, action) => {
  switch (action.type) {
    case ActionTypes.INSERT_SF_REQ:
      return {
        ...state,
        isInsertSFLoading: true,
        isInsertSFError: false,
      };
    case ActionTypes.INSERT_SF_SUCCESS:
      return {
        ...state,
        isInsertSFLoading: false,
        insertSFData: action.payload,
      };
    case ActionTypes.INSERT_SF_FAIL:
      return {
        ...state,
        isInsertSFError: true,
        isInsertSFLoading: false,
        insertSFErrorMessage: action.payload,
      };
    case ActionTypes.CELAR_ERROR_MESSAGE:
      return {
        ...state,
        isInsertSFError: false,
      };
    default:
      return state;
  }
};

export const getSFDataReducer = (state = initial_state, action) => {
  switch (action.type) {
    case ActionTypes.GET_SF_REQ:
      return {
        ...state,
        isGetSFLoading: true,
        isGetSFError: false,
      };
    case ActionTypes.GET_SF_SUCCESS:
      return {
        ...state,
        isGetSFLoading: false,
        getSFData: action.payload,
      };
    case ActionTypes.GET_SF_FAIL:
      return {
        ...state,
        isGetSFError: true,
        isGetSFLoading: false,
        getSFErrorMessage: action.payload,
      };
    case ActionTypes.CELAR_ERROR_MESSAGE:
      return {
        ...state,
        isGetSFError: false,
      };
    default:
      return state;
  }
};
