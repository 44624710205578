import React, { useEffect, useState } from "react";

import { Button } from "react-bootstrap";
import Navbar from "./Navbar";
import { BsEmojiSmile } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { signupAction } from "../redux/actions/AuthAction";
import { clearErrorMessageAction } from "../redux/actions/DashboardAction";
import bcrypt from "bcryptjs";
function Signup() {
	const navigate = useNavigate();
	const [data, setData] = useState({});
	const [check, setCheck] = useState(false);
	const dispatch = useDispatch();
	const auth = useSelector((state) => state.Auth);

	const changeHandler = (e) => {
		setData((b) => {
			return { ...b, [e.target.name]: e.target.value };
		});
	};
	const fillOutFields = (field_name) => {
		Swal.fire({
			icon: "error",
			title: "Error",
			text: field_name === "Confirm Password" ? "Please make sure your passwords must match" : ` Please Fill out ${field_name}`,
		});
	};
	const fillOutField = (field_name) => {
		Swal.fire({
			icon: "error",
			title: "Error",
			text: field_name,
		});
	};
	const FormSubmit = async (e) => {
		e.preventDefault();
		var allData = Object.keys(data);

		console.log("all", data);
		if (!allData.includes("first_name") || !data["first_name"]?.trim().length) {
			fillOutFields("First Name");
		} else if (!allData.includes("last_name") || !data["last_name"]?.trim().length) {
			fillOutFields("Last Name");
		} else if (!allData.includes("email") || !data["email"]?.trim().length) {
			fillOutFields("Email");
			// } else if (
			//   !allData.includes("username") ||
			//   !data["username"]?.trim().length
			// ) {
			//   fillOutFields("User Name");
		} /* else if (
      !allData.includes("password") ||
      !data["password"]?.trim().length
    ) {
      fillOutFields("Password");
    // } else if (
    //   !allData.includes("confirm_password") ||
    //   !data["confirm_password"]?.trim().length
    // ) {
    //   fillOutFields("confirm password");
    // } else if (data["confirm_password"]?.trim() !== data["password"]?.trim()) {
    //   fillOutFields("Confirm Password");
    }*/ else if (!allData.includes("company_size") || !data["company_size"]?.trim().length) {
			fillOutFields("Company");
		} else if (!allData.includes("industry") || !data["industry"]?.trim().length) {
			fillOutFields("Industry");
		} else if (!allData.includes("company_name") || !data["company_name"]?.trim().length) {
			fillOutFields("Company Name");
			// } else if (
			//   !allData.includes("position") ||
			//   !data["position"]?.trim().length
			// ) {
			//   fillOutFields("Title");
		} else if (!check) {
			fillOutField("Please check this box to receive important updates and to be notified when our beta is ready for use");
		} else {
			const receive_updates = check ? 1 : 0;
			const is_admin = 0;
			// const position = "Sales Manager";
			const temp_data = { ...data };
			// let last_login_datetime = new Date().toJSON().slice(0, 10);
			let last_login_datetime = new Date().toISOString();

			delete temp_data.confirm_password;
			//temp_data.password = bcrypt.hashSync(temp_data.password, 10);   // hash password
			temp_data.password = "THIS VALUE NEEDS TO BE SET MANUALLY BY C2C ADMINS";

			const body = { ...temp_data, receive_updates, is_admin, last_login_datetime };
			console.log("body", body);
			const response = await dispatch(signupAction(body));
			console.log("QESWSS", response);
			if (response?.status === 200) {
				//navigate("/profile");
				navigate("/Pricing");
				Swal.fire({
					icon: "success",
					title: "Success",
					text: "Your data has been saved successfully!",
				});
			}
		}
	};
	useEffect(() => {
		if (auth?.isSignupError) {
			Swal.fire({
				icon: "error",
				title: "Error",
				text: "Oops, something went wrong.",
			});
		}
		if (auth?.isSignupError && auth?.signupErrorMessage?.code === "ER_DUP_ENTRY") {
			Swal.fire({
				icon: "error",
				title: "Error",
				text: "Email already registered",
			});
		}

		setTimeout(() => {
			dispatch(clearErrorMessageAction());
		}, 2000);
	}, [auth?.isSignupError, auth?.signupErrorMessage, dispatch]);

	return (
		<>
			<div className="main_hero_div" id="main">
				<Navbar />

				<div className="container" id="sign_up_container">
					<div className="d-flex justify-content-center career_main_div  ">
						<div className="border_div_career my-5"></div>
					</div>

					<h4 className="display-5 text-white text-center mb-4" style={{ fontWeight: "300" }}>
						Beta Early Access
					</h4>
					<p className="text-white mt-5  display-3 guide_lines"> Sign Up for the Connect2Co beta to receive exclusive early access to test and use the platform</p>
					<p className="text-white  display-3 guide_lines">
						{" "}
						We'll send you an email once we're live & we look forward to building with you soon.... <BsEmojiSmile className="icons" />
					</p>
					{/* <div className='d-flex justify-content-center'> <Link to="/signup"><Button className="ms-3 sign_up">Sign Up</Button></Link></div> */}
					<form className="g-3" onSubmit={FormSubmit}>
						<div className="d-flex gap-5 flex-wrap justify-content-center form-grid">
							<div className="input_label">
								<label className="label">First Name</label>
								<input type="text" name="first_name" value={data?.first_name} onChange={changeHandler} />
							</div>
							<div className="input_label">
								<label className="label ">Last Name</label>
								<input type="text" name="last_name" value={data?.last_name} onChange={changeHandler} />
							</div>
						</div>

						<div className="d-flex gap-5 flex-wrap justify-content-center mt-3">
							<div className="input_label">
								<label className="label">E-mail</label>
								<br></br>
								<input type="text" name="email" value={data?.email} onChange={changeHandler} />
							</div>
							<div className="input_label">
								<label className="label">Company Name</label>
								<br></br>
								<input type="text" name="company_name" value={data?.company} onChange={changeHandler} />
							</div>
							{/* <div className="input_label">
                <label className="label">User Name</label>
                <br></br>
                <input
                  type="text"
                  name="username"
                  value={data?.username}
                  onChange={changeHandler}
                />
              </div> */}
						</div>
						<div className="d-flex gap-5 flex-wrap justify-content-center mt-3">
							{/*<div className="input_label">
                <label className="label">Password</label>
                <br></br>
                <input
                  type="password"
                  name="password"
                  value={data?.password}
                  onChange={changeHandler}
                  autocomplete="new-password"
                />
              </div> */}
							{/* <div className="input_label">
                <label className="label">Confirm Password</label>
                <br></br>
                <input
                  type="password"
                  name="confirm_password"
                  value={data?.confirm_password}
                  onChange={changeHandler}
                  autocomplete="new-password"
                />
              </div> */}
						</div>

						<div className="d-flex gap-5 flex-wrap justify-content-center mt-3">
							<div className="input_label">
								<label className="label">Company</label>
								<br></br>
								<select value={data?.company_size} onChange={changeHandler} name="company_size">
									<option className="option_border " selected hidden disabled>
										Size
									</option>
									<option className="option_border pt-2">0-10</option>
									<option className="backgound_option">10-50</option>
									<option className="option_border" style={{ borderBottom: "1px solid white" }}>
										50-250
									</option>
									<option className="backgound_option">250-1000</option>
								</select>
							</div>
							<div className="input_label">
								<label className="label">Industry</label>
								<br></br>
								<input type="text" name="industry" value={data?.industry} onChange={changeHandler} />
							</div>
						</div>
						{/* <div className="d-flex gap-5 flex-wrap justify-content-center mt-3">
              <div className="input_label">
                <label className="label">Company Name</label>
                <br></br>
                <input
                  type="text"
                  name="company_name"
                  value={data?.company}
                  onChange={changeHandler}
                />
              </div>
              <div className="input_label">
                <label className="label">Title</label>
                <br></br>
                <input
                  type="text"
                  name="position"
                  value={data?.position}
                  onChange={changeHandler}
                />
              </div>
            </div> */}
						{/* <div className='d-flex gap-4 flex-wrap justify-content-center mt-5'>
            <div className='input_label'>
            <div className="form-check">
                 
                    
                  </div>
            </div>
            <div className='input_label'>
            <div className="form-check ">
                   
                  </div>
            </div>
          </div> */}
						<div className="d-flex gap-4 flex-wrap justify-content-center mt-3">
							<div className="input_label col-12 col-md-auto"></div>
							<div className="input_label col-12 col-md-auto">
								<div className="d-flex gap-5 justify-content-space-between">
									<label
										className="form-check-label ms-2  mt-2 "
										style={{
											fontSize: "13px",
											color: "#238FDB",
											fontWeight: "700",
										}}
									>
										Receive Connect2Co email updates
									</label>
									<div class="customCheckbox">
										<div class="input-container">
											<input type="checkbox" name="beta_updates" checked={check} onChange={() => setCheck(!check)} />
											<label for="customCheckboxInput"></label>
										</div>
									</div>
								</div>

								<div className="d-flex justify-content-center">
									<Button disabled={auth.isSignupLoading} type="submit" className="mt-3 ms-3 sign_up">
										{auth.isSignupLoading ? <div class="spinner-border spinner-border-sm text-primary" role="status" /> : "SUBMIT"}
									</Button>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</>
	);
}

export default Signup;
