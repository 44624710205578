import Button from 'react-bootstrap/Button';
import { Link} from "react-router-dom";
import logo from "./Images/logo.png";
import Dropdown from 'react-bootstrap/Dropdown';
import { useEffect, useState } from 'react';
import { Navbar } from 'react-bootstrap';




function CollapsibleExample() {
  
  
 
  const leftSideBar = <div className='side_bar'>
                        <div className='side_icons'>
                          <div className='div_icon'>  <a href=' https://www.linkedin.com/company/connect2co/'><i className="fa-brands fa-linkedin-in icon-color"></i></a> </div>
                          {/* <div className='div_icon'> <i className="fa-brands fa-tiktok icon-color">
                          </i></div> */}
                          <div className='div_icon'><a href=' https://www.facebook.com/connect2co-110065278274879/?ref=page_internal'><i class="fa-brands fa-facebook-f icon-color"></i></a> </div>
                          <div className='div_icon'><a href=' https://www.instagram.com/connect2co/'><i class="fa-brands fa-instagram icon-color"></i></a> </div>
                        </div>
                      </div>
    const [leftSide, setLeftSide] = useState(leftSideBar)
  useEffect(() => {
    console.log(window.location.href)
    if(window.location.href.includes('signup') || window.location.href.includes('guide') || window.location.href.includes('service')){
      setLeftSide()
    }
  })
  
 

  return (
    <>
    <div>
      <Navbar/>
    </div>
    <div className='container-fluid d-flex justify-content-center' style={{paddingTop:"30px"}} >
      <nav className="navbar navbar-expand-lg  w-100">
        <div className="container-fluid" >
          <Link className="navbar-brand" to="/">
            <img src={logo} alt="" style={{ width: "200px" }} />
          </Link>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav m-auto mb-2 mb-lg-0 gap-3">

              <li className="nav-item">
              <li className="nav-item">
                        <Link className="nav-link ms-1 text-white " to="/about" >About Us</Link>
                      </li> 
            
              </li>
              <li className="nav-item">
                <Link className="nav-link text-white ms-3" to="/service"  >
                  Service & Support</Link>
              </li>
              <li className="nav-item">
                  <Link className="nav-link text-white ms-3" to="/Pricing">
                    Pricing
                  </Link>
                </li>
              <li className="nav-item">
                <Link className="nav-link text-white ms-3" to="/career">Careers</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link text-white ms-3" to="/guide">Guide</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link text-white ms-3" to="/coreValue">Values</Link>
              </li>
            </ul>
            <div className='btn_main'>
              <Link to="/login"><Button className="log_in">Log In</Button></Link>
              <Link to="/signup"><Button className="ms-3 sign_up">Sign Up</Button></Link>
            </div>
            <div className='side_bar_mobile_view'>
              <div className='div_icons ms-4'>  <a href=' https://www.linkedin.com/company/connect2co/'><i className="fa-brands fa-linkedin-in icon-color"></i></a></div>
              {/* <div className='mt-4 ms-4'><div className='div_icons' > <i className="fa-brands fa-tiktok icon-color"></i></div></div> */}
              <div className='mt-4 ms-4'><div className='div_icons' ><a href=' https://www.facebook.com/connect2co-110065278274879/?ref=page_internal'><i class="fa-brands fa-facebook-f icon-color"></i></a></div></div>
              <div className='mt-4 ms-4'><div className='div_icons' ><a href=' https://www.instagram.com/connect2co/'><i class="fa-brands fa-instagram icon-color"></i></a></div></div>
            </div>
          </div>
        </div>
      </nav>
      {leftSide}
    </div>
    </>);
}

export default CollapsibleExample;
