import React from 'react';

import Body from './Body';



function Home() {
  return (<>


      <div>
      
        <Body />
        
        </div>
    
  </>

  )
}

export default Home
