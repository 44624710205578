import React, { useEffect, useMemo, useState } from "react";
import contactIcon from "../../assets/images/contact-icon.png";
import leadsIcon from "../../assets/images/leads-icon.png";
import opportunitiesIcon from "../../assets/images/opportunities-icon.png";
import accountIcon from "../../assets/images/account-icon.png";
import addIcon from "../../assets/images/add.png";
import axios from "axios";
import LeadsTable from "../LeadsTable";
import AccountsTable from "../AccountsTable";
import ContactsTable from "../ContactsTable";
import OpportunitiesTable from "../OpportunitiesTable";
import EditLead from "../EditLead";
import EditAccount from "../EditAccount";
import EditContact from "../EditContact";
import EditOpportunity from "../EditOpportunity";
import { useDispatch, useSelector } from "react-redux";
import { SalesforceLogin } from "../../utils/salesforceApiService";
import { formatQuery } from "../../utils/helpers";
import { getSFDataAction } from "../../redux/actions/SalesforceAction";
import { AiOutlineArrowLeft } from "react-icons/ai";
import IconMapExpand from "../../assets/images/Icon-map-expand.png";
import closeIcon from "../../assets/images/edit-cancel.png";

axios.defaults.headers.post["Content-Type"] = "application/json";

const SalesforceCard = ({ pinnedCollab, pinnedProd, expanded, dashboardId, isPinnedSalesForce }) => {
	const [leads, setLeads] = useState({});
	const [accounts, setAccounts] = useState({});
	const [contacts, setContacts] = useState({});
	const [opportunities, setOpportunities] = useState({});
	const [isLoading, setIsLoading] = useState(true);
	const [showTable, setShowTable] = useState({
		leads: false,
		accounts: false,
		contacts: false,
		opportunities: false,
	});
	const [item, setItem] = useState({
		id: "",
		type: "",
		isCreateMode: false,
	});
	const dispatch = useDispatch();
	const user = localStorage?.getItem("userId");
	const SFData = useSelector((state) => state.getSFData);
	const baseUrl = useMemo(() => SFData?.getSFData?.data?.[0]?.url, [SFData?.getSFData?.data]);

	const getLeads = async (baseUrl) => {
		const salesforceLoginAccessToken = localStorage?.getItem("salesforceAccessToken");
		axios.defaults.headers.common["Authorization"] = `Bearer ${salesforceLoginAccessToken}`;

		try {
			const response = await axios.get(
				`${baseUrl}/services/data/v57.0/query?q=${formatQuery("Select id,name,title,email,company,status from Lead where status != 'Qualified'")}`
			);

			if (response.data) {
				setLeads(response.data);
			}
		} catch (error) {
			console.error(error);
		}
	};

	const getAccounts = async (baseUrl) => {
		const salesforceLoginAccessToken = localStorage?.getItem("salesforceAccessToken");
		axios.defaults.headers.common["Authorization"] = `Bearer ${salesforceLoginAccessToken}`;
		try {
			const response = await axios.get(`${baseUrl}/services/data/v57.0/query?q=${formatQuery("Select Fields(All) from Account limit 200")}`);

			if (response.data) {
				setAccounts(response.data);
			}
		} catch (error) {
			console.error(error);
		}
	};

	const getContacts = async (baseUrl) => {
		const salesforceLoginAccessToken = localStorage?.getItem("salesforceAccessToken");
		axios.defaults.headers.common["Authorization"] = `Bearer ${salesforceLoginAccessToken}`;
		try {
			const response = await axios.get(`${baseUrl}/services/data/v57.0/query?q=${formatQuery("Select Fields(All) from Contact limit 200")}`);

			if (response.data) {
				setContacts(response.data);
			}
		} catch (error) {
			console.error(error);
		}
	};

	const getOpportunities = async (baseUrl) => {
		const salesforceLoginAccessToken = localStorage?.getItem("salesforceAccessToken");
		axios.defaults.headers.common["Authorization"] = `Bearer ${salesforceLoginAccessToken}`;
		try {
			const response = await axios.get(`${baseUrl}/services/data/v57.0/query?q=${formatQuery("Select Fields(All) from Opportunity limit 200")}`);

			if (response.data) {
				setOpportunities(response.data);
			}
		} catch (error) {
			console.error(error);
		}
	};

	useEffect(() => {
		dispatch(getSFDataAction(user));
	}, []);

	useEffect(() => {
		setIsLoading(true);
		SalesforceLogin({
			loginUrl: baseUrl,
			username: SFData?.getSFData?.data?.[0]?.username,
			password: SFData?.getSFData?.data?.[0]?.password,
			accessToken: SFData?.getSFData?.data?.[0]?.accessToken,
		}).finally(() => {
			Promise.all([getLeads(baseUrl), getAccounts(baseUrl), getContacts(baseUrl), getOpportunities(baseUrl)]).finally(() => setIsLoading(false));
		});
	}, [SFData?.getSFData?.data, baseUrl]);

	// if (isLoading) {
	//   return (
	//     <div className="h-50 w-100 d-flex justify-content-center align-items-center">
	//       <div
	//         className="spinner-border spinner-border-md text-primary"
	//         role="status"
	//       />
	//     </div>
	//   );
	// }

	return (
		<div
			style={{
				backgroundColor: "var(--primary-color)",
				height: pinnedCollab ? "calc(100vh - 50vh)" : "calc(100vh - 25vh)",
				borderRadius: 10,
				padding: 25,
				overflow: "scroll",
			}}
		>
			<div
				style={{
					display: "flex",
					gap: "10px",
					alignItems: "center",
					justifyContent: "space-between",
				}}
			>
				<div
					style={{
						display: "flex",
						alignItems: "center",
						gap: "10px",
					}}
				>
					{item.id !== "" ? (
						<AiOutlineArrowLeft
							style={{
								color: "white",
								height: "20px",
								width: "20px",
								marginBottom: "16px",
								cursor: "pointer",
							}}
							onClick={() =>
								setItem({
									id: "",
								})
							}
						/>
					) : null}
					<p style={{ fontSize: 18, color: "#FFF" }}>Salesforce</p>
				</div>
				<div className="w-60 d-flex" style={{ paddingRight: 8 }}>
					<img
						style={{ width: 20, height: 20, objectFit: "contain", cursor: "pointer" }}
						alt={"add-icon"}
						src={IconMapExpand}
						onClick={() => expanded(true)}
						hidden={pinnedCollab || pinnedProd ? false : true}
					/>
					<img
						style={{ width: 20, height: 20, objectFit: "contain", cursor: "pointer", marginLeft: "20px" }}
						alt={"remove-icon"}
						src={closeIcon}
						onClick={() => isPinnedSalesForce(false)}
					/>
				</div>
			</div>

			{isLoading ? (
				<div className="h-50 w-100 d-flex justify-content-center align-items-center">
					<div className="spinner-border spinner-border-md text-primary" role="status" />
				</div>
			) : (
				<>
					{item.id === "" ? (
						<div
							style={{
								flexDirection: "column",
								justifyContent: "space-between",
								display: "flex",
								gap: "15px",

								// height: "75%",
							}}
						>
							<div
								style={{
									display: "flex",
									flexDirection: "row",
									alignItems: "center",
									justifyContent: "space-between",
								}}
							>
								<div
									style={{
										flexDirection: "row",
										display: "flex",
										alignItems: "center",
										cursor: "pointer",
									}}
									onClick={() => {
										setShowTable((prev) => ({
											leads: !prev.leads,
											accounts: false,
											contacts: false,
											opportunities: false,
										}));
									}}
								>
									<div
										style={{
											backgroundColor: "var(--bg-color)",
											borderRadius: 7,
											padding: 10,
										}}
									>
										<img
											src={leadsIcon}
											style={{
												width: 20,
												height: 20,
												objectFit: "contain",
											}}
											alt={"lead-icon"}
										/>
									</div>
									<div style={{ marginLeft: 10 }}>
										<p
											style={{
												color: "#FFF",
												padding: 0,
												margin: 0,
												fontSize: 12,
											}}
										>
											{leads?.totalSize}
										</p>
										<p
											style={{
												color: "grey",
												padding: 0,
												margin: 0,
												fontSize: 12,
											}}
										>
											Leads
										</p>
									</div>
								</div>
								<div
									style={{
										cursor: "pointer",
										padding: 10,
									}}
								>
									<img
										src={addIcon}
										style={{ width: 20, height: 20, objectFit: "contain" }}
										alt={"add-icon"}
										onClick={() =>
											setItem({
												type: "lead",
												isCreateMode: true,
											})
										}
									/>
								</div>
							</div>

							{showTable.leads ? <LeadsTable leads={leads} setItem={setItem} pinnedProd={pinnedProd} /> : null}

							<div
								style={{
									display: "flex",
									flexDirection: "row",
									alignItems: "center",
									justifyContent: "space-between",
								}}
							>
								<div
									style={{
										flexDirection: "row",
										display: "flex",
										alignItems: "center",
										cursor: "pointer",
									}}
									onClick={() => {
										setShowTable((prev) => ({
											leads: false,
											accounts: !prev.accounts,
											contacts: false,
											opportunities: false,
										}));
									}}
								>
									<div
										style={{
											backgroundColor: "var(--bg-color)",
											borderRadius: 7,
											padding: 10,
										}}
									>
										<img
											src={accountIcon}
											style={{
												width: 20,
												height: 20,
												objectFit: "contain",
											}}
											alt={"account-icon"}
										/>
									</div>
									<div style={{ marginLeft: 10 }}>
										<p
											style={{
												color: "#FFF",
												padding: 0,
												margin: 0,
												fontSize: 12,
											}}
										>
											{accounts?.totalSize}
										</p>
										<p
											style={{
												color: "grey",
												padding: 0,
												margin: 0,
												fontSize: 12,
											}}
										>
											Accounts
										</p>
									</div>
								</div>
								<div
									style={{
										cursor: "pointer",
										padding: 10,
									}}
								>
									<img
										src={addIcon}
										style={{ width: 20, height: 20, objectFit: "contain" }}
										alt={"add-icon"}
										onClick={() =>
											setItem({
												type: "account",
												isCreateMode: true,
											})
										}
									/>
								</div>
							</div>

							{showTable.accounts ? <AccountsTable accounts={accounts} setItem={setItem} pinnedProd={pinnedProd} /> : null}

							<div
								style={{
									display: "flex",
									flexDirection: "row",
									alignItems: "center",
									justifyContent: "space-between",
								}}
							>
								<div
									style={{
										flexDirection: "row",
										display: "flex",
										alignItems: "center",
										cursor: "pointer",
									}}
									onClick={() => {
										setShowTable((prev) => ({
											leads: false,
											accounts: false,
											contacts: !prev.contacts,
											opportunities: false,
										}));
									}}
								>
									<div
										style={{
											backgroundColor: "var(--bg-color)",
											borderRadius: 7,
											padding: 10,
										}}
									>
										<img
											src={contactIcon}
											style={{
												width: 20,
												height: 20,
												objectFit: "contain",
											}}
											alt={"contact-icon"}
										/>
									</div>
									<div style={{ marginLeft: 10 }}>
										<p
											style={{
												color: "#FFF",
												padding: 0,
												margin: 0,
												fontSize: 12,
											}}
										>
											{contacts?.totalSize}
										</p>
										<p
											style={{
												color: "grey",
												padding: 0,
												margin: 0,
												fontSize: 12,
											}}
										>
											Contacts
										</p>
									</div>
								</div>
								<div
									style={{
										cursor: "pointer",
										padding: 10,
									}}
								>
									<img
										src={addIcon}
										style={{ width: 20, height: 20, objectFit: "contain" }}
										alt={"add-icon"}
										onClick={() =>
											setItem({
												type: "contact",
												isCreateMode: true,
											})
										}
									/>
								</div>
							</div>

							{showTable.contacts ? <ContactsTable contacts={contacts} setItem={setItem} pinnedProd={pinnedProd} /> : null}

							<div
								style={{
									display: "flex",
									flexDirection: "row",
									alignItems: "center",
									justifyContent: "space-between",
								}}
							>
								<div
									style={{
										flexDirection: "row",
										display: "flex",
										alignItems: "center",
										cursor: "pointer",
									}}
									onClick={() => {
										setShowTable((prev) => ({
											leads: false,
											accounts: false,
											contacts: false,
											opportunities: !prev.opportunities,
										}));
									}}
								>
									<div
										style={{
											backgroundColor: "var(--bg-color)",
											borderRadius: 7,
											padding: 10,
										}}
									>
										<img
											src={opportunitiesIcon}
											style={{
												width: 20,
												height: 20,
												objectFit: "contain",
											}}
											alt={"opportunities-icon"}
										/>
									</div>
									<div style={{ marginLeft: 10 }}>
										<p
											style={{
												color: "#FFF",
												padding: 0,
												margin: 0,
												fontSize: 12,
											}}
										>
											{opportunities?.totalSize}
										</p>
										<p
											style={{
												color: "grey",
												padding: 0,
												margin: 0,
												fontSize: 12,
											}}
										>
											Opportunities
										</p>
									</div>
								</div>
								<div
									style={{
										cursor: "pointer",
										padding: 10,
									}}
								>
									<img
										src={addIcon}
										style={{ width: 20, height: 20, objectFit: "contain" }}
										alt={"add-icon"}
										onClick={() =>
											setItem({
												type: "opportunity",
												isCreateMode: true,
											})
										}
									/>
								</div>
							</div>

							{showTable.opportunities ? <OpportunitiesTable opportunities={opportunities} setItem={setItem} pinnedProd={pinnedProd} /> : null}
						</div>
					) : item.type === "lead" ? (
						<EditLead leadId={item.id} dashboardId={dashboardId} isCreateMode={item.isCreateMode} />
					) : item.type === "account" ? (
						<EditAccount accountId={item.id} dashboardId={dashboardId} isCreateMode={item.isCreateMode} />
					) : item.type === "contact" ? (
						<EditContact contactId={item.id} dashboardId={dashboardId} isCreateMode={item.isCreateMode} />
					) : item.type === "opportunity" ? (
						<EditOpportunity opportunityId={item.id} isCreateMode={item.isCreateMode} />
					) : null}
				</>
			)}
		</div>
	);
};

export default SalesforceCard;
