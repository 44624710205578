import axios from "axios";
import { useSelector } from "react-redux";
import "./index.css";
import Header from "../../Components/Header";
import Sidebar from "../../Components/Sidebar";
import React, { useEffect, useState } from 'react';
import { SimpleToast } from "../../utils/Toast";
import { useNavigate } from "react-router-dom";



const UserPaymentHistory = () => {
  const [billingData, setBillingData] = useState([]);
  const [showMoreOptions, setShowMoreOptions] = useState(false);
  const [status, setStatus] = useState('');
  const [subscriptionId, setSubscriptionId] = useState('');

  const auth = useSelector((state) => state.Auth);
  const navigate = useNavigate();

  
  const userId = auth?.profileData?.id;



  const handleMoreOptionsClick = () => {
    setShowMoreOptions(!showMoreOptions);
  };
  const [userCardDetails, setUserCardDetails] = useState({
    cardNumber: '**** **** **** 1234',
    expiry: '12/23',
    type: 'Mastercard',
  });
  const [currentPlan, setCurrentPlan] = useState({
    planType: 'test',
    status: '',
    endDate: '',
    cancelAtPeriodEnd: '',
  });
  const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };


  // const [currentPlan, setCurrentPlan] = useState('Basic'); // Example plan name, replace with actual plan info
 
    //  setCurrentPlan(auth?.profileData?.plan_type);
  useEffect(() => {
    // Fetch data from API
    const fetchData = async () => {
      try {
        const api = `https://nqb72sk5yl.execute-api.us-east-1.amazonaws.com/prod/billing_prod?type=getPayment`;
        // const api = `http://localhost:5000/get-payment/`;
        console.log("userId", userId);
    
      const response = await axios.post(
        api,
        { userId },
        {
          headers: {
            Authorization: window.localStorage.getItem("token"),
          },
        }
      );
      console.log("response", response);
        // const data = await response.json();
        console.log("data", response.data.paymentMethod);
        const paymentMethod = response.data.paymentMethod.data[0].card;
        // const subscriptions = response.data.subscriptions.data[0];
        const payments = response.data.payments;
        const userPlan = response.data.userPlan[0];
        console.log("userPlan", userPlan);

const paymentData = payments && payments.data && payments.data.length > 0 ? payments.data[0] : null;

console.log("paymentMethod", paymentMethod);

        // console.log("subscriptions", subscriptions);

        console.log("paymentData", paymentData);








        // Assuming the API response structure is like this:
        // { plan: {...}, card: {...}, history: [...] }

        // Update state based on API response
        // setCurrentPlan(payments); // Replace 'planType' with the actual key in your API response
        setUserCardDetails(paymentMethod);
        setBillingData(payments);
        setCurrentPlan(userPlan);
        // setStatus(auth?.profileData?.subscription_status);
        setSubscriptionId(userPlan.subscription_id);

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData(); // Call the fetchData function
  }, []);
  const handleCancel = async () => {
    try {
       const api = `https://nqb72sk5yl.execute-api.us-east-1.amazonaws.com/prod/billing_prod?type=cancelSubscription`;

      // const api = `http://localhost:5000/cancel-subscription/`;

      // Make API call to cancel subscription
      console.log("subscriptionId", subscriptionId);
      const response = await axios.post(
        api,
        { userId, subscriptionId },
        {
          headers: {
            Authorization: window.localStorage.getItem("token"),
          },
        }
      );
      console.log('Cancel API Response:', response);
      SimpleToast({
        title: "Success",
        text: "Your subscription will be canceled at the end of the period.",
        iconName: "success",
        iconColor: "green",
      });
  
      // Add any additional logic or state updates as needed
    } catch (error) {
      console.error('Error cancelling subscription:', error);
    }
  };

  const handleAddCard = async () => {
    try{
      if(currentPlan.is_card_added !== 1){
        navigate("/AddCard", { replace: true });
       }
      } catch (error) {
        console.error('Error redirection to card page:', error);
      }
    



  };



  return (
    <div className="d-flex flex-column flex-md-row">
      {/* Sidebar */}
      <Sidebar />

      {/* Main Content */}
      <div className="flex-grow-1">
        <Header />

        {/* User Details */}
        <div className="container ContainerPlan mt-4 d-flex flex-column flex-md-row">
          {/* Plan Details */}

          <div className="plan-details-container" style={{ flex: 1 }}>
            <div className="plan-details-header">
              <h3 className='planDetailsHeading'>Your Current Plan</h3>
            </div>
          
            <div className="plan-details">
              <div>
              <p>Plan Type: {currentPlan.is_card_added === 1 ? currentPlan.planType : 'FREE'}</p>
              <p>Status: {currentPlan.subscription_status}</p>
             <p>End date: {new Date(currentPlan.end_date).toLocaleString('en-US', options)}</p>
              <p>Cancel at period end: {currentPlan.cancel_at_period_end === 1 ? 'Yes' : 'No'}</p>

              {/* <p>Price: </p> */}
              </div>
              
              <div  className="more-options-button">
                <p onClick={handleMoreOptionsClick} >Action</p>
                {showMoreOptions && (
              <div className="more-options-dropdown">
                <div className="more-options-dropdown">
      {currentPlan.status === 'trialing' && currentPlan.cancel_at_period_end === 0  && <p onClick={handleCancel}>Cancel</p>}
      {currentPlan.status === 'active' && <p onClick={handleCancel}>Cancel</p>}
      {currentPlan.status === 'active' && <p>Change Plan</p>}
    </div>
              </div>
            )}
              </div>
            </div>

          </div>

          {/* Card Details */}
          <div className="card-details-container" style={{ flex: 1 }}>
            <h3 className='cardHeading'>Your Card Details</h3>
           
            <div className="card-details">
           

            <p className="card-number">
      Card Number: **** **** **** {userCardDetails.last4}
      {currentPlan && (
        <b className="addCardBtn" onClick={handleAddCard}>
          Add Card
        </b>
      )}
    </p>
              
              <p>Expiry Date: {userCardDetails.exp_month}/{userCardDetails.exp_year}</p>
              <p>Card Type: {userCardDetails.brand}</p>
              <br></br>
              <p></p>

            
           
            </div>
         
            
           
           
          </div>
        </div>

        {/* Payment History */}
        <div className="container">
          <h3 className='paymentHeading'>Your Payment History</h3>
          <div className="table-container">
            <table className="table">
              <thead>
                <tr>
                  <th>Sr. No</th>
                  <th>Plan Type</th>
                  <th>Payment Date</th>
                  <th>Status</th>
                  <th>Amount</th>
                  <th>Currency</th>
                </tr>
              </thead>
              <tbody>
                {billingData.length > 0 ? (
                  billingData.map((entry, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{entry.name}</td>
                      <td>{entry.payment_date}</td>
                      <td>{entry.status}</td>
                      <td>{entry.amount}</td>
                      <td>{entry.currency}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="7">No purchases have been made yet.</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserPaymentHistory;
