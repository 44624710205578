import React from 'react'
import Navbar from './Navbar';
import { Container } from "react-bootstrap"

function Blog() {
  return (<>
    <div>
      <Navbar />
      <div>
        <div className=' form_div'>
          <div className='w-100'>
            <Container className='log_container'>
              <h4 className='display-5 text-white text-center mb-4'>Thsi is feature Coming Soon...
              </h4>
              <p className="text-white text-center">
                Click here for Linkedin
              </p>
              <div className='d-flex justify-content-center'>
                <div className=' blog_div'> <a href='https://www.linkedin.com/company/connect2co'>
                  <i className="fa-brands fa-linkedin-in guide_icon"></i></a>
                </div></div>





            </Container>
          </div>

        </div>
      </div>
    </div>
  </>

  )
}

export default Blog
