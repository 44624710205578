import React from 'react'
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import {Link} from "react-router-dom"
function Intouch() {
    return (<>
    
        <Container className="mt-5 intouch_container">
            <div className=' career_contact pb-5'>
                <h4>Is your business ready to scale?</h4>
                <h1>Don't wait, get started now!</h1>
                <div className='mt-4'>
                    <Link to="/signup"><Button className="text-primary btn account_btn px-3 py-2">
                        <span>CREATE ACCOUNT</span>
                        <i className="fa-solid fa-arrow-right ps-2"></i>
                    </Button></Link>
                    
                </div>
            </div>
        </Container>
    </>

    )
}

export default Intouch
