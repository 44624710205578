import { combineReducers } from "redux";
import { ActionTypes } from "./constants";
import { authReducer, loginReduer, userSignupReduer } from "./reducers/AuthReducer";
import {
	dashboardsReducer,
	delNotificationReducer,
	getNotificationReducer,
	getTeamMemberReducer,
	inviteTeamMembersReducer,
	updateNotificationReducer,
} from "./reducers/DashboardReducer";
import { getSFDataReducer, insertSFDataReducer } from "./reducers/SalesforceReducer";

const appReducer = combineReducers({
	Auth: authReducer,
	Login: loginReduer,
	userSignup: userSignupReduer,
	dashboardReducer: dashboardsReducer,
	getTeamMembers: getTeamMemberReducer,
	inviteTeamMembers: inviteTeamMembersReducer,
	getNotifications: getNotificationReducer,
	delNotifications: delNotificationReducer,
	updateNotifications: updateNotificationReducer,
	insertSFData: insertSFDataReducer,
	getSFData: getSFDataReducer,
});

const initial_state = appReducer({}, {});

const rootReducer = (state, action) => {
	console.log(state);
	if (action.type === ActionTypes.LOGOUT_REQUEST) {
		state = initial_state;
	}
	return appReducer(state, action);
};

export default rootReducer;
