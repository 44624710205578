import { useSelector } from "react-redux";
import editIcon from "../assets/images/edit-colored.png";
import { useEffect, useMemo, useState } from "react";
import axios from "axios";
import { formatQuery } from "../utils/helpers";

const OpportunitiesTable = ({ opportunities, setItem, pinnedProd }) => {
  const [opportunitiesData, setOpportunitiesData] = useState(
    opportunities || {}
  );
  const SFData = useSelector((state) => state.getSFData);
  const baseUrl = useMemo(
    () => SFData?.getSFData?.data?.[0]?.url,
    [SFData?.getSFData?.data]
  );
  const [isLoading, setIsLoading] = useState(true);

  const getAccountFields = async () => {
    const salesforceLoginAccessToken = localStorage?.getItem(
      "salesforceAccessToken"
    );
    setIsLoading(true);
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${salesforceLoginAccessToken}`;

    opportunities.records.forEach(async (opportunity) => {
      try {
        const accountNameData = await axios.get(
          `${baseUrl}/services/data/v57.0/query?q=${formatQuery(
            `Select Name from Account where Id = '${
              opportunity?.AccountId || ""
            }'`
          )}`
        );

        if (accountNameData.data) {
          setOpportunitiesData((prev) => {
            const data = prev.records.find(
              (item) => item.AccountId === opportunity.AccountId
            );
            const records = prev.records.map((item) => {
              if (item.AccountId === data.AccountId) {
                return {
                  ...item,
                  AccountName: accountNameData.data.records?.[0]?.Name || "",
                };
              }
              return item;
            });

            return { ...prev, records };
          });
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error(error);
      }
    });
  };

  useEffect(() => {
    getAccountFields();
  }, []);

  const redirect = (id) => {
    const url = `${baseUrl}/lightning/r/Opportunity/${id}/view`;
    window.open(url);
  };

  if (isLoading) {
    return (
      <div className="h-50 w-100 d-flex justify-content-center align-items-center">
        <div
          className="spinner-border spinner-border-md text-primary"
          role="status"
        />
      </div>
    );
  }

  return (
    <table style={{ color: "grey", tableLayout: "fixed", width: "100%" }}>
      <thead style={{ fontSize: "12px" }}>
        <th
          style={{
            width: "calc(100% / 25)",
          }}
        ></th>
        <th
          style={{
            width: pinnedProd ? "calc(100% / 3.5)" : "calc(100% / 2.5)",
            paddingLeft: "10px",
          }}
        >
          Opportunity Name
        </th>
        <th
          style={{
            width: pinnedProd ? "calc(100% / 10)" : "calc(100% / 6)",
            paddingLeft: "10px",
          }}
        >
          Account Name
        </th>
        { pinnedProd ? null : 
          <th
            style={{
              width: "calc(100% / 6)",
              paddingLeft: "10px",
            }}
          >
            Stage
          </th>
        }
        { pinnedProd ? null : 
          <th
            style={{
              width: "calc(100% / 6)",
              paddingLeft: "10px",
            }}
          >
            Close Date
          </th>
        }
      </thead>
      <tbody style={{ color: "white", fontSize: "14px" }}>
        {opportunitiesData?.records?.map((opportunity, index) => {
          return (
            <tr key={opportunity.Id}>
              <td
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  width: "calc(100% / 25)",
                  paddingLeft: "10px",
                  paddingTop: "3px",
                  paddingBottom: "3px",
                }}
              >
                {index + 1}
              </td>
              <td
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  width: "calc(100% / 4)",
                  paddingLeft: "10px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexFlow: "row nowrap",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <p
                    style={{
                      flexBasis: "90%",
                      marginBottom: "0px",
                      overflow: "hidden",
                      cursor: "pointer",
                    }}
                    onClick={() => redirect(opportunity.Id)}
                  >
                    {opportunity.Name}
                  </p>
                  <img
                    src={editIcon}
                    alt="edit"
                    style={{
                      height: "20px",
                      width: "20px",
                      marginLeft: "5px",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      setItem({
                        id: opportunity.Id,
                        type: "opportunity",
                        isCreateMode: false,
                      })
                    }
                  />
                </div>
              </td>
              <td
                style={{
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  width: "calc(100% / 6)",
                  paddingLeft: "10px",
                }}
              >
                {opportunity?.AccountName}
              </td>
              { pinnedProd ? null : 
                <td
                  style={{
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    width: "calc(100% / 6)",
                    paddingLeft: "10px",
                  }}
                >
                  {opportunity.StageName}
                </td>
              }
              { pinnedProd ? null : 
                <td
                  style={{
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    width: "calc(100% / 6)",
                    paddingLeft: "10px",
                  }}
                >
                  {opportunity.CloseDate}
                </td>
              }
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default OpportunitiesTable;
