import React, { useState } from "react";

import { Button } from "react-bootstrap";
import Navbar from "./Navbar";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { resetPassword } from "../redux/actions/AuthAction";
import { SimpleToast } from "../utils/Toast";
import { Link,useSearchParams} from "react-router-dom";
function ResetPassword() {

  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [cnfpassword, setCnfPassword] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();

  var qEmail = searchParams.get("email");
  var hash = searchParams.get("hash");
  
  if(!qEmail || !hash){
    navigate("/login");
  }

  const dispatch = useDispatch();
  const [forgotAuth,setForgotAuth] =useState({isForgotLoading:false});
  const fillOutField = (field_name) => {
    SimpleToast({
      title: "Error",
      text: ` Please Fill out ${field_name}`,
      iconName: "error",
      iconColor: "#d33",
    });
  };
  const FormSubmit = async (e) => {
    e.preventDefault();
    if(!qEmail || !hash){
      navigate("/login");
      return;
    }
    if (!password) {
      fillOutField("Password");
    }else if (!cnfpassword) {
      fillOutField("Confirm Password");
    }else if (password != cnfpassword) {
      SimpleToast({
        title: "Error",
        text: ` Confirm Password is not same as password.`,
        iconName: "error",
        iconColor: "#d33",
      });
    } else {
      let forgotAuth = {};
      forgotAuth.isForgotLoading = true;
      setForgotAuth(forgotAuth)
      const response = await dispatch(resetPassword(qEmail,hash,password));
      if (response?.data?.success && response !== "error") {
        forgotAuth.isForgotLoading = false;
        setForgotAuth(forgotAuth)
        SimpleToast({
          title: "Success",
          text: response?.data?.message,
          iconName: "success",
          iconColor: "green",
        });
        navigate("/login");
      } else{
        forgotAuth.isForgotLoading = false;
        setForgotAuth(forgotAuth)
        SimpleToast({
          title: "Error",
          text: response?.data?.message,       
          iconName: "error",
          iconColor: "#d33",
        });
      }
    }
  };

  return (
    <div className="main_hero_div" id="main">
      <Navbar />

      <div className="container" id="sign_up_container">
        <div className="d-flex justify-content-center career_main_div">
          <div className="border_div_career my-5"></div>
        </div>

        <h4
          className="display-5 text-white text-center mb-4"
          style={{ fontWeight: "300" }}
        >
          Reset Password
        </h4>

        <form className="g-3" autocomplete="off" onSubmit={FormSubmit}>
          <div
            style={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
              gap: 10,
            }}
          >
            <div className="input_label">
              <label className="label">Password</label>
              <input
                type="password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="input_label">
              <label className="label">Confirm Password</label>
              <input
                type="password"
                name="cnfpassword"
                value={cnfpassword}
                onChange={(e) => setCnfPassword(e.target.value)}
              />
            </div>
          </div>
          <div className="d-flex justify-content-center">
            <Button
              disabled={forgotAuth.isForgotLoading}
              type="submit"
              className="mt-3 ms-3 sign_up"
            >
              {forgotAuth.isForgotLoading ? (
                <div
                  class="spinner-border spinner-border-sm text-primary"
                  role="status"
                />
              ) : (
                "SUBMIT"
              )}
            </Button>
            <Link style={{
              marginTop:'25px',
              marginLeft:'15px'
            }} to="/login">Back to Login</Link>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ResetPassword;
