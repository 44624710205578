import { useEffect, useState } from "react";
import { Button, Col, Modal, Nav, Row, Tab } from "react-bootstrap";
import bellIcon from "../../assets/images/bell-icon.png";
import addIcon from "../../assets/images/add.png";
import minus from "../../assets/images/minus.svg";
import avatarIcon from "../../assets/images/defaultAvatar.png";
import dropdownIcon from "../../assets/images/dropdown-icon.png";
import Delete from "../../assets/images/delete.png";

import DatePicker from "react-datepicker";
import {
  UpdatedToast,
  SelectedToast,
  SimpleToast,
  DeleteToast,
} from "../../utils/Toast";
import axiosApiInstance from "../../utils/axiosInterceptor";
function GoogleModal({ show, setShowModal, updateData,setUpdateData, onSuccess }) {
  const [getTitle, setGetTitle] = useState("");
  const [getDesc, setGetDesc] = useState("Add Description");
  const [usersRecurrence, setUsersRecurrence] = useState({
    key: "",
    value: "Does not repeat",
  });
  const [usersRecurrenceKey, setUsersRecurrenceKey] = useState();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [notification, setNotification] = useState("0");
  const [isLoading, setIsLoading] = useState(false);
  const [showAttendees, setShowAttendees] = useState(false);
  const [userData, setUserData] = useState([]);
  const [data, setData] = useState();
  const Tabtitle = [
    {
      title: "Event",
    }
  ];

  // console.log(updateData ,'my datasdfsdfsdf')
  useEffect(() => {
    setGetTitle(updateData.summary);
    setGetDesc(updateData.description || "Add Description");
    setNotification(
      ((updateData?.reminders?.overrides || []).length > 0 &&
        updateData?.reminders?.overrides[0]?.minutes) ||
        0
    );
    setStartDate(
      (updateData?.start?.dateTime && new Date(updateData?.start?.dateTime)) ||
        new Date()
    );

    setEndDate(
      (updateData?.end?.dateTime && new Date(updateData?.end?.dateTime)) ||
        new Date()
    );
    setData(
      ((updateData?.attendees || []).length > 0 &&
        updateData.attendees.map((i) => ({ ...i, key: false }))) ||
        []
    );

    setUsersRecurrenceKey(updateData?.recurrence || []);
    const printUserRecurr = (key) => {
      if ((updateData?.recurrence || []).length > 0) {
        const str = updateData?.recurrence[0];
        const indexOfEqual = str.indexOf("=");
        const indexOfSemiColen = str.indexOf(";");
        const result = str.substr(
          indexOfEqual + 1,
          indexOfSemiColen - indexOfEqual - 1
        );
        return result;
      } else {
        return key;
      }
    };
    setUsersRecurrence({
      value: printUserRecurr(),
    });
  }, [updateData]);

  const UpdateGoogleCalendarData = async () => {
    const UpdateBody = {
      summary: getTitle,
      description: getDesc,
      start: {
        dateTime: startDate.toISOString(),
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
      end: {
        dateTime: endDate.toISOString(),
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
      reminders: {
        useDefault: false,
        overrides: [{ method: "popup", minutes: notification }],
      },
      recurrence: [`${usersRecurrenceKey}` || []],
      attendees: [
        ...(userData.map((email) => {
          return { email };
        }) || []),
      ],
    };
    setIsLoading(true);
    await axiosApiInstance
      .put(
        `https://www.googleapis.com/calendar/v3/calendars/primary/events/${updateData.id}`,
        UpdateBody
        // {
        //   headers: {
        //     Authorization: "Bearer " + session?.provider_token,
        //   },
        // }
      )
      .then((res) => {
        setIsLoading(false);
        setShowModal(false);
        onSuccess();
        SimpleToast({
          title: "Success",
          text: "Information successfully added.",
          iconName: "success",
          iconColor: "green",
        });
      })

      .catch((err) => {
        setIsLoading(false);
        SimpleToast({
          title: "Error",
          text: `${err.message}`,
          iconName: "error",
          iconColor: "#d33",
        });
      });
  };

  const DeleteGoogleCalendarData = async () => {
    setIsLoading(true);
    await axiosApiInstance
      .delete(
        `https://www.googleapis.com/calendar/v3/calendars/primary/events/${updateData.id}`
        // {
        //   headers: {
        //     Authorization: "Bearer " + session?.provider_token,
        //   },
        // }
      )
      .then((res) => {
        setIsLoading(false);
        setShowModal(false);
        onSuccess();
      })

      .catch((err) => {
        setIsLoading(false);
        SimpleToast({
          title: "Error",
          text: `${err.message}`,
          iconName: "error",
          iconColor: "#d33",
        });
      });
  };
  const openUpdateDescModalHandle = () => {
    setShowModal(false);
    UpdatedToast({
      text: `Add Description.`,
      iconName: "info",
      iconColor: "#add8e6",
      confirmBtnText: "Confirm",
      inputValue: getDesc,
      preConfirm: (value) => {
        setGetDesc(value);
        setShowModal(true);
      },
      onCancel: () => {
        setShowModal(true);
      },
      input: "textarea",
    });
  };

  const openUpdateNotificaltionModalHandle = () => {
    setShowModal(false);
    UpdatedToast({
      text: `Set Reminder`,
      iconName: "info",
      iconColor: "#add8e6",
      confirmBtnText: "Confirm",
      inputValue: notification,
      preConfirm: (value) => {
        setNotification(value);
        setShowModal(true);
      },
      onCancel: () => {
        setShowModal(true);
      },
      input: "text",
    });
  };
  const openDoseNotRepeatUpdateModalHandle = () => {
    setShowModal(false);
    SelectedToast({
      text: ` Select Options`,
      iconName: "info",
      iconColor: "#add8e6",
      confirmBtnText: "Confirm",
      inputOptions: {
        "RRULE:FREQ=DAILY": "Daily",
        "RRULE:FREQ=WEEKLY;BYDAY=SA": "Weekly on Saturday",
        "RRULE:FREQ=MONTHLY;BYDAY=2SA": "Monthly on the third Saturday",
        "RRULE:FREQ=YEARLY": "Annually on January 21",
        "RRULE:FREQ=WEEKLY;BYDAY=MO,TU,WE,TH,FR":
          "Every weekday (Monday to Friday)",
      },
      inputPlaceholder: "Does not repeat",
      onCancel: () => {
        setShowModal(true);
      },
      preConfirm: ({ key, value }) => {
        setUsersRecurrence({ key, value });
        setShowModal(true);
      },
      input: "select",
    });
  };
  const deleteModalHandle = () => {
    setShowModal(false);
    DeleteToast({
      title: "Confirmation",
      text: `Do you want to delete this event?`,
      iconName: "warning",
      iconColor: "red",
      onCancel: () => {
        setShowModal(true);
      },
      onSubmit: () => {
        DeleteGoogleCalendarData();
      },
      preConfirm: () => {
        setShowModal(true);
      },
      input: "select",
    });
  };

  return (
    <div className="calendar-modal">
      <Row>
        <Col>
          <Modal
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={show}
            onHide={() => setShowModal(false)}
          >
            <Modal.Header
              style={{
                backgroundColor: "var(--primary-color)",
                border: 0,
                color: "#FFF",
                padding: "20px",
                fontSize: 30,
                width: "100%",
              }}
            >
              <Modal.Title
                style={{
                  borderColor: "#FFF",
                  position: "relative",
                  background: "none",
                  color: "#FFF",
                  textAlign: "center",
                  padding: 0,
                  fontSize: 30,
                  width: "100%",
                }}
              >
                Event Details
              </Modal.Title>

              <img
                className="position-absolute top-0 end-0 translate-middle p-2"
                onClick={deleteModalHandle}
                style={{
                  width: 50,
                  height: 50,
                  objectFit: "contain",
                  marginRight: "0px",
                  marginTop: "40px",
                  cursor: "pointer",
                }}
                alt={"delete-icon"}
                src={Delete}
              />
            </Modal.Header>
            <Modal.Body
              style={{
                backgroundColor: "var(--primary-color)",
                border: 0,
                padding: "0 20px",
              }}
            >
              <Tab.Container id="left-tabs-example" defaultActiveKey="Event">
                <Row className="mt-2 d-flex justify-content-center">
                  <Col
                    lg={8}
                    className="calendar-grid google-calendar d-flex justify-content-center align-items-center mt-2"
                  >
                    <Nav
                      variant="pills"
                      className="d-flex justify-content-between mb-3"
                    >
                      {Tabtitle.map((i, index) => (
                        <Nav.Item key={index}>
                          <Nav.Link eventKey={i.title}>{i.title}</Nav.Link>
                        </Nav.Item>
                      ))}
                    </Nav>
                  </Col>
                </Row>

                <Tab.Content>
                  {Tabtitle.map((i, indx) => (
                    <Tab.Pane eventKey={i.title} key={indx}>
                      <div className="d-flex justify-content-between flex-column h-100 gap-3">
                        <div className="mt-3">
                          <input
                            placeholder="Title"
                            onChange={(e) => setGetTitle(e.target.value)}
                            value={getTitle}
                            style={{
                              outline: "none",
                              borderWidth: "0 0 2px",
                              borderColor: "#FFF",
                              padding: "5px 10px 20px 10px",
                              background: "none",
                              borderRadius: 0,
                              color: "#FFF",
                              textAlign: "center",
                              fontSize: 20,
                            }}
                          />
                        </div>

                        <Row className="d-flex mt-3 justify-content-between">
                          <Col lg={6} md={6} sm={6}>
                            <div className="pick-style d-flex flex-column gap-1 cursor-pointer">
                              <p
                                style={{ margin: 0, padding: 0, color: "grey" }}
                              >
                                Start Date Time
                              </p>
                              <DatePicker
                                selected={startDate}
                                className="date-picker-style"
                                onChange={(date) => setStartDate(date)}
                                timeInputLabel="Time:"
                                dateFormat="d/MMM/yyyy h:mm aa"
                                showTimeInput
                              />
                              <p
                                style={{ margin: 0, padding: 0, color: "grey" }}
                              >
                                End Date Time
                              </p>
                              <DatePicker
                                selected={endDate}
                                className="date-picker-style"
                                onChange={(date) => setEndDate(date)}
                                timeInputLabel="Time:"
                                dateFormat="d/MMM/yyyy h:mm aa"
                                showTimeInput
                              />
                              <div className="d-flex align-items-center">
                                <p
                                  style={{
                                    margin: 0,
                                    padding: 0,
                                    color: "grey",
                                    cursor: "pointer",
                                  }}
                                  onClick={openDoseNotRepeatUpdateModalHandle}
                                >
                                  {usersRecurrence.value}
                                </p>

                                <div
                                  style={{
                                    cursor: "pointer",
                                    marginLeft: 10,
                                  }}
                                  onClick={openDoseNotRepeatUpdateModalHandle}
                                >
                                  <img
                                    src={dropdownIcon}
                                    style={{
                                      width: 20,
                                      height: 20,
                                      objectFit: "contain",
                                    }}
                                    alt={"add-icon"}
                                  />
                                </div>
                              </div>
                            </div>
                          </Col>
                          <Col lg={6} md={6} sm={6}>
                            <div className="desc-dots d-flex flex-column align-items-end gap-3">
                              <p
                                style={{
                                  margin: 0,
                                  padding: 0,
                                  color: "white",
                                  cursor: "pointer",
                                }}
                                onClick={openUpdateDescModalHandle}
                              >
                                {getDesc}
                              </p>

                              <div
                                className="minute-time-picker d-flex align-items-center my-2"
                                onClick={openUpdateNotificaltionModalHandle}
                              >
                                <img
                                  src={bellIcon}
                                  style={{
                                    width: 20,
                                    height: 20,
                                    objectFit: "contain",
                                  }}
                                  alt={"bell-icon"}
                                />
                                <p
                                  style={{
                                    margin: 0,
                                    padding: 0,
                                    color: "#fff",
                                    fontSize: 16,
                                    width: "47px",
                                    textAlign: "center",
                                    cursor: "pointer",
                                  }}
                                >
                                  {notification}
                                </p>
                                <p
                                  style={{
                                    margin: 0,
                                    padding: 0,
                                    color: "grey",
                                    fontSize: 14,
                                  }}
                                >
                                  minutes before
                                </p>
                              </div>
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col lg={6} md={6} sm={6}>
                            <div className="mt-1" style={{ display: "flex" }}>
                              <img
                                src={avatarIcon}
                                style={{
                                  width: 25,
                                  height: 25,
                                  objectFit: "contain",
                                }}
                                alt={"avatar-icon"}
                              />
                              <img
                                src={avatarIcon}
                                style={{
                                  width: 25,
                                  height: 25,
                                  objectFit: "contain",
                                }}
                                alt={"avatar-icon"}
                              />
                              <img
                                src={addIcon}
                                onClick={() => setShowAttendees(!showAttendees)}
                                style={{
                                  width: 25,
                                  height: 25,
                                  objectFit: "contain",
                                  marginLeft: 5,
                                  cursor: "pointer",
                                }}
                                alt={"add-icon"}
                              />
                            </div>
                          </Col>
                        </Row>

                        {showAttendees ? (
                          <Col lg={12} md={12} sm={12}>
                            <div className="">
                              {data.map((i, index) => (
                                <div
                                  className="user-modal d-flex gap-2 align-items-center my-2 justify-content-start"
                                  key={index}
                                >
                                  {/* <img
                                    style={{
                                      width: 30,
                                      height: 30,
                                      objectFit: "contain",
                                      marginRight: 6,
                                    }}
                                    src={i.image}
                                    alt={i.email}
                                  /> */}
                                  <h2
                                    style={{
                                      width: "80%",
                                      color: "#fff",
                                      fontSize: 15,
                                      fontWeight: 400,
                                      // textTransform: "capitalize",
                                    }}
                                  >
                                    {i.email}
                                  </h2>
                                  <img
                                    src={i.key ? minus : addIcon}
                                    alt="add-icon"
                                    style={{
                                      width: 25,
                                      height: 25,
                                      objectFit: "contain",
                                      cursor: "pointer",
                                      marginRight: 10,
                                    }}
                                    onClick={() => {
                                      const { email } = i;
                                      let emails = [...userData];
                                      const emailexist = emails.includes(email);
                                      if (!emailexist) {
                                        emails.push(email);
                                      } else {
                                        emails = emails.filter((eMail) => {
                                          return eMail !== email;
                                        });
                                      }
                                      setUserData(emails);
                                      const newData = [...data];
                                      newData[index]["key"] = !i.key;
                                      setData(newData);
                                    }}
                                  />
                                </div>
                              ))}
                            </div>
                          </Col>
                        ) : (
                          ""
                        )}
                      </div>
                    </Tab.Pane>
                  ))}
                </Tab.Content>
              </Tab.Container>
            </Modal.Body>
            <Modal.Footer
              className="d-flex justify-content-evenly"
              style={{
                backgroundColor: "var(--primary-color)",
                border: 0,
                width: "100%",
              }}
            >
              <Button
                variant="secondary"
                size="md"
                style={{
                  borderRadius: 100,
                  padding: "10px 20px",
                }}
                onClick={() =>{ setUpdateData({}) 
                setShowModal(false)}}
              >
                <p
                  style={{
                    margin: 0,
                    padding: 0,
                  }}
                >
                  Close
                </p>
              </Button>
              <Button
                size="md"
                variant="none"
                disabled={isLoading}
                style={{
                  backgroundColor: "var(--white-color)",
                  borderRadius: 100,
                  padding: "10px 20px",
                }}
              >
                <p
                  style={{
                    margin: 0,
                    padding: 0,
                    width: "200px",
                    color: "var(--secondry-color)",
                    borderRadius: 10,
                    fontWeight: "bold",
                  }}
                  onClick={UpdateGoogleCalendarData}
                >
                  {isLoading ? (
                    <div
                      className="spinner-border spinner-border-sm text-primary"
                      role="status"
                    />
                  ) : (
                    "Save To Google Calendar"
                  )}
                </p>
              </Button>
            </Modal.Footer>
          </Modal>
        </Col>
      </Row>
    </div>
  );
}

export default GoogleModal;
