import core from "./Images/core.png";
import employ from "./Images/employ.png";
import sustain from "./Images/sustain.png";
import profit from "./Images/profit.png";
import inovation from "./Images/inovation.png";
import call from "./Images/call.png";
import message from "./Images/message.png";
import Container from 'react-bootstrap/Container';
import Footer from './Footer';
import Navbar from "./Navbar";
import Footerabout from "./Footerabout";



function CoreValues() {

  return (<>
    <div className='portfolio_box'>
      <Navbar />
      <div className=' career_main_div'>
        <div className='d-flex justify-content-center align-items-center career_main_div' style={{marginTop: '85px'}} >
            <div className='border_div_career my-5'></div>
          </div>
        <div className='hero_section h-100 mb-5' style={{minHeight: '100%'}}>
          <div className='display-3 career_main_headingline'>Core values</div>
          <div className='container mt-5'>
            <div className='row mt-5 '>
              <div className='col-lg-4 col-md-4 col-sm-12 d-flex align-items-center'>


                <div className=' align-items-center' style={{ width: "100%", }}>
                  <div className='customer_pi'>
                    <img src={core} alt="" style={{ width: "100%", borderRadius: "20px" }} />
                  </div>

                </div>





              </div>
              <div className='col-lg-7 col-md-8 col-sm-12' id='spacing_id'>
                <h5 className='display-6 core_center customer_grid grid_heading' style={{ color: "#238FDB" }}>Customers</h5>
                <p className='career1_lines mt-3'>Connect2Co works by enabling our customers achievement. This platform is
                  dedicated to our customers and the businesses and employees therein. Our
                  relationship is co-dependent. We succeed when you do.</p>
                <p className='career1_lines mt-2'>Our solution will provide integrated support guides and has phenomenal customer service in
                  that of our responsiveness to all of your support cases and simplicity in adoption,
                  deployment and design. These factors serve as the building blocks of our customer
                  centric organization dedicated  to helping you scale and achieve profitability in a
                  sustainable direction.</p>
              </div>

            </div>
          </div>
        </div>
        {/* sec 2 */}
        <div className='container_fluid background_pic'>
          <div className='container '>
            <div className='row  align-items-center body_rows'>
              <div className='col-lg-8 col-md-8 col-sm-12'>
                <div className='ps-4'>
                  <h4 className='display-5  mb-4 grid_heading' style={{ color: "#238FDB" }}>Employees</h4>
                  <p className='career1_lines'>Customers alike, employees are vital to the success of our company. We strive to
                    ensure that we are fair in our hiring of diverse individuals. Whether  that be in regard
                    to race, gender, disability or sexual orientation, we do not discriminate.</p>
                  <p className='career1_lines'>We encourage our employees to support each other through  providing an atmosphere of
                    understanding. This includes anything from an openness and
                    support of questions when something is uncertain, or someone requires additional
                    clarification and an open ear to suggestions when an employee has a
                    recommendation.</p>

                </div>

              </div>
              {/* <div className="col-lg-1 col-md-1 col-sm-12"></div> */}
              <div className='col-lg-4 col-md-4 col-sm-12' id='spacing_div'>
                <img src={employ} alt="" style={{ width: "100%" }} />
              </div>
            </div>
          </div>
        </div>

        {/* sec 3 */}
        <div className='container core_height'>
          <div className='row justy-content-center align-items-center body_rows'>
            <div className='col-lg-4 col-md-4 col-sm-12' id='spacing_div'>
              <img src={sustain} alt="" style={{ width: "100%" }} />


            </div>
            <div className='col-lg-8 col-md-8 col-sm-12' id='spacing_id' >
              <div className=''>
                <div className='display-5  mb-4 grid_heading' style={{ color: "#238FDB" }}>Sustainability</div>
                <p className='career1_lines'>
                  The purpose of our organization is to build  the next generation of sustainable
                  companies. This includes our commitment to the three pillars of sustainability
                  (economic viability, environmental protection, and social equity).
                </p>
                {/* <p className='career1_lines'>Through AI  insights which cross-reference your company's data with country
                  specific environmental objectives (ESG), we will able to provide  actionable
                  suggestions and solutions to empower more sustainable descion making, while
                  focusing on the objective of maintaining and increasing profitability. </p> */}
              </div>

            </div>
          </div>
        </div>



        {/* SEC 4 */}
        <div className='container-fluid background_pic'>
          <div className='container core_height'>
            <div className='row  align-items-center body_rows'>
              <div className='col-lg-8 col-md-8 col-sm-12' >
                <div className=' ps-4'>
                  <h4 className='display-5  mb-4 grid_heading' style={{ color: "#238FDB" }}>Profitability</h4>
                  <p className='career1_lines'>Connect2Co places a strong emphasis on transparency and strategic insights and the decisions that are made as a byproduct. Many of these, support your bottom line. The customer experience that we provide is one which revolves around the enablement of your company's ability to reach strategic objectives and achieve competitive advantage in your respective industries.</p>
                  <p className='career1_lines'>Objectives may range from increasing transparency among departments to reducing costs on redundant software to increasing lead conversions for your sales department. These among  others are ways in which profitability can be achieved and increased within your company.</p>


                </div>



              </div>
              <div className='col-lg-4 col-md-4 col-sm-12' id='spacing_div'>
                <img src={profit} alt="" style={{ width: "100%" }} />


              </div>
            </div>
          </div>
        </div>


        {/* sec 5 */}
        <div className='container core_height'>
          <div className='row align-items-center body_rows'>
            <div className='col-lg-4 col-md-4 col-sm-12' id='spacing_id'>
              <img src={inovation} alt="" style={{ width: "100%" }} />


            </div>
            <div className='col-lg-8 col-md-8 col-sm-12'>

              <div className='display-5  mb-4 core_center' id='spacing_div' style={{ color: "#238FDB" }}>Innovation</div>
              <p className='career1_lines'>
                Connect2Co is driven by the possibilities that the future holds. We want to empower companies to think and act with a more future-oriented mindset. Many of the thought processes and systems used to achieve profitability in the past will continue to be utilized in the future.


              </p>
              <p className='career1_lines'>
                Thankfully however, technology is at the point where it can be used  to accommodate an increase in environmental and social conscientiousness; something that many organizations fail to do. Times are changing and adaptability is crucial to the evolution and success of humanity. We pride ourselves on the fact that our solution is agile. Suggestions, both internally and externally, if sound are implemented, and the greater good is always in the front of our minds. </p>




            </div>
          </div>
        </div>



        {/* sec 6 */}
        <div className='container-fluid background_pic'>

          <div style={{ height: "50vh" }} id='get'>
            <Container className=' container_div' >
              <h4 className='display-5 text-white text-center pt-3   pb-3' >
                <b className="">Get in touch</b> with us
              </h4>
              <div className='contact  mx-auto'>
                <div className='row'>
                  <div className='col-lg-6 col-md-6 col-sm-12 '>

                    <div className='d-flex flex-wrap gap-5  '>
                      <div className='d-flex  gap-2'>

                        <div className="career_contact_pic"> <img src={call} alt="" style={{ width: "40px", height: "40px" }} /></div>
                        <p className='contact_source'><b>+1 (845) 608 6705</b> (USA)</p>
                      </div>
                      {/* <div className='d-flex  gap-2'>
                        <div className="career_contact_pic"> <img src={call} alt="" style={{ width: "40px", height: "40px" }} /></div>
                        <p className='contact_source'><b>+49 (171) 831 4875</b> (Europe)</p>
                      </div> */}

                    </div>
                  </div>
                  <div className='col-lg-6 col-md-6 col-sm-12 spacing'>
                    <div className='d-flex flex-wrap gap-5  mt-2'>
                      <div className='d-flex  gap-2'>
                        <div className="career_contact_pic"> <img src={message} alt="" style={{ width: "40px", height: "40px" }} /></div>

                        <p className='contact_source'><b>liamjfitzgerald99@gmail.com</b> (CEO)</p>
                      </div>
                      <div className='d-flex  gap-2'>
                        <div className="career_contact_pic"> <img src={message} alt="" style={{ width: "40px", height: "40px" }} /></div>
                        <p className='contact_source'><b>connect2co.root@gmail.com</b> (Corporate)</p>
                      </div>


                    </div>
                  </div>
                </div>
              </div>
            </Container>
          </div>


          <div className='container-fluid ' id="intouch">

            <div className=' space ' >
              <Footerabout />
            </div>
          </div>





        </div>


      </div>





    </div>
  </>

  )
}

export default CoreValues

