import React, { useEffect, useState } from "react";
import Header from "../../Components/Header";
import Sidebar from "../../Components/Sidebar";
import edit from "../../assets/images/edit.png";
import add from "../../assets/images/add.png";
import editPerson from "../../assets/images/edit-person.png";
import bcrypt from 'bcryptjs'

import { useNavigate, useLocation } from "react-router-dom";
import ProfileCard from "../../Components/ProfileCard";
import { TeamCard, TeamList } from "../../Components/TeamCard";
import {
  adminNotifyAction,
  clearErrorMessageAction,
  deleteDashboard,
  editDashboard,
  findMember,
  getDashboards,
  getTeamMembers,
  inviteTeamMembersAction,
} from "../../redux/actions/DashboardAction";
import { useDispatch, useSelector } from "react-redux";
import "./index.css";
import { UpdatedToast, SimpleToast } from "../../utils/Toast";
import { updateProfileAction } from "../../redux/actions/AuthAction";
import { generateRandomPassword } from "../../utils/constant";

const Team = () => {
  const [del, setDel] = useState(false);
  const [editUser, setEditUser] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const checkDel = location?.state?.delVal;
  const dashbaordName = location?.state?.dashboardName;
  const dashboardId = location?.state?.dashboardId;
  const userId = localStorage.getItem("userId");
  const dispatch = useDispatch();
  const dashboard = useSelector((state) => state.dashboardReducer);
  const auth = useSelector((state) => state.Auth);
  const inviteTeamMembers = useSelector((state) => state.inviteTeamMembers);
  const getTeam = useSelector((state) => state.getTeamMembers);
  const [editName, setEditName] = useState(dashbaordName);
  const [searchMemberLoad, setSearchMemberLoad] = useState(false);
  useEffect(() => {
    if (checkDel) {
      setDel(true);
    }
  }, [checkDel]);
  const deleteDashboardHandle = async () => {
    const response = await dispatch(deleteDashboard(dashboardId));
    if (response?.status === 200) {
      const previousDashboards = [];
      const dashboardIds = {};
      for (let i = 1; i <= 5; i++) {
        const id = auth.profileData[`dashboard${i}`];

        if (id && id !== dashboardId) {
          previousDashboards.push(id);
        }
      }

      for (let i = 0; i < 5; i++) {
        if (previousDashboards[i]) {
          dashboardIds[`dashboard${i + 1}`] = previousDashboards[i];
        } else {
          dashboardIds[`dashboard${i + 1}`] = null;
        }
      }

      const data = await dispatch(updateProfileAction(userId, dashboardIds));

      if (data?.status === 200) {
        SimpleToast({
          title: "Success",
          text: "Dashboard successfully deleted.",
          iconName: "success",
          iconColor: "green",
        });

        clearErrorMessageAction();
        await dispatch(getDashboards(dashboardIds));
        navigate("/profile");
      }
    }
  };
  const editDashboardHandle = async (name) => {
    const data = await dispatch(editDashboard(dashboardId, name));

    if (data?.status === 200) {
      setEditName(name);
      SimpleToast({
        title: "Success",
        text: "Dashboard name was successfully updated!",
        iconName: "success",
        iconColor: "green",
      });
    }
  };

  useEffect(() => {
    if (!dashboardId) {
      navigate("/profile");
    }
  }, [location, dashboardId, navigate]);

  const deleteHandleConfirm = () => {
    UpdatedToast({
      text: `Are you sure that you want to delete ${dashbaordName}?`,
      iconName: "warning",
      iconColor: "#d33",
      confirmBtnText: "Yes, delete it!",
      preConfirm: deleteDashboardHandle,
      input: false,
    });
  };

  const openEditModalHandle = () => {
    UpdatedToast({
      text: `Please enter a new name for this team.`,
      iconName: "info",
      iconColor: "#add8e6",
      confirmBtnText: "Confirm",
      preConfirm: (name) => editDashboardHandle(name),
      input: "text",
    });
  };
  useEffect(() => {
    if (
      dashboard?.isEditDashboardError ||
      dashboard?.isDelDashboardError ||
      inviteTeamMembers?.isInviteTeamError
    ) {
      SimpleToast({
        title: "Error",
        text: "Oops, something went wrong.",
        iconName: "error",
        iconColor: "#d33",
      });
    }

    setTimeout(() => {
      dispatch(clearErrorMessageAction());
    }, 2000);
  }, [
    dashboard?.isEditDashboardError,
    dashboard?.isDelDashboardError,
    inviteTeamMembers?.isInviteTeamError,
    dispatch,
  ]);

  useEffect(() => {
    if (dashboardId) {
      dispatch(getTeamMembers(dashboardId));
    }
  }, [
    dispatch,
    dashboardId,
    auth?.updateProfileData,
    inviteTeamMembers?.inviteTeamData,
  ]);

  const searchMember = async (data) => {
    if (data) {
      setSearchMemberLoad(true);
      const response = await dispatch(
        inviteTeamMembersAction(data, setSearchMemberLoad)
      );

      if (response.status === 200) {
        SimpleToast({
          title: "Success",
          text: "User invited successfully!",
          iconName: "success",
          iconColor: "green",
        });
      } else {
        return;
      }
    }
  };

  const confirmInviteModal = async (email) => {
    if (email) {
      setSearchMemberLoad(true);

      const findMemberData = await findMember(email);
      if (findMemberData?.data?.length && findMemberData !== "error") {
        const res = await adminNotifyAction({
          user: userId,
          dashboard: dashboardId,
          status: "",
          message: `You invited ${email} to ${dashbaordName}`,
          datetime: new Date().getTime(),
        });
        if (res?.status === 200 && res?.data?.affectedRows === 1) {
          const data = {
            email: email,
            dashboard1: dashboardId,
            message: `You have been invited to join ${dashbaordName}`,
            status: "pending",
            datetime: new Date().getTime(),
          };

          const response = await dispatch(
            inviteTeamMembersAction(data, setSearchMemberLoad)
          );

          if (response.status === 200) {
            // setSearchMemberLoad(false);
            SimpleToast({
              title: "Success",
              text: "User invited successfully!",
              iconName: "success",
              iconColor: "green",
            });
          } else {
            return;
          }
        } else {
          SimpleToast({
            title: "Error",
            text: "Oops, something went wrong.",
            iconName: "error",
            iconColor: "#d33",
          });
        }
      } else if (findMemberData === "error") {
        setSearchMemberLoad(false);
        SimpleToast({
          title: "Error",
          text: "Oops, something went wrong.",
          iconName: "error",
          iconColor: "#d33",
        });
      } else {
        setSearchMemberLoad(false);

        let password = generateRandomPassword();
        const data = {
          email: email,
          password: bcrypt.hashSync(password, 10),   // hash password
          dashboard1: dashboardId,
        };

        UpdatedToast({
          title: "Confirmation Email & Password",
          text: `Email: ${email} Password: ${password}`,
          iconName: "info",
          iconColor: "#add8e6",
          confirmBtnText: "Invite",
          data: data,
          preConfirm: (name) => searchMember(name),
        });
      }
    }
  };
  const openInviteModal = () => {
    UpdatedToast({
      text: `Add team member Email`,
      iconName: "info",
      iconColor: "#add8e6",
      confirmBtnText: "Confirm",
      preConfirm: (email) => confirmInviteModal(email),
      input: "email",
      dashboardId: dashboardId,
    });
  };

  return (
    <div className="d-flex">
      <div>
        <Sidebar /> <Header />
      </div>

      <div className="teamlistMainContainer">
        <ProfileCard path="/profile" userData={auth?.profileData} />
        <div className="teamlistContainer">
          {/* {del && ( */}

          {dashboard?.isDelDashboardLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "65%",
                marginBottom: 10,
              }}
            >
              <div
                className="spinner-border-sm spinner-border text-white"
                role="status"
              />
            </div>
          ) : (
            del && (
              <div onClick={deleteHandleConfirm} className="teamDelBtn">
                <p>Delete?</p>
              </div>
            )
          )}

          {/* )} */}
          <TeamCard
            img1={add}
            img2={edit}
            path="/SalesDashboard"
            name={editName}
            id={dashboardId}
            setDel={setDel}
            del={del}
            openEditModal={openEditModalHandle}
            editLoader={dashboard?.isEditDashboardLoading}
            inviteTeamLoader={inviteTeamMembers?.isInviteTeamLoading}
            searchMemberLoad={searchMemberLoad}
            openInviteModal={openInviteModal}
          />
        </div>
        <div className="item">
          <div className="teamlistCardContainer">
            {getTeam?.isGetTeamLoading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "260px",
                }}
              >
                <div
                  className="spinner-border spinner-border text-white"
                  role="status"
                />
              </div>
            ) : getTeam?.getTeamData?.data?.length ? (
              getTeam?.getTeamData?.data?.map((item, ind) => {
                return (
                  <TeamList
                    id={item?.id}
                    personImg={item.profile_image}
                    fName={item?.first_name}
                    lName={item?.last_name}
                    designation={item.position}
                    dashboardId={dashboardId}
                    item={item}
                    editImg={editPerson}
                    editUser={editUser}
                    setEditUser={setEditUser}
                  />
                );
              })
            ) : (
              <div className="noDashboardFound">
                <p>No Members Found..</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Team;
