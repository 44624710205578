// YourComponent.js
import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CreditCardForm from './CreditCardForm'; // Replace with your actual component file path

const stripePromise = loadStripe('pk_test_51InwRQKATzDwMGDo6pIJXt9IJKrwt5oOUB1JMqmUBQ5sMdYjBtrkUjPO81dBbxdFCDV50m5S5JXgTqUJL8mLXtrr00Ncu1N0DV');

const YourComponent = () => {
  return (
    <Elements stripe={stripePromise}>
      {/* The component using useStripe and useElements */}
      <CreditCardForm options={{ style: { base: { fontSize: '16px' } } }} />

      {/* Other components that use useStripe and useElements */}
    </Elements>
  );
};

export default YourComponent;
