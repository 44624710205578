import axios from "axios";
import { salesforceApi } from "../../utils/api";
import { ActionTypes } from "../constants";

const config = {
  headers:{
    'x-api-key': process.env.REACT_APP_C2C_PROD_AWS_ACCESS_KEY
  }
};

export const insertSFDataAction = (data) => async (dispatch) => {
  dispatch({ type: ActionTypes.INSERT_SF_REQ });

  const api = `${salesforceApi}/insertSFConnector`;
  try {
    const response = await axios.post(api, data, config);
    dispatch({ type: ActionTypes.INSERT_SF_SUCCESS, payload: response });
    return response;
  } catch (error) {
    dispatch({
      type: ActionTypes.INSERT_SF_FAIL,
      payload: error?.message,
    });
  }
};
export const updateSFDataAction = (data) => async (dispatch) => {
  dispatch({ type: ActionTypes.INSERT_SF_REQ });

  const api = `${salesforceApi}/updateSFConnector`;
  try {
    const response = await axios.post(api, data, config);
    dispatch({ type: ActionTypes.INSERT_SF_SUCCESS, payload: response });
    return response;
  } catch (error) {
    dispatch({
      type: ActionTypes.INSERT_SF_FAIL,
      payload: error?.message,
    });
  }
};
export const getSFDataAction = (userId) => async (dispatch) => {
  dispatch({ type: ActionTypes.GET_SF_REQ });

  const api = `${salesforceApi}/getallconnectors`;
  try {
    const response = await axios.post(api, { userId }, config);
    dispatch({ type: ActionTypes.GET_SF_SUCCESS, payload: response });
    return response;
  } catch (error) {
    dispatch({
      type: ActionTypes.GET_SF_FAIL,
      payload: error?.message,
    });
  }
};
