import React from "react";
import { Button, ListGroup } from "react-bootstrap";

function GoogleDriveFolder({
  googleFolderIds,
  getParentFolderGoogleDriveData,
  setParentFolderShow,
  childrenFolderData,
  parentFolderShow,
  folderPathHierarchy,
  setFolderPathHierarchy
}) {
  return (
    <div className="google-drive-folder">
      <h4
        style={{
          color: "#fff",
          fontSize: 16,
          margin: 0,
          fontWeight: 400,
          padding: 0,
        }}
      >
        Folders
      </h4>
      <>
        <ListGroup
          as="ul"
          horizontal
          className="folder-list justify-content-start"
        >
          {parentFolderShow ? (
            <>
              {googleFolderIds?.length > 0 ? (
              <>
                {googleFolderIds?.map((item, index) => {
                  return (
                    <ListGroup.Item
                      as="li"
                      className="bg-transparent border-0 mail d-flex align-items-start gap-5 py-2 px-1"
                      key={index}
                      style={{ flex: "0 0 40%", margin: "0 7px 0 0" }}
                    >
                      <Button
                        size="md"
                        variant="none"
                        style={{
                          width: "100%",
                          height: "auto",
                          padding: "10px",
                          margin: "0 auto",
                          borderRadius: 14,
                          display: "inline-block",
                          backgroundColor: "#fff",
                        }}
                        onClick={() => {
                          //Set folder hierarchy
                          let currentPath = folderPathHierarchy;
                          currentPath.push(item.id);
                          setFolderPathHierarchy(currentPath);
                          
                          getParentFolderGoogleDriveData(item.id);
                          setParentFolderShow(false);
                        }}
                      >
                        <p style={{ color: "#15172C", margin: 0, padding: 0 }}>
                          {item?.name}
                        </p>
                      </Button>
                    </ListGroup.Item>
                  );
                })}
              </>
            ) : (
              <div
                style={{
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <h6
                  style={{
                    color: "#FFF",
                    textAlign: "center",
                  }}
                >
                  No folders found
                </h6>
              </div>
            )}
            </>
          )
          : (
            <>
              {childrenFolderData?.length > 0 ? (
              <>
                {childrenFolderData?.map((item, index) => {
                  return (
                    <ListGroup.Item
                      as="li"
                      className="bg-transparent border-0 mail d-flex align-items-start gap-5 py-2 px-1"
                      key={index}
                      style={{ flex: "0 0 40%", margin: "0 7px 0 0" }}
                    >
                      <Button
                        size="md"
                        variant="none"
                        style={{
                          width: "100%",
                          height: "auto",
                          padding: "10px",
                          margin: "0 auto",
                          borderRadius: 14,
                          display: "inline-block",
                          backgroundColor: "#fff",
                        }}
                        onClick={() => {
                          //Set folder hierarchy
                          let currentPath = folderPathHierarchy;
                          currentPath.push(item.id);
                          setFolderPathHierarchy(currentPath);

                          getParentFolderGoogleDriveData(item.id);
                          setParentFolderShow(false);
                        }}
                      >
                        <p style={{ color: "#15172C", margin: 0, padding: 0 }}>
                          {item?.name}
                        </p>
                      </Button>
                    </ListGroup.Item>
                  );
                })}
              </>
            ) : (
              <div
                style={{
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <h6
                  style={{
                    color: "#FFF",
                    textAlign: "center",
                  }}
                >
                  No folders found
                </h6>
              </div>
            )}
            </>
          )
          }
          
        </ListGroup>
      </>
    </div>
  );
}

export default GoogleDriveFolder;
