const ArrowSelect = ({ options, onChange }) => {
  return (
    <div
      style={{
        backgroundColor: "var(--secondry-color)",
        width: "15px",
        height: "15px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 50,
        marginLeft: 3,
      }}
    >
      <select
        name="lead-status"
        id="lead-status"
        style={{
          border: "none",
          outline: "none",
          color: "white",
          backgroundColor: "transparent",
          cursor: "pointer"
        }}
        onChange={onChange}
      >
        {options.map((status, index) => {
          return (
            <option
              key={`${status} ${index}`}
              value={status}
              style={{ backgroundColor: "#1F243E" }}
            >
              {status}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export default ArrowSelect;
