import call from "./Images/call.png";
import message from "./Images/message.png";
import Container from 'react-bootstrap/Container';
import Navbar from './Navbar';

export default function Services() {

  // useLayoutEffect(()=>{
  //     window.scrollBy(0, 5000)
  // })

  return (<>
    <div className='main_hero_div' id="main">
      <Navbar />
      <div>
        <div className='d-flex justify-content-center career_main_div  ' style={{ marginTop: "85px" }} >
          <div className='border_div_career my-5'></div>
        </div>
        <div className='display-3 career_main_heading'>Service & Support</div>
        <div className='container-fluid '>

          <div style={{ height: "50vh" }} id='get'>
            <Container className=' container_div' >
              <h4 className='display-5 text-white text-center pt-3   pb-3' >
                <b className="">Get in touch</b> with us
              </h4>
              <div className='contact  mx-auto'>
                <div className='row'>
                  <div className='col-lg-6 col-md-6 col-sm-12 '>

                    <div className='d-flex flex-wrap gap-5  '>
                      <div className='d-flex  gap-2'>

                        <img src={call} alt="" style={{ width: "40px", height: "40px" }} />
                        <p className='contact_source'><b>+1 (845) 608 6705</b> (USA)</p>
                      </div>
                      {/* <div className='d-flex  gap-2'>
                        <img src={call} alt="" style={{ width: "40px", height: "40px" }} />
                        <p className='contact_source'><b>+49 (171) 831 4875</b> (Europe)</p>
                      </div> */}

                    </div>
                  </div>
                  <div className='col-lg-6 col-md-6 col-sm-12 spacing'>
                    <div className='d-flex flex-wrap gap-5  mt-2'>
                      <div className='d-flex  gap-2'>
                        <img src={message} alt="" style={{ width: "40px", height: "40px" }} />
                        <p className='contact_source'><b>liamjfitzgerald99@gmail.com</b> (CEO)</p>
                      </div>
                      <div className='d-flex  gap-2'>
                        <img src={message} alt="" style={{ width: "40px", height: "40px" }} />
                        <p className='contact_source'><b>connect2co.root@gmail.com</b> (Corporate)</p>
                      </div>


                    </div>
                  </div>
                </div>
              </div>
            </Container>
          </div>


          <div className='container-fluid ' id="intouch">


          </div>





        </div>

      </div>

    </div>

  </>

  )
}
