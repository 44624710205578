import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";

// import "../../src/Components/css/AddCard.css";
// import "../../src/Components/css/AddCard.css";
import "./index.css";
import { SimpleToast } from "../../utils/Toast";

import Sidebar from "../../Components/Sidebar";
import Header from "../../Components/Header";
import cardImage from "../../assets/images/creditCard.png";

import {
    getUserProfileAction,
    updateProfileAction,
    addCard,
  } from "../../redux/actions/AuthAction";

  import { useNavigate } from "react-router-dom";

  import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, CardNumberElement, CardExpiryElement, CardCvcElement, useStripe, useElements } from '@stripe/react-stripe-js';

const stripePromise = loadStripe('pk_test_51InwRQKATzDwMGDo6pIJXt9IJKrwt5oOUB1JMqmUBQ5sMdYjBtrkUjPO81dBbxdFCDV50m5S5JXgTqUJL8mLXtrr00Ncu1N0DV');

const CreditCardForm = ({ loggedInUserId }) => {
  const auth = useSelector((state) => state.Auth);
  
  const userId = auth?.profileData?.id;
  const [loading, setLoading] = useState(false);

 
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    console.log("Checking conditions on page load:", auth?.profileData);
  
    // Check conditions on page load
    if (auth?.profileData?.is_card_added != 0 && auth?.profileData?.is_free != 0) {
      console.log("Redirecting to /profile...");
      setTimeout(() => {
        navigate("/profile", { replace: true });
      });
    }
  }, [auth, navigate]);



  const [formattedNumber, setFormattedNumber] = useState('');
  const [name, setName] = useState('');
  const [expiry, setExpiry] = useState('');
  const [cvc, setCvc] = useState('');
  const [focus, setFocus] = useState('');

  const handleCardNumberChange = (e) => {
    const cardNumber = e.target.value.replace(/\D/g, '');
    let formatted = '';
    for (let i = 0; i < cardNumber.length; i++) {
      formatted += cardNumber[i];
      if (i % 4 === 3 && i < 15) {
        formatted += ' ';
      }
    }

    if (formatted.length <= 19) {
      setFormattedNumber(formatted);
    }
  };
// const handleCardNumberChange = (e) => {
//     const rawCardNumber = e.target.value;
//     const cardNumber = rawCardNumber.replace(/\D/g, ''); // Remove non-digit characters
  
//     console.log('Raw Card Number:', rawCardNumber);
//     console.log('Formatted Card Number:', cardNumber);
  
//     let formatted = '';
//     for (let i = 0; i < cardNumber.length; i++) {
//       formatted += cardNumber[i];
//       if (i % 4 === 3 && i < 15) {
//         formatted += ' ';
//       }
//     }
  
//     console.log('Final Card Number:', formatted);
  
//     if (formatted.length <= 19) {
//       setFormattedNumber(formatted);
//     }
//   };
  

  const handleKeyDown = (e) => {
    if (e.key === 'Backspace') {
      setFormattedNumber((prevFormatted) => prevFormatted.replace(/ $/, ''));
    }
  };

  const handleExpiryChange = (e) => {
    const expiry = e.target.value.replace(/\D/g, '');
    let formatted = expiry;
  
    if (expiry.length > 2) {
      formatted = `${expiry.slice(0, 2)}/${expiry.slice(2)}`;
    }
    console.log("formatted", formatted);
  
    if (formatted.length <= 7) {
      setExpiry(formatted);
    }
  };

  const handlePayment = async () => {
    setLoading(true);
    const cardElement = elements.getElement(CardElement);

    if (!stripe || !elements || !cardElement) {
      SimpleToast({
        title: "Error",
        text: "Fill card detail correctly.",
        iconName: "error",
        iconColor: "#d33",
      });
      // Stripe.js has not yet loaded.
      return;
    }


      console.log("cardElements", CardElement);
 
    

      try {
        try {
          const { token, error } = await stripe.createToken(cardElement);
      
          if (error) {
            // Handle specific error cases
            if (error.empty || error.code === 'incomplete_number') {
              SimpleToast({
                title: "Error",
                text: "Please enter a valid card number.",
                iconName: "error",
                iconColor: "#d33",
              });
            } else if (error.code === 'incomplete_expiry') {
              SimpleToast({
                title: "Error",
                text: "Please enter a valid card expiration date.",
                iconName: "error",
                iconColor: "#d33",
              });
            } else if (error.code === 'incomplete_cvc') {
              SimpleToast({
                title: "Error",
                text: "Please enter a valid CVC.",
                iconName: "error",
                iconColor: "#d33",
              });
            } else {
              // Handle other errors
              console.error(error);
              SimpleToast({
                title: "Error",
                text: "Card details are not filled correctly.",
                iconName: "error",
                iconColor: "#d33",
              });
            }
            return;
          }
          console.log("token", token);
          console.log("tokenId", token.id);

          const api = `https://thdavi53s6.execute-api.us-east-1.amazonaws.com/prod/addCard_prod`;
          // const api = `http://localhost:5000/add-card/`;
          const tokenId = token.id;
      
        const response = await axios.post(
          api,
          { tokenId, userId },
          {
            headers: {
              Authorization: window.localStorage.getItem("token"),
            },
          }
        );

      
          SimpleToast({
            title: "Success",
            text: "Card added successfully.",
            iconName: "success",
            iconColor: "green",
          });

          setTimeout(() => {
            navigate("/profile", { replace: true });
          }, 1500);


       
        
     
        

        return response;
      } catch (error) {
        SimpleToast({
          title: "Error",
          text: "Oops, something went wrong.",
          iconName: "error",
          iconColor: "#d33",
        });
        return "error";
      }
    } finally {
      setLoading(false);
    }
      // const data = await response.json();
    // }
  };

  return (
    <div className="d-flex">
    <Sidebar /> <Header />
      <div className="credit-card-form-container">
        {/* <div className="credit-card-container">
          <Cards number={formattedNumber} name={name} expiry={expiry} cvc={cvc} focused={focus} />
        </div> */}
       
         <div className="credit-card-container">
            {/* <img
              src={cardImage} // Replace with your demo card image URL
              alt="Demo Card"
            /> */}
          </div>

 
        <div className="form-container">
       
        <h2 style={{ color: '#fff' }}>Your Payment Information</h2>
  <p>Fill in the details below to proceed with your payment.</p>

          <br></br><br></br>

     
          <form style={{ width: '400px' }}>
          {/* <form style={{ width: '100%', maxWidth: '400px', margin: 'auto' }}> */}

          <label htmlFor="card-number">Card Details</label>

          <CardElement
          onFocus={() => setFocus('number')}
          onBlur={() => setFocus('')}
          options={{
            style: {
              base: {
                fontSize: '16px',
                color: '#424770',
                '::placeholder': {
                  color: '#aab7c4',
                },
                border: '1px solid black', // Add border
        borderRadius: '5px', // Add border-radius for rounded corners
        padding: '10px', // Add padding
        margin: '10px', // Add margin
              },
              invalid: {
                color: '#9e2146',
              },
            },
          }}
        />
       
       
         
           
            {/* <button type="button" onClick={handlePayment}>Add Card</button> */}
            <button
              disabled={loading || auth?.isUpdateProfileLoading}
              type="button"
             onClick={handlePayment}
             style={{ marginTop: '20px' }}
            >
              {loading || auth?.isUpdateProfileLoading ? (
                <div
                  className="spinner-border spinner-border-sm text-primary"
                  role="status"
                />
              ) : (
                "Add Card"
              )}
            </button>

          </form>
        </div>
      </div>
      
    </div>
  );
};

export default CreditCardForm;