import React, { useState, useEffect } from "react";

import { Button } from "react-bootstrap";
import Navbar from "./Navbar";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { verifyemail } from "../redux/actions/AuthAction";
import { SimpleToast } from "../utils/Toast";
import { Link,useSearchParams} from "react-router-dom";
function VerifyEmail() {
    const dispatch = useDispatch();


  const navigate = useNavigate();
  
  const [searchParams, setSearchParams] = useSearchParams();

  var userid = searchParams.get("id");
  var hash = searchParams.get("hash");
  
  if(!userid || !hash){
    navigate("/login");
  }
  
  useEffect(() => {
    const verifyEmail = async () => {
      if (userid && hash) {
        try {
          // Make the API call for email verification
          const response = await dispatch(verifyemail(userid,hash,));
          
          if (response?.status === 200) {
            // Email verification successful, redirect to login
            SimpleToast({
                title: "Success",
                text: "Email verified successfully.",
                iconName: "success",
                iconColor: "green",
              });
            navigate("/editprofile");
          } else {
            // Handle error in email verification
            console.error("Error in email verification");
          }
        } catch (error) {
          // Handle network error or other issues
          console.error("Error in email verification:", error);
        }
      } else {
        // Redirect to login if user or hash is missing
        // navigate("/login");
      }
    };

    verifyEmail();
  }, [navigate, userid, hash]);



  

  return (
    <div className="main_hero_div" id="main">
    

      <div className="container" id="sign_up_container">
        <div className="d-flex justify-content-center career_main_div">
          <div className="border_div_career my-5"></div>
        </div>

      

      </div>
    </div>
  );
}

export default VerifyEmail;
