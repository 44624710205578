import React, { useEffect, useState } from "react";
import Header from "../../Components/Header";
import Sidebar from "../../Components/Sidebar";

import "./index.css";
import {
  clearErrorMessageAction,
  deleteNotificationAction,
  getNotificationsAction,
  updateNotificationAction,
} from "../../redux/actions/DashboardAction";
import { useDispatch, useSelector } from "react-redux";
import { SimpleToast } from "../../utils/Toast";
import { updateProfileAction } from "../../redux/actions/AuthAction";
import NotificationCard from "../../Components/Notifications";
const Notification = () => {
  const [userIds, setUserIds] = useState([]);
  const userId = localStorage.getItem("userId");
  const dispatch = useDispatch();
  const notifications = useSelector((state) => state.getNotifications);
  const auth = useSelector((state) => state.Auth);
  const delNotifications = useSelector((state) => state.delNotifications);
  const updateNotifications = useSelector((state) => state.updateNotifications);

  useEffect(() => {
    dispatch(getNotificationsAction(userId));
  }, [
    dispatch,
    userId,
    delNotifications?.delNotificationData,
    auth?.updateProfileData,
    auth?.profileData,
    updateNotifications?.updateNotificationData,
  ]);
  useEffect(() => {
    if (
      notifications?.isNotificationError ||
      auth?.isUpdateProfileError ||
      auth?.isProfileError
    ) {
      SimpleToast({
        title: "Error",
        text: notifications?.notificationErrorMessage
          ? notifications?.notificationErrorMessage
          : "Oops, something went wrong.",
        iconName: "error",
        iconColor: "#d33",
      });
    }
    setTimeout(() => {
      dispatch(clearErrorMessageAction());
    }, 2000);
  }, [
    notifications?.isNotificationError,
    notifications?.notificationErrorMessage,

    auth?.isUpdateProfileError,
    auth?.isProfileError,
    delNotifications?.isDelNotificationError,
    dispatch,
  ]);

  const acceptNotification = async (item, dashboardLength) => {
    if (!item?.dashboard) return;
    if (dashboardLength + 1 === 6) {
      SimpleToast({
        title: "Warning",
        text: "You can not accept more then 5 dashboards",
        iconName: "warning",
      });
    } else {
      const response = await dispatch(
        updateProfileAction(userId, {
          [`dashboard${dashboardLength + 1}`]: item?.dashboard,
        })
      );
      if (response?.status === 200 && response?.data?.affectedRows === 1) {
        const data = {
          id: item?.id,
          updates: {
            status: "accepted",
          },
        };
        const res = await dispatch(updateNotificationAction(data));
        if (res === "error") {
          SimpleToast({
            title: "Error",
            text: "Oops, something went wrong.",
            iconName: "error",
            iconColor: "#d33",
          });
        }
      } else {
        SimpleToast({
          title: "Error",
          text: "Oops, something went wrong.",
          iconName: "error",
          iconColor: "#d33",
        });
      }
    }
  };
  useEffect(() => {
    let setUpdatedIds = [];
    for (let i = 1; i <= 5; i++) {
      const id = auth.profileData[`dashboard${i}`];
      if (id) {
        setUpdatedIds.push(id);
      }
    }
    for (let i = 0; i <= 5; i++) {
      if (setUpdatedIds.length) {
        setUserIds(setUpdatedIds);
      }
    }
  }, [
    notifications?.notificationData,
    auth?.profileData,
    delNotifications?.delNotificationData,
    auth?.updateProfileData,
  ]);
  // useEffect(() => {
  //   if (notifications?.notificationData?.data?.length === 0) {
  //     dispatch(clearDashboardData());
  //   }
  //   let dashboardIds = [];
  //   let setUpdatedIds = [];
  //   let notificationIds = [];
  //   for (let i = 0; i <= notifications?.notificationData?.data?.length; i++) {
  //     const id = notifications?.notificationData?.data[i]?.dashboard;
  //     const notificationId = notifications?.notificationData?.data[i];

  //     if (id) {
  //       dashboardIds.push(id);
  //       notificationIds.push(notificationId);
  //     }
  //     if (notificationIds.length) {
  //       setNotiId(notificationIds);
  //     }
  //   }

  //   for (let i = 1; i <= 5; i++) {
  //     const id = auth.profileData[`dashboard${i}`];
  //     if (id) {
  //       setUpdatedIds.push(id);
  //     }
  //   }
  //   for (let i = 0; i <= 5; i++) {
  //     if (setUpdatedIds.length) {
  //       setUserIds(setUpdatedIds);
  //     }
  //   }
  //   if (!dashboardIds.length) return;
  //   dispatch(getDashboardByNotifcation(dashboardIds));

  //   setTimeout(() => {
  //     dispatch(clearErrorMessageAction());
  //   }, 2000);
  // }, [
  //   dispatch,
  //   notifications?.notificationData,
  //   auth?.profileData,
  //   delNotifications?.delNotificationData,
  //   auth?.updateProfileData,
  // ]);

  const deleteNotification = async (id) => {
    const response = await dispatch(deleteNotificationAction(id));
    if (response?.status === 200 && response?.data?.affectedRows === 1) {
      SimpleToast({
        title: "Success",
        text: "Notification successfully deleted.",
        iconName: "success",
        iconColor: "green",
      });
    } else {
      SimpleToast({
        title: "Warning",
        text: "Notification not deleted.",
        iconName: "warning",
      });
    }
  };

  return (
    <div className="d-flex">
      <div>
        <Sidebar /> <Header />
      </div>
      <div className="notification_heading">
        <p>Notifications</p>
        {notifications?.isNotificationLoading ||
        // dashboard?.isGetDashboardLoading ||
        updateNotifications?.isUpdateNotificationLoading ||
        auth?.isUpdateProfileLoading ||
        delNotifications?.isDelNotificationLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <div
              className="spinner-border spinner-border text-white"
              role="status"
            />
          </div>
        ) : notifications?.notificationData?.data?.length ? (
          notifications?.notificationData?.data
            .sort((a, b) => b.datetime - a.datetime)
            .map((item, ind) => {
              return (
                <NotificationCard
                  key={ind}
                  item={item}
                  acceptNotification={(data) =>
                    acceptNotification(data, userIds.length)
                  }
                  deleteNotification={() => deleteNotification(item?.id)}
                />
              );
            })
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <p style={{ fontSize: 20, color: "white", opacity: 0.4 }}>
              You have no pending notifications.
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Notification;
