import React from 'react';
import Intouch from './Intouch';
import main from "./Images/main.png";
import delegate from "./Images/delegate.png";
import integration from "./Images/integration.png";
import id from "./Images/id.png";
import strategic from "./Images/strategic.png";
import dashboardExample from "./Images/dashboard-example.png"
import Footer from './Footer';
import Navbar from "./Navbar"


function Body() {
    return (<>
        <section>
            <div className='main_hero_div'>
                <Navbar />
                <div className='container'>
                    <div className='w-100' style={{marginTop: '133px', position: 'relative'}}>
                        <div className='border_div d-flex mx-auto'></div>
                    </div>
                    <div className='hero_section mx-auto' id="hero_section" style={{minHeight: '70vh'}}>
                        <div className='row justify-content-center align-items-center'>
                            <div className='col-lg-6 col-md-6 col-sm-12 mb-5'>
                                <div className='display-4' id='main_hero_heading'>
                                    Your Company.<br></br>  On <span className='primary_text'>one platform</span>.<br></br> Managed Strategically.<br></br> <span className='primary_text'>Your Way</span>.
                                </div>
                                <br></br><br></br>
                                <p className='sec2_line'>Revolutionize the way you do work</p>
                                <p className='sec3_line'>Connect2Co - The Ultimate Digital Workspace with limitless Project Management and Collaboration possibilities.</p>
                            </div>
                            <div className='col-lg-6 col-md-6 col-sm-12'>
                                <img src={main} alt="" style={{ width: "100%" }} />
                            </div>

                        </div>
                        {/* <div className='down_div'><HiOutlineArrowDown className='down_icon' /></div> */}
                    </div>
                </div>
            </div>


            {/* sec 2 */}
            <div className='container div_height' id='testing' style={{ "max-width": "1800px" }}>
                <div className='row  align-items-center body_row'>
                    <div className='col-lg-6 col-md-6 col-sm-12' id='comapany_pic'>
                        <div className='comapany_pic'>
                            <img src={dashboardExample} alt="" style={{ width: "100%" }} />
                        </div>
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-12'>
                        <div className='border-line'></div>
                        <h4 className="display-6 ">
                            Create your Workspace
                        </h4>
                        <p className='sec2_line'>Sign-up and fill out your information. Tell us about your company and desired use in the ‘Launchpad’ form.</p>
                    </div>
                </div>
            </div>
            {/* sec 3 */}
            <div className='container-fluid background_pic' >
                <div className='container div_height'>
                    <div className=' row   align-items-center body_row'  >
                        <div className='col-lg-6 col-md-6 col-sm-12'>
                            <div className='border-line1'></div>
                            <div>
                                <h4 className="display-6 delegate_hading">
                                    Create a Team
                                </h4>
                                <p className='sec3_line'>Create a new dashboard. This will be the home to your team. Open your dashboard and add the emails of your team members. They are now part of your workspace.</p>
                            </div>
                        </div>
                        <div className='col-lg-6 col-md-6 col-sm-12 delegate_pic' id="spacing_div" >
                            <img src={delegate} alt="" style={{ width: "80%" }} />
                        </div>
                    </div>
                </div>
            </div>
            {/* sec 4 */}
            <div className="container div_height" id='home_space'>
                <div className='row  align-items-center  body_row' >
                    <div className='col-lg-6 col-md-6 col-sm-12' id='spacing_div'>
                        <div className='d-flex justify-content-center'>
                            <img src={integration} alt="" style={{ width: "70%", height: "450px" }} />
                        </div>
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-12' id='spacing_div'>
                        <div className='border-line1'></div>
                        <div>
                            <h4 className="display-6 delegate_hading">
                                Integrations
                            </h4>
                            <p className='sec3_line'>Connect2Co is your command center that allows you to do all of your work from one central location.</p>
                            <p className='sec3_line'>Open settings and enter the connection portal.</p>
                            <p className='sec3_line'>From here you will be able to manage connections to external solutions.</p>
                            <p className='sec3_line'>Once connected, Connect2Co uses API’s to allow you to perform tasks how you normally would on the original solution, directly via Connect2Co.</p>
                        </div>
                    </div>
                </div>
            </div>
            {/* sec 5 */}
            <div className="background_pic" id='home_space'>
                <div className='container div_height'>
                    <div className='row  align-items-center body_row '  >
                        <div className='col-lg-6 col-md-6 col-sm-12' id='spacing_div'>
                            <div className='border-line1'></div>
                            <div>
                                <h4 className="display-6 delegate_hading">
                                    Individual Dashboard
                                </h4>
                                <p className='sec3_line'>Open your team’s dashboard and access your workspace.</p>
                                <p className='sec3_line'>Actionable Integrations: Quick view components that allow you to perform work on external solutions, directly on Connect2Co.</p>
                                <p className='sec3_line'>Productivity Component: Create ‘waiting’ or ‘ongoing’ tasks. Your integrations allow Connect2Co to automatically and historically log ‘completed’ tasks.</p>
                                <p className='sec3_line'>Collaboration Component: Simple drag-and-drop logic enables you to communicate progress with team members. Delegate ‘waiting’ and ‘ongoing’ work. Report ‘completed’ work.</p>

                            </div>
                        </div>
                        <div className='col-lg-6 col-md-6 col-sm-12 d-flex justify-content-center' id='spacing_div'>
                            <div className='company_pics'>
                                <img src={id} alt="" style={{ width: "100%" }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* sec 6 */}
            <div className='container div_height' style={{paddingTop: '0px'}}>
                <div className='row align-items-center div_height'  >
                    <div className='col-lg-6 col-md-6 col-sm-12' id='spacing_div'>
                        <img src={strategic} alt="" style={{ width: "100%" }} />
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-12' id='spacing_div'>
                        <div className='border-line1'></div>
                        <div>
                            <h4 className="display-6 delegate_hading">
                                Strategic Management
                            </h4>
                            <p className='sec3_line'>
                                AI enabled benefits empowering sustainable objectives, executive transparency, and company wide business optimization.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className='intouch_div_background'>
                <div style={{ height: "43vh" }}>
                    <Intouch />
                </div>
                <Footer />
            </div>
        </section>

    </>

    )
}

export default Body
