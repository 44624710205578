import axiosApiInstance from "../../utils/axiosInterceptor";
const TaskService = {
    openTask: (item) => {
        var url = "";
        if (item.integration_platform == 'Google Workspace') {
          switch (item.action_type) {
            case 'Send Email':
                  axiosApiInstance
                    .get(`https://gmail.googleapis.com/gmail/v1/users/me/messages/${item.unique_id}`)
                    .then((res) => {
                      console.log(res);
                      if(res.data.payload.headers != undefined){
                        for (let index = 0; index < res.data.payload.headers.length; index++) {
                          const element = res.data.payload.headers[index];
                          if(element.name == "Message-Id"){
                            url = 'https://mail.google.com/mail/u/0/#search/rfc822msgid:' + encodeURIComponent(element.value)
                            window.open(url, '_blank').focus();
                          }
                        }
                      }
                     
                    })
                    .catch((err) => {
                      
                    });          
              break;
            case 'Create Meeting':
              url = item.unique_id
              break;
            case 'Share File':
            case 'Create File':
              if(item.gw_file_type.indexOf('spreadsheet') !== -1){
                url = `https://docs.google.com/spreadsheets/d/${item.unique_id}/edit`
              } 
              else if(item.gw_file_type.indexOf('document') !== -1) {
                url = `https://docs.google.com/document/d/${item.unique_id}/edit`
              } 
              else{
                url = `https://docs.google.com/presentation/d/${item.unique_id}/edit`
              } 
             
              break;
            default:
              break;
          }
        } else if (item.integration_platform == 'Salesforce') {
          url = item.unique_id
        }else if(item.integration_platform == 'Manual') {
            if(item.link != undefined && item.link != ""){
              url = item.link;
            }
        }
        if (url) {
          window.open(url, '_blank').focus();
        }
      }
};

export default TaskService;