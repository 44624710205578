import React from 'react';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import logo from "./Images/logo.png";
import ScrollToTop from './ScrollToTop';


function Footer() {
    return (<>
        <section className='footer_section'>
            <div className='container'>
                <div className='row'>
                    <div className="col-lg-4 col-md-4 col-sm-12 ">
                        <div className="footer_logo">
                            <Link to="/"> <img src={logo} alt="" style={{ width: "200px", height: "50px" }} /></Link>

                        </div>

                        <div className="footer_button" >
                            <Link to="/service"> <Button className="Contact_btn">CONTACT US</Button></Link>


                        </div>
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-12">

                        <h6 className='footer_heading'>Company</h6>
                        <ul className="footer_link mb-2 mb-lg-0 gap-3 footer_link" style={{ listStyle: "none" }}>
                            <li className="nav-item">
                                <Link className="nav-link text-white mt-4" to="/about" onClick={<ScrollToTop/>}>About Us</Link>
                            </li>
                          
                            <li className="nav-item">
                                  {/* <ScrollToTop> */}
                                <Link className="nav-link text-white mt-4" to="/career"  >Careers</Link>
                                 {/* </ScrollToTop> */}
                            </li>
                           
                          
                            <li className="nav-item">
                                <Link className="nav-link text-white mt-4" to="/Corevalue" onClick={<ScrollToTop/>}>Values</Link>
                            </li>
                        </ul>

                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-12">
                        <h6 className='footer_heading'>Product</h6>
                        <ul className="footer_link mb-2 mb-lg-0 gap-3 footer_link" style={{ listStyle: "none" }}>
                            <li className="nav-item">
                                <Link className="nav-link text-white mt-4" to="/service" >Service & Support</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link text-white mt-4" to="/guide" onClick={<ScrollToTop/>}>Guide</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link text-white mt-4" to="/service" >Contact Us</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link text-white mt-4" to="/privacypolicy" >Privacy Policy</Link>
                            </li>
                        </ul>

                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12">
                        <h6 className='footer_heading' style={{ textAlign: "center" }}>Follow Us</h6>
                        <div className="d-flex justify-content-center gap-3">
                            <div className='div_icon mt-0 ' ><a href='https://www.linkedin.com/company/connect2co'><i className="fa-brands fa-linkedin-in icon"></i></a></div>
                            {/* <div className='div_icon mt-0' ><a href=''><i className="fa-brands fa-tiktok icon"></i></a></div> */}
                            <div className='div_icon mt-0' ><a href=' https://www.facebook.com/connect2co-110065278274879/?ref=page_internal'><i className="fa-brands fa-facebook-f icon"></i></a></div>

                            <div className='div_icon mt-0' ><a href='https://www.instagram.com/connect2co/'><i className="fa-brands fa-instagram icon"></i></a></div>


                        </div>
                    </div>
                </div>
            </div>
            <div className='footer-footer'>
                Connect2Co &copy; 2023
            </div>
        </section>
    </>

    )
}

export default Footer

