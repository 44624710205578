import { ActionTypes } from "../constants";

const initial_state = {
  //create dashbaord
  data: {},
  insertId: null,
  isCreateDashboardLoading: false,
  isCreateDashboardError: false,
  createDashboardErrorMessage: "",

  //get dashboard data
  getDashboardData: [],
  isGetDashboardLoading: false,
  isGetDashboardError: false,
  getDashboardErrorMessage: "",

  //delete dashboard data
  delDashboardData: {},
  isDelDashboardLoading: false,
  isDelDashboardError: false,
  delDashboardErrorMessage: "",

  //edit dashboard name

  editDashboardData: {},
  isEditDashboardLoading: false,
  isEditDashboardError: false,
  editDashboardErrorMessage: "",

  // get team data

  getTeamData: {},
  getTeamDataPending: {},
  isGetTeamLoading: false,
  isGetTeamError: false,
  getTeamErrorMessage: "",

  // invite team data

  inviteTeamData: {},
  isInviteTeamLoading: false,
  isInviteTeamError: false,
  inviteTeamErrorMessage: "",

  // get notification

  notificationData: {},
  isNotificationLoading: false,
  isNotificationError: false,
  notificationErrorMessage: "",
  notificationDashboardData: [],

  // delete notification

  delNotificationData: {},
  isDelNotificationLoading: false,
  isDelNotificationError: false,
  delNotificationErrorMessage: "",

  // update notification

  updateNotificationData: {},
  isUpdateNotificationLoading: false,
  isUpdateNotificationError: false,
  updateNotificationErrorMessage: "",
};

export const dashboardsReducer = (state = initial_state, action) => {
  switch (action.type) {
    //login
    case ActionTypes.CREATE_DASHBOARD_REQ:
      return {
        ...state,
        isCreateDashboardLoading: true,
        isCreateDashboardError: false,
      };

    case ActionTypes.CREATE_DASHBOARD_SUCCESS:
      return {
        ...state,
        isCreateDashboardLoading: false,
        getDashboardData: [...state.getDashboardData, action.payload],
        insertId: action.payload.id,
      };

    case ActionTypes.CREATE_DASHBOARD_FAIL:
      return {
        ...state,
        isCreateDashboardLoading: false,
        isCreateDashboardError: true,
        createDashboardErrorMessage: action.payload,
      };

    case ActionTypes.GET_DASHBOARDS_REQ:
      return {
        ...state,
        isGetDashboardLoading: true,
        isGetDashboardError: false,
      };

    case ActionTypes.GET_DASHBOARDS_SUCCESS:
      return {
        ...state,
        isGetDashboardLoading: false,
        getDashboardData: action.payload,
      };
    case ActionTypes.GET_DASHBOARDS_SETTINGS_SUCCESS:
      return {
        ...state,
        isGetDashboardLoading: false,
        getDashboardSettingsData: action.payload,
      };
    case ActionTypes.GET_DASHBOARDS_WITH_NOTIFICATION:
      return {
        ...state,
        isGetDashboardLoading: false,
        notificationDashboardData: action.payload,
      };

    case ActionTypes.GET_DASHBOARDS_FAIL:
      return {
        ...state,
        isGetDashboardLoading: false,
        isGetDashboardError: true,
        getDashboardErrorMessage: action.payload,
      };

    case ActionTypes.DELETE_DASHBOARDS_REQ:
      return {
        ...state,
        isDelDashboardLoading: true,
        isDelDashboardError: false,
      };

    case ActionTypes.DELETE_DASHBOARDS_SUCCESS:
      return {
        ...state,
        isDelDashboardLoading: false,
        delDashboardData: action.payload,
      };

    case ActionTypes.DELETE_DASHBOARDS_FAIL:
      return {
        ...state,
        isDelDashboardLoading: false,
        isDelDashboardError: true,
        delDashboardErrorMessage: action.payload,
      };

    case ActionTypes.EDIT_DASHBOARDS_REQ:
      return {
        ...state,
        isEditDashboardLoading: true,
        isEditDashboardError: false,
      };

    case ActionTypes.EDIT_DASHBOARDS_SUCCESS:
      return {
        ...state,
        isEditDashboardLoading: false,
        editDashboardData: action.payload,
      };

    case ActionTypes.EDIT_DASHBOARDS_FAIL:
      return {
        ...state,
        isEditDashboardLoading: false,
        isEditDashboardError: true,
        editDashboardErrorMessage: action.payload,
      };

    case ActionTypes.CELAR_ERROR_MESSAGE:
      return {
        ...state,
        isCreateDashboardError: false,
        isGetDashboardError: false,
        isDelDashboardError: false,
        isEditDashboardError: false,
        delDashboardData: {},
        editDashboardData: {},
      };
    case ActionTypes.CLEAR_DASHBOARD_DATA:
      return {
        ...state,
        getDashboardData: [],
      };
    default:
      return state;
  }
};

export const getTeamMemberReducer = (state = initial_state, action) => {
  switch (action.type) {
    case ActionTypes.GET_TEAM_REQ:
      return {
        ...state,
        isGetTeamLoading: true,
        isGetTeamError: false,
      };
    case ActionTypes.GET_TEAM_SUCCESS:
      return {
        ...state,
        isGetTeamLoading: false,
        getTeamData: action.payload,
        getTeamDataPending: action.payload_pending
      };
    case ActionTypes.GET_TEAM_FAIL:
      return {
        ...state,
        isGetTeamLoading: false,
        isGetTeamError: true,
        getTeamErrorMessage: action.payload,
      };
    case ActionTypes.CELAR_ERROR_MESSAGE:
      return {
        ...state,
        isGetTeamError: false,
      };
    default:
      return state;
  }
};

export const inviteTeamMembersReducer = (state = initial_state, action) => {
  switch (action.type) {
    case ActionTypes.INVITE_MEMBERS_REQ:
      return {
        ...state,
        isInviteTeamLoading: true,
        isInviteTeamError: false,
      };
    case ActionTypes.INVITE_MEMBERS_SUCCESS:
      return {
        ...state,
        isInviteTeamLoading: false,
        inviteTeamData: action.payload,
      };
    case ActionTypes.INVITE_MEMBERS_FAIL:
      return {
        ...state,
        isInviteTeamLoading: false,
        isInviteTeamError: true,
        inviteTeamErrorMessage: action.payload,
      };
    case ActionTypes.CELAR_ERROR_MESSAGE:
      return {
        ...state,
        isInviteTeamError: false,
      };
    default:
      return state;
  }
};

export const getNotificationReducer = (state = initial_state, action) => {
  switch (action.type) {
    case ActionTypes.GET_NOTIFICATION_REQ:
      return {
        ...state,
        isNotificationLoading: true,
        isNotificationError: false,
      };
    case ActionTypes.GET_NOTIFICATION_SUCCESS:
      return {
        ...state,
        isNotificationLoading: false,
        notificationData: action.payload,
      };
    case ActionTypes.GET_NOTIFICATION_FAIL:
      return {
        ...state,
        isNotificationLoading: false,
        isNotificationError: true,
        notificationErrorMessage: action.payload,
      };
    case ActionTypes.CELAR_ERROR_MESSAGE:
      return {
        ...state,
        isNotificationError: false,
      };
    default:
      return state;
  }
};

export const delNotificationReducer = (state = initial_state, action) => {
  switch (action.type) {
    case ActionTypes.DEL_NOTIFICATION_REQ:
      return {
        ...state,
        isDelNotificationLoading: true,
        isDelNotificationError: false,
      };
    case ActionTypes.DEL_NOTIFICATION_SUCCESS:
      return {
        ...state,
        isDelNotificationLoading: false,
        delNotificationData: action.payload,
      };
    case ActionTypes.DEL_NOTIFICATION_FAIL:
      return {
        ...state,
        isDelNotificationLoading: false,
        isDelNotificationError: true,
        delNotificationErrorMessage: action.payload,
      };
    case ActionTypes.CELAR_ERROR_MESSAGE:
      return {
        ...state,
        isDelNotificationError: false,
      };
    default:
      return state;
  }
};

export const updateNotificationReducer = (state = initial_state, action) => {
  switch (action.type) {
    case ActionTypes.UPDATE_NOTIFICATION_REQ:
      return {
        ...state,
        isUpdateNotificationLoading: true,
        isUpdateNotificationError: false,
      };
    case ActionTypes.UPDATE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        isUpdateNotificationLoading: false,
        updateNotificationData: action.payload,
      };
    case ActionTypes.UPDATE_NOTIFICATION_FAIL:
      return {
        ...state,
        isUpdatelNotificationLoading: false,
        isUpdatelNotificationError: true,
        updateNotificationErrorMessage: action.payload,
      };
    case ActionTypes.CELAR_ERROR_MESSAGE:
      return {
        ...state,
        isUpdateNotificationError: false,
      };
    default:
      return state;
  }
};
