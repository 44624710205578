export const ActionTypes = {
  // Signup
  SIGNUP_REQ: "SIGNUP_REQ",
  SIGNUP_SUCCESS: "SIGNUP_SUCCESS",
  SIGNUP_FAIL: "SIGNUP_FAIL",
  //login

  SIGNIN_REQ: "SIGNIN_REQ",
  SIGNIN_SUCCESS: "SIGNIN_SUCCESS",
  SIGNIN_FAIL: "SIGNIN_FAIL",
  //user signup

  USER_SIGNUP_REQ: "USER_SIGNUP_REQ",

  USER_SIGNUP_SUCCESS: "USER_SIGNUP_SUCCESS",
  USER_SIGNUP_FAIL: "USER_SIGNUP_FAIL",
  // GET PROFILE
  GET_PROFILE_REQ: "GET_PROFILE_REQ",
  GET_PROFILE_SUCCESS: "GET_PROFILE_SUCCESS",
  GET_PROFILE_FAIL: "GET_PROFILE_FAIL",

  // Create Dashboard
  CREATE_DASHBOARD_REQ: "CREATE_DASHBOARD_REQ",
  CREATE_DASHBOARD_SUCCESS: "CREATE_DASHBOARD_SUCCESS",
  CREATE_DASHBOARD_FAIL: "CREATE_DASHBOARD_FAIL",

  //update user profile
  UPDATE_PROFILE_REQ: "UPDATE_PROFILE_REQ",
  UPDATE_PROFILE_SUCCESS: "UPDATE_PROFILE_SUCCESS",
  UPDATE_PROFILE_FAIL: "UPDATE_PROFILE_FAIL",
  UPDATE_OTHER_MEMBER: "UPDATE_OTHER_MEMBER",

  //get Dashboaords
  GET_DASHBOARDS_REQ: "GET_DASHBOARDS_REQ",
  GET_DASHBOARDS_SUCCESS: "GET_DASHBOARDS_SUCCESS",
  GET_DASHBOARDS_SETTINGS_SUCCESS: "GET_DASHBOARDS_SETTINGS_SUCCESS",
  GET_DASHBOARDS_FAIL: "GET_DASHBOARDS_FAIL",

  //delelte dashboard
  DELETE_DASHBOARDS_REQ: "DELETE_DASHBOARDS_REQ",
  DELETE_DASHBOARDS_SUCCESS: "DELETE_DASHBOARDS_SUCCESS",
  DELETE_DASHBOARDS_FAIL: "DELETE_DASHBOARDS_FAIL",

  //edit dashboard
  EDIT_DASHBOARDS_REQ: "EDIT_DASHBOARDS_REQ",
  EDIT_DASHBOARDS_SUCCESS: "EDIT_DASHBOARDS_SUCCESS",
  EDIT_DASHBOARDS_FAIL: "EDIT_DASHBOARDS_FAIL",

  //get team members
  GET_TEAM_REQ: "GET_TEAM_REQ",
  GET_TEAM_SUCCESS: "GET_TEAM_SUCCESS",
  GET_TEAM_FAIL: "GET_TEAM_FAIL",

  //invite member

  INVITE_MEMBERS_REQ: "INVITE_MEMBERS_REQ",
  INVITE_MEMBERS_SUCCESS: "INVITE_MEMBERS_SUCCESS",
  INVITE_MEMBERS_FAIL: "INVITE_MEMBERS_FAIL",

  //get notification
  GET_NOTIFICATION_REQ: "GET_NOTIFICATION_REQ",
  GET_NOTIFICATION_SUCCESS: "GET_NOTIFICATION_SUCCESS",
  GET_NOTIFICATION_FAIL: "GET_NOTIFICATION_FAIL",
  GET_DASHBOARDS_WITH_NOTIFICATION: "GET_DASHBOARDS_WITH_NOTIFICATION",

  //del notification
  DEL_NOTIFICATION_REQ: "DEL_NOTIFICATION_REQ",
  DEL_NOTIFICATION_SUCCESS: "DEL_NOTIFICATION_SUCCESS",
  DEL_NOTIFICATION_FAIL: "DEL_NOTIFICATION_FAIL",
  //update notification

  UPDATE_NOTIFICATION_REQ: "UPDATE_NOTIFICATION_REQ",
  UPDATE_NOTIFICATION_SUCCESS: "UPDATE_NOTIFICATION_SUCCESS",
  UPDATE_NOTIFICATION_FAIL: "UPDATE_NOTIFICATION_FAIL",
  // insertSalesforce

  INSERT_SF_REQ: "INSERT_SF_REQ",
  INSERT_SF_SUCCESS: "INSERT_SF_SUCCESS",
  INSERT_SF_FAIL: "INSERT_SF_FAIL",

  // getSalesforce

  GET_SF_REQ: "GET_SF_REQ",
  GET_SF_SUCCESS: "GET_SF_SUCCESS",
  GET_SF_FAIL: "GET_SF_FAIL",
  //clear error message
  CELAR_ERROR_MESSAGE: "CELAR_ERROR_MESSAGE",
  //logout handle
  LOGOUT_REQUEST: "LOGOUT_REQUEST",

  //clear dashboard
  CLEAR_DASHBOARD_DATA: "CLEAR_DASHBOARD_DATA",
};
