import axios from "axios";
import { formatQuery } from "./helpers";

const config = {
  headers:{
    'x-api-key': process.env.REACT_APP_C2C_PROD_AWS_ACCESS_KEY
  }
};

export const SalesforceLogin = async (body) => {
  try {
    const response = await axios.post(
      "https://16ouvqtl44.execute-api.us-east-1.amazonaws.com/default/salesforce_auth",
      body,
      config
    );

    if (response.data) {
      localStorage.setItem(
        "salesforceAccessToken",
        JSON.parse(response.data.body).accessToken
      );
      return JSON.parse(response.data.body);
    }
  } catch (error) {
    console.error(error);
  }
};
