import axios from "axios";
import { ActionTypes } from "../constants";
import { tasksApi } from "../../utils/api";

const config = {
  headers:{
    'x-api-key': process.env.REACT_APP_C2C_PROD_AWS_ACCESS_KEY
  }
};

//create dashboard
export const logExternalIntegrationTask = (data) => async (dispatch) => {
  const api = `${tasksApi}/historicalLogTasks`;
  try {
    const response = await axios.post(api, data, config);
    return response;
  } catch (error) {
      console.log("ERROR")
  }
};