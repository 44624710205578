import React from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";

import Button from "react-bootstrap/Button";
import Navbar from "./Navbar";
import Footerabout from "./Footerabout";

function TermsConditions() {
	const currentDate = new Date().toLocaleDateString("en-US", {
		year: "numeric",
		month: "long",
		day: "numeric",
	});
	return (
		<>
			<div className="main_hero_div" style={{ height: "1000px" }}>
				<Navbar />
				<div className="d-flex justify-content-center  " style={{ marginTop: "85px" }}>
					<div className="border_div_career my-5"></div>
				</div>
				<Container id="guide_container">
					<h4 className="display-5 guide_heading mb-4">Connect2Co User Agreement</h4>
					<p className="text-white display-3 guide_lines mt-5" id="guide-lin">
					This User Agreement ("Agreement") is made and entered into as of ‘Effective Date’ by and
between Connect2Co, Inc., ("Supplier") and the "Customer" also known as the entity
responsible for creating this account and making the payment for the services Connect2Co
offers, who will be utilizing the Connect2Co platform. The supplier and customer are
collectively referred to as the "Parties." The referred to ‘Effective Date’ is represented as the
date at which the user / company / customer receives their password and login information
to gain access to the Connect2Co platform.

						<br />
						<br />
						WHEREAS, the Supplier has developed a digital workspace software / web application that it
makes available for customers to integrate with their systems over the Internet;

						<br />
						<br />
						WHEREAS, the Customer wants to use the Supplier’s applications in its company
operations; and
						<br />
						<br />
						WHEREAS, the Supplier agrees to provide said applications to the Customer who agrees to
use and pay for Supplier’s services, which are subject to the following terms and conditions:

					</p>
					<h5 className="display-6 guide_heading mb-4">1. Definitions</h5>
					<p className="text-white display-3 guide_lines mt-5" id="guide-lin">
						<b>Services:</b> Subscription services provided by the Supplier accessible at connect2co.com.
						<br />
						<br />
						<b>Resources:</b> Documentation and support materials available at connect2co.com for using the
Services as well as (potentially) other resources sent to the customer.

						<br />
						<br />
						<b>User Subscriptions:</b> Authorized access to the Services and Documentation granted to
Customer's employees and authorized users.

						<br />
						<br />
						<b>Software:</b> Online software applications provided by the Supplier through the Services.
Support Services Policy: Support provided by the Supplier in relation to the Services.

						<br />
						
					</p>

                <h5 className='display-6 guide_heading mb-4'>2. User Subscriptions</h5>
                <p className="text-white display-3 guide_lines mt-5" id="guide-lin">
                The Customer is granted a non-transferable, non-exclusive right to permit Authorized Users
to use the Services and Documentation solely for internal business operations unless
expressly indicated to authorized representatives at Connect2Co, Inc. The Customer agrees:
 <br /><br />
 Not to exceed the maximum number of Authorized Users based on the purchased User
Subscriptions.
<br /><br />
<br/><br/>Not to use more than one Authorized User per User Subscription.
<br/><br/>
Not transmit or distribute any Viruses, or anything that could be considered harmful,
threatening, offensive, harassing, obscene, illegal, sexually explicit, violent, discriminatory, or
a direct encouragement to hurt a person or a person’s property.

Due to this clause, the Supplier reserves the right to remove and/or disable the Customer’s
access to any material if the Customer willingly breaks or desires to break said clause.



                </p>

                <h5 className='display-6 guide_heading mb-4'>3. Additional User Subscriptions</h5>
                <p className="text-white display-3 guide_lines mt-5" id="guide-lin">
                During the Subscription Term, the Customer may purchase additional User Subscriptions by
requesting them in writing to the Supplier or through a request via an email to the email:
support@connect2co.com. Additional User Subscriptions shall be subject to the fees
outlined in "Fees and service term."

                </p>

                <h5 className='display-6 guide_heading mb-4'>4. Fees and Service Term</h5>
                <p className="text-white display-3 guide_lines mt-5" id="guide-lin">
                The subscription fees range in accordance with our pricing models available at
connect2co.com. The duration of free trials vary depending on the pricing model tier
selected.

 <br /> <br />
 Additional User Subscriptions can be purchased at the amount reflected on connect2co.com
or indicated by an authorized Connect2Co representative. Additional Integrations /
Customizations may require additional charges as agreed upon in writing. Customers will
purchase 1 business license which will include user licenses in accordance with the tiered
pricing model selections.<br /> <br />
Additional users requested that do not push users towards the threshold of the higher priced
license type, will be able to request additional user licenses through the above-mentioned
mediums with payment amounts being subject to the aforementioned amounts (reflected at
connect2co.com).
                </p>

                <h5 className='display-6 guide_heading mb-4'>5. Subscription Term</h5>
                <p className="text-white display-3 guide_lines mt-5" id="guide-lin">
                The initial subscription term starts on the ‘Effective Date’ and expires at the date suggested
by viewing the duration for the chosen pricing model (ie. selecting the ‘Free Trial’ model
comes with 3 months free and expires at the end of the 3rd month). The end date is also
known as the ‘Expiration Date.’ After sales support will commence on the ‘Effective Date’ to
ensure a smooth user experience. If the user has chosen a pricing model OTHER than the
Free Trial and does not cancel before their ‘Expiration Date,’ the user’s subscription model
will renew for the term outlined in the initially chosen pricing model (tier). If the user HAS
chosen the ‘Free Trial,’ and does not cancel before their ‘Expiration Date,’ their license
subscription will automatically be upgraded to the next tier pricing model to ensure their
information and platform setup is maintained. In other words, at the point of expiration, their
subscription will renew with the subsequent pricing model tier.



                </p>

                <h5 className='display-6 guide_heading mb-4'>6. Customer Data</h5>
                <p className="text-white display-3 guide_lines mt-5" id="guide-lin">
                The Customer affirms that Customer Data will not violate any intellectual property rights or
laws. The Customer agrees to create daily backup copies of data and acknowledges that the
Supplier is not liable for any data loss.



                </p>

                <h5 className='display-6 guide_heading mb-4'>7. Support Services</h5>
                <p className="text-white display-3 guide_lines mt-5" id="guide-lin">
                Support Services will be provided by the Supplier during Normal Business Hours. The
Customer may use the support center to request or receive Support Services. Support
inquiries should be addressed via emails to support@connect2co.com.




                </p>

					<h5 className="display-6 guide_heading mb-4">8. Charges and Payments</h5>
					<p className="text-white display-3 guide_lines mt-5" id="guide-lin">
					The Supplier, utilizing Stripe as the integrated payment processor, will generate payment
receipts for the Charges to the Customer prior to the start of the service term. Payments are
due immediately upon accepting these terms and recur at the frequency selected in the
customer’s chosen pricing model. In the event of late payments, interest at the rate of 2%
per month will be charged.
					</p>

                <h5 className='display-6 guide_heading mb-4'>9. Proprietary Rights</h5>
                <p className="text-white display-3 guide_lines mt-5" id="guide-lin">
                No intellectual property rights are transferred between Parties. The Supplier owns all
Services and Documentation.






                </p>

					<h5 className="display-6 guide_heading mb-4">10. Limitation of Liability</h5>
					<p className="text-white display-3 guide_lines mt-5" id="guide-lin">
						Nothing in this Agreement will:
						<br />
						<br />
						1. limit any liability for personal injury or death resulting from negligence;
						<br />
						2. put parties liable for any loss of business, contracts, or opportunities;
						<br />
						3. limit any liability for fraudulent misrepresentation;
						<br />
						4. put parties responsible for any loss of savings or profits;
						<br />
						5.  limit any liabilities not allowed under related laws; or
						<br />
						6. prohibit any liabilities that may not be excluded under applicable law.
					</p>

					<h5 className="display-6 guide_heading mb-4">11. Terms and Termination</h5>
					<p className="text-white display-3 guide_lines mt-5" id="guide-lin">
					Either Party may terminate this Agreement with at least 15 days' written notice. Immediate
termination may occur for breach of contract terms. If a customer requests to terminate this
agreement, they must provide written notice at least 15 days prior to the start of the
subsequent month to avoid being billed for that month. For instance, if the customer submits
a termination request on or before the 14th of July, they will not be billed for August, and
their access will be terminated at the end of July. However, if the request is received after the
15th of July but before the 1st of August, the customer will still be billed for August and will
have access until September 1st, at which point their subscription will be terminated.

					</p>

                <h5 className='display-6 guide_heading mb-4'>12. General Terms</h5>
                <p className="text-white display-3 guide_lines mt-5" id="guide-lin">
                No waiver of breach is allowed without written consent. Severability applies if any provision
is deemed unlawful. This Agreement constitutes the entire understanding between the
Parties.
<br/><br/>

Governing Law <br/><br/>
This Agreement shall be governed by and construed in accordance with the laws of
Delaware. Exclusive jurisdiction is conferred upon the courts of Delaware for any claim or
dispute arising from this Agreement.
  <br/><br/>
  By selecting the box that asks the user to accept these terms, the Parties acknowledge and
agree to the terms of this Agreement:<br/><br/>

Supplier: Connect2Co, Inc. CEO, Liam Fitzgerald <br/>
Address: 1007 North Orange Street, 4th flr. Wilmington, DE 19801 <br/>
Email: liamjf@connect2co.com <br/>
Date: {currentDate} {/* Display the current date here */}





                </p>
                <br/><br/>

               
            </Container>
            <Footerabout />

        </div>
    </>
    )
}

export default TermsConditions;
