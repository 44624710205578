import axios from "axios";
import { useFormik } from "formik";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import leadsIcon from "../assets/images/leads-icon.png";
import { logExternalIntegrationTask } from "../redux/actions/TasksAction";
import {
  salesforceLeadsSources,
  salesforceSalutations,
} from "../utils/constant";
import ArrowSelect from "./ArrowSelect";
import Input from "./Input";
import moment from "moment";
import { SimpleToast } from "../utils/Toast";
import { formatQuery } from "../utils/helpers";

axios.defaults.headers.post["Content-Type"] = "application/json";

const EditContact = ({ dashboardId, contactId, isCreateMode = false }) => {
  const [contact, setContact] = useState();
  const SFData = useSelector((state) => state.getSFData);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const auth = useSelector((state) => state.Auth);
  const [state, setState] = useState({
    contactId: contactId || "",
    isCreateMode: isCreateMode || false,
  });
  const baseUrl = useMemo(
    () => SFData?.getSFData?.data?.[0]?.url,
    [SFData?.getSFData?.data]
  );

  const getContactById = useCallback(async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/services/data/v57.0/sobjects/Contact/${state.contactId}`
      );

      if (response.data) {
        setContact(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  }, [state.contactId, baseUrl]);

  const getAccountName = useCallback(async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/services/data/v57.0/query?q=${formatQuery(
          `Select Name from Account where Id = '${contact?.AccountId}'`
        )}`
      );

      if (response.data) {
        setContact((prev) => ({
          ...prev,
          AccountName: response.data.records?.[0].Name || "",
        }));
      }
    } catch (error) {
      console.error(error);
    }
  }, [baseUrl, contact?.AccountId]);

  useEffect(() => {
    if (!state.isCreateMode) {
      if (state.contactId) {
        getContactById().finally(() => getAccountName());
      }
    }
  }, [state.contactId, getContactById, state.isCreateMode, getAccountName]);

  const checkEvents = (payload) => {
    const events = [];
    const eventData = {
      unique_id: `${baseUrl}/${contact.Id}`,
      user_id: auth?.profileData.id,
      dashboard_id: dashboardId,
      description: `${auth.profileData.first_name} changed 1 Contact name`,
      integration_platform: "Salesforce",
      status: "Completed",
      action_type: "Contact name changed",
      created_datetime: moment().format(),
    };
    if (payload.Name !== contact.Name) {
      events.push({ ...eventData });
    }

    return events;
  };

  const updateContact = async (payload) => {
    const salesforceLoginAccessToken = localStorage?.getItem(
      "salesforceAccessToken"
    );
    delete payload.AccountName;
    delete payload.Number;
    try {
      const response = await axios.patch(
        `${baseUrl}/services/data/v57.0/sobjects/Contact/${state.contactId}`,
        payload,
        {
          headers: {
            "Access-Control-Allow-Methods": "PATCH",
            Authorization: `Bearer ${salesforceLoginAccessToken}`,
            "Access-Control-Allow-Headers": "*",
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      if (response.status >= 200 && response.status <= 300) {
        SimpleToast({
          title: "Success",
          text: "Contact updated successfully.",
          iconName: "success",
          iconColor: "green",
        });
        const eventData = checkEvents(payload);
        if (eventData) {
          eventData.forEach(async (event) => {
            await dispatch(logExternalIntegrationTask(event));
          });
        }
        getContactById().finally(() => getAccountName());
      }
    } catch (error) {
      console.error(error);
    }
  };

  const createContact = async (payload) => {
    const salesforceLoginAccessToken = localStorage?.getItem(
      "salesforceAccessToken"
    );
    delete payload.AccountName;
    delete payload.Number;
    try {
      const response = await axios.post(
        `${baseUrl}/services/data/v57.0/sobjects/Contact`,
        payload,
        {
          headers: {
            "Access-Control-Allow-Methods": "POST",
            Authorization: `Bearer ${salesforceLoginAccessToken}`,
            "Access-Control-Allow-Headers": "*",
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      if (response.data) {
        SimpleToast({
          title: "Success",
          text: "Contact created successfully.",
          iconName: "success",
          iconColor: "green",
        });
        const eventData = {
          unique_id: `${baseUrl}/${response.data.id}`,
          user_id: auth?.profileData.id,
          dashboard_id: dashboardId,
          description: `${auth.profileData.first_name} created 1 Contact`,
          integration_platform: "Salesforce",
          status: "Completed",
          action_type: "Create Contact",
          created_datetime: moment().format(),
        };
        await dispatch(logExternalIntegrationTask(eventData));

        setState({ contactId: response.data.id, isCreateMode: false });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const initialValues = {
    Salutation: contact?.Salutation || "",
    LeadSource: contact?.LeadSource || "",
    FirstName: contact?.FirstName || "",
    LastName: contact?.LastName || "",
    AccountName: contact?.AccountName || "",
    Title: contact?.Title || "",
    Department: contact?.Department || "",
    ReportsToId: contact?.ReportsToId || "",
    Number: contact?.Number || "",
    Email: contact?.Email || "",
    AssistantName: contact?.AssistantName || "",
    AssistantPhone: contact?.AssistantPhone || "",
  };

  const validate = (values) => {
    const errors = {};
    if (!values.LastName) {
      errors.LastName = "Required";
    }
    return errors;
  };

  const handleSubmit = async (e) => {
    const payload = { ...e };

    setIsLoading(true);

    if (state.isCreateMode) {
      await createContact(payload);
    } else {
      await updateContact(payload);
    }
    setIsLoading(false);
  };

  const formik = useFormik({
    initialValues,
    validate,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  const isFormikValid = useMemo(() => {
    return !formik.errors.LastName;
  }, [formik.errors]);

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            flexDirection: "row",
            display: "flex",
            alignItems: "center",
          }}
        >
          <div
            style={{
              backgroundColor: "var(--bg-color)",
              borderRadius: 7,
              padding: 10,
            }}
          >
            <img
              src={leadsIcon}
              style={{
                width: 20,
                height: 20,
                objectFit: "contain",
              }}
              alt={"lead-icon"}
            />
          </div>
          <div style={{ marginLeft: 10 }}>
            <p
              style={{
                color: "#FFF",
                padding: 0,
                margin: 0,
                fontSize: 12,
              }}
            >
              Contacts
            </p>
            <p
              style={{
                color: "grey",
                padding: 0,
                margin: 0,
                fontSize: 12,
              }}
            >
              Owner: Lf
            </p>
          </div>
        </div>
        <div
          style={{
            cursor: "pointer",
            padding: 10,
          }}
        ></div>
      </div>

      <div style={{ marginTop: "2vh" }}>
        <div style={{ display: "flex", flexFlow: "row nowrap" }}>
          <div style={{ flexBasis: "50%" }}>
            <div style={{ display: "flex", alignItems: "center", gap: "2px" }}>
              <p
                style={{ color: "grey", fontSize: "14px", marginBottom: "0px" }}
              >
                Salutation
              </p>
              <ArrowSelect
                options={salesforceSalutations}
                onChange={(e) =>
                  formik.setFieldValue("Salutation", e.target.value)
                }
              />
            </div>
            <p
              style={{ color: "white", fontSize: "16px", marginBottom: "0px" }}
            >
              {formik.values.Salutation ?? "-"}
            </p>
          </div>
          <div style={{ flexBasis: "50%" }}>
            <div style={{ display: "flex", alignItems: "center", gap: "2px" }}>
              <p
                style={{ color: "grey", fontSize: "14px", marginBottom: "0px" }}
              >
                Lead Source
              </p>
              <ArrowSelect
                options={salesforceLeadsSources}
                onChange={(e) =>
                  formik.setFieldValue("LeadSource", e.target.value)
                }
              />
            </div>
            <p
              style={{ color: "white", fontSize: "16px", marginBottom: "0px" }}
            >
              {formik.values.LeadSource ?? "-"}
            </p>
          </div>
        </div>

        <div style={{ display: "flex", flexFlow: "row nowrap" }}>
          <div
            style={{
              display: "flex",
              flexFlow: "column wrap",
              flexBasis: "50%",
            }}
          >
            <p style={{ color: "grey", fontSize: "14px", marginBottom: "0px" }}>
              First Name
            </p>
            <Input
              value={formik.values.FirstName}
              onChange={(e) =>
                formik.setFieldValue("FirstName", e.target.value)
              }
            />
          </div>
          <div
            style={{
              display: "flex",
              flexFlow: "column wrap",
              flexBasis: "50%",
            }}
          >
            <p style={{ color: "grey", fontSize: "14px", marginBottom: "0px" }}>
              Account Number
            </p>
            <Input
              value={formik.values.Number}
              onChange={(e) => formik.setFieldValue("Number", e.target.value)}
            />
          </div>
        </div>

        <div style={{ display: "flex", flexFlow: "row nowrap" }}>
          <div
            style={{
              display: "flex",
              flexFlow: "column wrap",
              flexBasis: "50%",
            }}
          >
            <p style={{ color: "grey", fontSize: "14px", marginBottom: "0px" }}>
              Last Name *
            </p>
            <Input
              value={formik.values.LastName}
              onChange={(e) => formik.setFieldValue("LastName", e.target.value)}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexFlow: "column wrap",
              flexBasis: "50%",
            }}
          >
            <p style={{ color: "grey", fontSize: "14px", marginBottom: "0px" }}>
              Email
            </p>
            <Input
              value={formik.values.Email}
              onChange={(e) => formik.setFieldValue("Email", e.target.value)}
            />
          </div>
        </div>

        <div style={{ display: "flex", flexFlow: "row nowrap" }}>
          <div
            style={{
              display: "flex",
              flexFlow: "column wrap",
              flexBasis: "50%",
            }}
          >
            <p style={{ color: "grey", fontSize: "14px", marginBottom: "0px" }}>
              Account Name
            </p>
            <Input
              value={formik.values.AccountName}
              onChange={(e) =>
                formik.setFieldValue("AccountName", e.target.value)
              }
            />
          </div>
          <div style={{ flexBasis: "50%" }}>
            <p style={{ color: "grey", fontSize: "14px", marginBottom: "0px" }}>
              Assistant
            </p>
            <Input
              value={formik.values.AssistantName}
              onChange={(e) =>
                formik.setFieldValue("AssistantName", e.target.value)
              }
            />
          </div>
        </div>

        <div style={{ display: "flex", flexFlow: "row nowrap" }}>
          <div
            style={{
              display: "flex",
              flexFlow: "column wrap",
              flexBasis: "50%",
            }}
          >
            <p style={{ color: "grey", fontSize: "14px", marginBottom: "0px" }}>
              Title
            </p>
            <Input
              value={formik.values.Title}
              onChange={(e) => formik.setFieldValue("Title", e.target.value)}
            />
          </div>
          <div style={{ flexBasis: "50%" }}>
            <p style={{ color: "grey", fontSize: "14px", marginBottom: "0px" }}>
              Assistant Phone
            </p>
            <Input
              value={formik.values.AssistantPhone}
              onChange={(e) =>
                formik.setFieldValue("AssistantPhone", e.target.value)
              }
            />
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexFlow: "row nowrap",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexFlow: "column wrap",
              flexBasis: "50%",
            }}
          >
            <p style={{ color: "grey", fontSize: "14px", marginBottom: "0px" }}>
              Department
            </p>
            <Input
              value={formik.values.Department}
              onChange={(e) =>
                formik.setFieldValue("Department", e.target.value)
              }
            />
          </div>
          <div style={{ flexBasis: "50%" }}>
            {isLoading ? (
              <div className="h-50 w-100 d-flex justify-content-center align-items-center">
                <div
                  className="spinner-border spinner-border-md text-primary"
                  role="status"
                />
              </div>
            ) : (
              <button
                style={{
                  backgroundColor: isFormikValid ? "#238FDB" : "grey",
                  padding: "0.5rem",
                  paddingBottom: "0.25rem",
                  paddingTop: "0.25rem",
                  border: "none",
                  borderRadius: "5px",
                  color: "whitesmoke",
                }}
                type="submit"
                onClick={formik.handleSubmit}
                disabled={!isFormikValid}
              >
                Save
              </button>
            )}
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexFlow: "column wrap",
            flexBasis: "50%",
          }}
        >
          <p style={{ color: "grey", fontSize: "14px", marginBottom: "0px" }}>
            Reports to Lead Source
          </p>
          <Input
            value={formik.values.ReportsToId}
            onChange={(e) =>
              formik.setFieldValue("ReportsToId", e.target.value)
            }
          />
        </div>
      </div>
    </div>
  );
};

export default EditContact;
