import react, {useState} from "react";
import Navbar from "./Navbar";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import {BsEmojiSmile} from "react-icons/bs"
import Swal from "sweetalert2";


function Career() {
  const navigate = useNavigate();
  const [data, setData] = useState({})
  const changeHandler = (e) => {
    setData(b => {
      return {...b, [e.target.name]: e.target.value}
    })
  }

  const fillOutFields = (field_name) => {
    Swal.fire({
      icon: 'error',
      title: 'Error',
      text: `Please Fill out ${field_name}`,
    })
  }
  const FormSubmit = (e) => {
    e.preventDefault();
    var allData = Object.keys(data)
    if (!allData.includes('first_name')) {
      fillOutFields('First Name')
    }else if (!allData.includes('last_name')) {
      fillOutFields('Last Name')
    }else if (!allData.includes('email')) {
      fillOutFields('Email')
    }else if (!allData.includes('position')) {
      fillOutFields('Position')
    }else if (!allData.includes('position_title')) {
      fillOutFields('Position Title')
    }else if (!allData.includes('linkedin')) {
      fillOutFields('Linkedin')
    }else{
      fetch('/django/post/career/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
        .then(response => response.json())
        .then((data) => {
          if (data.type === 'error') {
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Oops, something went wrong.',
            })
          }else{
            setData({})
            Swal.fire({
              icon: 'success',
              title: 'Success',
              text: 'Your data has been saved successfully',
            })
          }
        })
        .catch((error) => {
          console.log('Error:', error, JSON.stringify(error));
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Oops, something went wrong.',
          })
        });
      }
    
  };
  return (<>
      <div className='main_hero_div' id="main">
            <Navbar />

            <form className='container' style={{ marginTop: "85px" }} onSubmit={FormSubmit}>
                <div className='d-flex justify-content-center career_main_div  ' >
                    <div className='border_div_career my-5'></div>
                </div>

                <h5 className='display-5 careers_main_headingline mb-4'>Careers
                </h5>
                <p className="text-white text-center  display-3 guide_lines" style={{fontSize:"22px"}}>Fill out your information & we'll get in touch  <BsEmojiSmile className='icons'/>  </p>
               
                {/* <i class="fa-regular fa-face-smile"></i> */}
                {/* <div className='d-flex justify-content-center'> <Link to="/signup"><Button className="ms-3 sign_up">Sign Up</Button></Link></div> */}
                <div className='d-flex gap-4 flex-wrap justify-content-center'>
                    <div className='input_label'>
                        <label className='label'>First Name</label><br></br>
                        <input type="text" name="first_name" onChange={changeHandler} />
                    </div>
                    <div className='input_label'>
                        <label className='label'>Last Name</label><br></br>
                        <input type="text" name="last_name" onChange={changeHandler} />
                    </div>
                </div>

                <div className='d-flex gap-4 flex-wrap justify-content-center mt-3'>
                    <div className='input_label'>
                        <label className='label'>E-mail</label><br></br>
                        <input type="email" name="email" onChange={changeHandler} />
                    </div>
                    <div className='input_label'>
                        <label className='label'>Position</label><br></br>
                        <select className="ps-1" name="position" onChange={changeHandler}>
                          
                        <option className='option_border' selected hidden>Type</option>
                          
                            <option className='option_border'>Full-Time</option>
                            <option className='backgound_option'>Part-Time</option>
                            <option >Internship</option>
                            
                        </select>

                    </div>
                </div>

                <div className='d-flex gap-4 flex-wrap justify-content-center mt-3'>
                    <div className='input_label'>
                        <label className='label'>Position Title</label><br></br>
                        <input type="text" name="position_title" onChange={changeHandler} />
                        
                    </div>
                    <div className='input_label'>
                        {/* <label className='label'>Position Title</label><br></br>
                        <input type="text" /> */}
                    </div>
                </div>
                <div className=' d-flex gap-4 flex-wrap justify-content-center mt-3'>
                    <div className='linkin_div'>
                        <label className='label'>LinkedIn</label><br></br>
                        <input type="text" className='linkin_input' name="linkedin" onChange={changeHandler} />
                    </div>

                </div>
                <div className='d-flex justify-content-center mt-5'><Button type="submit" className="sign_up">SUBMIT</Button></div>
            </form>
        </div>
 

  </>

  )
}

export default Career

