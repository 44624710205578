import React, { useEffect, useRef, useState } from "react";
import "./index.css";
import { useNavigate,createSearchParams } from "react-router-dom";
import profile from "../../assets/images/defaultAvatar.png";
import { useDispatch, useSelector } from "react-redux";
import {
  clearErrorMessageAction,
  getDashboards,
  getDashboardUserSettings
} from "../../redux/actions/DashboardAction";
import { updateProfileAction } from "../../redux/actions/AuthAction";
import { deletePendingUserFromDashAction } from "../../redux/actions/DashboardAction";
import { SimpleToast, UpdatedToast } from "../../utils/Toast";
import Delete from "../../assets/images/delete.png";

export const TeamCard = ({
  img1,
  img2,
  path,
  name,
  ind,
  setDel,
  del,
  id,
  openEditModal,
  editLoader,
  openInviteModal,
  inviteTeamLoader,
  searchMemberLoad,
}) => {
  const navigate = useNavigate();
  const auth = useSelector((state) => state.Auth);
  const dispatch = useDispatch();

  const getDash = async () => {
    sessionStorage.setItem("dashboardId",id);
    navigate({
      pathname: path,
      search: createSearchParams({ id: id }).toString(),
    }, {
      state: { dashboardName: name, dashboardId: id, index: ind + 1 },
    });
    await dispatch(getDashboardUserSettings(auth?.profileData.id, id));
  };

  return (
    <div
      className="myTeamCard"
      key={ind}
      // onClick={() => {
      //   navigate(path, {
      //     state: { dashboardName: name, dashboardId: id, index: ind + 1 },
      //   });
      // }}
    >
      {editLoader || inviteTeamLoader || searchMemberLoad ? (
        <div>
          <div
            className="spinner-border spinner-border text-white"
            role="status"
          />
        </div>
      ) : Object.keys(auth?.profileData).length &&
        auth?.profileData?.is_admin === 1 ? (
        <>
          {/* <p onClick={() => {
              navigate(path, {
                state: { dashboardName: name, dashboardId: id, index: ind + 1 },
              });
          }}>{name}</p> */}
          <p onClick={() => {getDash()}}>{name}</p>

          <img onClick={openInviteModal} src={img1} alt="img1" />
          <img onClick={openEditModal} src={img2} alt="img2" />
          { del !== undefined && <img onClick={() => setDel(!del)} src={Delete} /> }
        </>
      ) : (
        <p onClick={() => {getDash()}}>{name}</p>
      )}
    </div>
  );
};

export const TeamList = ({
  id,
  personImg,
  fName,
  lName,
  designation,
  dashboardId,
  item,
  editImg,
  editUser,
  setEditUser,
}) => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.Auth);
  const navigate = useNavigate();
  const cardRef = useRef(null);
  const deleteMemberHandle = async () => {
    const dashboardIds = {};
    const previousDashboards = [];

    for (let i = 1; i <= 5; i++) {
      const id = item?.[`dashboard${i}`];

      if (id && id !== dashboardId) {
        previousDashboards.push(id);
      }
    }

    for (let i = 0; i < 5; i++) {
      if (previousDashboards[i]) {
        dashboardIds[`dashboard${i + 1}`] = previousDashboards[i];
      } else {
        dashboardIds[`dashboard${i + 1}`] = null;
      }
    }

    const data = await dispatch(updateProfileAction(id, dashboardIds));
    if (data?.status === 200) {
      SimpleToast({
        title: "Success",
        text: "User successfully deleted.",
        iconName: "success",
        iconColor: "green",
      });

      clearErrorMessageAction();
      await dispatch(getDashboards(dashboardIds));
      navigate("/profile");
    }
  };

  const deleteMemberHandle2 = async () => {   // create method to delete notification from notifications table
    const data = await dispatch(deletePendingUserFromDashAction(item?.id, dashboardId));
    if (data?.status === 200) {
      SimpleToast({
        title: "Success",
        text: "User successfully deleted.",
        iconName: "success",
        iconColor: "green",
      });

      // clearErrorMessageAction();
      // await dispatch(getDashboards(dashboardIds));
      navigate("/profile");
    }
  };
  const deleteHandleConfirm = () => {
    console.log('test')
    UpdatedToast({
      text: `Are you sure that you want to delete ${fName} ${lName} from this dashboard?`,
      iconName: "warning",
      iconColor: "#d33",
      confirmBtnText: "Yes, delete it!",
      preConfirm: item?.invitiation_status !== "pending" ? deleteMemberHandle : deleteMemberHandle2,
      input: false,
    });
  };
  useEffect(() => {
    if (auth?.isUpdateProfileError) {
      SimpleToast({
        title: "Error",
        text: "Oops, something went wrong.",
        iconName: "error",
        iconColor: "#d33",
      });
    }

    setTimeout(() => {
      dispatch(clearErrorMessageAction());
    }, 2000);
  }, [auth?.isUpdateProfileError, dispatch]);
  return (
    <div className="teamlistCard" key={id} ref={cardRef}>
      <div className="teamlistPersonImgContainer">
        <img src={personImg ? personImg : profile} alt="profile" />
      </div>
      {item?.last_login_datetime ? (
        item?.invitiation_status !== "pending" ? (
          <div className="teamlistTextContainer">
            <p>
              {fName} {lName}
            </p>
            <p>{designation}</p>
          </div>
        ) : (
          <div className="teamlistTextContainer">
            <p>{item?.email}</p>
            <p>Pending acceptance</p>
          </div>
        )
      ) : (
        <div className="teamlistTextContainer">
          <p>{item?.email}</p>
          <p>Created by admin</p>
        </div>
      )}

      {Object.keys(auth?.profileData).length &&
      auth?.profileData?.is_admin === 1 ? (
        <div className="teamlistEditImgContainer">
          <img
            onClick={(e) => {
              if (editUser === id) {
                setEditUser(null);
              } else {
                setEditUser(id);
              }
            }}
            src={editImg}
            alt="editPerson"
          />
        </div>
      ) : null}

      {editUser === id ? (
        <div style={{ marginBottom: 30 }}>
          {auth?.isUpdateProfileLoading ? (
            <div
              style={{
                backgroundColor: "var(--primary-color",
                padding: 8,
                borderRadius: 8,
                left: 280,
                position: "absolute",
              }}
            >
              <div
                className="spinner-border-sm spinner-border text-white"
                role="status"
              />
            </div>
          ) : (
            <div onClick={deleteHandleConfirm} className="teamlistDelBtn">
              Delete?
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
};
