import editIcon from "../assets/images/edit-colored.png";
import { useSelector } from "react-redux";
import { useMemo } from "react";

const LeadsTable = ({ leads, setItem, pinnedProd }) => {
  const SFData = useSelector((state) => state.getSFData);
  const baseUrl = useMemo(
    () => SFData?.getSFData?.data?.[0]?.url,
    [SFData?.getSFData?.data]
  );

  const redirect = (id) => {
    const url = `${baseUrl}/lightning/r/Lead/${id}/view`;
    window.open(url);
  };

  return (
    <table style={{ color: "grey", tableLayout: "fixed", width: "100%" }}>
      <thead style={{ fontSize: "12px" }}>
        <th
          style={{
            width: "calc(100% / 25)",
          }}
        ></th>
        <th
          style={{
            width: "calc(100% / 3.5)",
            paddingLeft: "10px",
          }}
        >
          Name
        </th>
        <th
          style={{
            width: "calc(100% / 10)",
            paddingLeft: "10px",
          }}
        >
          Title
        </th>
        { pinnedProd ? null :
          <th
            style={{
              width: "calc(100% / 5.5)",
              paddingLeft: "10px",
            }}
          >
            Email
          </th>
        }
        { pinnedProd ? null :
          <th
            style={{
              width: "calc(100% / 7.5)",
              paddingLeft: "10px",
            }}
          >
            Company
          </th>
        }
        { pinnedProd ? null :
          <th
            style={{
              width: "calc(100% / 7.5)",
              paddingLeft: "10px",
            }}
          >
            Lead Status
          </th>
        }
      </thead>
      <tbody style={{ color: "white", fontSize: "14px" }}>
        {leads?.records?.map((lead, index) => {
          return (
            <tr key={lead.Id}>
              <td
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  width: "calc(100% / 25)",
                  paddingLeft: "10px",
                  paddingTop: "3px",
                  paddingBottom: "3px",
                }}
              >
                {index + 1}
              </td>
              <td
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  width: "calc(100% / 3.5)",
                  paddingLeft: "10px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexFlow: "row nowrap",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <p
                    style={{
                      flexBasis: "80%",
                      marginBottom: "0px",
                      overflow: "hidden",
                      cursor: "pointer",
                    }}
                    onClick={() => redirect(lead.Id)}
                  >
                    {lead.Name}
                  </p>
                  <img
                    src={editIcon}
                    alt="edit"
                    style={{
                      height: "20px",
                      width: "20px",
                      marginLeft: "5px",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      setItem({
                        id: lead.Id,
                        type: "lead",
                        isCreateMode: false,
                      })
                    }
                  />
                </div>
              </td>
              <td
                style={{
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  width: "calc(100% / 10)",
                  paddingLeft: "10px",
                }}
              >
                {lead.Title}
              </td>
              { pinnedProd ? null :
                <td
                  style={{
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    width: "calc(100% / 5.5)",
                    paddingLeft: "10px",
                  }}
                >
                  {lead.Email}
                </td>
              }
              { pinnedProd ? null :
                <td
                  style={{
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    width: "calc(100% / 7.5)",
                    paddingLeft: "10px",
                  }}
                >
                  {lead.Company}
                </td>
              }
              { pinnedProd ? null :
                <td
                  style={{
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    width: "calc(100% / 7.5)",
                    paddingLeft: "10px",
                  }}
                >
                  {lead.Status}
                </td>
              }
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default LeadsTable;
