import React, { useEffect } from "react";
import { Button } from "react-bootstrap";
import PLusIcon from "../../../assets/images/simpleplus.png";
import IconMapExpand from "../../../assets/images/Icon-map-expand.png";
import avatarIcon from "../../../assets/images/defaultAvatar.png";
import GoogleDriveFiles from "./GoogleDriveFiles";
import GoogleDriveFolder from "./GoogleDriveFolder";
import { useState } from "react";
import { SimpleToast, UpdatedToast } from "../../../utils/Toast";
import axiosApiInstance from "../../../utils/axiosInterceptor";
import { logExternalIntegrationTask } from "../../../redux/actions/TasksAction";
import { useDispatch, useSelector } from "react-redux";
import moment from 'moment';

function GoogleDrive({ setActive, expand, pinnedCollab, pinnedProd, dashboardId, hasGoogleToken}) {
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("All Items");
  const [addNewLoading, setAddNewLoading] = useState(false);
  const [googleFolderIds, setGoogleFolderIds] = useState([]);
  const [filesData, setFilesData] = useState([]);
  const [childrenFileData, setChildrenFileData] = useState([]);   //Children file data nested inside of parent folder.
  const [childrenFolderData, setChildrenFolderData] = useState([]);   //Children folder data nested inside of parent folder.
  const [parentFolderShow, setParentFolderShow] = useState(true);
  const [maxResult, setMaxResult] = useState(50);
  const [nextPageTokens, setNextPageTokens] = useState();
  const [isFileCreated,setIsFileCreated] = useState(false);
  const [folderPathHierarchy, setFolderPathHierarchy] = useState([]);

  const dispatch = useDispatch();
  const auth = useSelector((state) => state.Auth);

const createFiles = async (name,mimeType,folderId) => {
    try{
      const data= {
        name,
        mimeType,
        parents: [folderId],
      }
      setIsLoading(true);

      const file = await axiosApiInstance.post('https://www.googleapis.com/drive/v3/files',data)  
      if(file){
        const fileData = {
          "user_id": auth?.profileData.id,
          "dashboard_id": dashboardId,
          "unique_id": file.data.id,
          "description": `${auth?.profileData.first_name} created ${file.data.name}`,
          "integration_platform": "Google Workspace",
          "status": "Completed",
          "action_type": "Create File",
          "created_datetime": moment().format(),
          "gw_file_name": file.data.name,
          "gw_file_type": file.data.mimeType
        }
        const resp = await dispatch(logExternalIntegrationTask(fileData));
        console.log(resp)
      }  

      setIsFileCreated(true)
      if(isFileCreated) setIsFileCreated(false)
      setIsLoading(false);
      // console.log(`Created new sheet with ID: ${file}`);
      SimpleToast({
        title: "Success",
        text: "Successfully created file!",
        iconName: "success",
        iconColor: "green",
      });

    }
    catch(err){
      setIsLoading(false)
    }
    
  }
  
  const openUploadFilesModalHandle = (type) => {
    const currentFolderId = folderPathHierarchy[folderPathHierarchy.length - 1];

    UpdatedToast({
      text: `Create a file`,
      iconName: "info",
      iconColor: "#add8e6",
      inputPlaceholder:'Enter the file name',
      confirmBtnText:'Submit',
      preConfirm: (value) => {
        createFiles(value,type, currentFolderId);
      },
      input:'text'
    });
  };

  const getGoogleDriveData = async () => {
    setIsLoading(true);
    await axiosApiInstance
      .get(`https://www.googleapis.com/drive/v3/files?pageSize=${maxResult}`, {
        params: {
          q: "(mimeType='application/vnd.google-apps.document' or mimeType='application/vnd.google-apps.spreadsheet' or mimeType='application/vnd.google-apps.presentation') and trashed=false",
          fields: "nextPageToken, files(id, name, kind, mimeType)",
        },
      })
      .then((response) => {
        const nextTokens = response.data.nextPageToken;
        const fileData = response.data.files;
        setNextPageTokens(nextTokens);

        setFilesData(fileData);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        SimpleToast({
          title: "Error",
          text: `${err.message}`,
          iconName: "error",
          iconColor: "#d33",
        });
      });
  };

  const backOneLevel = async () => {
    folderPathHierarchy.pop();
    if(folderPathHierarchy.length === 1){
      setParentFolderShow(true);
    }
    else{
      await getParentFolderGoogleDriveData(folderPathHierarchy[folderPathHierarchy.length - 1]);
    }
  };

  const getParentFolderGoogleDriveData = async (folderId) => {
    setIsLoading(true);
    await axiosApiInstance
      .get(
        `https://www.googleapis.com/drive/v3/files?q='${folderId}'+in+parents`
      )
      .then((res) => {
        const fileData = res.data.files.filter(
          (file) =>
            file.mimeType === "application/vnd.google-apps.spreadsheet" ||
            file.mimeType === "application/vnd.google-apps.document" ||
            file.mimeType === "application/vnd.google-apps.presentation"
        );
        const folderData = res.data.files.filter(
          (file) =>
            file.mimeType === "application/vnd.google-apps.folder"
        );
        
        setChildrenFileData(fileData);
        setChildrenFolderData(folderData);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        SimpleToast({
          title: "Error",
          text: `${err.message}`,
          iconName: "error",
          iconColor: "#d33",
        });
      });
  };

  const getAllDriveData = async () => {
    setActiveTab("All Items");
    getGoogleDriveData();
    getFolderGoogleDriveData();
  };

  const getRootGoogleDriveData = async () => {
    setParentFolderShow(true);

    //Set folder hierarchy
    let currentPath = [];
    currentPath.push("My Drive");
    setFolderPathHierarchy(currentPath);
    
    setActiveTab("My Drive");
    setIsLoading(true);
    await axiosApiInstance
      .get(`https://www.googleapis.com/drive/v3/files?pageSize=${maxResult}`, {
        params: {
          q: "'root' in parents and trashed=false",
          fields: "nextPageToken, files(id, name, kind, mimeType)",
        },
      })
      .then((res) => {
        const nextTokens = res.data.nextPageToken;
        setNextPageTokens(nextTokens);
        const fileData = res.data.files.filter(
          (file) =>
            file.mimeType === "application/vnd.google-apps.spreadsheet" ||
            file.mimeType === "application/vnd.google-apps.document" ||
            file.mimeType === "application/vnd.google-apps.presentation"
            // file.mimeType === "application/vnd.google-apps.presentation" ||
            // file.mimeType === "application/vnd.google-apps.shortcut"
        );
        const folderData = res.data.files.filter(
          (file) =>
            file.mimeType === "application/vnd.google-apps.folder"
        );
        
        setFilesData(fileData);
        setGoogleFolderIds(folderData);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        SimpleToast({
          title: "Error",
          text: `${err.message}`,
          iconName: "error",
          iconColor: "#d33",
        });
      });
  };

  const getSharedGoogleDriveData = async () => {
    setParentFolderShow(true);

    //Set folder hierarchy
    let currentPath = [];
    currentPath.push("Shared With Me");
    setFolderPathHierarchy(currentPath);

    setActiveTab("Shared With Me");
    setIsLoading(true);
    await axiosApiInstance
      .get(`https://www.googleapis.com/drive/v3/files?pageSize=${maxResult}`, {
        params: {
          q: "sharedWithMe=true and trashed=false",
          fields: "nextPageToken, files(id, name, kind, mimeType)",
        },
      })
      .then((res) => {
        const nextTokens = res.data.nextPageToken;
        setNextPageTokens(nextTokens);
        const fileData = res.data.files.filter(
          (file) =>
            file.mimeType === "application/vnd.google-apps.spreadsheet" ||
            file.mimeType === "application/vnd.google-apps.document" ||
            file.mimeType === "application/vnd.google-apps.presentation"
            // file.mimeType === "application/vnd.google-apps.presentation" ||
            // file.mimeType === "application/vnd.google-apps.shortcut"
        );
        const folderData = res.data.files.filter(
          (file) =>
            file.mimeType === "application/vnd.google-apps.folder"
        );
        
        setFilesData(fileData);
        setGoogleFolderIds(folderData);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        SimpleToast({
          title: "Error",
          text: `${err.message}`,
          iconName: "error",
          iconColor: "#d33",
        });
      });
  };
  
  const getFolderGoogleDriveData = async () => {
    setParentFolderShow(true);

    //Set folder hierarchy
    let currentPath = [];
    currentPath.push("All Files");
    setFolderPathHierarchy(currentPath);

    setIsLoading(true);
    await axiosApiInstance
      .get(`https://www.googleapis.com/drive/v3/files`, {
        params: {
          q: "mimeType='application/vnd.google-apps.folder' and trashed=false",
          fields: "nextPageToken, files(id, name, kind, mimeType)"
        },
      })
      .then(async (response) => {
        let foldData = response.data.files;
        setGoogleFolderIds(foldData);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        SimpleToast({
          title: "Error",
          text: `${err.message}`,
          iconName: "error",
          iconColor: "#d33",
        });
      });
  };

  const uploadGoogleFileData = async (file) => {
    setAddNewLoading(true);
    const form = new FormData();
    form.append(
      "metadata",
      new Blob(
        [
          JSON.stringify({
            name: file.name,
            mimeType: file.type,
          }),
        ],
        { type: "application/json" }
      )
    );
    form.append("file", file);

    await axiosApiInstance
      .post(
        `https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart`,
        form
      )
      .then((response) => {
        SimpleToast({
          title: "Success",
          text: "File successfully upload.",
          iconName: "success",
          iconColor: "green",
        });
        setAddNewLoading(false);
      })
      .catch((err) => {
        setAddNewLoading(false);
        SimpleToast({
          title: "Error",
          text: `${err.message}`,
          iconName: "error",
          iconColor: "#d33",
        });
      });
  };

  const shareGoogleFileData = async (email, id) => {
    var emails = email.split(",");
    setIsLoading(true);

    const apiRequest = async (fileID, emailAddress) => {
      const requestBody = {
        role: "writer",
        type: "user",
        value: emailAddress.trim(),
      };

      const res = await axiosApiInstance.post(
        `https://www.googleapis.com/drive/v2/files/${fileID}/permissions/`,
        requestBody
      );
      return res;
    };

    try {
      const apiRequests = emails.map((emailAddress) =>
        apiRequest(id, emailAddress)
      );
      const responses = await Promise.all(apiRequests);
      responses.forEach((response) => {
        if (response?.data) {
          SimpleToast({
            title: "Success",
            text: "File successfully Shared.",
            iconName: "success",
            iconColor: "green",
          });
          setIsLoading(false);
        } else {
          setIsLoading(false);
          return;
        }
      });
    } catch (err) {
      setIsLoading(false);
      SimpleToast({
        title: "Error",
        text: `${err.response.data.error.message}`,
        iconName: "error",
        iconColor: "#d33",
      });
    }
  };

  useEffect(() => {
    if(hasGoogleToken) getAllDriveData();
  }, [isFileCreated]);

  return (
    <div className="google-drive">
      <div className="d-flex justify-content-between align-items-center gap-3">
        <Button
          size="md"
          variant="none"
          className="poppins"
          onClick={() => setActive(0)}
          style={{
            width: "140px",
            height: "40px",
            backgroundColor: "var(--secondry-color)",
            borderRadius: 10
          }}
        >
          <p style={{ color: "#FFF", margin: 0, padding: 0 }}>Google Drive</p>
        </Button>
        <img
          style={{ width: 20, height: 20, objectFit: "contain", cursor: "pointer"}}
          alt={"add-icon"}
          src={IconMapExpand}
          onClick={() => expand(true)}
          hidden={pinnedCollab || pinnedProd ? false : true}
        />
      </div>
      <div className="d-flex justify-content-between align-items-center gap-3 mt-3">
        <div style={{display:'flex',justifyContent:'space-between'}}>
        <Button
            size="sm"
            variant="none"
            className="poppins"
            onClick={() => getAllDriveData()}         
            style={{
              backgroundColor: activeTab === "All Items" ? "var(--secondry-color)" : "#15172c",
              borderRadius: 10
            }}
          >
            <p style={{ color: "#FFF", margin: 0, padding: 0 }}>All Items</p>
          </Button>
          <Button
            size="sm"
            variant="none"
            className="poppins"
            onClick={() => getRootGoogleDriveData()}
            style={{
              backgroundColor: activeTab === "My Drive" ? "var(--secondry-color)" : "#15172c",
              borderRadius: 10,
              marginLeft: 7
            }}
          >
            <p style={{ color: "#FFF", margin: 0, padding: 0 }}>My Drive</p>
          </Button>
          <Button
            size="sm"
            variant="none"
            className="poppins"
            onClick={() => getSharedGoogleDriveData()}
            style={{
              backgroundColor: activeTab === "Shared With Me" ? "var(--secondry-color)" : "#15172c",
              borderRadius: 10,
              marginLeft: 7
            }}
          >
            <p style={{ color: "#FFF", margin: 0, padding: 0 }}>Shared With Me</p>
          </Button>
        </div>
        <div style={{display:'flex',justifyContent:'space-between'}}>
          <div><button onClick={() => openUploadFilesModalHandle('application/vnd.google-apps.document')} style={{width:"40px",height:"40px",borderRadius:'11px',background:"#79C8FF",color:'white'}}>GD</button></div>
          <div><button onClick={() => openUploadFilesModalHandle('application/vnd.google-apps.spreadsheet')} style={{width:"40px",height:"40px",borderRadius:'11px',background:"#00CC7E",color:'white'}}>GS</button></div>
          <div><button onClick={() => openUploadFilesModalHandle('application/vnd.google-apps.presentation')} style={{width:"40px",height:"40px",borderRadius:'11px',background:"#FFD800",color:'white'}}>GS</button></div>          
        </div>
      </div>
      <div className="user"><div className="mt-3 d-flex justify-content-center"></div></div>

      <div className="goolge-sheets-list">
        {isLoading ? (
          <div className="h-50 w-100 d-flex justify-content-center align-items-center">
            <div
              className="spinner-border spinner-border-md text-primary"
              role="status"
            />
          </div>
        ) : (
          <>
            <GoogleDriveFolder
              googleFolderIds={googleFolderIds}
              childrenFolderData={childrenFolderData}
              parentFolderShow={parentFolderShow}
              setParentFolderShow={setParentFolderShow}
              getParentFolderGoogleDriveData={getParentFolderGoogleDriveData}
              folderPathHierarchy={folderPathHierarchy}
              setFolderPathHierarchy={setFolderPathHierarchy}
            />
            <GoogleDriveFiles
              filesData={filesData}
              parentFolderShow={parentFolderShow}
              nextPageTokens={nextPageTokens}
              setMaxResult={setMaxResult}
              maxResult={maxResult}
              shareGoogleFileData={shareGoogleFileData}
              getGoogleDriveData={getGoogleDriveData}
              childrenFileData={childrenFileData}
              setParentFolderShow={setParentFolderShow}
              backOneLevel={backOneLevel}
            />
          </>
        )
        }
      </div>
    </div>
  );
}

export default GoogleDrive;
