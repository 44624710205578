import React from 'react';
import {Link} from "react-router-dom";
import Container from 'react-bootstrap/Container';

import Button from 'react-bootstrap/Button';
import  Navbar  from './Navbar';

function Guide() {
  return (<>
   <div className='main_hero_div' style={{ height: "1000px" }}>
      <Navbar/>
    <div className='d-flex justify-content-center  ' style={{marginTop:"85px"}} >
            <div className='border_div_career my-5'></div>
          </div>
          <Container id="guide_container">
              <h4 className='display-5 guide_heading mb-4'>Guide (Coming Soon)
              </h4>
              <p className="text-white   display-3 guide_lines mt-5" id="guide-lin">     A guide to help you get the most out of the Connect2Co platform...<br></br>
                Best practices, tips & tricks for onboarding & general use
              </p>
             




            </Container>
            </div>
  
  
  </>
    
  )
}

export default Guide
