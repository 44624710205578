import { useEffect } from "react";
import { Outlet, Navigate } from "react-router-dom";
import { SimpleToast } from "./Toast";

const PrivateRoutes = ({ auth, userId }) => {
  useEffect(() => {
    if (auth?.isProfileError) {
      SimpleToast({
        title: "Error",
        text: "Oops, something went wrong.",
        iconName: "error",
        iconColor: "#d33",
      });
    }
  }, [auth?.isProfileError]);

  if (userId && auth?.isProfileLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <div
            className="spinner-border spinner-border text-primary"
            role="status"
          />
        </div>
      </div>
    );
  }

  return Object.keys(auth?.profileData).length ? (
    <Outlet />
  ) : (
    <Navigate to="/" />
  );
};

export default PrivateRoutes;
