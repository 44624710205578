import { ActionTypes } from "../constants";

const initial_state = {
  //signup
  data: {},
  isSignupLoading: false,
  isSignupError: false,
  signupErrorMessage: "",
  //signin
  loginData: {},
  isLoginLoading: false,
  isLoginError: false,
  loginErrorMessage: "",
  //user signup
  userSignupData: {},
  isuserSignupLoading: false,
  isuserSignupError: false,
  userSignupErrorMessage: "",

  //user profile
  profileData: {},
  isProfileLoading: true,
  isProfileError: false,
  profileErrorMessage: "",

  //update profile
  updateProfileData: {},
  isUpdateProfileLoading: false,
  isUpdateProfileError: false,
  updateProfileErrorMessage: "",
};

export const authReducer = (state = initial_state, action) => {
  switch (action.type) {
    //login
    case ActionTypes.SIGNUP_REQ:
      return {
        ...state,
        isSignupLoading: true,
        isSignupError: false,
      };

    case ActionTypes.SIGNUP_SUCCESS:
      localStorage.setItem("userId", action.payload?.data?.insertId);
      return {
        ...state,
        isSignupLoading: false,
        data: action.payload,
      };

    case ActionTypes.SIGNUP_FAIL:
      return {
        ...state,
        isSignupLoading: false,
        isSignupError: true,
        signupErrorMessage: action.payload,
      };

    //user profile

    case ActionTypes.GET_PROFILE_REQ:
      return {
        ...state,
        isProfileLoading: true,
        isProfileError: false,
      };

    case ActionTypes.GET_PROFILE_SUCCESS:
      return {
        ...state,
        isProfileLoading: false,
        profileData: action.payload.data[0],
      };

    case ActionTypes.GET_PROFILE_FAIL:
      return {
        ...state,
        isProfileLoading: false,
        isProfileError: true,
        profileErrorMessage: action.payload,
      };

    case ActionTypes.UPDATE_PROFILE_REQ:
      return {
        ...state,
        isUpdateProfileLoading: true,
        isUpdateProfileError: false,
      };

    case ActionTypes.UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        isUpdateProfileLoading: false,
        updateProfileData: action.payload.data,
        profileData: {
          ...state.profileData,
          ...action.payload.userData,
          ...action.payload.dashboard,
        },
      };

    case ActionTypes.UPDATE_OTHER_MEMBER:
      return {
        ...state,
        isUpdateProfileLoading: false,
        updateProfileData: action.payload.data,
        profileData: {
          ...state.profileData,
        },
      };
    case ActionTypes.UPDATE_PROFILE_FAIL:
      return {
        ...state,
        isUpdateProfileLoading: false,
        isUpdateProfileError: true,
        updateProfileErrorMessage: action.payload,
      };

    case ActionTypes.CELAR_ERROR_MESSAGE:
      return {
        ...state,
        isSignupError: false,
        isProfileError: false,
        isUpdateProfileError: false,
        signupErrorMessage: "",
      };
    default:
      return state;
  }
};
export const loginReduer = (state = initial_state, action) => {
  switch (action.type) {
    case ActionTypes.SIGNIN_REQ:
      return {
        ...state,
        isLoginLoading: true,
        isLoginError: false,
      };

    case ActionTypes.SIGNIN_SUCCESS:
      return {
        ...state,
        isLoginLoading: false,
        loginData: action.payload,
      };

    case ActionTypes.SIGNIN_FAIL:
      return {
        ...state,
        isLoginLoading: false,
        isLoginError: true,
        loginErrorMessage: action.payload,
      };
    case ActionTypes.CELAR_ERROR_MESSAGE:
      return {
        ...state,
        isLoginError: false,
      };
    default:
      return state;
  }
};

export const userSignupReduer = (state = initial_state, action) => {
  switch (action.type) {
    case ActionTypes.USER_SIGNUP_REQ:
      return {
        ...state,
        isuserSignupLoading: true,
        isuserSignupError: false,
      };

    case ActionTypes.USER_SIGNUP_SUCCESS:
      // localStorage.setItem("userId", action.payload?.data?.[0]?.id);
      return {
        ...state,
        isuserSignupLoading: false,
        userSignupData: action.payload,
      };

    case ActionTypes.USER_SIGNUP_FAIL:
      return {
        ...state,
        isuserSignupLoading: false,
        isuserSignupError: true,
        userSignupErrorMessage: action.payload,
      };
    case ActionTypes.CELAR_ERROR_MESSAGE:
      return {
        ...state,
        isuserSignupError: false,
      };
    default:
      return state;
  }
};
