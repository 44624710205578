import React, { useEffect, useState } from "react";

import GoogleCalendarTabs from "./GoogleCalendarTabs";
import GoogleCalendarChart from "./GoogleCalendarChart";
import { SimpleToast } from "../../../utils/Toast";
import axiosApiInstance from '../../../utils/axiosInterceptor'
import moment from "moment";

function GoogleCalendar({ setActive, expand, pinnedCollab, pinnedProd, dashboardId, hasGoogleToken}) {
  const [googleAuth, setGoogleAuth] = useState(false);
  const [isNewData,setIsNewData] = useState(true)
  const [clendarData, setClendarData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [updateData, setUpdateData] = useState({});

  const showGoogleCalendarData = async () => {
    setIsLoading(true);
    let result = [];
    await axiosApiInstance
      .get("https://www.googleapis.com/calendar/v3/calendars/primary/events", {
        params:{
          timeMin: moment().startOf('day').toISOString(),
          timeMax: moment().endOf('day').toISOString(),
          singleEvents: true,
        }
      })
      .then((res) => {
        result = res.data.items;
        res.data.items?.forEach((i, indInner) => {
          let startTime = new Date(
            i.start?.dateTime
          ).toLocaleTimeString();
          let endTime = new Date(
            i.end?.dateTime
          ).toLocaleTimeString();
    
          var eventsAtGivenTime = result.filter((obj => obj.start.dateTime == i.start?.dateTime));
          var eventWidth = 100/eventsAtGivenTime.length;

          var eventAtTimeIndex = eventsAtGivenTime.findIndex((obj => obj.id == i.id))

          i.width = eventWidth;
          i.left = eventAtTimeIndex;
        });
        
        setClendarData(res.data.items);
  
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        if(err.response.status === 400 && err.response.data.error_description === 'Token has been expired or revoked.') return
        SimpleToast({
          title: "Error",
          text: `${err.message}`,
          iconName: "error",
          iconColor: "#d33",
        });
      });
  };

  useEffect(() => {
    if(hasGoogleToken) showGoogleCalendarData();
  }, []);

  return (
    <div className="google-calendar h-100">
      {!googleAuth ? (
        <>
          <GoogleCalendarChart
            setActive={setActive}
            data={clendarData}
            setData={setClendarData}
            setGoogleAuth={setGoogleAuth}
            isLoading={isLoading}
            onSuccess={showGoogleCalendarData}
            setIsLoading={setIsLoading}
            updateData={updateData}
            setUpdateData={setUpdateData}
            setIsNewData={setIsNewData}
            pinnedProd={pinnedProd}
            pinnedCollab={pinnedCollab}
            expand={expand}
          />
        </>
      ) : (
        <GoogleCalendarTabs
          setGoogleAuth={setGoogleAuth}
          onSuccess={showGoogleCalendarData}
          updateData={updateData}
          setUpdateData={setUpdateData}
          isNewData={isNewData}
          setIsNewData={setIsNewData}
          dashboardId={dashboardId}
        />
      )}
    </div>
  );
}

export default GoogleCalendar;
