import React from "react";
import { useSelector } from "react-redux";
import menu4 from "../../assets/images/side-menu-4.png";
import "./index.css";
const NotificationCard = ({ item, acceptNotification, deleteNotification }) => {
  const auth = useSelector((state) => state.Auth);
  const date = new Date(Number(item?.datetime)).toLocaleDateString('en-us', { year:"numeric", month:"numeric", day:"numeric"})

  return (
    <div className="notificaton_container">
      <img alt="side-icon-4" src={menu4} />
      {!item?.status ? (
        <>
          <p>{item?.message}</p>
          <p className="date">{date}</p>
        </>
      ) : (
        <>
          <p>{item?.message}</p>
          <div className="notification_btn">
            {item?.status === "accepted" ? (
              <button style={{ backgroundColor: "var(--primary-color)" }}>
                Accepted
              </button>
            ) : (
              <>
                <button onClick={() => acceptNotification(item)}>Accept</button>
                <button onClick={() => deleteNotification(item)}>Reject</button>
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default NotificationCard;
